import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Roles, UserProfile } from '@vru/master-data';
import { AlertService } from '@vru/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * _OfficerGuard_ is used to check if the user is an officer or not.
 * The officer includes the following roles:
 *  - Admin
 *  - BackOffice
 *  - Dean
 *  - Establishment
 *  - Executive
 *  - Teacher
 */
@Injectable({
  providedIn: 'root',
})
export class OfficerGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private alertService: AlertService,
    private router: Router,
    private store: Store
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.getDestination();
  }

  canActivateChild(): Observable<boolean | UrlTree> {
    return this.getDestination();
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.getDestination();
  }

  checkRole(): Observable<boolean> {
    const permissiveUser: Roles[] = [
      'admin',
      'backoffice',
      'establishment',
      'executive',
      'dean',
      'teacher',
    ];
    return this.store
      .selectOnce<UserProfile>((state) => state.auth)
      .pipe(
        map((user) => {
          const canAccept = permissiveUser.includes(user.role);
          if (!canAccept) {
            this.alertService.noPermit();
          }
          return canAccept;
        })
      );
  }

  getDestination(): Observable<boolean | UrlTree> {
    return this.checkRole().pipe(
      map((canAccept) => canAccept || this.router.parseUrl('/'))
    );
  }
}
