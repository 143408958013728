export enum thaiFullMonth {
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
}

export const thaiFullMonthDropdown = [
  { label: thaiFullMonth[0], value: 1 },
  { label: thaiFullMonth[1], value: 2 },
  { label: thaiFullMonth[2], value: 3 },
  { label: thaiFullMonth[3], value: 4 },
  { label: thaiFullMonth[4], value: 5 },
  { label: thaiFullMonth[5], value: 6 },
  { label: thaiFullMonth[6], value: 7 },
  { label: thaiFullMonth[7], value: 8 },
  { label: thaiFullMonth[8], value: 9 },
  { label: thaiFullMonth[9], value: 10 },
  { label: thaiFullMonth[10], value: 11 },
  { label: thaiFullMonth[11], value: 12 },
];

export enum Day {
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
}

export enum ThaiDay {
  'จันทร์',
  'อังคาร',
  'พุธ',
  'พฤหัส',
  'ศุกร์',
  'เสาร์',
  'อาทิตย์',
}

export enum ThaiShortDay {
  'จ.',
  'อ.',
  'พ.',
  'พฤ.',
  'ศ.',
  'ส.',
  'อา.',
}

export const thaiFullDayDropdown = [
  { label: ThaiDay[0], value: Day[0] },
  { label: ThaiDay[1], value: Day[1] },
  { label: ThaiDay[2], value: Day[2] },
  { label: ThaiDay[3], value: Day[3] },
  { label: ThaiDay[4], value: Day[4] },
  { label: ThaiDay[5], value: Day[5] },
  { label: ThaiDay[6], value: Day[6] },
];