import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService, DrfList } from '@vru/master-data';
import { CreateStudentCreditTransfer, CreditTransferParams, CurrentAcademicCalendars, StudentCreditTransferModel } from '../model/student-credit-transfer.model';

@Injectable({
  providedIn: 'root'
})
export class StudentCreditTransferService {

  private readonly studentCreditTransferUrl = '/student-transferred-courses/';
  private readonly calendarsUrl = '/academic-calendars/current-semester/';

  constructor(
    private apiService: ApiService
  ) { }

  onGetStudentCreditTransfer(param?: CreditTransferParams): Observable<DrfList<StudentCreditTransferModel>> {
    return this.apiService.get<DrfList<StudentCreditTransferModel>>(this.studentCreditTransferUrl, param);
  }

  onGetStudentCreditTransferById(id: number): Observable<number> {
    return this.apiService.get<number>(this.studentCreditTransferUrl + `${id}`);
  }

  onCreateStudentCreditTransfer(data: CreateStudentCreditTransfer): Observable<CreateStudentCreditTransfer> {
    return this.apiService.post<CreateStudentCreditTransfer>(this.studentCreditTransferUrl, data);
  }

  onUpdateStudentCreditTransfer(id: number, data: CreateStudentCreditTransfer): Observable<CreateStudentCreditTransfer> {
    return this.apiService.patch<CreateStudentCreditTransfer>(this.studentCreditTransferUrl + `${id}/`, data);
  }

  onDeleteStudentCreditTransfer(id: number): Observable<number> {
    return this.apiService.delete<number>(this.studentCreditTransferUrl + `${id}`);
  }

  getCalendar(): Observable<CurrentAcademicCalendars> {
    return this.apiService.get<CurrentAcademicCalendars>(this.calendarsUrl);
  }

}
