<form class="position-relative"
    [formGroup]="permissionForm"
    (ngSubmit)="onSubmit()">
  <div class="frame-filter mt-4">
    <div class="d-flex justify-content-center"
        [class.spinner-blur-effect]="isLoading">
      <table class="table">
        <thead>
          <tr>
            <th colspan="2">ตั้งค่าสิทธิ์ในการใช้งาน</th>
          </tr>
        </thead>
        <tbody>
          <tr [class.isReadOnly]="readOnly">
            <td>ใช้งานระบบได้ปกติ</td>
            <td class="text-right">
              <div class="custom-control custom-switch"
                  (click)="$event.stopPropagation()">
                <input type="checkbox"
                    class="custom-control-input"
                    id="system-toggle"
                    formControlName="can_login"
                    [readOnly]="readOnly">
                <label class="custom-control-label"
                    for="system-toggle">{{ getToggleLabel('can_login') }}</label>
              </div>
            </td>
          </tr>
          <tr [class.isReadOnly]="readOnly">
            <td>ลงทะเบียนได้</td>
            <td class="text-right">
              <div class="custom-control custom-switch"
                  (click)="$event.stopPropagation()">
                <input type="checkbox"
                    class="custom-control-input"
                    id="enroll-toggle"
                    formControlName="can_enroll"
                    [readOnly]="readOnly">
                <label class="custom-control-label"
                    for="enroll-toggle">{{ getToggleLabel('can_enroll') }}</label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="container-spinner-float">
      <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
          name="permission-settings"
          color="#3cd070"
          size="medium"
          type="ball-clip-rotate-pulse"
          [fullScreen]="false"
          [showSpinner]="isLoading">
      </ngx-spinner>
    </div>
  </div>
  <div *ngIf="!readOnly"
      class="d-flex justify-content-end align-items-end pt-3">
    <button type="button"
        class="btn btn-outline-green-vru mr-2"
        [ladda]="isSaving">
      ยกเลิก
    </button>
    <button type="submit"
        class="btn btn-green-vru"
        [ladda]="isSaving">
      บันทึก
    </button>
  </div>
</form>