import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DrfList } from '../common.model';
import {
  Examination,
  ExaminationListParams,
  ExaminationUpdate,
} from './examination.model';

@Injectable({
  providedIn: 'root',
})
export class ExaminationService {
  examinationPath = '/examination-managements/';

  constructor(private api: ApiService) {}

  getList(params?: ExaminationListParams): Observable<DrfList<Examination>> {
    return this.api.get<DrfList<Examination>>(this.examinationPath, params);
  }

  post(payload: Omit<ExaminationUpdate, 'id'>): Observable<unknown> {
    // TODO: Assign type of retrieving
    return this.api.post(this.examinationPath, payload);
  }

  patch(id: number, payload: ExaminationUpdate): Observable<unknown> {
    // TODO: Assign type of retrieving
    return this.api.patch(this.examinationPath + `${id}/`, payload);
  }
}
