<ng-container *ngIf="chatRoom; else elseTemplate">
  <div class="chat-container d-flex flex-column h-100 border">
    <div class="d-flex justify-content-between p-2 shadow">
      <div class="col d-flex flex-column justify-content-center overflow-hidden">
        <h6 class="text-truncate mb-0">
          <ng-container *ngIf="chatRoom.users.length === 1; else moreThanTwoUserTemplate">
            <ng-container *ngFor="let user of chatRoom.users">
              {{ user.first_name + ' ' + user.last_name + ' ' }}
            </ng-container>
          </ng-container>
          <ng-template #moreThanTwoUserTemplate>
            <ng-container *ngFor="let user of chatRoom.users; let j=index">
              <ng-container *ngIf="(chatRoom.users.length - 1) !== j; else lastUserTemplate">
                {{ user.first_name + ' ' + user.last_name + ', ' }}
              </ng-container>
              <ng-template #lastUserTemplate>
                {{ user.first_name + ' ' + user.last_name }}
              </ng-template>
            </ng-container>
          </ng-template>
        </h6>
      </div>
      <vru-carbon-icon iconName="close"
          size="35"
          presetFilter="fill-gray"
          (click)="onCloseChatRoom()"></vru-carbon-icon>
    </div>
    <div id="messages-box"
        class="chat-container flex-fill d-flex flex-column-reverse bg-light overflow-auto"
        (scroll)="onMessageScrollEnd($event)"
        [class.spinner-blur-effect]="isLoading">
      <div (resized)="setScrollbarElementBottom()">
        <div class="d-flex flex-column-reverse mt-auto">
          <ng-container *ngFor="let messages of chatMessageList">
            <ng-container *ngIf="messages.sender.id === user; else otherUserMessageTemplate">
              <div class="d-flex align-items-end justify-content-end px-3 pb-2 pt-0 w-75 ml-auto">
                <div class="d-flex flex-column no-gutters"
                    (click)="onSelectMessages(messages)">
                  <div class="d-flex flex-wrap chat-messages px-3 py-2 shadow-sm user-messages">
                    <span class="text-break">{{ messages.message }}</span>
                  </div>
                </div>
                <ng-container *ngIf="messages.sender.photo; else noPhoto">
                  <div class="ml-2">
                    <img class="rounded rounded-circle shadow-sm profile-chat"
                        [src]="messages.sender.photo"
                        [ngbTooltip]="messageDate"
                        container="body">
                  </div>
                </ng-container>
                <ng-template #noPhoto>
                  <div class="ml-2">
                    <vru-carbon-icon class="bg-green-vru"
                        iconName="user-avatar-filled"
                        presetFilter="fill-green-vru"
                        size="26"
                        [ngbTooltip]="messageDate"
                        container="body"></vru-carbon-icon>
                  </div>
                </ng-template>
              </div>
            </ng-container>
            <ng-template #otherUserMessageTemplate>
              <div class="d-flex align-items-end px-3 pb-2 pt-0 w-75">
                <ng-container *ngIf="messages.sender.photo; else noPhoto">
                  <div class="mr-2">
                    <img class="rounded rounded-circle shadow-sm profile-chat"
                        [src]="messages.sender.photo"
                        [ngbTooltip]="messageDate"
                        container="body">
                  </div>
                </ng-container>
                <ng-template #noPhoto>
                  <div class="mr-2">
                    <div class="rounded rounded-circle d-flex bg-green-vru justify-content-center align-items-center">
                      <vru-carbon-icon class="rounded rounded-circle bg-white border-icon"
                          iconName="user-avatar-filled"
                          presetFilter="fill-green-vru"
                          size="24"
                          [ngbTooltip]="messageDate"
                          container="body"></vru-carbon-icon>
                    </div>
                  </div>
                </ng-template>
                <div class="d-flex flex-column no-gutters"
                    (click)="onSelectMessages(messages)">
                  <div class="d-flex flex-wrap chat-messages bg-white px-3 py-2 shadow-sm">
                    <span class="text-break">{{ messages.message }}</span>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template #messageDate>
              <div class="d-flex flex-column no-gutters">
                <span class="col-12">{{ parseDateLabel(messages.created_at)[0] }}</span>
                <span class="col-12">{{ parseDateLabel(messages.created_at)[1] }}</span>
                <span class="col-12">{{ messages.sender.first_name + ' ' + messages.sender.last_name }}</span>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="container-spinner-float">
      <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
          name="messages-loading"
          color="#3cd070"
          size="medium"
          type="ball-clip-rotate-pulse"
          [fullScreen]="false"
          [showSpinner]="isLoading">
      </ngx-spinner>
    </div>
    <div class="d-flex align-items-center p-3 shadow-sm">
      <input type="text"
          class="form-control rounded-pill chat-input-size"
          placeholder="พิมพ์ข้อความ"
          [(ngModel)]="messages"
          (keyup.enter)="onSendChatMessage()">
      <vru-carbon-icon class="pl-2"
          iconName="send"
          size="28"
          presetFilter="fill-green-vru"
          (click)="onSendChatMessage()"></vru-carbon-icon>
    </div>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="flex-fill d-flex flex-column justify-content-center align-items-center border h-100">
    <vru-carbon-icon iconName="chat"
        size="200"
        presetFilter="fill-green-vru"></vru-carbon-icon>
    <h1 class="text-secondary">เลือกกล่องข้อความ</h1>
  </div>
</ng-template>