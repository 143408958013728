import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  IMasterDataDegrees,
  IMasterDataFaculty,
  IModel,
  IMasterDataQualifications,
  IMasterDataNameTitles,
  IMasterDataStudentTypes,
  IMasterDataSyllabus,
  IMasterDataStudyFieldGroups,
  IMasterDataRaces,
  IMasterDataNationality,
  IMasterDataReligions,
  IMasterDataProvinces,
  ISubjects,
  IMasterDataStudentGroup,
  TeacherModel,
} from '../master-data';
import { ApiService } from '../api/api.service';
import { SubjectGroup } from '../education/subject-group.model';
import { Teacher, TeacherExpand } from '../education/teacher/teacher.model';

@Injectable({
  providedIn: 'root',
})
export class MasterDataApiService {
  constructor(private http: ApiService) { }

  //ระดับการศึกษา
  getDropdownDegrees() {
    return this.http.get<IModel<IMasterDataDegrees>>('/degrees/').pipe(map((item) => item.results));
  }

  //คณะ
  getDropdownFaculty() {
    return this.http.get<IModel<IMasterDataFaculty>>('/faculties/').pipe(map((item) => item.results));
  }

  //วุฒิ
  getDropdownQualifications() {
    return this.http.get<IModel<IMasterDataQualifications>>('/qualifications/').pipe(map((item) => item.results));
  }

  //คำนำหน้า
  getDropdownNameTitles() {
    return this.http.get<IModel<IMasterDataNameTitles>>('/name-titles/').pipe(map((item) => item.results));
  }

  //ประเภทนักศึกษา
  getDropdownStudentTypes() {
    return this.http.get<IModel<IMasterDataStudentTypes>>('/student-types/').pipe(map((item) => item.results));
  }

  //สาขาวิชา
  getDropdownSyllabus() {
    return this.http.get<IModel<IMasterDataSyllabus>>('/programs/').pipe(map((item) => item.results));
  }

  //กลุ่มสาขาวิชา
  getDropdownStudyFieldGroups() {
    return this.http.get<IModel<IMasterDataStudyFieldGroups>>('/field-of-study-groups/').pipe(map((item) => item.results));
  }

  //กลุ่มวิชา
  getDropdownSubjectGroup() {
    return this.http.get<IModel<SubjectGroup>>('/course-groups/').pipe(map((item) => item.results));
  }

  //รายวิชา
  getDropdownSubject(params?: {[k: string]: string[]}) {
    return this.http.get<IModel<ISubjects>>('/courses/', params).pipe(map((item) => item.results));
  }

  //เชื้อชาติ
  getDropdownRaces() {
    return this.http.get<IModel<IMasterDataRaces>>('/races/').pipe(map((item) => item.results));
  }

  //สัญชาติ
  getDropdownNationality() {
    return this.http.get<IModel<IMasterDataNationality>>('/nationalities/').pipe(map((item) => item.results));
  }

  //ศาสนา
  getDropdownReligion() {
    return this.http.get<IModel<IMasterDataReligions>>('/religions/').pipe(map((item) => item.results));
  }

  //จังหวัด
  getDropdownProvince() {
    return this.http.get<IModel<IMasterDataProvinces>>('/provinces/').pipe(map((item) => item.results));
  }

  //อาจารย์
  getDropdownAdvisor<T extends TeacherExpand | void>(params?: {[k: string]: string[] | number | string}) {
    return this.http.get<IModel<Teacher<T>>>('/teachers/', params).pipe(map((item) => item.results));
  }

  //อาจารย์
  getDropdownTeacher(params?: {[k: string]: string[]}) {
    return this.http.get<IModel<TeacherModel>>('/teachers/', params).pipe(map((item) => item.results));
  }

  //หมู่เรียน
  getDropdownStudentGroup() {
    return this.http.get<IModel<IMasterDataStudentGroup>>('/student-sections/').pipe(map((item) => item.results));
  }
}
