import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DrfList, Nullable } from '../common.model';
import { PendingPaymentStudentParams } from '../person/student.model';
import { PendingPaymentUser } from '../user/user.model';
import {
  PaymentInfo,
  PaymentReceiptListItem,
  PaymentReceiptParams,
  PaymentReceiptRequest,
  PendingPayment,
  PendingPaymentParams,
  Receipt,
  ReceiptPost,
  TuitionLateFines,
  TuitionLateFinesParams,
} from './payment.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  readonly paymentPath = 'payments';
  readonly receiptsPath = 'receipts';
  readonly pendingPaymentPath = 'pending-payments';
  readonly userPendingPaymentPath = 'user-pending-payments';

  constructor(private api: ApiService) {}

  createReceipt(payload: ReceiptPost): Observable<Receipt> {
    return this.api.post('/receipts/', payload);
  }

  deleteFee(id: number) {
    return this.api.delete([this.paymentPath, id.toString()]);
  }

  getPendingPayments(
    params?: PendingPaymentParams
  ): Observable<PendingPayment> {
    return this.api.get(this.pendingPaymentPath, params);
  }

  getPendingReceipt(id: number): Observable<PaymentInfo> {
    return this.api.get([this.pendingPaymentPath, `receipts`, id.toString()]);
  }

  getPendingUserPayment(id: number): Observable<PaymentInfo> {
    return this.api.get([
      this.pendingPaymentPath,
      `user-payments`,
      id.toString(),
    ]);
  }

  getReceipts(
    params?: Nullable<PaymentReceiptParams>
  ): Observable<DrfList<PaymentReceiptListItem>> {
    return this.api.get<DrfList<PaymentReceiptListItem>>(
      this.receiptsPath,
      params
    );
  }

  getUserPendingPayments(
    params: PendingPaymentStudentParams
  ): Observable<DrfList<PendingPaymentUser>> {
    return this.api.get(this.userPendingPaymentPath, params);
  }

  getTuitionLateFines(
    params?: TuitionLateFinesParams
  ): Observable<TuitionLateFines | null> {
    return this.api.get<TuitionLateFines>(
      '/academic-calendars/calculate-fine/',
      params
    );
  }

  issueReceipt(payload: PaymentReceiptRequest): Observable<Blob> {
    return this.api.postBlob([this.receiptsPath, '/issue/'], payload);
  }
}
