<div class="component-container">
  <div class="d-flex align-items-center">
    <vru-svg-label [backgroundColor]="backgroundColor"
        [labelHeight]="labelHeight"
        [labelWidth]="labelWidth"
        [title]="title"
        [titleColor]="titleColor"
        [titleSize]="titleSize">
    </vru-svg-label>
  </div>
  <div class="col d-flex align-items-center">
    <div *ngFor="let tabConfig of tabConfigs; index as index"
        class="px-2 d-inline-block">
      <a class="context"
          [style.color]="tabConfig.value === selectedTab ? styleConfig.activeColor : styleConfig.color"
          [style.text-decoration]="tabConfig.value === selectedTab ? 'underline' : 'none'"
          (click)="onTabClick(index)">
        {{ tabConfig.title }}
      </a>
    </div>
  </div>
</div>
