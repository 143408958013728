import { Component, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimePickerAdapter } from '@vru/utils';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vru-input-datetime',
  templateUrl: './input-datetime.component.html',
  styleUrls: ['./input-datetime.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputDatetimeComponent,
    },
    { provide: NgbTimeAdapter, useClass: NgbTimePickerAdapter },
  ],
})
export class InputDatetimeComponent implements ControlValueAccessor {
  @Input() placeholder = 'dd/mm/yyyy';

  formGroup = new FormGroup({
    date: new FormControl(),
    time: new FormControl(),
  });

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  touch = () => {};

  registerOnChange(fn: () => void): void {
    this.formGroup.valueChanges
      .pipe(
        map((res: DateTimeFormGroup) => {
          let dateTime: string | null = null;
          if (res.date) {
            dateTime = res.date;
          }
          if (res.time) {
            dateTime = dateTime == null ? res.time : dateTime + `T${res.time}`;
          }
          return dateTime;
        })
      )
      .subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.touch = fn;
  }

  writeValue(dateTime: string): void {
    console.log('dateTime', dateTime);
    let dateAndTime: (string | null)[] = [null, null];
    if (dateTime) {
      dateAndTime = dateTime.split('T');
    }
    console.log('dateAndTime', dateAndTime);
    this.formGroup.patchValue({ date: dateAndTime[0], time: dateAndTime[1] });
  }
}

interface DateTimeFormGroup {
  date: string;
  time: string;
}
