import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { API_CONFIG } from '@vru/master-data';
import { LaddaModule } from 'angular2-ladda';
import { environment } from '../environments/environment';
import { NgbVruDateParserFormatter } from '../shared/service/ngDateParser';
import { CustomNgbDateAdapter } from '../shared/service/ngb-datepicker-adapter';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { CommonInterceptor } from './core/interceptors/common.interceptor';
import { AlertModalComponent } from './modules/modal/alert-modal/alert-modal.component';
import { NewsFeedModule } from './modules/news-feed/news-feed.module';

@NgModule({
  declarations: [AppComponent, AlertModalComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    LaddaModule.forRoot({
      style: 'zoom-in',
      spinnerSize: 30,
    }),
    NewsFeedModule,
    RouterModule.forRoot([], { initialNavigation: 'enabledBlocking' }),
  ],
  providers: [
    {
      provide: API_CONFIG,
      useValue: {
        apiEndpoint: environment.baseUrl,
      },
    },
    { provide: HTTP_INTERCEPTORS, useClass: CommonInterceptor, multi: true },
    { provide: NgbDateAdapter, useClass: CustomNgbDateAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbVruDateParserFormatter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
