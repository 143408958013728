import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'vru-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  @Input() header = 'โปรดยืนยัน';
  @Input() theme: 'danger' | 'green-vru' | 'warning' = 'green-vru'
  @Input() body?: string;

  constructor(public activeModal: NgbActiveModal) {}
}
