<div class="page-container position-relative pt-3 pt-sm-5 d-flex flex-column no-gutters">
  <div class="board-white flex-fill py-3 py-md-4 py-lg-5 px-2 px-sm-3 px-lg-5 fade-in"
      [class.spinner-blur-effect]="isLoading">
    <div class="d-flex flex-column flex-fill no-gutters">
      <div class="col-12 d-flex flex-wrap no-gutters align-items-center">
        <vru-carbon-icon iconName="back"
            (click)="goBack()"></vru-carbon-icon>
        <h5 class="mb-0 pointer"
            (click)="goBack()">ย้อนกลับ</h5>
      </div>
      <ng-container *ngIf="newsInformation; else noNewsInformantion">
        <div class="col-12 d-flex flex-column flex-wrap flex-fill no-gutters">
          <div class="col-12 d-flex justify-content-center flex-wrap no-gutters py-3 py-lg-5">
            <img [src]="newsInformation.cover_photo"
                class="img-post mr-auto ml-auto pointer rounded">
          </div>
          <div class="d-flex flex-column flex-wrap pb-3">
            <h2>{{ newsInformation.title }}</h2>
          </div>
          <div class="d-flex flex-wrap flex-fill overflow-hidden">
            <div [innerHTML]="newsInformation.detail | safeHtml"></div>
          </div>
          <div class="d-flex justify-content-end">
            <vru-carbon-icon iconName="calendar"
                presetFilter="fill-gray"></vru-carbon-icon>
            <span>{{ newsInformation.since }}</span>
          </div>
        </div>
      </ng-container>
      <ng-template #noNewsInformantion>
        <div class="d-flex flex-column justify-content-center p-5 not-found-page align-items-center">
          <vru-carbon-icon iconName="border-none"
              size="100"
              presetFilter="fill-gray"></vru-carbon-icon>
          <h2 class="text-secondary text-center pt-5 mb-0">ไม่พบข่าวสำหรับท่าน</h2>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="container-spinner-float">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
        name="new-detail"
        color="#3cd070"
        size="medium"
        type="ball-clip-rotate-pulse"
        [fullScreen]="false"
        [showSpinner]="isLoading">
    </ngx-spinner>
  </div>
</div>