import {
  AppealRequest,
  AppealTemplateParams,
  AppealTemplate,
  AppealTemplatesDetail,
  AppealTemplateType,
  AppealRequestDetail,
  AppealRequestReject,
  AppealTemplateTypeParams,
  AppealEditStatusParam,
  AppealReportParams,
  AppealFinanceReportParams,
  AppealPaidAction
} from './appeal.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService, DrfList } from '@vru/master-data';

@Injectable({
  providedIn: 'root',
})
export class AppealService {
  private readonly appealTemplateTypes = '/appeal-template-types/';
  private readonly appealTemplate = '/appeal-templates/';
  private readonly appealRequest = '/appeals/';
  private readonly appealRequestTask = 'my-tasks/';
  private readonly appealRequestReport =
    this.appealRequest + 'appeals-range-report/';
  private readonly appealFinanceReport = '/receipts/appeal-report';
  private readonly appealFileAdditionalUrl = '/upload-additional-documents/';

  private readonly appealActionMissingDocument = '/missing-documents/';
  private readonly appealActionTerminate = '/terminate/';
  private readonly appealActionPublish = '/publish/';
  private readonly appealActionApprove = '/approve/';
  private readonly appealActionReject = '/reject/';
  private readonly appealActionDocumentPickup = '/document-pickup/';
  private readonly appealActionDocumentRevise = '/revise/';
  private readonly appealActionDocumentPaid = '/paid/';
  private readonly appealActionChangeStatus = '/change-status/';

  constructor(private apiService: ApiService) {}

  // AppealTemplateTypes Service
  getAppealTemplateTypes(
    params?: AppealTemplateTypeParams
  ): Observable<DrfList<AppealTemplateType>> {
    return this.apiService.get<DrfList<AppealTemplateType>>(
      this.appealTemplateTypes,
      params
    );
  }

  postAppealTemplateTypes(
    params: AppealTemplateType
  ): Observable<AppealTemplateType> {
    return this.apiService.post<AppealTemplateType>(
      this.appealTemplateTypes,
      params
    );
  }

  patchAppealTemplateTypes(
    id: number,
    params: AppealTemplateType
  ): Observable<AppealTemplateType> {
    return this.apiService.patch<AppealTemplateType>(
      this.appealTemplateTypes + `${id}/`,
      params
    );
  }

  patchAppealRequestAdditionalFiles(id: number, params: FormData) {
    return this.apiService.patch<AppealRequestDetail>(
      this.appealRequest + `${id}` + this.appealFileAdditionalUrl,
      params
    );
  }

  deleteAppealTemplateTypes(id: number) {
    return this.apiService.delete(this.appealTemplateTypes + `${id}/`);
  }

  // AppealTemplate Service
  getAppealTemplate(
    params?: AppealTemplateParams
  ): Observable<DrfList<AppealTemplate>> {
    return this.apiService.get<DrfList<AppealTemplate>>(
      this.appealTemplate,
      params
    );
  }

  getAppealTemplateById(id: number): Observable<AppealTemplatesDetail> {
    return this.apiService.get<AppealTemplatesDetail>(
      this.appealTemplate + `${id}/`
    );
  }

  postAppealTemplate(
    params: AppealTemplatesDetail
  ): Observable<AppealTemplatesDetail> {
    return this.apiService.post<AppealTemplatesDetail>(
      this.appealTemplate,
      params
    );
  }

  patchAppealTemplate(
    id: number,
    params: AppealTemplatesDetail | FormData
  ): Observable<AppealTemplatesDetail> {
    return this.apiService.patch<AppealTemplatesDetail>(
      this.appealTemplate + `${id}/`,
      params
    );
  }

  deleteAppealTemplate(id: number) {
    return this.apiService.delete(this.appealTemplate + `${id}/`);
  }

  // AppealRequest Service
  getAppealRequest(
    params?: AppealTemplateParams
  ): Observable<DrfList<AppealRequest>> {
    return this.apiService.get<DrfList<AppealRequest>>(
      this.appealRequest,
      params
    );
  }

  getAppealRequestByID(id: number): Observable<AppealRequestDetail> {
    return this.apiService.get<AppealRequestDetail>(
      this.appealRequest + `${id}/`
    );
  }

  getAppealTemplateReport(id: number): Observable<File> {
    return this.apiService.getBlob<File>(
      this.appealRequest + `${id}/appeals-detail-report/`
    );
  }

  postAppealRequest(params: FormData): Observable<AppealRequest> {
    return this.apiService.post<AppealRequest>(this.appealRequest, params);
  }

  patchAppealRequest(id: number, params: FormData): Observable<AppealRequest> {
    return this.apiService.patch<AppealRequest>(
      this.appealRequest + `${id}/`,
      params
    );
  }

  deleteAppealRequest(id: number) {
    return this.apiService.delete(this.appealTemplateTypes + `${id}/`);
  }

  getAppealRequestReport(params?: AppealReportParams): Observable<File> {
    return this.apiService.getBlob<File>(this.appealRequestReport, params);
  }

  getAppealFinanceReport(params?: AppealFinanceReportParams): Observable<File> {
    return this.apiService.getBlob<File>(this.appealFinanceReport, params);
  }

  // AppealRequest Service
  getAppealRequestTask(
    params?: AppealTemplateParams
  ): Observable<DrfList<AppealRequest>> {
    return this.apiService.get<DrfList<AppealRequest>>(
      this.appealRequest + this.appealRequestTask,
      params
    );
  }

  // Appeal Approval Action
  postAppealRequestMissingDocument(
    id: number,
    params?: AppealRequest
  ): Observable<AppealRequest> {
    return this.apiService.post<AppealRequest>(
      this.appealRequest + `${id}` + this.appealActionMissingDocument,
      params
    );
  }

  postAppealRequestTerminate(
    id: number,
    params?: AppealRequest
  ): Observable<AppealRequest> {
    return this.apiService.post<AppealRequest>(
      this.appealRequest + `${id}` + this.appealActionTerminate,
      params
    );
  }

  postAppealRequestPublish(
    id: number,
    params?: AppealRequest
  ): Observable<AppealRequest> {
    return this.apiService.post<AppealRequest>(
      this.appealRequest + `${id}` + this.appealActionPublish,
      params
    );
  }

  postAppealRequestApprove(
    id: number,
    params?: AppealRequest
  ): Observable<AppealRequest> {
    return this.apiService.post<AppealRequest>(
      this.appealRequest + `${id}` + this.appealActionApprove,
      params
    );
  }

  postAppealRequestReject(
    id: number,
    params: AppealRequestReject
  ): Observable<AppealRequest> {
    return this.apiService.post<AppealRequest>(
      this.appealRequest + `${id}` + this.appealActionReject,
      params
    );
  }

  postAppealRequestDocumentPickup(
    id: number,
    params?: AppealRequest
  ): Observable<AppealRequest> {
    return this.apiService.post<AppealRequest>(
      this.appealRequest + `${id}` + this.appealActionDocumentPickup,
      params
    );
  }

  postAppealRequestDocumentRevise(
    id: number,
    params?: AppealRequest
  ): Observable<AppealRequest> {
    return this.apiService.post<AppealRequest>(
      this.appealRequest + `${id}` + this.appealActionDocumentRevise,
      params
    );
  }

  postAppealRequestDocumentPaid(
    id: number,
    params?: AppealRequest
  ): Observable<AppealPaidAction[]> {
    return this.apiService.post<AppealPaidAction[]>(
      this.appealRequest + `${id}` + this.appealActionDocumentPaid,
      params
    );
  }

  postAppealRequestEditStatus(
    id: number,
    params?: AppealEditStatusParam
  ): Observable<{ id: number }> {
    return this.apiService.post<{ id: number }>(
      this.appealRequest + `${id}` + this.appealActionChangeStatus,
      params
    );
  }
}
