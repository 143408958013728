import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import {
  AlertMessages,
  CourseCategoryChange,
  CourseResult,
  Dropdown,
  ErrorMessages,
  GradeRecordEnrollment,
  GraduateEmploymentService,
  GraduateGradeRecord,
  StudentApiService,
  StudentEmploymentModel,
  StudentGraduateQualification,
  StudentUserContext,
  StudyPlanApiService,
  UserProfile,
} from '@vru/master-data';
import { AlertService } from '@vru/services';
import { DictGeneratorService } from '@vru/utils';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { JoiningGraduationCeremonyBlockComponent } from '../joining-graduation-ceremony-block/joining-graduation-ceremony-block.component';
import { StudentInformationConfig } from '../student-information.config';

@Component({
  selector: 'vru-graduation-verification',
  templateUrl: './graduation-verification.component.html',
  styleUrls: ['./graduation-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraduationVerificationComponent implements OnInit {
  @ViewChild('adjustCourseModal') adjustCourseModal!: ElementRef;

  courseGroupDropdown: CustomGradeRecord['course_structure'][] = [];
  courseDisplayDropdown: Dropdown<string>[] = [
    { label: 'แสดงเฉพาะรายวิชาที่ลงทะเบียน', value: 'only_enrollment' },
    { label: 'แสดงรายวิชาในหลักสูตรทั้งหมด', value: 'whole_program' },
  ];
  isSelectCourse = false;

  employmentStatus?: StudentEmploymentModel;

  adjustCourseForm!: FormGroup;
  creditInformationKeyPair: KeyPair[] = [
    { label: 'หน่วยกิต', key: 'creditBalanceStr' },
    { label: 'หน่วยกิตที่ผ่าน', key: 'program_credits' },
    { label: 'หน่วยกิตต่ำสุด', key: 'minimum_credit_to_graduate' },
    { label: 'GPA ต่ำสุด', key: 'minimum_grade_to_graduate' },
    { label: 'หน่วยกิตที่ลง', key: 'registered_credits' },
    { label: 'GPAX', key: 'gpaxStr' },
    { label: 'เกียรตินิยม', key: 'honor' },
  ];
  isLoadingCategoryChange = false;
  isLoadingJoiningCeremony = false;
  graduateQualification?: GraduateQualification;
  selectedDisplay = 'only_enrollment';
  studentId!: number;
  user!: UserProfile;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private config: StudentInformationConfig,
    private dictGen: DictGeneratorService,
    private ngbModal: NgbModal,
    private form: FormBuilder,
    private alert: AlertService,
    private store: Store,
    private studentApi: StudentApiService,
    private router: Router,
    private studyPlanApi: StudyPlanApiService,
    private employmentApi: GraduateEmploymentService
  ) {
    this.subscribeUserStore();
  }

  ngOnInit() {
    this.getGraduateEmployments();
    this.subscribeRouteParams();
    this.initialAdjustCourseForm();
  }

  cancelChangeCategorySelection(): void {
    this.isSelectCourse = false;
  }

  defineCreditFunctions(
    graduateQualification: StudentGraduateQualification
  ): void {
    Object.defineProperty(graduateQualification, 'creditBalance', {
      get: function () {
        return this.program_credits - this.minimum_credit_to_graduate;
      },
    });
    Object.defineProperty(graduateQualification, 'creditBalanceStr', {
      get: function () {
        const creditBalance = this.creditBalance;
        let msg = `ครบ ${this.minimum_credit_to_graduate} หน่วยกิต`;
        const credit = Math.abs(creditBalance);
        if (creditBalance > 0) {
          msg = `เกิน ${credit} หน่วยกิต`;
        } else if (creditBalance < 0) {
          msg = `ขาด ${credit} หน่วยกิต`;
        }
        return msg;
      },
    });
    Object.defineProperty(graduateQualification, 'isGraduate', {
      get: function (): boolean {
        const isGradePass = this.gpax >= this.minimum_grade_to_graduate;
        const isCreditPass =
          this.total_program_credits >= this.minimum_credit_to_graduate;
        return !!(isGradePass && isCreditPass);
      },
    });
    Object.defineProperty(graduateQualification, 'gpaxStr', {
      get: function (): string {
        return this.gpax?.toFixed(2);
      },
    });
  }

  fetchCourseGroup(): void {
    const retrieveGradeRecord = (gradeRecord?: CustomGradeRecord[]) => {
      return (
        gradeRecord?.reduce((dropdown, gradeRecord) => {
          dropdown.push(gradeRecord.course_structure);
          if (gradeRecord.children?.length) {
            const subDropdown = retrieveGradeRecord(gradeRecord.children);
            dropdown.push(...subDropdown);
          }
          return dropdown;
        }, [] as CustomGradeRecord['course_structure'][]) || []
      );
    };
    this.courseGroupDropdown =
      retrieveGradeRecord(this.graduateQualification?.grade_record) || [];
    const electiveCourseGroup = this.courseGroupDropdown.find((courseGroup) => {
      const nameEng = courseGroup.course_group?.name_eng;
      if (nameEng) return /elective/i.test(nameEng);
      return;
    });
    if (!electiveCourseGroup) {
      this.courseGroupDropdown.push({
        course_group_name: '(หมวดวิชาเลือกเสรี)',
        indent: 0,
        number: '',
        sequence: this.courseGroupDropdown.length - 1,
        course_group: null,
      });
    }
  }

  getGraduateEmployments(): void {
    this.employmentApi.getGraduateEmploymentStatus().subscribe({
      next: (res) => {
        this.employmentStatus = res;
        this.cdRef.detectChanges();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  initialAdjustCourseForm() {
    this.adjustCourseForm = this.form.group({
      course: [[], Validators.required],
      group_course: [null, Validators.required],
    });
  }

  makeUpGraduateQualification(
    qualification: StudentGraduateQualification
  ): GraduateQualification {
    const makeRecords = (gradeRecord: CustomGradeRecord) => {
      gradeRecord.course_enrollments.forEach((enrolledCourse) => {
        let records: CustomGradeRecordEnrollment['records'] = [];
        records = records.concat(enrolledCourse.enrollments || []);
        records = records.concat(
          enrolledCourse.student_transferred_courses || []
        );
        records.sort((a, b) => {
          a.academic_year ??= 0;
          b.academic_year ??= 0;
          a.semester ??= 0;
          b.semester ??= 0;
          if (a.academic_year !== b.academic_year) {
            return a.academic_year - b.academic_year;
          } else {
            return a.semester - b.semester;
          }
        });
        enrolledCourse.records = records;
      });
      if (gradeRecord.children) {
        gradeRecord.children.forEach((subGradeRecord) => {
          makeRecords(subGradeRecord);
        });
      }
    };
    (qualification as GraduateQualification).grade_record.forEach(
      (gradeRecord) => {
        makeRecords(gradeRecord);
      }
    );
    return qualification as GraduateQualification;
  }

  navigateToStudentEmployment() {
    const path = this.config.graduateEmploymentPath;
    if (path == null) {
      this.alert.errorWithContactAdminTh();
      throw new Error(
        'The graduateEmploymentPath is undefined. So, system cannot navigate to Graduate Employee page'
      );
    }
    const onlyPath = !new RegExp(/http(s)?:\/\//).test(path);
    if (onlyPath) {
      this.router.navigate([path]);
      return;
    }
    window.location.href = path;
  }

  onChangeCategoryClick(): void {
    this.isSelectCourse = true;
  }

  onCheckCourseClick(gradeRecord: CustomGradeRecord): void {
    let everyCheck = true;
    let someCheck = false;
    gradeRecord.course_enrollments.forEach((courseEnrollment) => {
      everyCheck = everyCheck && courseEnrollment.selected;
      someCheck = someCheck || courseEnrollment.selected;
    });
    gradeRecord.allSelected = everyCheck || (someCheck ? null : false);
  }

  onCheckAllCourseClick(gradeRecord: CustomGradeRecord): void {
    const target = gradeRecord.allSelected ?? false;
    gradeRecord.course_enrollments.forEach((courseEnrollments) => {
      courseEnrollments.selected = target;
    });
  }

  reloadDataResolver(): void {
    this.router.navigate(['./'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        reload: this.dictGen.randomNumber(4),
      },
    });
  }

  retrieveSelectedEnrolledCourse(gradeRecords: CustomGradeRecord[]) {
    return gradeRecords.reduce((arr, gradeRecord) => {
      const enrollments = gradeRecord.course_enrollments;
      const selected = enrollments.filter((enrollment) => enrollment.selected);
      const selectedChildren = this.retrieveSelectedEnrolledCourse(
        gradeRecord.children || []
      );
      const allSelected: CustomGradeRecordEnrollment[] =
        selected.concat(selectedChildren);
      return arr.concat(allSelected);
    }, [] as CustomGradeRecordEnrollment[]);
  }

  resetCourseSelection(
    gradeRecord: CustomGradeRecord,
    { descendant } = { descendant: true }
  ): void {
    const selectedCourses = gradeRecord.course_enrollments;
    selectedCourses.forEach((course) => {
      course.selected = false;
    });
    if (descendant && gradeRecord.children) {
      gradeRecord.children.forEach((childGradeRecord) =>
        this.resetCourseSelection(childGradeRecord)
      );
    }
    gradeRecord.allSelected = false;
  }

  submitChangingCategory(
    courseStrId: number,
    enrollments: number[]
  ): Observable<unknown> {
    this.isLoadingCategoryChange = true;
    const requestBody: CourseCategoryChange = {
      student: this.studentId,
      enrollments,
      course_structure: courseStrId,
    };
    const api$ = this.studyPlanApi
      .changeCourseCategory(requestBody)
      .pipe(share());
    api$.subscribe({
      next: () => {
        this.alert.success(
          AlertMessages.success.save +
            '\n\n' +
            AlertMessages.system.plsWaitRefreshLoading,
          3000
        );
        this.isLoadingCategoryChange = false;
      },
      error: () => {
        this.alert.error(ErrorMessages.common.plsCheckOrAdmin);
        this.isLoadingCategoryChange = false;
      },
    });
    return api$;
  }

  isLessOneCourseSelected(): boolean | undefined {
    const checkLessOne = (gradeRecords: CustomGradeRecord[]): boolean => {
      return gradeRecords.reduce((isSome, record) => {
        if (isSome) return isSome;
        const someChecked = record.course_enrollments.some(
          (course) => course.selected
        );
        const someChildChecked = record.children
          ? checkLessOne(record.children)
          : false;
        return someChecked || someChildChecked;
      }, false as boolean);
    };
    const gradeRecords = this.graduateQualification?.grade_record;
    return gradeRecords ? checkLessOne(gradeRecords) : undefined;
  }

  submitChangeCategorySelection(): void {
    if (!this.isLessOneCourseSelected()) {
      this.alert.error(AlertMessages.validation.plsLessOneChoice, 3000);
      return;
    }
    const modal = this.ngbModal.open(this.adjustCourseModal, {
      size: 'md',
      backdrop: 'static',
    });
    modal.result
      .then((courseStr: CustomGradeRecord['course_structure']) => {
        if (!courseStr.id) {
          throw new Error('The selected course structure id is in valid');
        }
        const gradeRecords = this.graduateQualification?.grade_record || [];
        const selected = this.retrieveSelectedEnrolledCourse(gradeRecords);
        const enrollmentIds = selected.reduce((arr, course) => {
          return arr.concat(
            course.enrollments.map((enrollment) => enrollment.id)
          );
        }, [] as number[]);
        this.submitChangingCategory(courseStr.id, enrollmentIds).subscribe({
          next: () => {
            gradeRecords.forEach((gradeRecord) => {
              this.resetCourseSelection(gradeRecord);
            });
            this.isSelectCourse = false;
            this.reloadDataResolver();
          },
        });
      })
      .catch((err) => {
        if (err) {
          console.error(err);
        }
      });
  }

  subscribeRouteParams(): void {
    this.activatedRoute.parent?.params.subscribe({
      next: ({ id }) => {
        this.studentId ||= id;
      },
    });
    this.activatedRoute.data
      .pipe(
        map((res) => {
          const data = res['graduateQualification'] as
            | StudentGraduateQualification
            | { error: HttpErrorResponse };
          if (data == null) {
            throw new Error(
              'The data is undefined that may be cause no resolver'
            );
          }
          if ('error' in data) {
            throw data.error;
          }
          return data;
        })
      )
      .subscribe({
        next: (graduateQualification) => {
          this.defineCreditFunctions(graduateQualification);
          this.graduateQualification = this.makeUpGraduateQualification(
            graduateQualification
          );
          this.fetchCourseGroup();
          this.cdRef.detectChanges();
        },
        error: (err) => {
          console.error(err);
          if (err.name === 'mayBeNoStudyPlan') {
            this.alert.error(
              'เนื่องจากนักศึกษา' +
                ErrorMessages.studyPlanError.mayBeNoStudyPlan +
                ' ' +
                ErrorMessages.common.plsCheckOrAdmin,
              4000
            );
          } else {
            this.alert.errorWithContactAdmin();
          }
          this.router.navigate(['../'], {
            relativeTo: this.activatedRoute,
            replaceUrl: true,
          });
        },
      });
  }

  subscribeUserStore(): void {
    this.store
      .select<UserProfile>((state) => state.auth)
      .subscribe({
        next: (res) => {
          this.user = res;
          const isStudent = res.context.student;
          if (isStudent) {
            this.studentId = isStudent.id;
          }
        },
      });
  }

  updateJoiningCeremony(
    isJoin: boolean,
    joinCeremonyComp?: JoiningGraduationCeremonyBlockComponent
  ): void {
    this.isLoadingJoiningCeremony = true;
    this.studentApi
      .patch(this.studentId, { join_graduation_ceremony: isJoin })
      .subscribe({
        next: () => {
          this.alert.success(AlertMessages.success.save);
          this.isLoadingJoiningCeremony = false;
          joinCeremonyComp?.markAsPristine();
          this.cdRef.detectChanges();
        },
        error: (err) => {
          const errorsMsg =
            ErrorMessages.common.foundError +
              ':\n' +
              err.error.errors?.join(', ') ||
            ErrorMessages.common.cannotProcess;
          this.alert.error(errorsMsg, 3000);
          this.isLoadingJoiningCeremony = false;
          this.cdRef.detectChanges();
        },
      });
  }

  get canSaveEmployment(): boolean {
    return (
      (this.isStudent
        ? (this.user.context.student as StudentUserContext | undefined)?.status
            ?.value === 'graduated'
        : false) || this.isAdmin
    );
  }

  get canSubmitGraduateReq(): boolean {
    return (
      (this.isStudent && this.graduateQualification?.graduation_registered) ||
      this.isAdmin
    );
  }

  get isAdmin(): boolean {
    return this.user.role === 'admin';
  }

  get isStudent(): boolean {
    return this.user.role === 'student';
  }
}

export interface CustomGradeRecord extends GraduateGradeRecord {
  course_enrollments: CustomGradeRecordEnrollment[];
  children: CustomGradeRecord[];
  allSelected?: boolean | null;
}

export interface CustomGradeRecordEnrollment extends GradeRecordEnrollment {
  records: Partial<CourseResult>[];
  selected: boolean;
}

export interface GraduateQualification extends StudentGraduateQualification {
  creditBalance: () => number;
  creditBalanceStr: () => string;
  course_enrollments: CustomGradeRecordEnrollment[];
  grade_record: CustomGradeRecord[];
  gpaxStr: string;
  readonly isGraduate: boolean;
}

export interface KeyPair {
  label: string;
  key: keyof GraduateQualification;
}
