import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  NgbDatepickerModule,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { UtilsModule } from '@vru/utils';
import { LaddaModule } from 'angular2-ladda';
import { UiModule } from '../ui.module';
import { GraduationVerificationComponent } from './graduation-verification/graduation-verification.component';
import { JoiningGraduationCeremonyBlockModule } from './joining-graduation-ceremony-block/joining-graduation-ceremony-block.module';
import { StudentActivationComponent } from './student-activation/student-activation.component';
import { StudentCreditTransferComponent } from './student-credit-transfer/student-credit-transfer.component';
import { StudentGradeRecordComponent } from './student-grade-record/student-grade-record.component';
import { StudentChangeProgramComponent } from './student-information-info/student-change-program/student-change-program.component';
import { StudentInformationInfoComponent } from './student-information-info/student-information-info.component';
import { StudentInformationRoutingModule } from './student-information-routing.module';
import { StudentProfileFormComponent } from './student-profile-form/student-profile-form.component';
import { StudentProfileComponent } from './student-profile/student-profile.component';
import { StudentSubjectComponent } from './student-subject/student-subject.component';

@NgModule({
  declarations: [
    GraduationVerificationComponent,
    StudentActivationComponent,
    StudentCreditTransferComponent,
    StudentInformationInfoComponent,
    StudentProfileComponent,
    StudentProfileFormComponent,
    StudentGradeRecordComponent,
    StudentSubjectComponent,
    StudentChangeProgramComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    JoiningGraduationCeremonyBlockModule,
    LaddaModule,
    NgbDatepickerModule,
    NgbPopoverModule,
    NgSelectModule,
    StudentInformationRoutingModule,
    UiModule,
    UtilsModule,
  ],
  exports: [
    GraduationVerificationComponent,
    StudentActivationComponent,
    StudentCreditTransferComponent,
    StudentInformationInfoComponent,
    StudentProfileComponent,
    StudentProfileFormComponent,
    StudentGradeRecordComponent,
    StudentSubjectComponent,
  ],
})
export class StudentInformationModule {}
