import { Course } from '../subject.model';
import { CourseSection } from './course-section.model';

export interface CourseEnrollment {
  course: Pick<Course, 'id' | 'code' | 'name_thai'>;
  course_group: number;
  course_section: Pick<
    CourseSection,
    | 'id'
    | 'section_no'
    | 'teachers'
    | 'timetable_managements'
    | 'examination_managements'
  > & { semester: number; academic_year: number };
  enrollment_approve_at: Date | null;
  enrollment_approve_status: CourseEnrollmentStatus;
  enrollment_approver: number;
  enrollment_type: string;
  evaluated: boolean;
  final_score: number;
  grade: string;
  grading: string;
  midterm_score: number;
  numeric_grade: string;
  reserved_seat: boolean;
  status: string;
  student: number;
  total_score: number;
}

export interface CourseEnrollmentParams {
  code?: string;
  course_section?: number[];
  current_semester?: number;
  evaluated?: boolean;
  program?: number;
  start_academic_year?: number;
  student: number;
}

export interface StudentEnrollment {
  id: number;
  code: string;
  first_name_thai: string;
  last_name_thai: string;
  student_type: string;
  degree: string;
  faculty: string;
  program: string;
  status: string;
  enrollment_type: string;
  exam_seat?: string | null; 
}

export interface StudentEnrollmentParams {
  code: string | null;
  student_name?: string | null;
  course_section: number | null;
}

export interface StudentExamSeat {
  student_code: string | null,
  name: string | null
}

export enum CourseEnrollmentStatuses {
  approve,
  pending,
  reject,
}

/** type of CourseEnrollmentStatuses */
export type CourseEnrollmentStatus = keyof typeof CourseEnrollmentStatuses;
