import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  API_CONFIG,
  ApiConfig,
  ChatRoom,
  ChatRoomParam,
  ChatRoomUser,
  ChatService,
  DrfList,
  Dropdown,
  DropdownApiService,
  ErrorHeader,
  UserApiService,
  UserProfileDropdownItem as UserProfile,
} from '@vru/master-data';
import { AlertService, AuthenticationService } from '@vru/services';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { convertError } from '@vru/utils';

@Component({
  selector: 'vru-manage-chat',
  templateUrl: './manage-chat.component.html',
  styleUrls: ['./manage-chat.component.scss'],
})
export class ManageChatComponent implements OnInit, OnDestroy {
  @ViewChild('AddNewChatModal') addNewChatModal!: ElementRef;

  chatListWebSocket!: WebSocketSubject<any>;
  CHAT_URL = '';

  page = 1;
  pageSize = 20;
  isLoading = false;
  isMessageLoading = false;
  isInitialLoading = false;
  isOpenChatRoom = true;

  nextPage = '';
  previousScrollbar = 0;
  selectedChatRoom: ChatRoom | null = null;
  selectNewChatType: string | null = null;

  user!: UserProfile;
  targetUsers: UserProfile[] = [];
  targetRole: string[] | null = [];
  chatRoomList: ChatRoom[] = [];

  roleGroupDropdownList: Dropdown[] = [];
  initialUserDropdown: Partial<UserProfile>[] = [];
  userDropdownAccessor = (res: DrfList<UserProfile>) => {
    return res.results.filter(
      (profile) => !['admin', 'admission_applicant'].includes(profile.role)
    );
  };
  userDropdownGenerator = (
    search: string
  ): Observable<DrfList<UserProfile>> => {
    return this.userProfile.getPublicList({
      name: search,
    }) as Observable<DrfList<UserProfile>>;
  };

  constructor(
    @SkipSelf() @Inject(API_CONFIG) private apiConfig: ApiConfig,
    private auth: AuthenticationService,
    private chatService: ChatService,
    private alertService: AlertService,
    private userProfile: UserApiService,
    private store: Store,
    private ngbModal: NgbModal,
    private dropdownService: DropdownApiService
  ) {
    this.subscribeUserStore();
    const domain = this.apiConfig.apiEndpoint.split('//')[1];
    this.CHAT_URL = `wss://${domain}/ws/chat-rooms/`;
    this.pageSize = Math.ceil(
      Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      ) / 65
    );
  }

  ngOnDestroy(): void {
    this.chatListWebSocket?.unsubscribe();
  }

  ngOnInit() {
    if (this.user) {
      this.getChatList();
      this.subscribeChatRoom();
      this.getDropdownList();
    }
  }

  subscribeChatRoom(): void {
    this.chatListWebSocket = webSocket(
      this.CHAT_URL + `?uid=${this.auth.accessToken}`
    );
    this.chatListWebSocket.asObservable().subscribe({
      next: () => {
        this.getChatList();
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  getChatList() {
    this.isLoading = true;
    const params: ChatRoomParam = {
      page: this.page,
      page_size: this.pageSize,
    };
    this.chatService.getChatRoomList(params).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.nextPage = res.next;
        this.chatRoomList = res.results;
        this.transformUsersData(res.results);
      },
      error: async (err) => {
        this.isLoading = false;
        this.alertService.error(await convertError(err), 5000);
      },
    });
  }

  getDropdownList() {
    this.dropdownService.getDropdown({ type: 'role' }).subscribe({
      next: (res) => {
        if (this.user.role == 'student') {
          this.roleGroupDropdownList = res.role.filter((role) =>
            [
              'finance',
              'dean',
              'admin',
              'executive',
              'teacher',
              'backoffice',
            ].includes(String(role.value))
          );
        } else {
          this.roleGroupDropdownList = res.role.filter((role) =>
            [
              'finance',
              'dean',
              'admin',
              'executive',
              'teacher',
              'backoffice',
              'student',
            ].includes(String(role.value))
          );
        }
      },
      error: (err: ErrorHeader) => {
        if (err.error) {
          this.alertService.error(err.error.detail, 10000);
        }
      },
    });
  }

  onSelectChatRoom(target: ChatRoom) {
    this.isOpenChatRoom = false;
    this.selectedChatRoom = target;
  }

  transformUsersData(users: ChatRoom[]) {
    if (this.user) {
      const userFilter = users.map((obj) => obj.users);
      userFilter.forEach((user, index) => {
        this.chatRoomList[index].users = user.filter(
          (target) => target.id !== this.user.id
        );
      });
    } else {
      this.alertService.error('ไม่พบข้อมูลของผู้ใช้', 10000);
    }
  }

  onClickCreateChat() {
    if (this.selectNewChatType === 'person') {
      if (this.targetUsers) {
        const usersChat = this.targetUsers.map((user) => user.user);
        usersChat.push(this.user.id);
        const payload: ChatRoomUser = {
          users: usersChat,
          type: usersChat.length == 2 ? 'private' : 'group',
        };
        this.chatService.postChatRoom(payload).subscribe({
          next: () => {
            this.targetRole = [];
            this.targetUsers = [];
            this.alertService.success('สร้างกล่องข้อความเรียบร้อย');
            this.subscribeChatRoom();
            this.getChatList();
          },
          error: (err: ErrorHeader) => {
            if (err.error) {
              this.alertService.error(err.error.detail, 10000);
            }
            this.subscribeChatRoom();
          },
        });
      } else {
        this.alertService.error('กรุณาเลือกตำแหน่ง');
      }
    } else {
      if (this.targetRole) {
        const payload: ChatRoomUser = {
          roles: this.targetRole,
          type: 'group',
        };
        this.chatService.postChatRoom(payload).subscribe({
          next: () => {
            this.alertService.success('สร้างกล่องข้อความเรียบร้อย');
            this.subscribeChatRoom();
            this.getChatList();
          },
          error: (err: ErrorHeader) => {
            if (err.error) {
              this.alertService.error(err.error.detail, 10000);
            }
            this.subscribeChatRoom();
          },
        });
      } else {
        this.alertService.error('กรุณาเลือกตำแหน่ง');
      }
    }

    this.ngbModal.dismissAll();
  }

  subscribeUserStore() {
    this.store
      .select<UserProfile>((state) => state.auth)
      .subscribe({
        next: (res) => {
          this.user = res;
        },
      });
  }

  onOpenNewChatModal(type: string) {
    this.selectNewChatType = type;
    this.ngbModal.open(this.addNewChatModal, {
      size: 'md',
      backdrop: 'static',
    });
  }

  onCloseModal() {
    this.targetUsers = [];
    this.ngbModal.dismissAll();
  }

  onCloseChatRoom() {
    this.isOpenChatRoom = true;
    this.selectedChatRoom = null;
  }

  onMessageScrollEnd(event: any) {
    const tracker = event.target;
    const limit = tracker.scrollHeight - tracker.clientHeight;
    this.previousScrollbar = event.target.scrollTop;
    if (this.previousScrollbar >= limit-1) {
      if (this.nextPage) {
        this.pageSize = this.pageSize + this.pageSize;
        this.getChatList();
      }
    }
  }
}
