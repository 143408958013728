import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFileListComponent } from './upload-file-list.component';
import { CarbonIconModule } from '../carbon-icon/carbon-icon.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [UploadFileListComponent],
  exports: [UploadFileListComponent],
  imports: [CarbonIconModule, CommonModule, FormsModule],
})
export class UploadFileListModule {}
