import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GradeRoutingModule } from './grade-routing.module';
import { GradeSubmissionModule } from './grade-submission/grade-submission.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, GradeSubmissionModule, GradeRoutingModule],
  exports: [GradeSubmissionModule],
})
export class GradeModule {}
