<div>
  <div class="modal-header">
    <h4 class="modal-title">
      <p>กรุณาตรวจสอบอีเมล์ของท่านให้ถูกต้อง</p>
    </h4>
  </div>
  <div class="modal-body">
    <p>ระบบจะส่งข้อมูลรับสมัครไปที่อีเมลล์</p>
    <h4>{{email || '(กรุณาระบุ E-mail ของท่าน)'}}</h4>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn btn-danger" (click)="activeModal.close()">Ok</button>
  </div>
</div>
