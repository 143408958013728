import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  PartialControlGroup,
  ProgramResponsibleTeacher,
  ProgramResponsibleModel,
  Tqf2Teacher,
  Tqf2TeacherEducationRecord,
} from '@vru/master-data';

@Injectable({
  providedIn: 'root',
})
export class ProgramTeacherService {
  constructor(private fb: FormBuilder) {}

  buildProgramTeacherFormGroup(
    initial?: Partial<ProgramResponsibleTeacher>
  ): FormGroup {
    const programTeacherForm = new FormGroup({
      id: new FormControl({ disabled: !initial?.id, value: initial?.id }),
      position: new FormControl(initial?.position),
      program: new FormControl(initial?.program),
      teacher:
        typeof initial?.teacher === 'number'
          ? new FormControl(initial?.teacher)
          : this.buildTqf2Teacher(initial?.teacher),
      teaching_load: new FormArray(
        initial?.teaching_load?.map(
          (teachingLoad) => new FormControl(teachingLoad)
        ) || []
      ),
      year: new FormArray(
        initial?.year?.map((year) => new FormControl(year)) || []
      ),
    });
    if (initial?.id != null) {
      programTeacherForm.addControl('id', new FormControl(initial.id));
    }
    return programTeacherForm;
  }

  buildTqf2Teacher(initialForm?: Tqf2Teacher) {
    const controlGroup: PartialControlGroup<Tqf2Teacher> = {
      full_academic_position: new FormControl(
        initialForm?.full_academic_position
      ),
      name: new FormControl(initialForm?.name),
      teacher_educational_records: new FormArray(
        (initialForm?.teacher_educational_records || [undefined]).map(
          (record) => this.buildTqf2TeacherEducationRecord(record)
        )
      ),
    };
    if (initialForm?.id != null) {
      controlGroup.id = new FormControl(initialForm.id);
    }
    return new FormGroup(controlGroup);
  }

  buildTqf2TeacherEducationRecord(initialForm?: Tqf2TeacherEducationRecord) {
    const controlGroup: PartialControlGroup<Tqf2TeacherEducationRecord> = {
      academy: new FormControl(initialForm?.academy),
      educational_background: new FormControl(
        initialForm?.educational_background
      ),
      year: new FormControl(initialForm?.year),
    };
    if (initialForm?.id != null) {
      controlGroup.id = new FormControl(initialForm.id);
    }
    return new FormGroup(controlGroup);
  }

  buildProgramResponsibleForm(initial?: Partial<ProgramResponsibleModel>) {
    const formGroup = this.fb.group({
      teacher: [initial?.teacher, Validators.required],
      teaching_load: [
        {
          disabled: initial?.teaching_load === undefined,
          value: initial?.teaching_load,
        },
      ],
      year: [
        {
          disabled: initial?.year === undefined,
          value: initial?.year,
        },
      ],
      position: [
        {
          disabled: initial?.position === undefined,
          value: initial?.position,
        },
      ]
    });
    if (initial?.id) {
      formGroup.addControl(
        'id',
        this.fb.control(initial.id, Validators.required)
      );
    }
    return formGroup;
  }

  buildProgramResponsibleFormArr(
    initial?: Partial<ProgramResponsibleModel>[]
  ) {
    return this.fb.array(
      (initial?.length ? initial : [undefined]).map((resp) =>
        this.buildProgramResponsibleForm(resp)
      )
    );
  }
}
