import {
  Component,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
  SkipSelf,
} from '@angular/core';
import { Primitive } from '@vru/master-data';

@Component({
  selector: 'vru-icon-date-status',
  templateUrl: './icon-date-status.component.html',
  styleUrls: ['./icon-date-status.component.scss'],
})
export class IconDateStatusComponent implements OnChanges {
  @Input() date?: Date | string | null;
  @Input() datePipeParam = 'dd/MM/yyyy';
  @Input() displayStyle: 'inline' | 'block' = 'inline';
  @Input() status?: Primitive | null;

  displayedStatus?: IconDateStatusConfig;

  constructor(
    @Optional()
    @SkipSelf()
    @Inject(ICON_DATE_STATUS_CONFIG)
    private config: IconDateStatuses
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status) {
      if (!this.config) {
        this.config = this.defaultStatus;
      }
      this.displayedStatus = this.config.find(
        (status) => status.value === changes.status.currentValue
      );
    }
  }

  get defaultStatus(): IconDateStatuses {
    return [
      { icon: 'icon icon-clock-circle-fill', value: 'wait', color: 'text-warning' },
      { icon: 'bi bi-x-circle-fill', value: 'rejected', color: 'text-red' },
      { icon: 'bi bi-check-circle-fill', value: 'approved', color: 'text-green' },
    ];
  }
}

export const ICON_DATE_STATUS_CONFIG = new InjectionToken<IconDateStatuses>(
  'Icon and date status configs'
);

export interface IconDateStatusConfig {
  /** Icon font */
  icon: string;
  value: Primitive;
  /** Color style class */
  color?: string;
}

export type IconDateStatuses = IconDateStatusConfig[];
