import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DrfList } from '../common.model';
import { SubjectGroup, SubjectGroupParams } from './subject-group.model';

@Injectable({
  providedIn: 'root',
})
export class SubjectGroupApiService {
  subjectGroupUrlPath = '/course-groups/';

  constructor(private api: ApiService) {}

  getSubjectGroups(
    params?: SubjectGroupParams
  ): Observable<DrfList<SubjectGroup>> {
    return this.api.get(this.subjectGroupUrlPath, params);
  }

  getSubjectGroupDetail(id: number): Observable<SubjectGroup> {
    return this.api.get(this.subjectGroupUrlPath + `${id}`);
  }
}
