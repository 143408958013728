import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vru-svg-label',
  templateUrl: './svg-label.component.html',
  styleUrls: ['./svg-label.component.scss'],
})
export class SvgLabelComponent implements OnInit {
  @Input() backgroundColor = '#777';
  @Input() labelHeight = 40;
  @Input() labelWidth = 175;
  @Input() title!: string;
  @Input() titleSize = 20;
  @Input() titleColor = 'white';

  labelSvgPath!: string;
  
  ngOnInit(): void {
    this.labelSvgPath =
      `M0,4 q 0,-4 4,-4 h${this.labelWidth - 37} q 5,0 9,4 l17,12` +
      `q 7,4 0,8 l-17,12 q -5,4 -9,4 h-${this.labelWidth - 37} q -4,0 -4,-4 z`;
  }
}
