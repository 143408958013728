import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { NotificationCount, NotificationParams } from './notification.model'

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService {
  readonly webNotification = '/website-notifications/';

  constructor(private http: ApiService) {}

  getNotification(params: NotificationParams): Observable<NotificationCount> {
    return this.http.get<NotificationCount>(this.webNotification, params);
  }

  postReadOneNotification(id: number, params?: any) {
    return this.http.post(this.webNotification + `${id}/read/`, params)
  }

  postReadAllNotification(params?: any) {
    return this.http.post(this.webNotification + 'read-all/', params)
  }
}
