import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarArrowStyleComponent } from './navbar-arrow-style.component';
import { SvgLabelModule } from '../svg-label/svg-label.module';

@NgModule({
  declarations: [NavbarArrowStyleComponent],
  exports: [NavbarArrowStyleComponent],
  imports: [CommonModule, SvgLabelModule],
})
export class NavbarArrowStyleModule {}
