import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService, DrfList, PaginationParams } from '@vru/master-data';
import {
  EvaluationFormList,
  EvaluationFormListParams,
  EvaluationType,
  EvaluationForm,
} from './assessment.model';

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  private readonly evaluationType = '/evaluation-form-types/';
  private readonly evaluationForm = '/evaluation-forms/';

  constructor(private apiService: ApiService) {}

  // Evaluation Type Services
  getEvaluationTypes(params: PaginationParams): Observable<DrfList<EvaluationType>> {
    return this.apiService.get<DrfList<EvaluationType>>(this.evaluationType, params);
  }

  postEvaluationTypes(params: EvaluationType): Observable<EvaluationType> {
    return this.apiService.post<EvaluationType>(this.evaluationType, params);
  }

  patchEvaluationType(
    id: number,
    params: EvaluationType
  ): Observable<EvaluationType> {
    return this.apiService.patch<EvaluationType>(
      this.evaluationType + `${id}/`,
      params
    );
  }

  deleteEvaluationTypes(id: number) {
    return this.apiService.delete(this.evaluationType + `${id}`);
  }

  // Evaluation Form Services
  getEvaluationForm(
    params: EvaluationFormListParams
  ): Observable<DrfList<EvaluationFormList>> {
    return this.apiService.get<DrfList<EvaluationFormList>>(
      this.evaluationForm,
      params
    );
  }

  postEvaluationForms(params: EvaluationForm): Observable<EvaluationForm> {
    return this.apiService.post<EvaluationForm>(this.evaluationForm, params);
  }

  patchEvaluationForms(
    id: number,
    params: EvaluationForm
  ): Observable<EvaluationForm> {
    return this.apiService.patch<EvaluationForm>(
      this.evaluationForm + `${id}/`,
      params
    );
  }

  getEvaluationListById(
    id: number,
    params?: {[name: string] : string}
  ): Observable<EvaluationForm> {
    return this.apiService.get<EvaluationForm>(
      this.evaluationForm + `${id}/`,
      params
    );
  }

  createEvaluation(params?: EvaluationForm): Observable<EvaluationForm> {
    return this.apiService.post<EvaluationForm>(this.evaluationForm, params);
  }

  updateEvaluation(
    id: number,
    params?: EvaluationForm
  ): Observable<EvaluationForm> {
    return this.apiService.patch<EvaluationForm>(
      this.evaluationForm + `${id}/`,
      params
    );
  }
  deleteEvaluationForms(id: number) {
    return this.apiService.delete(this.evaluationForm + `${id}`);
  }
}
