<div class="modal-header d-flex align-items-center justify-content-lg-between">
  <h5 class="modal-title"
      id="modal-user-list-report">
    {{ type === 'profile-list' ? 'พิมพ์รายงานรายชื่อบุคลากร' : 'พิมพ์รายงานรหัสประจำตัวและรหัสผ่าน'}}
  </h5>
  <vru-carbon-icon iconName="close"
      size="25"
      aria-label="Close"
      presetFilter="fill-white"
      (click)="closeModal()"></vru-carbon-icon>
</div>
<div class="modal-body">
  <div class="form-group row">
    <div class="col">
      <label for="faculty">คณะ</label>
      <ng-select [items]="facultyDropdown"
          id="faculty"
          bindLabel="label"
          bindValue="value"
          placeholder="เลือก"
          (change)="onSelectFieldOfStudy($event)"
          [clearable]="true"
          [searchable]="true"
          [(ngModel)]="selectedFaculty"></ng-select>
    </div>
  </div>
  <div class="form-group row">
    <div class="col">
      <label for="fieldOfStudy">สาขาวิชา</label>
      <ng-select [items]="fieldOfStudyDropdown"
          id="fieldOfStudy"
          bindLabel="label"
          bindValue="value"
          placeholder="เลือก"
          [clearable]="true"
          [searchable]="true"
          [(ngModel)]="selectedFieldOfStudy"></ng-select>
    </div>
  </div>
  <div class="form-group row">
    <div class="col">
      <label for="employeeType">ประเภทบุคลากร</label>
      <ng-select [items]="employeeTypeDropdown"
          id="employeeType"
          bindLabel="label"
          bindValue="value"
          placeholder="เลือก"
          [clearable]="true"
          [searchable]="true"
          [(ngModel)]="selectEmployeeType"></ng-select>
    </div>
  </div>
  <div class="form-group row">
    <div class="col">
      <label for="work_status">สถานะการทำงาน</label>
      <ng-select bindLabel="label"
          bindValue="value"
          [items]="workStatusDropdown"
          id="work_status"
          placeholder="เลือก"
          [searchable]="true"
          [clearable]="true"
          [(ngModel)]="selectWorkStatus">
        <ng-template ng-option-tmp
            let-item="item">
          <div class="d-flex text-wrap">{{ item.label }}</div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="form-group row" *ngIf="type === 'profile-list'">
    <div class="col">
      <label class="require">หน่วยงาน</label>
      <div class="form-check">
        <label class="form-check-label pointer" for="teacher">
          <input class="form-check-input position-static" type="checkbox" id="teacher" [(ngModel)]="selectedTeacher">
          อาจารย์
        </label>
      </div>
      <div class="form-check">
        <label class="form-check-label pointer" for="officer">
          <input class="form-check-input position-static" type="checkbox" id="officer" [(ngModel)]="selectedOfficer">
          เจ้าหน้าที่
        </label>
      </div>
      <div *ngIf="submitted && roleIsEmpty"
          [ngClass]="{'d-block': submitted && roleIsEmpty}"
          class="invalid-feedback">
        กรุณาเลือกหน่วยงาน
      </div>
    </div>
  </div>
</div>
<div class="modal-footer pr-5">
  <div class="row justify-content-end my-2">
    <button type="button"
        class="btn btn-outline-green-vru"
        (click)="closeModal()">ยกเลิก
    </button>
    <button type="button"
        class="btn btn-green-vru ml-2"
        (click)="downloadReport()">ดาวน์โหลดรายงาน
    </button>
  </div>
</div>