import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  _subscriptionGroup: Map<string, { [k: string]: Subscription }> = new Map();

  constructor() {}

  deregisterGroup(groupName: string): void {
    this.unsubscribeGroup(groupName);
    this._subscriptionGroup.delete(groupName);
  }

  getSubscriptionGroup(
    groupName: string
  ): { [k: string]: Subscription } | undefined {
    return this._subscriptionGroup.get(groupName);
  }

  registerGroup(groupName: string): { [k: string]: Subscription } {
    if (this._subscriptionGroup.has(groupName)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this._subscriptionGroup.get(groupName)!;
    }
    this._subscriptionGroup.set(groupName, {});
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._subscriptionGroup.get(groupName)!;
  }

  unsubscribeGroup(groupName: string): void {
    if (!this._subscriptionGroup.has(groupName)) {
      return;
    }
    const subscriptions = this._subscriptionGroup.get(groupName);
    if (subscriptions == null) {
      return;
    }
    Object.keys(subscriptions).forEach((key) => {
      if (!subscriptions[key].closed) {
        subscriptions[key].unsubscribe();
      }
    });
  }

  unsubscribeAll(): void {
    for (const [groupName] of this._subscriptionGroup) {
      this.unsubscribeGroup(groupName);
    }
  }

  get subscriptionGroup(): Map<string, { [k: string]: Subscription }> {
    return this._subscriptionGroup;
  }
}
