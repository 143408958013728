import { DrfList } from './../common.model';
import { Observable } from 'rxjs';
import { QuestionAnswer, QuestionAnswerParams, QuestionAnswerSent, QuestionAnswerDetail, QuestionAnswerInbox } from './question-answer.model';
import { Injectable } from "@angular/core";
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionAnswerService {
  private readonly questionAnswerSentUrl = '/sents/';
  private readonly questionAnswerInboxUrl = '/inboxes/';
  private readonly questionAnswerApprovalUrl = '/pending-review-inboxes/';

  constructor(
    private apiService: ApiService
  ) { }

  getQuestionAnswerApproval(params?: QuestionAnswerParams): Observable<DrfList<QuestionAnswer>> {
    return this.apiService.get<DrfList<QuestionAnswer>>(this.questionAnswerApprovalUrl, params);
  }

  getQuestionAnswerSent(params?: QuestionAnswerParams): Observable<DrfList<QuestionAnswer>> {
    return this.apiService.get<DrfList<QuestionAnswer>>(this.questionAnswerSentUrl, params);
  }

  getQuestionAnswerInbox(params?: QuestionAnswerParams): Observable<DrfList<QuestionAnswer>> {
    return this.apiService.get<DrfList<QuestionAnswer>>(this.questionAnswerInboxUrl, params);
  }

  getQuestionAnswerApprovalDetail(id: number): Observable<QuestionAnswerDetail> {
    return this.apiService.get<QuestionAnswerDetail>([this.questionAnswerApprovalUrl, id.toString()]);
  }

  getQuestionAnswerSentDetail(id: number): Observable<QuestionAnswerDetail> {
    return this.apiService.get<QuestionAnswerDetail>(this.questionAnswerSentUrl + `${id}/`);
  }

  getQuestionAnswerInboxDetail(id: number): Observable<QuestionAnswerDetail> {
    return this.apiService.get<QuestionAnswerDetail>(this.questionAnswerInboxUrl + `${id}/`);
  }

  postQuestionAnswerSent(params: QuestionAnswerSent): Observable<QuestionAnswerDetail> {
    return this.apiService.post<QuestionAnswerDetail>(this.questionAnswerSentUrl, params);
  }

  patchQuestionAnswerInbox(id: number, params: QuestionAnswerInbox): Observable<QuestionAnswerDetail> {
    return this.apiService.patch<QuestionAnswerDetail>(this.questionAnswerInboxUrl + `${id}/`, params);
  }

  approveQuestionAnswer(id: number, params?: any) {
    return this.apiService.post([this.questionAnswerApprovalUrl, id.toString(), 'approve'], params);
  }

  rejectQuestionAnswer(id: number, params?: any) {
    return this.apiService.post([this.questionAnswerApprovalUrl, id.toString(), 'reject'], params);
  }
}