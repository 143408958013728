import { FormGroup, FormGroupDirective, FormBuilder } from '@angular/forms';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'vru-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit {
  config: any;
  value = '';
  @Input() height = 500;
  @Input() name = '';
  @Output() modelChange = new EventEmitter();
  formGroup: FormGroup;
  constructor(private fb: FormBuilder) {
    this.formGroup = this.fb.group({});
    this.config = {
      height: this.height,
      base_url: '/tinymce',
      suffix: '.min',
      skin_url: '/tinymce/skins/lightgray',
      selector: 'textarea',
      branding: false,
      menu: {
        edit: {
          title: 'Edit',
          items: 'undo redo | cut copy paste | selectall | searchreplace',
        },
        insert: {
          title: 'Insert',
          items: 'image link template codesample inserttable | charmap emoticons hr | ' + '| insertdatetime',
        },
        format: {
          title: 'Format',
          items:
            'bold italic underline strikethrough superscript subscript codeformat | ' +
            'formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | ' +
            'removeformat',
        },
        tools: {
          title: 'Tools',
          items: 'spellchecker spellcheckerlanguage | code wordcount',
        },
        table: {
          title: 'Table',
          items: 'inserttable | cell row column | tableprops deletetable',
        },
      },
      statusbar: false,
      content_css: '../../../../assets/scss/content-editor.css',
      width: '99.8%',
      // language: this.translate.currentLang === 'en' ? 'en' : 'th_TH',
      // language_url: this.translate.currentLang === 'en' ? null : 'assets/langs/th_TH.js',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount imagetools',
        'textcolor',
      ],
      toolbar:
        'styleselect fontsizeselect | bold italic underline | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist | removeformat',
      file_picker_types: 'image',
      file_picker_callback: (cb: any) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = (e?: any) => {
          const file = e.target.files[0];
          const reader = new FileReader();
          reader.onload = (event: any) => {
            const blobInfo = event.target.result;
            cb(blobInfo, { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();

        interface HTMLInputEvent extends Event {
          target: HTMLInputElement & EventTarget;
        }
      },
      setup: (editor: any) => {
        editor.on('ObjectResized', (e: any) => {
          if (e.target.nodeName === 'IMG') {
            console.log('img', e);
            const selectedImage = editor.selection.getNode();
            editor.dom.setStyle(selectedImage, 'width', e.width + 'px');
            editor.dom.setStyle(selectedImage, 'height', e.height + 'px');

            selectedImage.removeAttribute('width');
            selectedImage.removeAttribute('height');
          }
        });
      },
      init_instance_callback: (editor: any) => {
        editor.on('ObjectSelected', (e: any) => {
          if (e.target.nodeName === 'IMG') {
            const selectedImage = editor.selection.getNode();
            editor.dom.setStyle(selectedImage, 'width', e.target.width + 'px');
            editor.dom.setStyle(selectedImage, 'height', e.target.height + 'px');
            selectedImage.removeAttribute('width');
            selectedImage.removeAttribute('height');
          }
        });
        editor.on('keydown', (e: any) => {
          if (e.keyCode === 9) {
            e.preventDefault();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            tinymce.activeEditor.execCommand('mceInsertContent', false, '&nbsp;&nbsp;&nbsp;&nbsp;');
          }
        });
      },
      automatic_uploads: true,
      fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt',
      content_style: 'body {font-size: 10pt;}',
      table_toolbar:
        'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow |' +
        ' tableinsertcolbefore tableinsertcolafter tabledeletecol',
      skin: 'lightgray',
    };
  }
  ngOnInit(): void {
    // const form = this.container.formDirective as FormGroupDirective;
    // this.formGroup = form.form;
  }

  valueChange() {
    // this.modelChange.emit(this.value);
  }
}
