export enum GuardianTypes {
  'father',
  'mother',
  'other',
}

export enum StudentStatuses {
  studying,
  terminated,
  graduated,
}

export enum ThaiStudentStatuses {
  'กำลังศึกษาอยู่',
  'พ้นสภาพนักศึกษา',
  'สำเร็จการศึกษา',
}
