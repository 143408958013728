export const convertError = (error: {
  error: { [key: string]: string[] };
  status: number;
}): Promise<string> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let messageError = '';
    if (!(error.status === 500)) {
      for await (const key of Object.entries(error.error)) {
        messageError += `<b style="font-size: 16px">${
          key[0] === 'detail' || key[1].length === 0
            ? '</b>'
            : key[0].replace(/_/g, ' ') + ':</b>'
        } ${
          Array.isArray(key[1])
            ? key[1].join('\n\n')
            : typeof key[1] !== 'object'
            ? key[1]
            : JSON.stringify(key[1])
        } \n`;
      }
    } else {
      messageError =
        'Sorry! some problem occurred. Please contact the administrator';
    }
    resolve(messageError);
  });
};