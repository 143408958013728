<div class="homepage-container" #container>
  <div class="homepage-navbar">
    <homepage-navbar></homepage-navbar>
  </div>
  <div class="homepage-content">
    <router-outlet (activate)="onActivate($event, container)"></router-outlet>
  </div>
  <div class="homepage-footer">
    <homepage-footer></homepage-footer>
  </div>
</div>