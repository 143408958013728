import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbTimepickerModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AcademicCalendarViewModule } from './academic-calendar-view/academic-calendar-view.module';
import { AppealLoaModule } from './appeal-loa/appeal-loa.module';
import { CarbonIconModule } from './carbon-icon/carbon-icon.module';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { CourseTypeaheadDropdownComponent } from './course-typeahead-dropdown/course-typeahead-dropdown.component';
import { DropdownOrderingComponent } from './dropdown-ordering/dropdown-ordering.component';
import { FileDropboxComponent } from './file-dropbox/file-dropbox.component';
import { InformationDisplayComponent } from './information-display/information-display.component';
import { InputDatetimeComponent } from './input-datetime/input-datetime.component';
import { NavbarArrowStyleModule } from './navbar-arrow-style/navbar-arrow-style.module';
import { NgSelectLabelDirective } from './ng-select-typeahead/directives/ng-select-label-tmp.directive';
import { NgSelectMultiLabelDirective } from './ng-select-typeahead/directives/ng-select-multi-label-tmp.directive';
import { NgSelectOptionDirective } from './ng-select-typeahead/directives/ng-select-option-tmp.directive';
import { NgSelectTypeaheadComponent } from './ng-select-typeahead/ng-select-typeahead.component';
import { SubDistrictTypeaheadComponent } from './ng-select/sub-district-typeahead/sub-district-typeahead.component';
import { NotificationModule } from './notification/notification.module';
import { PaginationTableFooterModule } from './pagination-table-footer/pagination-table-footer.module';
import { SpinnerGlobalComponent } from './spinner-global/spinner-global.component';
import { IconDateStatusComponent } from './status/icon-date-status/icon-date-status.component';
import { StudentSectionTypeaheadDropdownComponent } from './student-section-typeahead-dropdown/student-section-typeahead-dropdown.component';
import { StudentTypeaheadDropdownComponent } from './student-typeahead-dropdown/student-typeahead-dropdown.component';
import { SvgLabelModule } from './svg-label/svg-label.module';
import { TabsModule } from './tabs/tabs.module';
import { TeacherTypeaheadDropdownComponent } from './teacher-typeahead-dropdown/teacher-typeahead-dropdown.component';
import { TemplateDownloadHypertextComponent } from './template-download-hypertext/template-download-hypertext.component';
import { TqfStatusIconComponent } from './tqf/tqf-status-icon/tqf-status-icon.component';
import { UploadFileListModule } from './upload-file-list/upload-file-list.module';
import { UploadProfilePhotoComponent } from './upload-profile-photo/upload-profile-photo.component';
import { UserTypeaheadDropdownComponent } from './user-typeahead-dropdown/user-typeahead-dropdown.component';
import { UtilsModule } from '@vru/utils';
import { YearSelectComponent } from './selection/year-select/year-select.component';

@NgModule({
  declarations: [
    ConfirmationModalComponent,
    CourseTypeaheadDropdownComponent,
    FileDropboxComponent,
    IconDateStatusComponent,
    InformationDisplayComponent,
    InputDatetimeComponent,
    SpinnerGlobalComponent,
    NgSelectLabelDirective,
    NgSelectMultiLabelDirective,
    NgSelectTypeaheadComponent,
    NgSelectOptionDirective,
    TqfStatusIconComponent,
    UploadProfilePhotoComponent,
    StudentTypeaheadDropdownComponent,
    SubDistrictTypeaheadComponent,
    TeacherTypeaheadDropdownComponent,
    DropdownOrderingComponent,
    StudentSectionTypeaheadDropdownComponent,
    TemplateDownloadHypertextComponent,
    UserTypeaheadDropdownComponent,
    YearSelectComponent,
  ],
  exports: [
    AcademicCalendarViewModule,
    AngularSvgIconModule,
    CarbonIconModule,
    ConfirmationModalComponent,
    CourseTypeaheadDropdownComponent,
    FileDropboxComponent,
    IconDateStatusComponent,
    InformationDisplayComponent,
    InputDatetimeComponent,
    NavbarArrowStyleModule,
    NgSelectLabelDirective,
    NgSelectMultiLabelDirective,
    NgSelectTypeaheadComponent,
    NgSelectOptionDirective,
    NgxFileDropModule,
    NgxSpinnerModule,
    NotificationModule,
    SpinnerGlobalComponent,
    StudentSectionTypeaheadDropdownComponent,
    StudentTypeaheadDropdownComponent,
    SubDistrictTypeaheadComponent,
    TabsModule,
    TeacherTypeaheadDropdownComponent,
    TqfStatusIconComponent,
    PaginationTableFooterModule,
    UploadFileListModule,
    UploadProfilePhotoComponent,
    AppealLoaModule,
    DropdownOrderingComponent,
    TemplateDownloadHypertextComponent,
    UserTypeaheadDropdownComponent,
    YearSelectComponent,
  ],
  imports: [
    AcademicCalendarViewModule,
    AngularSvgIconModule.forRoot(),
    AppealLoaModule,
    CarbonIconModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    NavbarArrowStyleModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbTimepickerModule,
    NgbTooltipModule,
    NgSelectModule,
    NgxFileDropModule,
    NgxSpinnerModule,
    NotificationModule,
    ReactiveFormsModule,
    SvgLabelModule,
    TabsModule,
    UploadFileListModule,
    UtilsModule,
  ],
})
export class UiModule {}

export * as NavbarArrowStyleModel from './navbar-arrow-style/navbar-arrow-style.model';
export * as UploadFileListModel from './upload-file-list/upload-file-list.model';
