import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DrfList } from '../common.model';
import { CourseEnrollment, CourseEnrollmentParams } from './course-enrollment.model';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentApiService {
  readonly enrollmentPath = '/enrollments/';

  constructor(private api: ApiService) {}

  getCourseEnrollments(
    params?: CourseEnrollmentParams
  ): Observable<DrfList<CourseEnrollment>> {
    return this.api.get(this.enrollmentPath, params);
  }

  getCourseEnrollmentDetail(id: number): Observable<CourseEnrollment> {
    return this.api.get(this.enrollmentPath + id);
  }
}
