import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { Dropdown, DropdownApiService } from '@vru/master-data';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'vru-student-section-typeahead-dropdown',
  templateUrl: './student-section-typeahead-dropdown.component.html',
  styleUrls: ['./student-section-typeahead-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: StudentSectionTypeaheadDropdownComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: StudentSectionTypeaheadDropdownComponent,
    },
  ],
})
export class StudentSectionTypeaheadDropdownComponent {
  @Input() bindLabel = 'label';
  @Input() clearable = false;
  @Input() readonly = false;

  isLoading = false;
  selectedStudent?: Dropdown<number>;

  private _onTouch: any;
  private _onChange!: (val: any) => void;

  constructor(
    private cdRef: ChangeDetectorRef,
    private dropdownApi: DropdownApiService
  ) {}

  dataApiGenerator = (code: string) => {
    this.isLoading = true;
    this.cdRef.markForCheck();
    return this.dropdownApi
      .getDropdown('student_section', { code })
      .pipe(tap(() => (this.isLoading = false)));
  };

  onValueChange(val: Dropdown<number>) {
    this._onChange(val);
  }

  registerOnTouched(fn: any) {
    this._onTouch = fn;
  }

  registerOnChange(fn: (val: any) => void) {
    this._onChange = fn;
  }

  // TODO: Implement alternative validation
  validate(): ValidationErrors | null {
    return this.selectedStudent
      ? null
      : {
          student: {
            required: true,
          },
        };
  }

  writeValue(val: Dropdown<number>): void {
    this.selectedStudent = val;
  }
}
