export const success = {
  cancel: 'ยกเลิกข้อมูลสำเร็จ',
  delete: 'การลบข้อมูลสำเร็จ',
  save: 'บันทึกข้อมูลสำเร็จ',
  update: 'แก้ไขข้อมูลสำเร็จ',
  approval: 'การอนุมัติสำเร็จ',
  import: 'การนำเข้าข้อมูลสำเร็จ',
  reject: 'ปฏิเสธการอนุมัติแล้ว',
  request: 'ส่งคำขอสำเร็จ',
}

export const confirm = {
  deleteList: 'คุณยืนยันที่จะลบรายการนี้'
}

export const contact = {
  admin: 'กรุณาติดต่อผู้ดูแลระบบ',
  advisor: 'กรุณาติดต่ออาจารย์ที่ปรึกษา',
}

export const failure = {
  cannotProcess: 'ไม่สามารถดำเนินการได้',
  disabledAccount: 'บัญชีของคุณถูกปิดการใช้งาน',
  notFound: 'ไม่พบหน้าที่คุณร้องขอ',
  /** @deprecated Please use validation instead */
  plsFillValid: 'กรุณากรอกข้อมูลให้ถูกต้อง' // TODO: Remove cause move to const validation 
};

export const system = {
  plsReLogin: 'กรุณาเข้าสู่ระบบอีกครั้ง',
  plsWaitRefreshLoading: 'กรุณารอสักครู่ ระบบกำลังโหลดข้อมูลใหม่',
}

export const validation = {
  plsFillAcademicYear: 'กรุณากรอกปีการศึกษา',
  plsFillUsernamePass: 'กรุณากรอกบัญชีผู้ใช้และรหัสผ่านให้ครบถ้วน',
  plsFillValid: 'กรุณากรอกข้อมูลให้ถูกต้อง',
  plsLessOneChoice: 'กรุณาเลือกอย่างน้อยหนึ่งรายการ',
  plsSelectStudent: 'กรุณาเลือกนักศึกษา',
}
