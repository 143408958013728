export enum RoleEnum {
  academic_resources_officer = 'Academic Resources Officer',
  admin = 'Admin',
  admission_applicant = 'Admission Applicant',
  alumni = 'Alumni',
  backoffice = 'Backoffice',
  committee = 'Committee',
  company_contact_person = 'Company',
  credit_bank_student = 'Credit Bank Student',
  dean = 'Dean',
  enrollment_officer = 'Enrollment Officer',
  establishment = 'Establishment',
  executive = 'Executive',
  faculty_officer = 'Faculty Officer',
  finance = 'Finance',
  general = 'General',
  guardian = 'Guardian',
  program_officer = 'Program Officer',
  registrar = 'Registrar',
  student = 'Student',
  student_development_officer = 'Student Development Officer',
  student_support_officer = 'Student Support Officer',
  teacher = 'Teacher',
  university_president = 'University President',
  vice_president = 'Vice President',
}

export enum ThaiRoles {
  academic_resources_officer = 'สำนักวิทยบริการ',
  admin = 'ผู้ดูแลระบบ',
  admission_applicant = 'ผู้สมัคร',
  alumni = 'ศิษย์เก่า',
  backoffice = 'เจ้าหน้าที่',
  committee = 'คณะกรรมการ',
  company_contact_person = 'ผู้ติดต่อของสถานประกอบการ',
  credit_bank_student = 'นักศึกษาคลังหน่วยกิต',
  dean = 'คณบดี',
  enrollment_officer = 'งานทะเบียนเรียน',
  establishment = 'สถานประกอบการ',
  executive = 'ผู้บริหารมหาวิทยาลัย',
  faculty_officer = 'คณะ',
  finance = 'การเงิน',
  general = 'ทั่วไป',
  guardian = 'ผู้ปกครอง',
  program_officer = 'งานหลักสูตร',
  registrar = 'ทะเบียนนักศึกษา',
  student = 'นักศึกษา',
  student_development_officer = 'กองพัฒนานักศึกษา',
  student_support_officer = 'งานประมวลผลการเรียน',
  teacher = 'อาจารย์',
  university_president = 'อธิการบดี',
  vice_president = 'รองอธิการบดี',
}

export const RoleGroups = {
  admin: ['admin'],
  backoffice: ['admin', 'backoffice'],
  officer: [
    'admin',
    'backoffice',
    'enrollment_officer',
    'executive',
    'finance',
    'program_officer',
    'registrar',
    'student_development_officer',
    'student_support_officer',
    'university_president',
    'vice_president',
  ],
  student: ['admin', 'student', 'credit_bank_student'],
  teacher: ['admin', 'teacher', 'dean'],
} as const;

export type RoleGroup = keyof typeof RoleGroups;
export type RoleKey = Roles | Capitalize<RoleGroup>;
export type Roles = keyof typeof RoleEnum;
