import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../api/api.service';
import { DrfList, Nullable } from '../../common.model';
import { TaskOrderResponse } from '../../export-task/export.model';
import {
  StudentCreate,
  StudentGraduateQualification,
  StudentImportPayload,
  StudentImportRetrieve,
  StudentSetting,
} from '../../person/student.model';
import {
  CourseResult,
  GradeChangeRecord,
  GradeRecordOverview,
} from '../grade/grade.model';
import {
  GraduateStudent,
  GraduateStudentListParams,
  Student,
  StudentExpandField,
  StudentGraduateCertificateParams as StudentGraduateCertificateRequest,
  StudentListParams,
  StudentUpdate,
} from './student.model';

@Injectable({
  providedIn: 'root',
})
export class StudentApiService {
  readonly graduateApprovalPath = '/student-graduation-approvals/';
  readonly studentCertPath = 'student-status-certificates';
  readonly studentPath = '/students/';
  readonly studentsImportPath = '/import-students/';
  readonly publicPath = 'information/';
  importStudentUrl = '/import-students/';

  constructor(private api: ApiService) {}

  getStudents(params?: any) {
    return this.api.get<DrfList<Student>>(this.studentPath, params);
  }

  changeGrades(
    studentId: number,
    gradeChanges: Pick<CourseResult, 'id' | 'grade'>[]
  ): Observable<{ details: string }> {
    return this.api.patch(
      [this.studentPath, studentId.toString(), 'change-grade-by-staff/'],
      { enrollments: gradeChanges }
    );
  }

  create(data: StudentCreate): Observable<Student> {
    return this.api.post<Student>(this.studentPath, data);
  }

  deleteFile(fileId: number): Observable<unknown> {
    return this.api.delete('/student-file-upload/' + fileId);
  }

  getDetail<expand extends StudentExpandField | void>(
    id: number,
    params?: StudentListParams
  ): Observable<Student<expand>> {
    return this.api.get(this.studentPath + `${id}/`, params);
  }

  getGradeChangeHistory(studentId: number): Observable<GradeChangeRecord[]> {
    return this.api.get([
      this.studentPath,
      studentId.toString(),
      'grade-change-history',
    ]);
  }

  getGraduateStudents(
    params?: Nullable<GraduateStudentListParams>
  ): Observable<DrfList<GraduateStudent>> {
    return this.api.get(this.studentPath + 'graduating/', params);
  }

  /**
   * Get graduate qualification of student.
   * @param id Student ID
   */
  getGraduateQualification(
    id: number
  ): Observable<StudentGraduateQualification> {
    return this.api.get(
      this.studentPath + `${id}/grade-graduation-qualification/`
    );
  }

  getList<expand extends StudentExpandField | void>(params?: {
    [k: string]: any;
  }): Observable<DrfList<Student<expand>>> {
    return this.api.get(this.studentPath, params);
  }

  getPublicList<expand extends StudentExpandField | void>(params?: {
    [k: string]: any;
  }): Observable<DrfList<Student<expand>>> {
    return this.api.get(this.studentPath + this.publicPath, params);
  }

  getSetting(id: number): Observable<Student> {
    return this.api.get(this.studentPath + `${id}/settings/`);
  }

  importStudents(
    payload: StudentImportPayload
  ): Observable<StudentImportRetrieve> {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      let value = payload[key as keyof StudentImportPayload];
      if (typeof value === 'number') {
        value = value.toString();
      }
      if (typeof value !== 'undefined') {
        formData.append(key, value);
      }
    });
    return this.api.post(this.studentsImportPath, formData);
  }

  patch(
    id: number,
    student: Partial<StudentUpdate> | FormData
  ): Observable<Student> {
    return this.api.patch(this.studentPath + `${id}/`, student);
  }

  patchSetting(
    id: number,
    setting: Partial<StudentSetting>
  ): Observable<StudentSetting> {
    return this.api.patch(this.studentPath + `${id}/settings/`, setting);
  }

  delete(id: number): Observable<void> {
    return this.api.delete(this.studentPath + `${id}/`);
  }

  getGradeResult(id: number) {
    return this.api.get<GradeRecordOverview>(
      this.studentPath + `${id}/` + 'grade-results'
    );
  }

  getStudentListReport(params: any) {
    return this.api.getBlob(this.studentPath + 'student-list-report/', params);
  }

  requestStudentCertificate(
    payload: StudentGraduateCertificateRequest
  ): Observable<TaskOrderResponse> {
    return this.api.post(this.studentCertPath, payload);
  }

  uploadFile(studentId: number, file: File | File[]): Observable<unknown> {
    let _files: File[];
    if (!Array.isArray(file)) {
      _files = [file];
    } else {
      _files = file;
    }
    const formData = new FormData();
    _files.forEach((_file) => {
      formData.append('file_upload', _file);
    });
    formData.append('student', studentId.toString());
    return this.api.post('/student-file-upload/', formData);
  }

  postMoveCourse(
    payload: { student_section: number | null; study_plan: number | null },
    id: number
  ) {
    return this.api.patch(`/students/${id}/change-program/`, payload);
  }

  getStudentProfileReport(params: Record<string, any>): Observable<File> {
    return this.api.getBlob<File>(
      `${this.studentPath}student-profile-report/`,
      params
    );
  }

  getImportStudentTemplate(): Observable<Blob> {
    return this.api.getBlob(this.importStudentUrl);
  }
}
