import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Dropdown, DropdownApiService } from '@vru/master-data';
import { AlertService } from '@vru/services';

@Component({
  selector: 'vru-user-list-report-modal',
  templateUrl: './user-list-report-modal.component.html',
  styleUrls: ['./user-list-report-modal.component.scss'],
})
export class UserListReportModalComponent {
  facultyDropdown: Dropdown[] = [];
  fieldOfStudyDropdown: Dropdown[] = [];
  employeeTypeDropdown: Dropdown[] = [];
  workStatusDropdown: Dropdown[] = [];

  selectedFaculty?: number | null;
  selectedFieldOfStudy?: number | null;
  selectedTeacher = false;
  selectedOfficer = false;
  selectEmployeeType?: number | null;
  selectWorkStatus?: number | null;

  type = 'profile-list';
  submitted = false;

  constructor(
    private activeModal: NgbActiveModal,
    private dropdownApiService: DropdownApiService,
    private alertService: AlertService
  ) {}

  closeModal(): void {
    this.activeModal.dismiss();
  }

  onSelectFieldOfStudy(faculty: Dropdown) {
    this.fieldOfStudyDropdown = [];
    if (!faculty?.value) {
      return;
    }
    const param = {
      type: 'field_of_study',
      faculty: faculty.value,
    };
    this.dropdownApiService.getDropdown(param).subscribe({
      next: (res) => {
        this.fieldOfStudyDropdown = res.field_of_study;
      },
      error: () => {
        this.alertService.errorWithContactAdmin();
      },
    });
  }

  get selectBothRole(): boolean {
    return this.selectedTeacher && this.selectedOfficer;
  }

  get selectRole(): string {
    return this.selectedTeacher ? 'teacher' : 'officer';
  }

  get roleIsEmpty(): boolean {
    return (
      !this.selectedTeacher &&
      !this.selectedOfficer &&
      this.type === 'profile-list'
    );
  }

  downloadReport() {
    this.submitted = true;
    if (this.roleIsEmpty) {
      return;
    }
    const roleSelected = this.selectBothRole ? 'both' : this.selectRole;
    const selected = {
      faculty: this.selectedFaculty,
      field_of_study: this.selectedFieldOfStudy,
      employee_type: this.selectEmployeeType,
      work_status: this.selectWorkStatus,
    };
    if (this.type === 'profile-list') {
      this.activeModal.close({
        role: roleSelected,
        ...selected,
      });
    } else if (this.type === 'user-list') {
      this.activeModal.close({
        ...selected,
      });
    }
  }
}
