import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Course } from '@vru/master-data';

@Injectable({
  providedIn: 'root',
})
// TODO: Rename to CourseService
export class SubjectService {
  constructor(private fb: FormBuilder) {}

  // TODO: Rename to buildCourseFormGroup
  buildSubjectFormGroup(initialForm?: Partial<Course> | null): FormGroup {
    const subjectGroup: Partial<Course> = {
      code: initialForm?.code,
      credit_hour: initialForm?.credit_hour,
      name_eng: initialForm?.name_eng,
      name_thai: initialForm?.name_thai,
      id: initialForm?.id,
    };
    return this.fb.group(subjectGroup);
  }

  // TODO: Rename buildCoursesFormArray
  buildSubjectFormArray(initialForm: Partial<Course | number | null>[]): FormArray {
    const formGroups = initialForm.map((subject) => {
      return this.buildSubjectFormGroup(
        typeof subject === 'number' ? { id: subject } : subject
      );
    });
    return this.fb.array(formGroups);
  }
}
