import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcademicCalendarViewComponent } from './academic-calendar-view.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [AcademicCalendarViewComponent],
  exports: [AcademicCalendarViewComponent],
  imports: [CommonModule, NgSelectModule, NgbDatepickerModule],
})
export class AcademicCalendarViewModule {}
