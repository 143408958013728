import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StudentCharacteristics } from '@vru/master-data';

@Injectable({
  providedIn: 'root',
})
export class Tqf2Service {
  constructor(private fb: FormBuilder) {}

  buildStudentCharacteristicFormGroup(
    initial?: StudentCharacteristics
  ): FormGroup {
    return this.fb.group({
      special_characteristic: [
        initial?.special_characteristic,
        [Validators.required],
      ],
      student_activity: [initial?.student_activity, [Validators.required]],
    });
  }
}
