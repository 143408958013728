import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CarbonIconComponent } from './carbon-icon.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [CarbonIconComponent],
  exports: [CarbonIconComponent],
  imports: [AngularSvgIconModule, CommonModule, NgbPopoverModule],
})
export class CarbonIconModule {}
