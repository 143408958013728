import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { Dropdown, Primitive, ValueOf } from '../common.model';

@Injectable({
	providedIn: 'root',
})
export class DropdownApiService {
	constructor(private http: ApiService) {}

	callDropdownApi(
		params1: string | string[] | { [k: string]: Primitive },
		params2?: { [k: string]: Primitive | undefined | null },
		isPublic = false
	) {
		let typeObj: { [k: string]: Primitive } = {};
		let isOneType = false;
		if (typeof params1 === 'string') {
			typeObj = { type: params1 };
			isOneType = true;
		} else if (Array.isArray(params1)) {
			typeObj = { type: (params1 as string[]).join(',') };
		} else {
			typeObj = params1;
		}
		const params = Object.assign(params2 || {}, typeObj);
		const api = this._getDropdown(params, isPublic);
		if (isOneType) {
			return api.pipe(map((res) => res[params1 as string]));
		}
		return api;
	}

	getDropdown<V = number, C = any>(
		type: string,
		params?: { [k: string]: Primitive | undefined | null }
	): Observable<Dropdown<V, C>[]>;

	getDropdown<V = number>(
		type: string[],
		params?: { [k: string]: Primitive | undefined | null }
	): Observable<{ [T in ValueOf<typeof type>]: Dropdown<V, any>[] }>;

	getDropdown(
		params1: { [k: string]: Primitive },
		params2?: { [k: string]: Primitive | undefined | null }
	): Observable<{
		[T in keyof typeof params1]: Dropdown[];
	}>;

	getDropdown(
		params1: string | string[] | { [k: string]: Primitive },
		params2?: { [k: string]: Primitive | undefined | null }
	): Observable<Dropdown[] | { [t: string]: Dropdown[] }> {
		return this.callDropdownApi(params1, params2);
	}

	getPublicDropdown<V = number, C = any>(
		type: string,
		params?: { [k: string]: Primitive | undefined | null }
	): Observable<Dropdown<V, C>[]>;

	getPublicDropdown(
		type: string[],
		params?: { [k: string]: Primitive | undefined | null }
	): Observable<{ [T in ValueOf<typeof type>]: Dropdown[] }>;

	getPublicDropdown(
		params1: { [k: string]: Primitive },
		params2?: { [k: string]: Primitive | undefined | null }
	): Observable<{
		[T in keyof typeof params1]: Dropdown[];
	}>;

	getPublicDropdown(
		params1: string | string[] | { [k: string]: Primitive },
		params2?: { [k: string]: Primitive | undefined | null }
	): Observable<Dropdown[] | { [t: string]: Dropdown[] }> {
		return this.callDropdownApi(params1, params2, true);
	}

	private _getDropdown(
		params?: { [k: string]: Primitive },
		isPublic = false
	): Observable<{ [t: string]: Dropdown[] }> {
		const pathUrl = isPublic ? '/public-dropdown/' : '/dropdown/';
		return this.http.get(pathUrl, params);
	}
}
