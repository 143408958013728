import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  SubDistrict,
  ThaiLocationParam,
  ThaiLocationService,
} from '@vru/master-data';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from 'rxjs/operators';

@Component({
  selector: 'vru-sub-district-typeahead',
  templateUrl: './sub-district-typeahead.component.html',
  styleUrls: ['./sub-district-typeahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SubDistrictTypeaheadComponent,
      multi: true,
    },
  ],
})
export class SubDistrictTypeaheadComponent implements ControlValueAccessor {
  @Input() clearable = true;
  @Input() showAsPostalCode = false;
  @Output() subDistrictChange = new EventEmitter<SubDistrict>();

  isLoading = false;
  subDistrictDropdown: SubDistrict[] = [];
  selected?: SubDistrict;
  search$ = new Subject<string>();
  private onChange?: (val?: any) => void;
  private onTouched?: () => void;
  private onValidatorChange?: () => void;

  constructor(private thaiLocation: ThaiLocationService) {
    this.subscribeSearch();
  }

  onLocationSelect(subDistrict: SubDistrict): void {
    this.subDistrictChange.emit(subDistrict);
    this.onChange?.(subDistrict);
    this.onTouched?.();
  }

  registerOnChange(fn: (val?: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  subscribeSearch(): void {
    this.search$
      .pipe(
        distinctUntilChanged(),
        filter((text) => text != null),
        debounceTime(400),
        switchMap((searchText) => {
          this.isLoading = true;
          const params: ThaiLocationParam = !this.showAsPostalCode
            ? { value_thai: searchText }
            : { postal_code: searchText };
          return this.thaiLocation.getSubDistricts(params);
        })
      )
      .subscribe({
        next: (res) => {
          this.subDistrictDropdown = res.results;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  writeValue(subDistrict: SubDistrict): void {
    this.selected = subDistrict;
  }
}
