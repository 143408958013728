import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuConfig } from './student-information-info/student-information-info.component';

@Injectable()
export abstract class StudentInformationConfig {
  backPagePath?: string;
  graduateEmploymentPath?: string;
  menuDisplayConfigs: MenuConfig[] = [];
  readonly prototypeMenuConfigs: MenuConfig[] = [
    {
      label: 'ประวัตินักศึกษา',
      path: 'profile',
      permissible: ['teacher', 'backoffice', 'admin'],
    },
    {
      label: 'ผลการเรียน',
      path: 'grade-record',
      permissible: ['teacher', 'backoffice', 'admin'],
    },
    {
      label: 'เทียบโอน',
      path: 'credit-transfer',
      permissible: ['backoffice', 'admin'],
    },
    {
      label: 'ตรวจสอบจบ',
      path: 'verify-graduation',
      permissible: ['teacher', 'backoffice', 'admin'],
    },
    {
      label: 'ย้ายหลักสูตร',
      path: 'change-program',
      permissible: ['teacher', 'backoffice', 'admin'],
    },
    {
      label: 'สิทธิ์การใช้งาน',
      path: 'activation',
      permissible: ['teacher', 'admin'],
    },
  ];
  abstract showHeader?: boolean;
  abstract studentRecord?: {
    enableGradeChangeHistory?: boolean;
    showStudentInfo?: boolean;
  };

  constructor(protected activatedRoute: ActivatedRoute) {
    this.menuDisplayConfigs = this.prototypeMenuConfigs;
  }

  subscribeBackPageRoute(): void {
    this.activatedRoute.queryParams.subscribe({
      next: (params) => {
        this.backPagePath = params['backPage'];
      },
    });
  }
}
