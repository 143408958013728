import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { load } from 'js-yaml';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(private http: HttpClient) {}

  abbreviateFullName(
    name?: string | null,
    splitter = ' '
  ): string | undefined | null {
    if (name == null || name === '') {
      return name;
    }
    name = name.trim();
    const apartNames = name.split(splitter);
    const abbvNames = apartNames.map((apartName) => apartName[0]);
    return abbvNames.join('.') + '.';
  }

  downloadFile(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    this.downloadUrlFile(url, filename);
  }

  downloadUrlFile(url: string, filename: string, anchorTarget = '_self') {
    const element = document.createElement('a');
    document.body.appendChild(element);
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    element.target = anchorTarget;
    element.click();
    document.body.removeChild(element);
  }

  fileListToArr(fileList: FileList): Array<File> {
    const files: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (file != null) files.push(file);
    }
    return files;
  }

  isIntString(value: string) {
    return /^[0-9]*(.[0-9]+)?$/.test(value);
  }

  isPrimitiveType(value: any): boolean {
    return ['boolean', 'number', 'string'].includes(typeof value);
  }

  convertBase64ToBlob(base64: string): Blob {
    const byteString = window.atob(base64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  convertImageToUrl(file: File): Observable<string | ArrayBuffer | null> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Observable((subscriber) => {
      reader.onload = (event) => {
        subscriber.next(event.target?.result);
      };
    });
  }

  getTextWidth(text: string, font?: string): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    context.font = font || window.getComputedStyle(document.body).font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  getYamlFile(path: string): Observable<any> {
    return this.http
      .get(path, { observe: 'body', responseType: 'text' })
      .pipe(map((text) => load(text)));
  }

  pick(
    obj: { [k: string]: any },
    keys: string[],
    criteria?: (iteration: (typeof keys[number])) => boolean
  ): { [p in keyof typeof obj]: any } {
    const shallowClone: { [k: string]: any } = {};
    keys.forEach((key) => {
      if (criteria && !criteria(key)) return;
      if (obj[key] === undefined) return;
      shallowClone[key] = obj[key];
    });
    return shallowClone;
  }

  roundDecimals(n: number, digits: number) {
    const factor = Math.pow(10, digits);
    return Math.round(n * factor) / factor;
  }

  toFormData(obj: { [k: string]: number | boolean | string | Blob }): FormData {
    const formData = new FormData();
    Object.keys(obj).forEach((key) => {
      formData.append(
        key,
        obj[key] instanceof Blob ? (obj[key] as Blob) : String(obj[key])
      );
    });
    return formData;
  }
}
