<div class="card card-main d-flex w-100 h-100 p-3 p-md-4 p-lg-5 shadow-sm">
  <div class="icon-options">
    <vru-carbon-icon *vruDisplayPermission="['Student','Officer']"
      class="d-inline-block mr-3"
      description="พิมพ์ประวัตินักศึกษา"
      iconName="print"
      presetFilter="fill-dark-gray"
      [size]="26">
    </vru-carbon-icon>
    <vru-carbon-icon *vruDisplayPermission="['Student', 'backoffice', 'admin', 'guardian']"
        class="d-inline-block mr-1 mr-lg-3"
        description="แก้ไข"
        iconName="edit"
        presetFilter="fill-dark-gray"
        size="26"
        [routerLink]="['../', 'edit']">
    </vru-carbon-icon>
  </div>
  <div class="w-100 mb-4">
    <div class="d-flex flex-column align-items-center">
      <vru-upload-profile-photo radius="50%"
        [canEdit]="false"
        [src]="studentInfo?.photo || undefined">
      </vru-upload-profile-photo>
    </div>
  </div>
  <div class="card shadow-sm mb-4 p-2 p-md-3 p-lg-0">
    <vru-information-display class="p-3 p-lg-5"
        [dataMap]="studentInformationConfigs"
        [header]="'ข้อมูลทั่วไป'"
        [source]="studentInfo || {}">
    </vru-information-display>
  </div>
  <div class="card shadow-sm mb-4 p-2 p-md-3 p-lg-0">
    <vru-information-display class="p-3 p-lg-5"
        [dataMap]="studentProfileConfigs"
        [header]="'ข้อมูลส่วนตัว'"
        [source]="studentInfo || {}">
    </vru-information-display>
  </div>
  <div class="card shadow-sm mb-4 p-2 p-md-3 p-lg-5 pb-lg-0">
    <h4 class="mt-3 mb-4 px-2 px-md-3 px-lg-0">ประวัติครอบครัว</h4>
    <h5 class="mb-2 px-2 px-md-3 px-lg-0">ผู้ปกครอง</h5>
    <vru-information-display class="information-display-family p-3 p-lg-5"
        [dataMap]="familyInfoMap"
        [source]="studentInfo?.guardian || {}">
    </vru-information-display>
    <h5 class="mb-2 px-2 px-md-3 px-lg-0">บิดา</h5>
    <vru-information-display class="information-display-family p-3 p-lg-5"
        [dataMap]="familyInfoMap"
        [source]="studentInfo?.father || {}">
    </vru-information-display>
    <h5 class="mb-2 px-2 px-md-3 px-lg-0">มารดา</h5>
    <vru-information-display class="information-display-family p-3 p-lg-5"
        [dataMap]="familyInfoMap"
        [source]="studentInfo?.mother || {}">
    </vru-information-display>
    <ng-container *ngIf="studentInfo?.supporter">
      <h5 class="mb-2 px-2 px-md-3 px-lg-0">ผู้สนับสนุนการศึกษา</h5>
      <vru-information-display class="information-display-family p-3 p-lg-5"
          [dataMap]="familyInfoMap"
          [source]="studentInfo?.supporter || {}">
      </vru-information-display>
    </ng-container>
  </div>
  <div class="card shadow-sm mb-4 p-2 p-md-3 p-lg-0">
    <vru-information-display class="p-3 p-lg-5"
        [header]="'ประวัติการศึกษา'"
        [dataMap]="educationProfileMap"
        [source]="studentInfo || {}">
    </vru-information-display>
  </div>
  <div class="card shadow-sm mb-3 mb-lg-0 p-2 p-md-3 p-lg-0">
    <div class="p-lg-5 p-3">
      <h4 class="mb-3">เอกสารที่เกี่ยวข้อง</h4>
      <ul *ngIf="studentInfo?.student_file_uploads?.length"
          class="pl-4">
        <li *ngFor="let file of studentInfo?.student_file_uploads">
          <a href="{{ file.file_upload }}"
              target="_blank"
              rel="noopener noreferrer">
            {{ file.file_name }}
          </a>
        </li>
      </ul>
      <label *ngIf="!studentInfo?.student_file_uploads?.length"
          class="font-italic pl-4">
        ไม่มีเอกสารแนบ
      </label>
    </div>
  </div>
</div>