import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DrfList } from '../common.model';
import {
  CourseSection,
  CourseSectionExpandKey,
  CourseSectionParams,
  CourseSectionTimetable,
  CourseSectionUpdate,
  CourseSectionUpdateResponse,
} from './course-section.model';

@Injectable({
  providedIn: 'root',
})
export class CourseSectionApiService {
  courseSectionPath = '/course-sections/';
  readonly timeManagement = '/timetable-managements/';
  constructor(private api: ApiService) {}

  delete(id: number): Observable<void> {
    return this.api.delete(this.courseSectionPath + `${id}/`);
  }

  getDetail<Expand extends void | CourseSectionExpandKey = void>(
    id: number,
    params?: any
  ): Observable<CourseSection<Expand>> {
    return this.api.get(this.courseSectionPath + `${id}/`, params);
  }

  getList<Expand extends CourseSectionExpandKey>(
    params?: CourseSectionParams
  ): Observable<DrfList<CourseSection<Expand>>> {
    return this.api.get(this.courseSectionPath, params);
  }

  getTimetableDetail(
    id: number,
    params: Params
  ): Observable<CourseSectionTimetable[]> {
    return this.api.get([this.timeManagement, id.toString()], params);
  }

  patch(
    id: number,
    payload: Partial<CourseSectionUpdate>
  ): Observable<CourseSectionUpdateResponse> {
    return this.api.patch(this.courseSectionPath + `${id}/`, payload);
  }

  patchTimeTable(
    id: number,
    payload: Partial<CourseSectionUpdate>
  ): Observable<unknown> {
    // TODO: Assign type of retrieving
    return this.api.patch(this.timeManagement + `${id}/`, payload);
  }

  post(payload: Omit<CourseSectionUpdate, 'id'>) {
    return this.api.post(this.courseSectionPath, payload);
  }
}
