<div class="d-flex flex-column-reverse align-items-end">
  <vru-carbon-icon class="d-flex"
      iconName="bell"
      [size]="34"
      popoverClass="notification-container"
      display="dynamic"
      [popoverTitle]="notificationHeader"
      [autoClose]="'outside'"
      [ngbPopover]="notificationBody">
  </vru-carbon-icon>
  <ng-container *ngIf="unreadCount">
    <span class="position-absolute badge badge-pill bg-danger text-white pointer"
        popoverClass="notification-container"
        display="dynamic"
        [popoverTitle]="notificationHeader"
        [autoClose]="'outside'"
        [ngbPopover]="notificationBody">
      {{ unreadCount > 99 ? '99+' : unreadCount }}
    </span>
  </ng-container>
</div>

<ng-template #notificationHeader>
  <div class="d-flex flex-wrap align-items-end justify-content-between">
    <div class="d-flex flex-nowrap align-items-center">
      <span class="bi bi-bell-fill text-green-vru mr-1 font-content"></span>
      <h6 class="mb-0">การแจ้งเตือน</h6>
    </div>
    <div class="d-flex flex-nowrap">
      <span class="pointer"
          (click)="readAllNotification()">อ่านทั้งหมด</span>
    </div>
  </div>
</ng-template>

<ng-template #notificationBody>
  <div class="d-flex flex-column"
      [class.spinner-blur-effect]="isLoading">
    <ng-container *ngIf="notificationList.length > 0; else notificationNone">
      <ng-container *ngFor="let notification of notificationList; let i=index">
        <div class="col-12 d-flex no-gutters px-0 align-items-center border-bottom pointer"
            (click)="onClickNotification(notification)">
          <div class="col py-2 d-flex flex-column notification-content">
            <div class="col overflow-hidden">
              <p class="font-content mb-0">
                {{ notification.content || '-' }}
              </p>
            </div>
            <div class="col-auto">
              <span class="font-date">{{ notification.time_since || '-' }}</span>
            </div>
          </div>
          <ng-container *ngIf="!notification.read">
            <span class="text-red bi bi-circle-fill px-2"></span>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #notificationNone>
      <div class="d-flex flex-column align-items-center justify-content-center notification-none">
        <h1 class="bi bi-bell text-secondary"></h1>
        <h5 class="text-secondary mb-0">ไม่พบการแจ้งเตือน</h5>
      </div>
    </ng-template>
    <div class="d-flex justify-content-center mt-2">
      <ngb-pagination size="sm"
          [maxSize]="3"
          [(page)]="pagePopover"
          [pageSize]="pageSizePopOver"
          [collectionSize]="popoverTotalCount"
          (pageChange)="getNotificationList()">
      </ngb-pagination>
    </div>
  </div>
  <div class="container-spinner-float">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
        name="notification-detail"
        color="#3cd070"
        size="medium"
        type="ball-clip-rotate-pulse"
        [fullScreen]="false"
        [showSpinner]="isLoading">
    </ngx-spinner>
  </div>
</ng-template>