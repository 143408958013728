import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

export class NgbTimePickerAdapter implements NgbTimeAdapter<string> {
  splitter = ':';

  /**
   * @description
   * Number of position by counting from hour, minute, second.
   * For example, if you need `HH`:`mm` place this field to 2
   */
  timePosition = 2;

  fromModel(timeStr: string | null): NgbTimeStruct | null {
    if (timeStr == null) {
      return null
    }
    let timeParts = ['0', '0', '0'];
    timeParts = timeStr.split(this.splitter);
    if (timeParts.length < this.timePosition) {
      throw new Error('Time value from model is incorrect format');
    }
    return {
      hour: Number(timeParts[0]),
      minute: Number(timeParts?.[1] || 0),
      second: Number(timeParts?.[2] || 0),
    };
  }

  toModel(time: NgbTimeStruct): string | null {
    if (!time) {
      return null;
    }
    let timeStr: string | null = null;
    const padTime = (time: number) => {
      return time.toString().padStart(2, '0')
    }
    if (this.timePosition >= 1) {
      timeStr = padTime(time.hour);
    }
    if (this.timePosition >= 2) {
      timeStr = timeStr + `:${padTime(time.minute)}`;
    }
    if (this.timePosition >= 3) {
      timeStr = timeStr + `:${padTime(time.second)}`;
    }
    return timeStr;
  }
}
