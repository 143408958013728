import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderingDropdown } from '@vru/master-data';

@Component({
  selector: 'vru-dropdown-ordering',
  templateUrl: './dropdown-ordering.component.html',
  styleUrls: ['./dropdown-ordering.component.scss']
})
export class DropdownOrderingComponent {
  @Input() sortDropdown: OrderingDropdown[] = [];
  @Output() sortChange = new EventEmitter<string>();

  orderBy = null;

  onOrderChange(orderBy?: string) {
    this.sortChange.emit(orderBy);
  }
}
