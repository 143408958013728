import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondToHour',
})
export class SecondToHourPipe implements PipeTransform {
  transform(value: number): number {
    return Math.round(value / 3600);
  }
}
