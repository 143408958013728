/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { GradeRange } from '@vru/master-data';
import { GradeScoreLowerBoundary } from './grade.model';

@Injectable({
  providedIn: 'root',
})
export class GradeService {
  readonly zScoreGradeBoundaries: ReadonlyArray<GradeScoreLowerBoundary> = [
    { grade: 'A', zScore: 3 },
    { grade: 'B+', zScore: 2 },
    { grade: 'B', zScore: 1 },
    { grade: 'C+', zScore: 0 },
    { grade: 'C', zScore: -1 },
    { grade: 'D+', zScore: -2 },
    { grade: 'D', zScore: -3 },
  ];

  constructor() {}

  orderDescendGradeRanges(ranges: GradeRange[]): GradeRange[] {
    return ranges.sort((a, b) => b.max_value - a.max_value);
  }

  toFixRateGrade(scoreTest: number, ranges: GradeRange[]) {
    return this.orderDescendGradeRanges(ranges).find(
      (range) => range.max_value > scoreTest
    );
  }

  toPassNotPassGrade(
    scoreTest: number,
    ranges: GradeRange[]
  ): GradeRange | undefined {
    return this.toFixRateGrade(scoreTest, ranges);
  }
}
