import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngxs/store';
import {
  RoleGroup,
  RoleGroups,
  RoleKey,
  Roles,
  UserProfile,
} from '@vru/master-data';
import { AlertService } from '@vru/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export abstract class RoleBaseGuard
  implements CanActivate, CanActivateChild, CanLoad
{
  /**
   * If `false` it will return as snapshot role.
   * Otherwise, `true` is default, it will use store with `selectOne`.
   */
  protected isAsync = true;
  /**
   * If it exist path, it will return as `UrlTree` when no permission.
   * Otherwise, you can manually navigate.
   */
  protected outletPath?: string;
  /**
   * The user role which is on `Roles` type that you allow for the guard.
   * Overriding this field you may have to import `Roles` type to this variable in your class.
   * @example
   * 
      import { Roles } from '@vru/master-data';

      export class StudentProfileFormGuard extends RoleBaseGuard {
        permissive: Roles[] = ['admin', 'backoffice', 'student'];
      }
   */
  protected permissive: RoleKey[] = [];

  constructor(
    private alert: AlertService,
    private router: Router,
    private store: Store
  ) {}

  canActivate(): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.getRolePermission();
  }

  canActivateChild(): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.getRolePermission();
  }

  canLoad(): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.getRolePermission();
  }

  checkRoleAsAsync(): Observable<boolean | UrlTree> {
    return this.store
      .selectOnce<UserProfile>((state) => state.auth)
      .pipe(
        map((user) => {
          const permissive = this.flatPermissive(this.permissive);
          const canAccept = permissive.includes(user.role);
          if (!canAccept) {
            this.alert.noPermit();
            if (this.outletPath) {
              return this.router.parseUrl(this.outletPath);
            }
          }
          return canAccept;
        })
      );
  }

  checkRoleAsSnapshot(): boolean | UrlTree {
    const user = this.store.selectSnapshot<UserProfile>((state) => state.auth);
    const permissive = this.flatPermissive(this.permissive);
    const canAccept = permissive.includes(user.role);
    if (!canAccept) {
      this.alert.noPermit();
      if (this.outletPath) {
        return this.router.parseUrl(this.outletPath);
      }
    }
    return canAccept;
  }

  // TODO: Move to a permission service
  flatPermissive(permissive: RoleKey[]): Roles[] {
    return permissive.reduce((arr, role) => {
      const roleGroupCheck = this.isRoleGroup(role);
      if (roleGroupCheck) {
        arr.push(...this.retrieveRolesFrom(role.toLowerCase() as RoleGroup));
      } else {
        arr.push(role as Roles);
      }
      return arr;
    }, [] as Roles[]);
  }

  getRolePermission(): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.isAsync ? this.checkRoleAsAsync() : this.checkRoleAsSnapshot();
  }

  // TODO: Move to a permission service
  isRoleGroup(roleGroup: string) {
    const isCapitalize = /^[A-Z]/.test(roleGroup);
    if (!isCapitalize) return false;
    const roleGroups = Object.keys(RoleGroups);
    return roleGroups.includes(roleGroup.toLowerCase());
  }

  // TODO: Move to a permission service
  retrieveRolesFrom(roleGroup: RoleGroup): Roles[] {
    return [...RoleGroups[roleGroup]];
  }
}
