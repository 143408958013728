import { Injectable } from '@angular/core';
import { RoleGroups } from '@vru/master-data';

@Injectable()
export abstract class DisplayPermissionService {
  acceptable: string[] = [];

  constructor() {}

  abstract get selfPermission(): string;

  get canAccept(): boolean {
    return this.acceptable.includes(this.selfPermission);
  }

  get roleGroups(): { readonly [groupName: string]: readonly string[] } {
    return RoleGroups;
  }
}
