import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Directive({
  selector: '[requiredLang]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: LanguageValidatorDirective,
      multi: true,
    },
  ],
})
export class LanguageValidatorDirective implements Validator {
  @Input() requiredLang: 'en' | 'th' = 'en';

  validate(control: AbstractControl): ValidationErrors | null {
    return LanguageValidator()(this.requiredLang, control);
  }
}

export function LanguageValidator(): (
  lang: 'en' | 'th',
  control: AbstractControl
) => ValidationErrors | null {
  return (lang: string, control: AbstractControl) => {
    const text = control.value;
    if (typeof text !== 'string') {
      return {
        valueType: {
          required: 'string',
          actual: typeof text,
        },
      };
    }
    let tester: RegExp;
    if (lang === 'th') {
      tester = /(^\s?[\u0E00-\u0E7F]+\s?)/;
    } else {
      tester = /[A-z]+/;
    }
    const isCorrect = tester.test(text);
    return isCorrect
      ? null
      : {
          language: {
            required: lang,
            actualValue: text,
          },
        };
  };
}
