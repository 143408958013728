import { Pipe, PipeTransform } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Pipe({
  name: 'vruSubjectFormGroupRetriever',
})
export class SubjectFormGroupRetrieverPipe implements PipeTransform {
  transform(courseStructureForm: FormGroup): FormGroup[] {
    return (courseStructureForm?.get('subjects') as FormArray)
      .controls as FormGroup[];
  }
}
