import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DrfList } from '../common.model';
import { News, NewsFeedParams, UniversitySummaryCount } from './news-feed.model';

@Injectable({
  providedIn: 'root',
})
export class NewsFeedService {
  readonly newsManagementsUrl = '/news-managements/';
  readonly summaryCountUrl = '/summary-count/';

  constructor(private http: ApiService) {}

  getSummaryCount(): Observable<UniversitySummaryCount> {
    return this.http.get<UniversitySummaryCount>(this.summaryCountUrl);
  }

  getNews(params?: NewsFeedParams): Observable<DrfList<News>> {
    return this.http.get<DrfList<News>>(`/news/`, params);
  }

  getNewsPersonal(params?: NewsFeedParams): Observable<DrfList<News>> {
    return this.http.get<DrfList<News>>(`/news/me/`, params);
  }


  getNewsDetail(id: number, params?: NewsFeedParams): Observable<News> {
    return this.http.get<News>(`/news/` + `${id}/`, params);
  }

  postNews(params: News): Observable<News> {
    return this.http.post<News>(this.newsManagementsUrl, params)
  }

  patchNews(id: number, params: News): Observable<News> {
    return this.http.patch<News>(this.newsManagementsUrl + `${id}/` , params);
  }

  deleteNews(id: number) {
    return this.http.delete(this.newsManagementsUrl + `${id}/`);
  }

  patchImageCover(id: number, params: FormData): Observable<News> {
    return this.http.patch<News>(this.newsManagementsUrl + `${id}/`, params);
  }
}
