import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { idLastMatcher } from '@vru/utils';
import { GradeSubmissionDetailComponent } from './grade-submission-detail/grade-submission-detail.component';
import { GradeSubmissionDetailResolver } from './grade-submission-detail/grade-submission-detail.resolver';
import { GradeSubmissionListComponent } from './grade-submission-list/grade-submission-list.component';

const routes: Routes = [
  { path: 'list', component: GradeSubmissionListComponent },
  {
    matcher: idLastMatcher,
    component: GradeSubmissionDetailComponent,
    resolve: { result: GradeSubmissionDetailResolver },
    runGuardsAndResolvers: 'always',
  },
  {
    path: '',
    redirectTo: 'list',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GradeSubmissionRoutingModule {}
