/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ComponentSubscriptionService } from '@vru/services';
import {
  asyncScheduler,
  iif,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { NgSelectLabelDirective } from './directives/ng-select-label-tmp.directive';
import { NgSelectMultiLabelDirective } from './directives/ng-select-multi-label-tmp.directive';
import { NgSelectOptionDirective } from './directives/ng-select-option-tmp.directive';

@Component({
  selector: 'vru-ng-select-typeahead',
  templateUrl: './ng-select-typeahead.component.html',
  styleUrls: ['./ng-select-typeahead.component.scss'],
  providers: [
    ComponentSubscriptionService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NgSelectTypeaheadComponent,
    },
  ],
})
export class NgSelectTypeaheadComponent<Data, Response = Data[]>
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() bindLabel = 'label';
  @Input() bindValue!: string;
  @Input() clearable = true;
  @Input() dropdown: Array<Data> = [];
  @Input() loading = false;
  @Input() minTermLength = 2;
  @Input() multiple = false;
  @Input() placeholder!: string;
  @Input() appendTo = '';
  @Input() addTag = false;
  @Input() addTagText!: string;
  /**
   * Set input to read only mode.
   * The `true` value is preferred when it is used with `setDisabledState`.
   */
  @Input() readonly = false;
  @Input() selectedValue!: Data;
  @Input() showAllAsDefault = false;
  @Input() startWith?: Array<Data>;
  @Input() typeToSearchText = 'กรุณาพิมพ์ 2 ตัวอักษรเพื่อค้นหา';
  @Output() selectedValueChange = new EventEmitter<Data>();
  @ContentChild(NgSelectLabelDirective) labelContent!: NgSelectLabelDirective;
  @ContentChild(NgSelectMultiLabelDirective) multiLabelContent!: NgSelectMultiLabelDirective;
  @ContentChild(NgSelectOptionDirective) optionContent!: NgSelectOptionDirective;

  dropdown$!: Observable<Data[]>;
  formControl = new FormControl();
  search$ = new Subject<string>();
  touched = false;

  private _onChangeSubs: Subscription[] = [];
  /**
   * The disable state of the input which come from `setDisabledState`.
   */
  private disabled = false;

  @Input() accessor: (res: Response) => Data[] = (res) =>
    res as unknown as Data[];
  /** A function that access data from response to return array of data */
  @Input() dataApiGenerator: (search: string) => Observable<Response> = (
    search
  ) => of([{ label: search, value: search }] as unknown as Response);

  private _onTouched = () => {};

  constructor(
    private cdRef: ChangeDetectorRef,
    private compSubsService: ComponentSubscriptionService
  ) {}

  ngOnInit(): void {
    this.formControl.setValue(this.selectedValue, { emitEvent: false });
    const search$ = this.search$.pipe(
      distinctUntilChanged(),
      debounceTime(200)
    );
    this.dropdown$ = iif(
      () => this.showAllAsDefault,
      search$.pipe(startWith('')),
      search$.pipe(filter((text) => text != null && text !== ''))
    ).pipe(
      tap(() => this.loading = true),
      switchMap((text) => this.dataApiGenerator(text).pipe(delay(100))),
      map(this.accessor)
    );
    this.compSubsService.store.dropdown = this.dropdown$.subscribe({
      next: (res) => {
        this.loading = false;
        if (!Array.isArray(res)) {
          throw new Error(
            'Data response has not been array. Use the accessor to convert type'
          );
        }
        this.dropdown = res;
        this.cdRef.markForCheck();
      },
      error: (err) => {
        this.loading = false;
        console.error(err);
      },
    });
  }

  ngOnDestroy(): void {
    this.compSubsService.destroy();
  }

  markAsTouched(): void {
    if (!this.touched) {
      this._onTouched();
      this.touched = true;
    }
  }

  onOpen(): void {
    if (!this.showAllAsDefault) {
      return;
    }
    if (this.selectedValue == null && this.dropdown.length === 0) {
      this.dataApiGenerator('')
        .pipe(take(1), map(this.accessor))
        .subscribe({
          next: (res) => (this.dropdown = res),
        });
    }
  }

  onSelect(selectedData: Data): void {
    this.selectedValue = selectedData;
    this.markAsTouched();
    this.selectedValueChange.emit(this.selectedValue);
  }

  registerOnChange(onChangeFn: any): void {
    this.compSubsService.add =
      this.formControl.valueChanges.subscribe(onChangeFn);
    this._onChangeSubs.push(onChangeFn);
  }

  registerOnTouched(onTouchFn: any): void {
    this._onTouched = onTouchFn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(data: Data): void {
    if (!this.dropdown.length) {
      if (this.startWith) {
        this.dropdown = this.startWith;
        asyncScheduler.schedule(() => this.dropdown = []);
      } else if (data && typeof data === 'object') {
        if ((data as { [k: string]: any })[this.bindLabel]) {
          this.dropdown = [data];
        }
      }
    }
    this.formControl.setValue(data, { emitEvent: false });
    // TODO: Need implement
    this.selectedValue = data;
  }

  get _readonly(): boolean {
    return this.readonly || this.disabled;
  }
}
