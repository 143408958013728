import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable({ providedIn: 'root' })
export class CustomNgbDateAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[0], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    if (date) {
      const dateSet = {
        year: date.year,
        month: date.month - 1,
        date: date.day,
      };
      const format = 'YYYY' + this.DELIMITER + 'MM' + this.DELIMITER + 'DD';
      return moment().set(dateSet).format(format);
    }
    return null;
  }
}
