<div class="d-inline-flex pointer text-green-shamrock mt-3"
    (click)="onDownloadTemplateClick()">
  <div class="position-relative px-1">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
        class="d-block px-2"
        name="template-download"
        color="#2d7054"
        size="small"
        type="ball-clip-rotate"
        [fullScreen]="false"
        [showSpinner]="loading">
    </ngx-spinner>
    <span class="bi bi-file-earmark-spreadsheet-fill h5 mb-0"
        [class.invisible]="loading">
    </span>
  </div>
  <a class="text-green-vru mx-1">Download Template</a>
</div>