/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import {
  CourseResultWithStatus,
  ErrorMessages,
  Grade,
  GradeChangeRecord,
  GradeRecord as PrototypeGradeRecord,
  GradeRecordOverview,
  Grades,
  RoleGroups,
  Student,
  StudentApiService,
  TransferredCourse,
  UserProfile,
} from '@vru/master-data';
import { AlertService, ComponentSubscriptionService } from '@vru/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { MapConfigs } from '../../information-display/information-display.model';
import { StudentAccessorComponent } from '../student-accessor/student-accessor.component';
import { StudentInformationConfig } from '../student-information.config';

@Component({
  selector: 'vru-student-grade-record',
  templateUrl: './student-grade-record.component.html',
  styleUrls: ['./student-grade-record.component.scss'],
  providers: [ComponentSubscriptionService],
})
export class StudentGradeRecordComponent
  extends StudentAccessorComponent
  implements OnInit
{
  @ViewChild('gradeChangeHistoryTemp') gradeChangeHistoryTemp!: ElementRef;

  configs: { [k: string]: boolean } = {};
  courseGradeEdit: (GradeRecordChange | undefined | null)[] = [];
  gradeDetails: GradeRecord[] = [];
  gradeOptions: Grade[] = Object.keys(Grades)
    .filter((key) => /[^0-9]/.test(key))
    .map<Grade>((key) => key as Grade);
  gradeGPAX!: GradeRecordOverview;
  gradeChangeRecords: GradeChangeRecord[] = [];
  studentId!: number;
  studentInfo: Partial<Student> = {};
  studentDisplayConfigs: MapConfigs[] = [
    { label: 'รหัสนักศึกษา', path: 'code', maxSize: 'trimester' },
    { label: 'ชื่อ', path: 'full_name_thai', maxSize: 'trimester' },
    { label: 'ระดับการศึกษา', path: 'degree.name_thai', maxSize: 'trimester' },
    { label: 'หลักสูตร', path: 'program.name', maxSize: 'trimester' },
    { label: 'คณะ', path: 'faculty.name_thai', maxSize: 'trimester' },
    {
      label: 'สาขา',
      path: 'program.field_of_study.name_thai',
      maxSize: 'trimester',
    },
    { label: 'ชั้นปี', path: 'college_year', maxSize: 'trimester' },
    {
      label: 'อาจารย์ที่ปรึกษา',
      path: 'advisors',
      arrayPath: 'name',
      maxSize: 'trimester',
    },
  ];
  isLoading = true;
  isGradeChangeRecordLoading = false;
  private currentUser!: UserProfile;

  constructor(
    private alert: AlertService,
    private activatedRoute: ActivatedRoute,
    protected config: StudentInformationConfig,
    private ngbModal: NgbModal,
    protected router: Router,
    private spinner: NgxSpinnerService,
    protected store: Store,
    private studentApi: StudentApiService
  ) {
    super(activatedRoute, router, store);
    this.configs = {
      ...(this.config.studentRecord || {}),
      showHeader: this.config.showHeader || false,
    };
  }

  ngOnInit(): void {
    this.spinner.show('loading-student-record-spinner');
    this.spinner.show('course-grade-edit-spinner');
    super.ngOnInit();
    this.getCurrentUser();
  }

  private getCurrentUser(): void {
    this.currentUser = this.store.selectSnapshot((state) => state.auth);
  }

  rejectAccessStudentId(): void {
    this.goToBackPage();
  }

  resolveAccessStudentId(studentId: number): void {
    this.studentId = studentId;
    this.fetchData();
  }

  takeStudentGrade() {
    this.isLoading = true;
    this.studentId = this.activatedRoute.parent?.snapshot.params.id;
    this.fetchData();
  }

  fetchData(): void {
    forkJoin({
      student: this.studentApi.getDetail<void>(this.studentId, {
        expand: ['advisors', 'degree', 'faculty', 'program.field_of_study'],
      }),
      grades: this.studentApi.getGradeResult(this.studentId),
    }).subscribe({
      next: (res) => {
        this.studentInfo = {
          ...res.student,
          full_name_thai:
            res.student.first_name_thai + ' ' + res.student.last_name_thai,
        };
        this.gradeDetails = this.makeUpGradeResults(res.grades.details);
        this.gradeGPAX = res.grades;
        this.isLoading = false;
      },
    });
  }

  makeUpGradeResults(gradeRecords: PrototypeGradeRecord[]): GradeRecord[] {
    return gradeRecords.map((gradeRecord) => {
      return {
        ...gradeRecord,
        courseRecords: [
          ...gradeRecord.enrollments,
          ...gradeRecord.student_transferred_courses,
        ],
      };
    });
  }

  initializeGradeEdit(course: CourseResultWithStatus | TransferredCourse) {
    this.courseGradeEdit[course.id] = {
      previous: course.grade,
      editing: false,
    };
  }

  onEditSubmit(course: CourseResultWithStatus | TransferredCourse) {
    const updateCourse = this.courseGradeEdit[course.id] as GradeRecordChange;
    updateCourse.isUpdating = true;
    this.studentApi
      .changeGrades(this.studentId, [{ id: course.id, grade: course.grade }])
      .subscribe({
        next: (res) => {
          this.alert.success(res.details);
          updateCourse.editing = false;
          updateCourse.previous = course.grade;
          updateCourse.isUpdating = false;
          this.fetchData();
        },
        error: (err) => {
          console.error(err);
          if (err.status === 400) {
            this.alert.error(
              err.error.enrollments?.[0] ||
                ErrorMessages.common.plsCheckOrAdmin,
              4000
            );
          } else {
            this.alert.errorWithContactAdmin();
          }
          updateCourse.isUpdating = false;
        },
      });
  }

  onGradeEditCancel(course: CourseResultWithStatus | TransferredCourse) {
    if (!this.courseGradeEdit[course.id]) {
      this.initializeGradeEdit(course);
    }
    this.courseGradeEdit[course.id]!.editing = false;
    course.grade = this.courseGradeEdit[course.id]!.previous;
  }

  onGradeEditClick(course: CourseResultWithStatus | TransferredCourse) {
    if (!this.courseGradeEdit[course.id]) {
      this.initializeGradeEdit(course);
    }
    this.courseGradeEdit[course.id]!.editing = true;
  }

  onOpenModalGradeChange() {
    this.isGradeChangeRecordLoading = true;
    this.studentApi.getGradeChangeHistory(this.studentId).subscribe({
      next: (res) => {
        this.gradeChangeRecords = res;
        this.ngbModal.open(this.gradeChangeHistoryTemp, {
          centered: true,
          scrollable: true,
          size: 'xl',
          windowClass: 'modal-vh80',
        });
        this.isGradeChangeRecordLoading = false;
      },
      error: () => {
        this.isGradeChangeRecordLoading = false;
      },
    });
  }

  get editView(): boolean {
    const permitRoles = [...(RoleGroups.backoffice as readonly string[])];
    return permitRoles.includes(this.currentUser.role);
  }
}

export interface GradeRecord extends PrototypeGradeRecord {
  courseRecords: (TransferredCourse | CourseResultWithStatus)[];
}

export interface GradeRecordChange {
  editing: boolean;
  previous: string | null;
  isUpdating?: boolean;
}
