import { NgModule } from '@angular/core';
import { Route, RouterModule, UrlSegment } from '@angular/router';
import { GraduateQualificationResolver } from '@vru/services';
import { GraduationVerificationComponent } from './graduation-verification/graduation-verification.component';
import { StudentCreditTransferComponent } from './student-credit-transfer/student-credit-transfer.component';
import { StudentGradeRecordComponent } from './student-grade-record/student-grade-record.component';
import { StudentInformationInfoComponent } from './student-information-info/student-information-info.component';
import { StudentProfileFormGuard } from './student-profile-form.guard';
import { StudentProfileFormComponent } from './student-profile-form/student-profile-form.component';
import { StudentProfileComponent } from './student-profile/student-profile.component';
import { StudentSubjectComponent } from './student-subject/student-subject.component';
import { StudentActivationComponent } from './student-activation/student-activation.component';
import { StudentChangeProgramComponent } from './student-information-info/student-change-program/student-change-program.component';

const defaultMatcher = (url: UrlSegment[]) => {
  return url.length >= 1 && url[0].path.match(/^[0-9]*$/)
    ? { consumed: url.slice(0, 1), posParams: { id: url[0] } }
    : null;
};

const overviewMatcher = (url: UrlSegment[]) => {
  return url.length >= 1 && url[0].path.match(/^overview$/)
    ? { consumed: url.slice(0, 1) }
    : null;
};

const childRoute: Route[] = [
  {
    path: '',
    redirectTo: 'profile',
  },
  {
    path: 'credit-transfer',
    component: StudentCreditTransferComponent,
  },
  {
    path: 'edit',
    component: StudentProfileFormComponent,
    canActivate: [StudentProfileFormGuard],
  },
  {
    path: 'profile',
    component: StudentProfileComponent,
  },
  {
    path: 'grade-record',
    component: StudentGradeRecordComponent,
  },
  {
    path: 'activation',
    component: StudentActivationComponent,
  },
  {
    path: 'verify-enrollment',
    component: StudentSubjectComponent,
  },
  {
    path: 'change-program',
    component: StudentChangeProgramComponent,
  },
  {
    path: 'verify-graduation',
    component: GraduationVerificationComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      graduateQualification: GraduateQualificationResolver,
    },
  },
];

const routes: Route[] = [
  {
    matcher: defaultMatcher,
    children: [
      {
        matcher: overviewMatcher,
        component: StudentInformationInfoComponent,
        children: childRoute,
      },
      ...childRoute,
    ],
  },
  {
    path: 'create',
    component: StudentProfileFormComponent,
  },
  {
    path: '',
    children: childRoute,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  // ** If you use default provider, copy line below to your module.
  // providers: [GraduateQualificationResolver],
})
export class StudentInformationRoutingModule {}
