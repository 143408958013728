import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Params } from '@angular/router';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  Address,
  ApiService,
  FamilyIncomeRanges,
  Father,
  Guardian,
  Mother,
  NestedNullable,
  Nullable,
  Student,
  Supporter,
} from '@vru/master-data';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DictGeneratorService } from '@vru/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormService } from './form.service';

@Injectable({
  providedIn: 'root',
})
export class StudentService {
  constructor(
    private fb: FormBuilder,
    private form: FormService,
    private dictGen: DictGeneratorService,
    private apiService: ApiService
  ) {}

  buildAddressFormGroup(address?: Nullable<Address> | null): FormGroup {
    const keys = [
      'house_no',
      'moo',
      'village',
      'soi',
      'road',
      'sub_district',
    ] as const;
    const initial = keys.reduce((obj, key) => {
      obj[key] = (address?.[key] || null) as any;
      return obj;
    }, {} as Params);
    return this.fb.group(initial);
  }

  buildStudentForm(
    student?: Nullable<Student<'father' | 'mother' | 'supporter' | 'guardian'>>
  ): FormGroup {
    const plainKeys: (keyof Student)[] = [
      'address_id_card',
      'advisors',
      'blood_type',
      'birth_date',
      'code',
      'congenital_disease',
      'disability',
      'first_name_eng',
      'gpax',
      'id_card_expired',
      'id_card_issued',
      'last_name_eng',
      'medical_history',
      'name_of_brothers_sisters',
      'nationality',
      'photo',
      'previous_education_level_name',
      'previous_field_of_study',
      'previous_academy_gpax',
      'race',
      'religion',
      'province',
      'special_interest',
      'student_file_uploads',
      'supporter',
    ];
    const requiredKeys: (keyof Student)[] = [
      'advisors',
      'campus',
      'college_year',
      'degree',
      'faculty',
      'first_name_eng',
      'first_name_thai',
      'gender',
      'guardian_type',
      'id_card',
      'last_name_eng',
      'last_name_thai',
      'name_title',
      'phone_number',
      'previous_academy',
      'previous_education_level',
      'program',
      'qualification',
      'start_academic_year',
      'student_section',
      'student_type',
    ];
    const plainInit = this.form.buildFormGroupInitializer(plainKeys, student);
    const requiredInit = this.form.buildFormGroupInitializer(
      requiredKeys,
      student,
      [Validators.required]
    );
    return this.fb.group({
      ...plainInit,
      ...requiredInit,
      address_current: this.buildAddressFormGroup(student?.address_current),
      advisors: [[]],
      email: [
        student?.email,
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      father: this.buildFatherForm(student?.father),
      guardian: this.buildGuardianForm(student?.guardian),
      mother: this.buildMotherForm(student?.mother),
      name_of_brothers_sisters: this.fb.array([]),
      student_file_uploads: this.fb.array([]),
      supporter: this.buildSupporterForm(student?.supporter),
    });
  }

  buildFatherForm(father?: Father | null): FormGroup {
    const keys: (keyof Father)[] = [
      'address_selected',
      'address',
      'income',
      'marital_status',
      'first_name',
      'last_name',
      'occupation',
      'phone_number',
      'email',
      'status',
      'remark',
    ];
    const fatherInit = this.form.buildFormGroupInitializer<keyof Father>(
      keys,
      father
    );
    fatherInit.phone_number[1] = [Validators.maxLength(10)];
    return this.fb.group(fatherInit);
  }

  buildGuardianForm(guardian?: Guardian | null): FormGroup {
    const keys: (keyof Guardian)[] = [
      'address_selected',
      'address',
      'income',
      'first_name',
      'last_name',
      'occupation',
      'phone_number',
      'email',
      'remark',
    ];
    const guardianInit = this.form.buildFormGroupInitializer<keyof Guardian>(
      keys,
      guardian
    );
    guardianInit.phone_number[1] = [Validators.maxLength(10)];
    return this.fb.group(guardianInit);
  }

  buildMotherForm(mother?: Mother | null): FormGroup {
    const keys: (keyof Mother)[] = [
      'address_selected',
      'address',
      'income',
      'marital_status',
      'first_name',
      'last_name',
      'occupation',
      'phone_number',
      'email',
      'status',
      'remark',
    ];
    const motherInit = this.form.buildFormGroupInitializer<keyof Mother>(
      keys,
      mother
    );
    motherInit.phone_number[1] = [Validators.maxLength(10)];
    return this.fb.group(motherInit);
  }

  buildSupporterForm(supporter?: Supporter | null): FormGroup {
    const keys: (keyof Supporter)[] = [
      'address',
      'address_selected',
      'income',
      'first_name',
      'last_name',
      'occupation',
      'email',
      'phone_number',
      'status',
      'remark',
    ];
    const initial = this.form.buildFormGroupInitializer<keyof Mother>(
      keys,
      supporter
    );
    return this.fb.group(initial);
  }

  generateStudent(): Observable<
    NestedNullable<Student<'father' | 'mother' | 'supporter' | 'guardian'>>
  > {
    return this.dictGen.getAddress().pipe(
      map((address) => {
        const first_name_eng = this.dictGen.getName();
        const last_name_eng = this.dictGen.getName();
        return {
          address_current: address,
          address_id_card: address,
          advisors: [],
          birth_date: (() => {
            const date = this.dictGen.randomDate(
              new Date('2006'),
              new Date('2007')
            );
            return date.toISOString().split('T')[0];
          })(),
          blood_type: this.dictGen.getBloodType(),
          congenital_disease: '',
          degree: 1,
          disability: '',
          email:
            first_name_eng.toLowerCase() +
            this.dictGen.randomFrom(['.', '_']) +
            last_name_eng.toLocaleLowerCase() +
            (this.dictGen.randomNumber(0, 1)
              ? this.dictGen.randomNumber(2)
              : '') +
            '@vru.cdm',
          faculty: 1,
          father: {
            name: this.dictGen.getName(),
            address,
            address_selected: 'address_current',
            phone_number: this.dictGen.getThPhoneNumber(),
            occupation: '',
            income: FamilyIncomeRanges[this.dictGen.randomNumber(0, 5)],
            status: 'no_status',
            marital_status: 'no_status',
          },
          first_name_eng,
          // first_name_thai: null,
          gpax: this.dictGen.randomNumber(180, 400) / 100,
          // guardian: '',
          id_card_expired: (() => {
            const date = this.dictGen.randomDate(new Date(), new Date('2027'));
            return date.toISOString().split('T')[0];
          })(),
          id_card_issued: (() => {
            const date = this.dictGen.randomDate(
              new Date('2022'),
              new Date('2027')
            );
            return date.toISOString().split('T')[0];
          })(),
          id_card: this.dictGen.getLikeThaiIdCard(),
          last_name_eng,
          // last_name_thai: null,
          mother: {
            address,
            address_selected: 'address_current',
            name: this.dictGen.getName(),
            phone_number: this.dictGen.getThPhoneNumber(),
            income: FamilyIncomeRanges[this.dictGen.randomNumber(0, 5)],
            status: 'no_status',
            marital_status: 'no_status',
            occupation: '',
          },
          name_of_brothers_sisters: [this.dictGen.getName()],
          name_title: Number(this.dictGen.randomFrom(['1', '2', '3'])),
          nationality: 1,
          phone_number: this.dictGen.getThPhoneNumber(),
          photo: '',
          previous_academy: '',
          previous_education_level_name: '',
          previous_education_level: '',
          previous_field_of_study: '',
          program: 1,
          province: null,
          qualification: 1,
          race: 1,
          religion: 1,
          special_interest: '',
          start_academic_year: 2565,
          student_file_uploads: [],
          student_section: 1,
          student_type: 1,
          // supporter: {},
        } as NestedNullable<
          Student<'father' | 'mother' | 'supporter' | 'guardian'>
        >;
      })
    );
  }

  getStudentId(id: number) {
    return this.apiService.get(`/students/${id}/`);
  }
}
