import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { HideLoaderAction, ShowLoaderAction } from '../loader/load.actioins';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  constructor(private ngxSpinner: NgxSpinnerService, private store: Store) {
    this.spinnerSubject = new BehaviorSubject<boolean>(false);
    this.spinner = this.spinnerSubject;
  }
  private spinnerSubject: BehaviorSubject<boolean>;
  public spinner: Observable<boolean>;

  show(): void {
    this.store.dispatch(new ShowLoaderAction());
  }

  hide(): void {
    this.store.dispatch(new HideLoaderAction());
  }
  setSpinner(active: boolean): void {
    this.spinnerSubject.next(active);
  }

  isShow(active?: boolean, name = '', conf?: SpinnerConf): void {
    active ? this.ngxSpinner.show() : this.ngxSpinner.hide();
  }

  getSpinner(): Observable<boolean> {
    return this.spinner;
  }
}

export interface SpinnerConf {
  type: string;
  size: 'small' | 'medium' | 'large' | 'default';
  bdColor: 'rgba(0, 0, 0, 1)';
  color: string;
  template: string;
}
