import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vru-template-download-hypertext',
  templateUrl: './template-download-hypertext.component.html',
  styleUrls: ['./template-download-hypertext.component.scss'],
})
export class TemplateDownloadHypertextComponent {
  @Input() loading = false;
  @Output() clicked = new EventEmitter();

  onDownloadTemplateClick(): void {
    this.clicked.emit();
  }
}
