<div class="container-image"
    ngbDropdown
    placement="bottom"
    [class.hover]="canEdit"
    [style.border-radius]="radius"
    [style.height]="size"
    [style.width]="size">
  <img *ngIf="photoUrl || src; else defaultImage"
      class="h-100 w-100 object-cover"
      alt="Profile"
      [style.border-radius]="radius"
      [src]="photoUrl || src">
  <div *ngIf="canEdit"
      class="btn-edit"
      ngbDropdownToggle>
    <svg-icon class="icon-edit"
        src="assets/utils/images/carbon-icons/edit.svg"
        [svgStyle]="{ 'height.px': 20, 'width.px': 20 }">
    </svg-icon>
  </div>
  <div ngbDropdownMenu>
    <button ngbDropdownItem
        type="button"
        (click)="fileInput.click()">Upload</button>
    <button ngbDropdownItem
        type="button"
        (click)="onRemoveClick()">Remove</button>
  </div>
  <ng-template #defaultImage>
    <vru-carbon-icon class="icon-default"
        iconName="pictograms/user-profile"
        presetFilter="fill-white"
        size="50">
    </vru-carbon-icon>
  </ng-template>
  <input #fileInput
      accept="image/*"
      class="d-none"
      title="file"
      type="file"
      (change)="onUploadFileChange($event)">
</div>