import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThaiTqfStatuses, TqfStatus, TqfStatuses } from '@vru/master-data';

@Component({
  selector: 'vru-tqf-status-icon',
  templateUrl: './tqf-status-icon.component.html',
  styleUrls: ['./tqf-status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TqfStatusIconComponent {
  @Input() status?: TqfStatus;
  @Input() tqfStatus?: { [k: string]: string } | null;

  getThaiTqfStatus(status?: TqfStatus): string | undefined {
    if (status == null) return;
    return this.tqfStatus
      ? this.tqfStatus[status]
      : ThaiTqfStatuses[TqfStatuses[status]];
  }
}
