import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';

export function matchPassword(
  passwordCtrlName: string,
  confirmCtrlName: string
): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const passwordCtrl = formGroup.get(passwordCtrlName);
    const cfPassword = formGroup.get(confirmCtrlName);
    const getMissingCtrlNameErrorMsg = (ctrlName: string) =>
      `Cannot find some password control. The form may be missing the control name ${ctrlName}`;
    if (!passwordCtrl) {
      throw new Error(getMissingCtrlNameErrorMsg(passwordCtrlName));
    }
    if (!cfPassword) {
      throw new Error(getMissingCtrlNameErrorMsg(confirmCtrlName));
    }
    if (passwordCtrl?.errors || cfPassword?.errors) {
      return { password: { control: 'Some control is invalid' } };
    }
    if (passwordCtrl.value !== cfPassword.value) {
      return { password: { matchPassword: true } };
    }
    return null;
  };
}

@Directive({
  selector: '[vruMatchPassword]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MatchPasswordDirective,
      multi: true,
    },
  ],
})
export class MatchPasswordDirective implements Validator {
  @Input() registerMatchPassword: string[] = [];

  validate(control: AbstractControl): ValidationErrors | null {
    return matchPassword(
      this.registerMatchPassword[0],
      this.registerMatchPassword[1]
    )(control);
  }
}
