<div class="page-container">
  <div class="header w-100">
    <div class="banner-background overflow-hidden">
      <div class="d-flex align-items-center flex-wrap title-name h-100 px-3 px-sm-5">
        <div class="col-auto fade-in">
          <span class="title-text font-weight-bold ">มหาวิทยาลัยราชภัฏ วไลยอลงกรณ์<br> ในพระบรมราชูปถัมภ์ <br></span>
          <span class="text-white font-weight-bold">มหาวิทยาลัยต้นแบบของการผลิตครู</span>
        </div>
      </div>
      <img class="img-banner shadow fade-in"
          src="../../../assets/vru-cover.png"
          alt="vru-cover">
    </div>
  </div>
  <div class="stat d-flex flex-wrap no-gutters px-5 pb-2 w-100">
    <div class="col-12 d-flex flex-wrap stat-card shadow bg-white p-4 px-5 fade-in-top">
      <div class="col-12 col-sm-6 col-md-3 col-lg-3 d-flex flex-column text-center">
        <label class="h-50">หลักสูตร</label>
        <span class="text-green-vru h-50 font-weight-bold">{{ summaryCountData?.program_count || '-' }}</span>
      </div>
      <div class="col-12 col-sm-6 col-md-3 col-lg-3 d-flex flex-column text-center">
        <label class="h-50">คณะ</label>
        <span class="text-green-vru h-50 font-weight-bold">{{ summaryCountData?.faculty_count || '-' }}</span>
      </div>
      <div class="col-12 col-sm-6 col-md-3 col-lg-3 d-flex flex-column text-center">
        <label class="h-50">นักศึกษาปัจจุบัน</label>
        <span class="text-green-vru h-50 font-weight-bold">{{ summaryCountData?.student_count || '-' }}</span>
      </div>
      <div class="col-12 col-sm-6 col-md-3 col-lg-3 d-flex flex-column text-center">
        <label class="h-50">ศิษย์เก่า</label>
        <span class="text-green-vru h-50 font-weight-bold">{{ summaryCountData?.alumni_count || '-' }}</span>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center text-center py-2 py-sm-4">
    <h2 class="text-secondary mx-5 mb-0 font-weight-light fade-in slogan-text">วิชาการเด่น เน้นคุณธรรม นำท้องถิ่นพัฒนา
      ก้าวหน้าด้านเทคโนโลยี
    </h2>
  </div>
  <div class="d-flex flex-wrap no-gutters justify-content-center px-3 px-sm-5 pb-5 fade-in">
    <div class="col-6 col-sm-4 col-lg pt-2 px-1">
      <div class="d-flex flex-column rounded-20 justify-content-center text-center background-search p-2 h-100 pointer"
          (click)="goSearchInformation('search-subjects')">
        <label class="pointer">ค้นหา/ตรวจสอบ</label>
        <h6 class="faculty-text">รายวิชาที่เปิดสอน</h6>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-lg pt-2 px-1">
      <div class="d-flex flex-column rounded-20 justify-content-center text-center background-search p-2 h-100 pointer"
          (click)="goSearchInformation('search-student')">
        <label class="pointer">ค้นหา</label>
        <h6 class="faculty-text">รายชื่อนักศึกษา</h6>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-lg pt-2 px-1">
      <div class="d-flex flex-column rounded-20 justify-content-center text-center background-search p-2 h-100 pointer"
          (click)="goSearchInformation('search-timetable')">
        <label class="pointer">ค้นหา</label>
        <h6 class="faculty-text">ตารางเรียน/สอน</h6>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-lg pt-2 px-1">
      <div class="d-flex flex-column rounded-20 justify-content-center text-center background-search p-2 h-100 pointer"
          (click)="goSearchInformation('search-rooms')">
        <label class="pointer">ตรวจสอบ</label>
        <h6 class="faculty-text">ตารางใช้ห้อง</h6>
      </div>
    </div>
    <div class="col-6 col-sm-4 col-lg pt-2 px-1">
      <div class="d-flex flex-column rounded-20 justify-content-center text-center background-search p-2 h-100 pointer"
          (click)="goSearchInformation('search-syllabus')">
        <label class="pointer">ตรวจสอบ</label>
        <h6 class="faculty-text">หลักสูตรที่เปิดสอน</h6>
      </div>
    </div>
  </div>
  <div class="d-flex flex-wrap no-gutters px-3 px-sm-5 pb-3 pb-sm-5">
    <div class="col-auto fade-in-left-1s">
      <h2 class="text-green-vru border-bottom-green-vru slogan-text">คณะและวิทยาลัย</h2>
    </div>
    <div class="col-12 d-flex no-gutters flex-wrap"
        [class.spinner-blur-effect]="isFacultyLoading">
      <ng-container *ngFor="let faculty of facultyList">
        <div class="pr-1 col-12 col-sm-6 col-lg-4 col-xl-3 mb-1">
          <div
              class="faculty-card pt-1 pt-sm-3 d-flex flex-wrap px-1 px-sm-3 rounded justify-content-between border-bottom-gray shadow-sm h-100">
            <div class="col d-flex flex-column fade-in-left-1s">
              <h6 class="faculty-text">{{ faculty.name_thai }}</h6>
              <span class="pb-2 mb-0 faculty-text">{{ faculty.name_eng }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="d-flex bg-vru flex-wrap no-gutters px-3 px-sm-5 py-3 py-sm-5">
    <div class="col-auto fade-in-left-1s pb-3">
      <h2 class="text-green-vru border-bottom-green-vru mb-0 slogan-text">ข่าวสารและกิจกรรม</h2>
    </div>
    <div class="col-12 d-flex flex-wrap no-gutters fade-in-left-1s"
        [class.spinner-blur-effect]="isNewsLoading">
      <ng-container *ngFor="let news of newsList">
        <div class="col-12 col-sm-6 col-lg-4 col-xxl-2 py-2 px-0 px-sm-2">
          <div class="card-publicizing pointer shadow border rounded"
              (click)="goNewsDetail(news.id)">
            <img class="card-publicizing-image"
                [src]="news.cover_photo">
            <p class="pt-1 pt-sm-2 px-2">{{ news.title }}</p>
            <p class="pt-1 pt-sm-2 text-right px-2 pb-2 date-text">{{ news.since }}</p>
          </div>
        </div>
      </ng-container>
      <div class="col-12 d-flex justify-content-end">
        <p class="pointer mb-1 mr-1 faculty-text"
            (click)="goNewsList()">ดูข่าวทั้งหมด</p>
        <vru-carbon-icon iconName="next-filled"
            size="20"
            presetFilter="fill-green-vru"
            (click)="goNewsList()"></vru-carbon-icon>
      </div>
    </div>
  </div>
</div>
