import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { StudentGraduateQualification } from '@vru/master-data';
import { GraduateQualificationResolver } from '@vru/services';
import { Observable } from 'rxjs';

@Injectable()
export class GraduationVerificationResolver extends GraduateQualificationResolver {
  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<StudentGraduateQualification | { error: HttpErrorResponse }> {
    const studentId =
      route?.parent?.parent?.params?.id ||
      this.studentId ||
      this.guardianStudentId ||
      this.creditBankStudentId;

    if (!studentId) {
      throw new Error(
        'Cannot get student information because of the student id route is invalid'
      );
    }
    return this.getGraduateQualification(studentId);
  }
}
