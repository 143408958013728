export enum Grades {
  'A',
  'B+',
  'B',
  'C+',
  'C',
  'D+',
  'D',
  'F',
  'PD',
  'P',
  'NP',
  'W',
  'T',
  'I',
  'Au'
}