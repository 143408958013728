import { StudentSection } from './student-section.model';
import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { DrfList } from '../../common.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudentSectionApiService {

  studentSectionPath = '/student-sections/';

  constructor(private api: ApiService) {}

  getStudentSections(params?: any) {
    return this.api.get<DrfList<StudentSection>>(this.studentSectionPath, params);
  }

  getDetail(
    id: number,
    params?: any
  ): Observable<StudentSection> {
    return this.api.get(this.studentSectionPath + `${id}/`, params);
  }

}
