<div>
  <table class="table table-hover">
    <thead *ngIf="showHeader">
      <tr>
        <th>{{ headerTitle }}</th>
        <th>{{ headerFilename }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let uploadFile of uploadFiles; index as i">
        <td class="col-name">
          <span *ngIf="showNumber"
              class="position-absolute">{{ i + 1 }}.</span>
          <span [class.ml-4]="showNumber">{{ uploadFile[bindLabel] }}
            <small class="text-red">*</small>
          </span>
        </td>
        <td class="col-filename">
          <div>
            <label class="text-truncate mb-0"
                [class.text-link]="!uploadFile.file?.name && !!uploadFile[bindUrl]"
                [title]="uploadFile.file?.name || uploadFile[bindFileName]"
                (click)="onFileNameClick(
                  !uploadFile.file?.name && bindUrl 
                    ? uploadFile[bindUrl]
                    : undefined,
                  $event
                )">
              {{ uploadFile.file?.name || uploadFile[bindFileName] }}
            </label>
          </div>
        </td>
        <td *ngIf="!readonly"
            class="col-icon">
          <vru-carbon-icon *ngIf="uploadFile.file"
              class="pointer"
              iconName="close"
              [presetFilter]="'fill-red'"
              [size]="22"
              (click)="uploadFile.file = null">
          </vru-carbon-icon>
          <vru-carbon-icon *ngIf="!uploadFile.file"
              class="pointer"
              iconName="upload"
              [presetFilter]="'fill-gray'"
              [size]="22"
              (click)="uploadInput.click()">
          </vru-carbon-icon>
          <input #uploadInput
              class="d-none"
              placeholder="click for upload file"
              type="file"
              [accept]="uploadFile.fileType || '*'"
              (change)="onFileInputChange($event, uploadFile)">
        </td>
      </tr>
    </tbody>
  </table>
</div>