import { Injectable } from '@angular/core';
import { v4 as uuidV4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor() {}

  /**
   * @description
   * Make up uuid to each item
   */
  makeUpUuid<T>(arr: T[]): T[] {
    return arr.map((item) => Object.assign({ uuid: uuidV4() }, item));
  }
}
