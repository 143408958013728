<div class="board-white d-flex-column w-100 h-100 overflow-auto p-5">
  <div class="mb-4">
    <div class="row no-gutters">
      <h4 class="mb-0">ตั้งค่าลายเซ็น</h4>
    </div>
  </div>
  <div class="frame-filter flex-grow-1 flex-shrink-1 position-relative mx-0">
    <div class="d-flex-column h-100"
        [class.spinner-blur-effect]="isLoading">
      <div class="flex-grow-1 pt-md-4 px-md-4 mb-4">
        <div class="d-flex flex-row justify-content-center"
            (resized)="onResize($event)">
          <div *ngIf="signMode !== 'textSign'"
              class="flex-grow-0 flex-shrink-0 position-relative">
            <label class="label-drawing-status status-{{signMode}}">{{ statusLabel[signMode] }}</label>
            <vru-image-drawing [aspectRatio]="aspectRatio"
                [options]="drawingOptions"
                [(mode)]="signMode"
                [(image)]="signatureImage"
                (imageCropped)="onImageCropped($event)">
            </vru-image-drawing>
          </div>
          <div *ngIf="signMode === 'textSign'">
            <h5>{{ statusLabel[signMode] }}</h5>
            <vru-text-signature (cancelClick)="signMode = 'preview'"
                (signatureSaveClick)="onTextSignSave($event)">
            </vru-text-signature>
          </div>
        </div>
      </div>
      <div class="row justify-content-end align-items-end px-2">
        <button *ngIf="['sign', 'crop', 'textSign'].includes(signMode)"
            class="btn btn-outline-danger btn-width-sm mx-2"
            type="button"
            [ladda]="isUpdating"
            (click)="onCancelClick()">ยกเลิก</button>
        <button *ngIf="['textSign', 'sign'].includes(signMode)"
            class="btn btn-outline-green-vru btn-width-sm mx-2"
            type="button"
            [ladda]="isUpdating"
            (click)="onConfirmClick()">ตกลง</button>
        <button *ngIf="signMode === 'crop'"
            class="btn btn-outline-green-vru btn-width-sm mx-2"
            type="button"
            [ladda]="isUpdating"
            (click)="submit($any(croppedImage))">ยืนยัน</button>
        <button *ngIf="signMode === 'preview'"
            class="btn btn-green-vru btn-icon mx-2"
            type="button"
            [ladda]="isUpdating"
            (click)="signMode = 'sign'">
          <span class="bi bi-pen"></span>
          <span class="ml-2">เซ็นใหม่</span>
        </button>
        <button *ngIf="signMode === 'preview'"
            class="btn btn-green-vru btn-icon mx-2"
            id="btn-sign-with-text"
            type="button"
            (click)="onSignWithTextClick()">
          <span class="bi bi-type"></span>
          <span class="ml-2">เซ็นด้วยการพิมพ์</span>
        </button>
      </div>
    </div>
    <div class="container-spinner-float">
      <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
          name="signature-setting-loading"
          color="#3cd070"
          size="medium"
          type="ball-clip-rotate-pulse"
          [fullScreen]="false"
          [showSpinner]="isLoading">
      </ngx-spinner>
    </div>
  </div>
</div>