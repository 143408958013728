import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { DropdownApiService, RoleEnum, Teacher } from '@vru/master-data';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'vru-user-typeahead-dropdown',
  templateUrl: './user-typeahead-dropdown.component.html',
  styleUrls: ['./user-typeahead-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UserTypeaheadDropdownComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: UserTypeaheadDropdownComponent,
    },
  ],
})
export class UserTypeaheadDropdownComponent implements ControlValueAccessor {
  @Input() bindLabel = 'name';
  @Input() showRole = false;
  @Input() readonly = false;
  @Output() selectedChange = new EventEmitter();

  isLoading = false;
  selectedStudent?: Partial<Teacher>;
  showName = false;
  roles = RoleEnum;

  private _onTouch: any;
  private _onChange!: (val: any) => void;

  constructor(
    private cdRef: ChangeDetectorRef,
    private dropdownApi: DropdownApiService
  ) {}

  dataApiGenerator = (name: string) => {
    this.isLoading = true;
    this.cdRef.markForCheck();
    return this.dropdownApi
      .getDropdown('profile', { name })
      .pipe(tap(() => (this.isLoading = false)));
  };

  onValueChange(val: Partial<Teacher>) {
    this._onChange(val);
    this.selectedChange.emit(val);
  }

  registerOnTouched(fn: any) {
    this._onTouch = fn;
  }

  registerOnChange(fn: (val: any) => void) {
    this._onChange = fn;
  }

  // TODO: Implement alternative validation
  validate(): ValidationErrors | null {
    return this.selectedStudent
      ? null
      : {
          teacher: {
            required: true,
          },
        };
  }

  writeValue(val: Partial<Teacher>): void {
    this.selectedStudent = val;
  }
}
