import { DrfList } from '@vru/master-data';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import {
  ChatMessage,
  ChatRoom,
  ChatRoomParam,
  ChatRoomUser,
  ChatMessageParam,
  ChatMessageCreate,
  ChatBadge,
} from './chat.model';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private readonly chatRoomUrl = '/chat-rooms/';
  private readonly chatMessageUrl = '/chat-messages/';
  private readonly chatBadgeUrl = this.chatRoomUrl + 'badge/';

  constructor(private apiService: ApiService) {}

  getChatRoomList(params?: ChatRoomParam): Observable<DrfList<ChatRoom>> {
    return this.apiService.get<DrfList<ChatRoom>>(this.chatRoomUrl, params);
  }

  postChatRoom(params: ChatRoomUser) {
    return this.apiService.post(this.chatRoomUrl, params);
  }

  patchChatRoom(params: ChatRoomUser) {
    return this.apiService.patch(this.chatRoomUrl, params);
  }

  getChatMessageList(
    params: ChatMessageParam
  ): Observable<DrfList<ChatMessage>> {
    return this.apiService.get<DrfList<ChatMessage>>(this.chatMessageUrl, params);
  }

  postMessageCreate(params: ChatMessageCreate) {
    return this.apiService.post(this.chatMessageUrl, params);
  }

  getChatBadge(): Observable<ChatBadge> {
    return this.apiService.get<ChatBadge>(this.chatBadgeUrl)
  }
}