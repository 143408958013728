import { Injectable } from '@angular/core';
import { Nullable, PaymentDetail } from '@vru/master-data';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor() {}

  findPaymentByFeeType(
    feeType: string,
    paymentDetails: Nullable<PaymentDetail>[]
  ): Nullable<PaymentDetail> | undefined {
    const index = this.findPaymentByFeeTypeIndex(feeType, paymentDetails)
    return paymentDetails[index];
  }

  findPaymentByFeeTypeIndex(
    feeType: string,
    paymentDetails: Nullable<PaymentDetail>[]
  ): number {
    return paymentDetails.findIndex((detail) => detail?.fee_type === feeType);
  }
}
