import { RoleEnum } from '../user/user.constant';
import { Dropdown } from '../common.model';

export const Tqf2ApprovalPermission: {
  [status in keyof typeof ApprovalStatusEnum]: readonly (keyof typeof RoleEnum)[];
} = {
  pending: ['teacher'],
  wait_for_dean_approval: ['dean'],
  wait_for_backoffice_approval: ['backoffice'],
  wait_for_subcommittee_approval: ['backoffice'],
  wait_for_technical_committee_approval: ['backoffice'],
  wait_for_academic_council_approval: ['backoffice'],
  wait_for_university_council_approval: ['backoffice'],
  wait_for_ops_approval: ['backoffice'],
  revised: ['teacher'],
  approved: [],
} as const;

export enum ApprovalStatusEnum {
  'revised' = 0,
  'pending',
  'wait_for_dean_approval',
  'wait_for_backoffice_approval',
  'wait_for_subcommittee_approval',
  'wait_for_technical_committee_approval',
  'wait_for_academic_council_approval',
  'wait_for_university_council_approval',
  'wait_for_ops_approval',
  'approved',
}

export enum ApprovalStatusThEnum {
  'ปรับปรุง' = 0,
  'รอดำเนินการ',
  'รอการอนุมัติจากคณบดี',
  'รอการอนุมัติจากฝ่ายทะเบียน',
  'รอการอนุมัติจากคณะอนุกรรมการ',
  'รอการอนุมัติจากคณะกรรมการวิชาการคณะ',
  'รอการอนุมัติจากสภาการวิชาการ',
  'รอการอนุมัติจากสภามหาวิทยาลัย',
  'รอการอนุมัติจากสำนักงานปลัดกระทรวง อว. (สป.อว.)',
  'อนุมัติ',
}

export enum ApproveDateKeys {
  subcommittee_approved_date = 4,
  technical_committee_approved_date,
  academic_council_approved_date,
  university_council_approved_date,
  ops_approved_date,
}

export enum ThaiTqfStatuses {
  'รอดำเนินการ',
  'ระหว่างการพิจารณา',
  'ยกเลิก',
  'อนุมัติ',
  'ต้องปรับปรุง',
  'ใช้งาน',
}

export enum Tqf2ApprovalRoleTh {
  'อาจารย์' = 1,
  'คณบดี',
  'เจ้าหน้าที่ทะเบียน',
  'คณะอนุกรรมการ',
  'คณะกรรมการวิชาการคณะ',
  'สภาการวิชาการ',
  'สภามหาวิทยาลัย',
  'สำนักงานปลัดกระทรวง อว. (สป.อว.)',
}

export enum TqfStatuses {
  pending,
  in_consideration,
  canceled,
  approved,
  need_to_revise,
  in_use,
}

export enum ThaiTqf4Statuses {
  'ร่าง',
  'ไม่อนุมัติ',
  'อนุมัติ',
  'รอประธานหลักสูตรอนุมัติ',
  'รอคณะบดีอนุมัติ',
}

export enum EngTqf4Statuses {
  draft,
  rejected,
  approved,
  wait_for_president,
  wait_for_dean,
}

export type Tqf4Status = keyof typeof EngTqf4Statuses;

export const Tqf4StatusDropdown = [
  { label: ThaiTqf4Statuses[0], value: EngTqf4Statuses[0] },
  { label: ThaiTqf4Statuses[1], value: EngTqf4Statuses[1] },
  { label: ThaiTqf4Statuses[2], value: EngTqf4Statuses[2] },
  { label: ThaiTqf4Statuses[3], value: EngTqf4Statuses[3] },
  { label: ThaiTqf4Statuses[4], value: EngTqf4Statuses[4] },
];

export const TQFdisplayingDropdown: Dropdown<boolean | string>[] = [
    { label: 'รายการที่รอการอนุมัติ', value: true },
    { label: 'แสดงทั้งหมด', value: 'null' },
];
