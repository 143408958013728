import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModalModule, NgbTooltipModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatRoutingModule } from './chat-routing.module';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { ManageChatComponent } from './manage-chat/manage-chat.component';
import { UiModule } from '../ui.module';
import { UtilsModule } from '@vru/utils';
import { AngularResizeEventModule } from 'angular-resize-event';

@NgModule({
  imports: [
    CommonModule,
    ChatRoutingModule,
    UiModule,
    UtilsModule,
    NgbModalModule,
    NgSelectModule,
    NgbTooltipModule,
    NgbDropdownModule,
    AngularResizeEventModule
  ],
  declarations: [
    ManageChatComponent,
    ChatMessageComponent
  ]
})
export class ChatModule { }
