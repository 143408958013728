import { Injectable } from '@angular/core';
import { ErrorMessages } from '@vru/master-data';
import Swal, {
  SweetAlertIcon,
  SweetAlertOptions,
  SweetAlertResult,
} from 'sweetalert2';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private errorService: ErrorService) {}

  success(title: string = 'Success', timer = 2000) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'success',
      title: title || 'Success',
      showConfirmButton: false,
      timer: timer || 2000,
    });
  }

  error(title: string = 'Error', timer = 2000, options?: SweetAlertOptions) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'error',
      title: title || 'Error',
      showConfirmButton: false,
      timer: timer || 2000,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
      ...(options || {}),
    });
  }

  errorByDrf(err: any | null): void {
    this.error(this.errorService.getErrorMsgByVarType(err) || undefined);
  }

  errorDetail(title: string, html: string) {
    Swal.fire({
      confirmButtonColor: '#dc3545',
      icon: 'error',
      title,
      showConfirmButton: true,
      html,
    });
  }

  /**
   * Show error toast with message that
   * _'Sorry! some problem occurred. Please contact the administrator'_
   */
  errorWithContactAdmin(): void {
    Swal.fire({
      icon: 'error',
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      title: 'Sorry! some problem occurred. Please contact the administrator',
      toast: true,
    });
  }

  /**
   * Show error toast with message that
   * _'พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ'_
   */
  errorWithContactAdminTh(): void {
    Swal.fire({
      icon: 'error',
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      title: ErrorMessages.common.foundErrorPlsAdmin,
      toast: true,
    });
  }

  alertSuccessSubmit(): void {
    Swal.fire({
      icon: 'success',
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      title: 'บันทึกสำเร็จ',
      toast: true,
    });
  }

  errorWithInvalidForm(): void {
    Swal.fire({
      icon: 'error',
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
      toast: true,
    });
  }

  confirm(
    title: string = 'Are you sure?',
    text: string = ` queryYou won't be able to revert this!`,
    icon: SweetAlertIcon = 'warning',
    confirmButtonText: string = 'Yes, delete it!',
    cancelButtonText: string = 'Cancel'
  ): Promise<SweetAlertResult> {
    return Swal.fire({
      title: title || 'Are you sure?',
      text: text || `You won't be able to revert this!`,
      icon: icon || 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3A3A3A',
      cancelButtonColor: '#fff#00000029',
      confirmButtonText: confirmButtonText || 'Yes, delete it!',
      cancelButtonText: cancelButtonText || 'Cancel',
    });
  }

  approval(
    title: string = 'Confirm ?',
    text: string = ` queryWould you like to approve the document?`,
    icon: SweetAlertIcon = 'warning',
    confirmButtonText: string = 'Yes'
  ) {
    return Swal.fire({
      title: title || 'Are you sure?',
      text: text || `Would you like to approve the document?`,
      icon: icon || 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3A3A3A',
      cancelButtonColor: '#fff#00000029',
      confirmButtonText: confirmButtonText || 'Yes',
    });
  }

  confirmInvoice(
    title: string = 'Are you sure?',
    text: string = ` queryWould you like to confirm invoice?`,
    icon: SweetAlertIcon = 'warning',
    confirmButtonText: string = 'Yes'
  ) {
    return Swal.fire({
      title: title || 'Are you sure?',
      text: text || `Would you like to approve the document?`,
      icon: icon || 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3A3A3A',
      cancelButtonColor: '#fff#00000029',
      confirmButtonText: confirmButtonText || 'Yes',
    });
  }

  confirmClearInvoice(
    title: string = 'Are you sure?',
    text: string = ` queryWould you like to confirm clear advance?`,
    icon: SweetAlertIcon = 'warning',
    confirmButtonText: string = 'Yes'
  ) {
    return Swal.fire({
      title: title || 'Are you sure?',
      text: text || `Would you like to approve the document?`,
      icon: icon || 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3A3A3A',
      cancelButtonColor: '#fff#00000029',
      confirmButtonText: confirmButtonText || 'Yes',
    });
  }

  deleteConfirm(
    title?: string,
    text?: string,
    confirmButtonText?: string,
    cancelButtonText?: string
  ): Promise<SweetAlertResult> {
    return Swal.fire({
      title: title || 'ยืนยันการลบทิ้ง',
      text: text || `คุณต้องการลบทิ้งใช่หรือไม่`,
      iconColor: '#dc3545',
      iconHtml: '<span class="bi bi-trash3 icon-large text-red"></span>',
      buttonsStyling: false,
      customClass: {
        icon: 'border-0',
        confirmButton: 'btn btn-danger btn-default-size mx-2',
        cancelButton: 'btn btn-outline-danger btn-default-size mx-2',
      },
      showCancelButton: true,
      confirmButtonText: confirmButtonText || 'ใช่',
      cancelButtonText: cancelButtonText || 'ไม่',
    });
  }

  paidInvoice(
    title: string = 'Are you sure?',
    text: string = ` queryWould you like to pay invoice?`,
    icon: SweetAlertIcon = 'warning',
    confirmButtonText: string = 'Yes'
  ) {
    return Swal.fire({
      title: title || 'Are you sure?',
      text: text || `Would you like to approve the document?`,
      icon: icon || 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3A3A3A',
      cancelButtonColor: '#fff#00000029',
      confirmButtonText: confirmButtonText || 'Yes',
    });
  }

  rejectConfirm(
    title?: string,
    text?: string,
    confirmButtonText?: string,
    cancelButtonText?: string
  ): Promise<SweetAlertResult> {
    return Swal.fire({
      title: title || 'ยืนยันการยกเลิก',
      text: text || `คุณต้องการยกเลิกใช่หรือไม่`,
      iconColor: '#dc3545',
      iconHtml: '<span class="bi bi-x-circle-fill icon-large text-red"></span>',
      buttonsStyling: false,
      customClass: {
        icon: 'border-0',
        confirmButton: 'btn btn-danger btn-default-size mx-2',
        cancelButton: 'btn btn-outline-danger btn-default-size mx-2',
      },
      showCancelButton: true,
      confirmButtonText: confirmButtonText || 'ใช่',
      cancelButtonText: cancelButtonText || 'ไม่',
    });
  }

  rejectedorterminated(title?: string) {
    return Swal.fire({
      title: title ? title : 'Are you sure?',
      input: 'text',
      icon: 'warning',
      confirmButtonColor: '#3A3A3A',
      cancelButtonColor: '#fff#00000029',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      preConfirm: (word) => {
        if (word === '') {
          Swal.showValidationMessage(`please enter your reason`);
        }
      },
    });
  }

  refundClearInvoice(
    title: string = 'Are you sure?',
    text: string = ` queryWould you like to confirm refund clear advance?`,
    icon: SweetAlertIcon = 'warning',
    confirmButtonText: string = 'Yes'
  ) {
    return Swal.fire({
      title: title || 'Are you sure?',
      text: text || `Would you like to approve the document?`,
      icon: icon || 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3A3A3A',
      cancelButtonColor: '#fff#00000029',
      confirmButtonText: confirmButtonText || 'Yes',
    });
  }

  paidClearInvoice(
    title: string = 'Are you sure?',
    text: string = ` queryWould you like to confirm paid clear advance?`,
    icon: SweetAlertIcon = 'warning',
    confirmButtonText: string = 'Yes'
  ) {
    return Swal.fire({
      title: title || 'Are you sure?',
      text: text || `Would you like to approve the document?`,
      icon: icon || 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3A3A3A',
      cancelButtonColor: '#fff#00000029',
      confirmButtonText: confirmButtonText || 'Yes',
    });
  }

  noPermit() {
    return Swal.fire({
      confirmButtonColor: '#dc3545',
      icon: 'error',
      html: `${ErrorMessages.common.notAllowPermitExplain}<br>
        <p class="mt-4 mb-0">${ErrorMessages.common.hasAdminQuestion}</p>`,
      title: ErrorMessages.common.notAllowPermit,
    });
  }

  warn(title: string = 'Warning', timer = 2000) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      iconColor: '#ffc107',
      icon: 'warning',
      title: title || 'Warning',
      showConfirmButton: false,
      timer: timer || 2000,
    });
  }
}
