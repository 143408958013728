import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { DrfList } from '../common.model';
import {
  AdmissionLoginRetrieve,
  AdmissionRegistrationInfo,
  AdmissionRegistrationRound,
  AdmissionRegistrationRoundParams,
  AdmissionRoundType,
  ExportApplicantParam,
  ImportAdmission,
  ImportAdmissionStatus,
  QrCodeVerification,
  RegistrationSubMenuConfig,
} from './admission.model';

@Injectable({
  providedIn: 'root',
})
export class AdmissionApiService {
  registrationUrl = '/registration-rounds/';
  roundTypeUrl = '/registration-round-types/';
  registrationSubMenuFile = '/register-config-submenu/';
  programOfDegreeUrl = '/program-of-degree/';
  importApplicantUrl = '/import-admission/';
  importTaskStatus = '/background-task-status/';
  exportApplicantUrl = '/student-registration-managements/applicant-list-report/';
  exportApplicantNumberUrl = '/student-registration-managements/number-of-applicant-report/';
  constructor(private http: ApiService) {}

  deleteAdmissionRound(id: number): Observable<AdmissionRegistrationRound> {
    return this.http.delete(this.registrationUrl + `${id}/`);
  }

  deleteAdmissionRoundType(id: number): Observable<AdmissionRoundType> {
    return this.http.delete(this.roundTypeUrl + `${id}/`);
  }

  getAdmissionRoundType(): Observable<DrfList<AdmissionRoundType>> {
    return this.http.get(this.roundTypeUrl);
  }

  getImportApplicantTemplate(): Observable<File> {
    return this.http.getBlob(this.importApplicantUrl);
  }
  
  getRegistrationRound(
    params?: AdmissionRegistrationRoundParams
  ): Observable<DrfList<AdmissionRegistrationRound>> {
    return this.http.get(this.registrationUrl, params);
  }

  getRegistrationRoundDetail(
    id: number
  ): Observable<AdmissionRegistrationRound> {
    return this.http.get(this.registrationUrl + `${id}/`);
  }

  getSelfStudentRegistration(): Observable<AdmissionRegistrationInfo> {
    return of(this.localRegisterAccessToken).pipe(
      switchMap((token) => {
        if (typeof token !== 'string') {
          return throwError('Token is not valid');
        }
        const header = this.http
          .requestHeader()
          .set('Authorization', 'Bearer ' + token);
        return this.http.get<DrfList<AdmissionRegistrationInfo>>(
          `/student-registrations/`,
          { expand: 'faculty,program,room' },
          header
        );
      }),
      map((registerList) => {
        const register = registerList.results?.[0];
        if (register == null) {
          throw new Error('Not found self register');
        }
        return register;
      })
    );
  }

  loginRegisterStatus(payload: {
    username: string;
    password: string;
  }): Observable<AdmissionLoginRetrieve> {
    return this.http
      .post<AdmissionLoginRetrieve>('/admission-applicant-token-auth/', payload)
      .pipe(
        tap((res) => {
          this.localRegisterAccessToken = res.access;
          this.localRegisterRefreshToken = res.refresh;
        })
      );
  }

  postQrCodeForVerification(
    qrCodeFile: File | string
  ): Observable<QrCodeVerification> {
    const formData = new FormData();
    formData.append('file', qrCodeFile);
    return of({
      student_id: '641457570001',
      name: 'นางสมพร เจ',
      graduate_date: '1 มกราคม 2564',
      education: 'มหาวิทยาลัยราชภัฎ วไลยอลงกรณ์ ในพระบรมราชูปถัมภ์',
    });
    // TODO: make calling real API
    // return this.http.post('api', formData);
  }

  patchAdmissionRoundType(
    id: number,
    body: Partial<AdmissionRoundType>
  ): Observable<AdmissionRoundType> {
    return this.http.patch(this.roundTypeUrl + `${id}/`, body);
  }

  patchRegistrationRound(
    id: number,
    request: AdmissionRegistrationRound
  ): Observable<AdmissionRegistrationRound> {
    return this.http.patch(this.registrationUrl + `${id}/`, request);
  }

  postAdmissionRoundType(
    body: AdmissionRoundType
  ): Observable<AdmissionRoundType> {
    return this.http.post(this.roundTypeUrl, body);
  }

  postRegistrationRound(
    request: AdmissionRegistrationRound
  ): Observable<AdmissionRegistrationRound> {
    return this.http.post(this.registrationUrl, request);
  }

  patchRegistrationSubMenuFile(
    id: number | undefined,
    file: FormData,
  ): Observable<RegistrationSubMenuConfig> {
    return this.http.patch(this.registrationSubMenuFile + `${id}/`, file);
  }

  postImportApplicant(params: any): Observable<ImportAdmission> {
    return this.http.post<ImportAdmission>(this.importApplicantUrl, params)
  }

  getImportStatus(params: ImportAdmission): Observable<ImportAdmissionStatus> {
    return this.http.get<ImportAdmissionStatus>(this.importTaskStatus, params)
  }

  exportApplicantReport(params: ExportApplicantParam): Observable<File>{
    return this.http.getBlob<File>(this.exportApplicantUrl, params)
  }

  exportApplicantNumberReport(params: ExportApplicantParam): Observable<File> {
    return this.http.getBlob<File>(this.exportApplicantNumberUrl, params)
  }

  get localRegisterAccessToken(): string | null {
    return localStorage.getItem('registerAccessToken');
  }

  set localRegisterAccessToken(data: string | null) {
    localStorage.setItem('registerAccessToken', data || '');
  }

  get localRegisterRefreshToken(): string | null {
    return localStorage.getItem('registerRefreshToken');
  }

  set localRegisterRefreshToken(data: string | null) {
    localStorage.setItem('registerRefreshToken', data || '');
  }
}
