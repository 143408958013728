<ng-select [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [class.readonly]="readonly"
    [clearable]="clearable"
    [formControl]="formControl"
    [items]="dropdown"
    [loading]="loading"
    [minTermLength]="minTermLength"
    [multiple]="multiple"
    [placeholder]="placeholder"
    [readonly]="_readonly"
    [typeahead]="search$"
    [typeToSearchText]="typeToSearchText"
    [appendTo]="appendTo"
    [addTag]="addTag"
    [addTagText]="addTagText"
    (change)="onSelect($event)"
    (open)="onOpen()">
  <ng-container *ngIf="optionContent">
    <ng-template let-item="item"
        ng-option-tmp>
      <ng-container *ngTemplateOutlet="optionContent.templateRef; context: { $implicit: item }">
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="multiLabelContent">
    <ng-template let-items="items"
        ng-multi-label-tmp>
      <ng-container *ngTemplateOutlet="multiLabelContent.templateRef; context: { $implicit: items }">
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="labelContent">
    <ng-template let-item="item"
        ng-label-tmp>
      <ng-container *ngTemplateOutlet="labelContent.templateRef; context: { $implicit: item }">
      </ng-container>
    </ng-template>
  </ng-container>
</ng-select>
