import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../api/api.service';
import { Nullable } from '../../common.model';
import { StudentApiService } from '../student/student-api.service';
import { StudentListParams } from '../student/student.model';
import {
  DiplomaParams,
  QualificationCertificateParams,
} from './graduation.model';
import { TaskOrderResponse } from '../../export-task/export.model';

@Injectable({
  providedIn: 'root',
})
export class GraduationApiService {
  readonly graduationApprovalPath = '/student-graduation-approvals/';
  readonly qualificationCertsPath = 'qualification-certificates';

  constructor(
    private http: ApiService,
    private studentApi: StudentApiService
  ) {}

  getGraduatesReportFile(
    params?: Nullable<StudentListParams>
  ): Observable<File> {
    params = {
      ...params,
      graduated_year: params?.graduated_year
        ? params.graduated_year - 543
        : params?.graduated_year,
    };
    return this.http.getBlob(
      this.studentApi.studentPath + 'graduating-report/',
      params
    );
  }

  /**
   * Get the file of graduates who join the graduation ceremony
   */
  getCeremonyGraduatesReportFile(
    params?: Nullable<StudentListParams>
  ): Observable<File> {
    params = {
      ...params,
      join_graduation_ceremony: true,
      graduated_year: params?.graduated_year
        ? params.graduated_year - 543
        : params?.graduated_year,
    };
    return this.http.getBlob(
      this.studentApi.studentPath + 'graduating-report/',
      params
    );
  }

  getGraduationApprovals() {
    return this.http.get(this.graduationApprovalPath);
  }

  getGraduationApprovalDetail(id: number) {
    return this.http.get(this.graduationApprovalPath + id);
  }

  getDiploma(
    id: number,
    params: { [P in keyof DiplomaParams]?: any }
  ): Observable<File> {
    return this.http.getBlob(
      this.studentApi.studentPath + `${id}/diploma/`,
      params
    );
  }

  requestQualificationCertificate(
    params: QualificationCertificateParams
  ): Observable<TaskOrderResponse> {
    return this.http.post(this.qualificationCertsPath, params);
  }
}
