<div class="position-relative mt-3">
  <div class="board-white w-100 h-100 p-5 overflow-auto">
    <div class="frame-filter flex-nowrap mx-0 mb-จ pt-3"
        [class.spinner-blur-effect]="isLoading">
      <table class="table table-hover mt-3 text-center">
        <thead>
          <tr>
            <th>รหัสเทียบโอน</th>
            <th>ชื่อสถาบัน</th>
            <th>ชื่อวิชา</th>
            <th>เกรด</th>
            <th>เทียบโอนวิชา</th>
            <th>เกรด</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let creditTransferDisplays of studentCreditTransferList; index as i">
            <td class="align-middle">{{creditTransferDisplays?.transferred_course?.code || '-'}}</td>
            <td class="align-middle">{{creditTransferDisplays?.transferred_course?.institution?.name || '-'}}</td>
            <td class="align-middle">{{creditTransferDisplays?.transferred_course?.course_name || '-'}}</td>
            <td class="align-middle">{{creditTransferDisplays.transferred_grade || '-'}}</td>
            <td class="align-middle">{{creditTransferDisplays?.transferred_course?.course?.name_thai || '-'}}</td>
            <td class="align-middle">{{creditTransferDisplays?.transferred_course?.grade || '-'}}</td>
            <td class="">
              <div class="d-flex justify-content-end align-items-end">
                <button type="button"
                    class="btn btn-outline-green-vru mr-2"
                    (click)="onCreditTransferOpenModal(creditTransferDisplays)">แก้ไข</button>
                <button type="button"
                    class="btn btn-danger"
                    (click)="onDeleteCreditTransferData(creditTransferDisplays.id, creditTransferDisplays)">ลบ</button>
              </div>
            </td>
          </tr>
          <tr *ngIf="!studentCreditTransferList?.length"> ไม่พบการเทียบโอน </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex mt-3 justify-content-end align-items-end">
      <button class="btn btn-green-vru mr-2"
          (click)="onCreditTransferOpenModal()">+ เพิ่มข้อมูล</button>
    </div>
    <div class="container-spinner-float">
      <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
          name="student-credit-transfer"
          color="#3cd070"
          size="medium"
          type="ball-clip-rotate-pulse"
          [fullScreen]="false"
          [showSpinner]="isLoading">
      </ngx-spinner>
    </div>
  </div>
</div>

<ng-template #studentCreditTransferModal
    let-modalOpen>

  <div class="modal-header">
    <h4>เทียบโอน</h4>
  </div>

  <form class="position-relative"
      [formGroup]="studentCreditTransferFormGroup">
    <div class="modal-body py-4">
      <div class="form-group row">
        <div class="col-lg-12">
          <label>รหัสเทียบโอน</label>
          <ng-select [items]="studentCreditTransferDropdown"
              bindValue="value"
              bindLabel="label"
              placeholder="เลือก"
              formControlName="value"
              [clearable]="false"
              [typeahead]="studentCreditTransferDropdown$"
              [typeToSearchText]="'กรุณาพิมพ์อย่างน้อย 2 ตัวอักษร'"
              [ngClass]="{'is-invalid': isSubmitted && form?.value?.errors}"
              (change)="onChangeCreditTransfer($event)">
            <ng-template ng-option-tmp let-item="item">
              <div>{{ item.label }}</div>
              <div>
                {{ item.context['course.credit'] }}({{ item.context['course.theory_hour'] }}
                -{{ item.context['course.practice_hour'] }}
                -{{ item.context['course.self_learning_hour'] }})
              </div>
              <div class="d-flex text-wrap">{{ item.context.course_name }}</div>
            </ng-template>
          </ng-select>
          <div *ngIf="isSubmitted && form?.value?.errors"
              class="invalid-feedback">
            <div *ngIf="form?.value?.errors?.required">กรุณาเลือก รหัสเทียบโอน</div>
          </div>
        </div>
      </div>
      <ng-container formGroupName="context">
        <div class="form-group row">
          <ng-container formGroupName="institution">
            <div class="col-lg-6">
              <label>ชื่อสถาบัน</label>
              <input type="text"
                  class="form-control"
                  formControlName="name"
                  placeholder="กรุณาเลือกรหัสเทียบโอน"
                  readonly>
            </div>
          </ng-container>
          <div class="col-lg-6">
            <label>เทียบโอนวิชา</label>
            <input type="text"
                class="form-control"
                formControlName="course.name_thai"
                placeholder="กรุณาเลือกรหัสเทียบโอน"
                readonly>
          </div>
          <div class="col-lg-6">
            <label>ชื่อวิชา (ที่ถูกย้าย)</label>
            <input type="text"
                class="form-control"
                formControlName="course_name"
                placeholder="กรุณาเลือกรหัสเทียบโอน"
                readonly>
          </div>
        </div>
      </ng-container>
      <div class="form-group row">
        <div class="col-lg-12">
          <label>เกรดที่ถูกเทียบโอน</label>
          <input type="text"
              class="form-control"
              formControlName="transferred_grade"
              maxlength="4"
              placeholder="เกรดที่ถูกเทียบโอน"
              [ngClass]="{ 'is-invalid': isSubmitted && form?.transferred_grade?.errors }">
          <div *ngIf="isSubmitted && form?.transferred_grade?.errors"
              class="invalid-feedback">
            <div *ngIf="form?.transferred_grade?.errors?.required">กรุณากรอกเกรดที่ถูกเทียบโอน</div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="modal-footer">
    <div class="row">
      <button class="btn btn-outline-green-vru mr-2"
          type="button"
          (click)="modalOpen.dismiss(studentCreditTransferFormGroup.reset())">
        ยกเลิก
      </button>
      <button class="btn btn-green-vru"
          type="submit"
          (click)="onCheckInvalidModal(studentCreditTransferFormGroup.getRawValue())">
        บันทึก
      </button>
    </div>
  </div>

</ng-template>
