import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DrfList } from '../common.model';
import { Course, SubjectGetParam } from '../subject.model';

@Injectable({
  providedIn: 'root',
})
// TODO: Rename to CourseApiService
export class SubjectApiService {
  pathUrl = '/courses/';

  constructor(private apiService: ApiService) {}

  getSubjects(params?: SubjectGetParam): Observable<DrfList<Course>> {
    return this.apiService.get<DrfList<Course>>(this.pathUrl, params);
  }

  getSubjectById(id: number): Observable<Course> {
    return this.apiService.get<Course>(this.pathUrl + id + '/');
  }

  postSubject(data: Course): Observable<Course> {
    return this.apiService.post<Course>(this.pathUrl, data);
  }

  patchSubject(data: Course, id: number): Observable<Course> {
    return this.apiService.patch<Course>(this.pathUrl + id + '/', data);
  }

  deleteSubject(id: number) {
    return this.apiService.delete(this.pathUrl + id);
  }
}
