import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { RoleKey } from '@vru/master-data';
import { RoleBaseGuard } from '@vru/utils';

@Injectable({
  providedIn: 'root',
})
export class StudentProfileFormGuard
  extends RoleBaseGuard
  implements CanActivate, CanActivateChild, CanLoad
{
  permissive: RoleKey[] = ['admin', 'backoffice', 'Student', 'guardian'];
}
