import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  AdmissionProgram,
  AdmissionRegistrationRound,
  AdmissionRoundType,
  Modify,
  NestedNullable,
  Nullable,
  Payment,
  PaymentDetail,
  RegistrationSubMenuConfig,
} from '@vru/master-data';
import { PaymentService } from './payment.service';

@Injectable({
  providedIn: 'root',
})
export class AdmissionService {
  constructor(private fb: FormBuilder, private payment: PaymentService) {}

  buildAdmissionPaymentDetailFormGroup(
    initial?: Nullable<PaymentDetail> | null
  ): FormGroup {
    const formGroup = this.fb.group({
      fee_type: [initial?.fee_type],
      amount: [initial?.amount, Validators.maxLength(6)],
    });
    if (initial?.id != null) {
      formGroup.setControl('id', this.fb.control(initial.id));
    }
    return formGroup;
  }

  buildAdmissionRoundFormGroup(admissionRound?: Nullable<AdmissionRegistrationRound>): FormGroup {
    const formObj = Object.keys(this.admissionRoundTemplate).reduce(
      (form, key) => {
        const keyForm = key as keyof AdmissionRegistrationRound;
        if (admissionRound?.[keyForm] != null) {
          form[keyForm] = admissionRound[keyForm];
        }
        return form;
      },
      this.admissionRoundTemplate as { [k: string]: any }
    ) as AdmissionRoundWithForm;
    formObj.degrees = new FormControl(formObj.degrees);
    formObj.upload_file_types = new FormControl(formObj.upload_file_types);
    formObj.register_config_submenu = this.buildRoundMenuConfigFormArray(
      admissionRound?.register_config_submenu || []
    );
    formObj.admission_programs = this.buildAdmissionProgramsArray(
      admissionRound?.admission_programs || []
    );
    const formGroup = this.fb.group(formObj);
    if (admissionRound?.id != null) {
      formGroup.setControl('id', this.fb.control(admissionRound.id));
    }
    return formGroup;
  }

  buildApplicationFeePaymentFormGroup(
    applicationFeePayment?: NestedNullable<Payment> | null
  ): FormGroup {
    const payment: NestedNullable<Payment> = applicationFeePayment || {};
    payment.payment_details ||= [];
    const paymentForms = payment.payment_details.map((payment) =>
      this.buildAdmissionPaymentDetailFormGroup(payment)
    );
    const paymentDetailIndex: number =
      this.payment.findPaymentByFeeTypeIndex(
        '19_other_fee',
        (payment?.payment_details || []) as Nullable<PaymentDetail>[]
      );
    let paymentDetailForm = paymentForms[paymentDetailIndex];
    if (paymentDetailIndex === -1) {
      paymentDetailForm = this.buildAdmissionPaymentDetailFormGroup({
        fee_type: '19_other_fee',
      });
      paymentForms.push(paymentDetailForm);
    }
    this.setPaymentDetailFormValidators(paymentDetailForm);
    return this.fb.group({
      payment_details: this.fb.array(paymentForms),
    });
  }

  buildRoundMenuConfigFormArray(
    roundMenuConfigs?: Nullable<RegistrationSubMenuConfig>[]
  ): FormArray {
    roundMenuConfigs = roundMenuConfigs || [];
    const formGroups = roundMenuConfigs?.map((config) =>
      this.buildRoundMenuConfigFormGroup(config)
    );
    return this.fb.array(formGroups);
  }

  buildAdmissionProgramsArray(admissionProgram?: Nullable<AdmissionProgram>[]): FormArray {
    admissionProgram = admissionProgram || [];
    const formGroups = admissionProgram?.map((config) =>
      this.buildAdmissionProgramFormGroup(config)
    );
    return this.fb.array(formGroups);
  }

  buildAdmissionProgramFormGroup(admissionProgram?: Nullable<AdmissionProgram>): FormGroup {
    return this.fb.group({
      application_fee_payment: this.buildApplicationFeePaymentFormGroup(
        admissionProgram?.application_fee_payment
      ),
      eligible_education_level: this.fb.array(admissionProgram?.eligible_education_level?.map((item) => this.fb.control(item)) || []),
      id: admissionProgram?.id,
      interview_end_date: [admissionProgram?.interview_end_date, Validators.required],
      initial_fee_payment: this.buildInitialFeePaymentFormGroup(
        admissionProgram?.initial_fee_payment
      ),
      interview_room: [
        admissionProgram?.interview_room
      ],
      building: [admissionProgram?.building],
      interview_start_date: [admissionProgram?.interview_start_date, Validators.required],
      number_of_admitted: [admissionProgram?.number_of_admitted, Validators.required],
      opened: [admissionProgram?.opened ? admissionProgram?.opened : false, Validators.required],
      program_name: [admissionProgram?.program_name, Validators.required],
      program: [admissionProgram?.program, Validators.required],
      registration_round: [admissionProgram?.registration_round, Validators.required],
    });
  }

  buildInitialFeePaymentFormGroup(
    initialFeePayment?: NestedNullable<Payment> | null
  ): FormGroup {
    const payment: NestedNullable<Payment> = initialFeePayment || {};
    payment.payment_details ||= [];
    const paymentForms = payment.payment_details.map((payment) =>
      this.buildAdmissionPaymentDetailFormGroup(payment)
    );
    const paymentDetailIndex: number =
      this.payment.findPaymentByFeeTypeIndex(
        '05_entrance_fee',
        (payment?.payment_details || []) as Nullable<PaymentDetail>[]
      );
    let paymentDetailForm = paymentForms[paymentDetailIndex];
    if (paymentDetailIndex === -1) {
      paymentDetailForm = this.buildAdmissionPaymentDetailFormGroup({
        fee_type: '05_entrance_fee',
      });
      paymentForms.push(paymentDetailForm);
    }
    this.setPaymentDetailFormValidators(paymentDetailForm);
    return this.fb.group({
      payment_details: this.fb.array(paymentForms),
    });
  }

  buildRoundMenuConfigFormGroup(
    roundMenuConfig?: Nullable<RegistrationSubMenuConfig>
  ): FormGroup {
    const template = {
      attachment_type: null,
      file_name: null,
      file_upload: null,
      name: null,
      opened: false,
      sequence: null,
      url: null,
    };
    roundMenuConfig = { ...template, ...roundMenuConfig };
    return this.fb.group(roundMenuConfig);
  }

  buildAdmissionRoundTypeFormArray(
    roundTypes?: Nullable<AdmissionRoundType>[]
  ): FormArray {
    roundTypes = roundTypes || [{ name: null }];
    const formGroups = roundTypes.map((roundType) =>
      this.buildAdmissionRoundTypeFormGroup(roundType)
    );
    return new FormArray(formGroups);
  }

  buildAdmissionRoundTypeFormGroup(
    roundType?: Nullable<AdmissionRoundType>
  ): FormGroup {
    const formGroup = this.fb.group({
      name: roundType?.name || null,
      editable: roundType?.editable || false
    });
    if (roundType?.id) {
      formGroup.setControl('id', new FormControl(roundType.id));
    }
    return formGroup;
  }

  setPaymentDetailFormValidators(paymentDetailForm: FormGroup) {
    paymentDetailForm
      .get('amount')
      ?.setValidators([Validators.required, Validators.maxLength(6)]);
    paymentDetailForm.get('fee_type')?.setValidators([Validators.required]);
  }

  validateAdmissionFeePayment(payment: Partial<Payment>): ValidationErrors | null {
    if (!payment?.payment_details) {
      return { payment_details: 'Payment details is required' };
    }
    const admissionPaymentDetail = payment.payment_details.find(
      (paymentDetail) => paymentDetail.fee_type === '19_other_fee'
    );
    if (!admissionPaymentDetail) {
      return {
        payment_details:
          'Admission fee is required 19_other_fee payment detail',
      };
    }
    admissionPaymentDetail.amount ||= 0;
    return null;
  }

  get admissionRoundTemplate(): Nullable<AdmissionRegistrationRound> {
    return {
      alive: true,
      admission_programs: [],
      application_fee_paid_end_date: null,
      degrees: null,
      initial_fee_paid_end_date: null,
      register_config_submenu: [],
      registration_end_date: null,
      registration_fee_paid_end_date: null,
      registration_start_date: null,
      student_type: null,
      round_no: null,
      round_type: null,
      programs: null,
      title: null,
      upload_file_types: [],
      year: null,
      campus: null
    };
  }
}

type AdmissionRoundWithForm = {
  [P in keyof AdmissionRegistrationRound]:
  | AdmissionRegistrationRound[P]
  | FormArray
  | FormControl;
};

export type PartialPayment = Nullable<
  Modify<Payment, { payment_details: Partial<PaymentDetail>[] }>
>;
