<ng-select [bindLabel]="!showAsPostalCode ? 'value_thai' : 'postal_code'"
    [clearable]="clearable"
    [items]="subDistrictDropdown"
    [loading]="isLoading"
    [typeToSearchText]="'พิมพ์อย่างน้อย 2 ตัวเพื่อค้นหา'"
    [typeahead]="search$"
    [(ngModel)]="selected"
    (change)="onLocationSelect($event)">
  <ng-template ng-option-tmp
      let-item="item">
    <span>{{ !showAsPostalCode ? item.value_thai : item.postal_code }}</span>
    <small class="d-block">
      {{ item.district?.value_thai }} >
      {{ item.province?.value_thai }} >
      {{ showAsPostalCode ? item.value_thai : item.postal_code }}
    </small>
  </ng-template>
</ng-select>