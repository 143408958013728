<div class="component-container d-flex align-items-center"
    [formGroup]="formGroup">
  <div class="input-group mr-3">
    <input #date="ngbDatepicker"
        class="form-control"
        formControlName="date"
        name="dp"
        ngbDatepicker
        placeholder="{{ placeholder }}"
        readonly
        (click)="date.toggle()">
    <button class="btn btn-green-vru calendar"
        title="แสดงปฏิทิน"
        (click)="touch(); date.toggle()"
        type="button">
      <vru-carbon-icon iconName="calendar"
          presetFilter="fill-white"
          size="18">
      </vru-carbon-icon>
    </button>
  </div>
  <ngb-timepicker formControlName="time"
      (click)="touch()">
  </ngb-timepicker>
</div>