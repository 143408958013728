<div class="position-relative px-5 pb-4">
  <div class="row pt-4">
    <div class="col-12"
        [class.col-md-6]="!onlyFullName">
      <div class="form-group mb-4">
        <label class="d-block">ชื่อจริง</label>
        <input class="form-control"
            [readOnly]="selectOnly"
            [(ngModel)]="_fullName"
            (ngModelChange)="onFullNameChange($event, fullNameCanvas)">
      </div>
      <div class="canvas-group mb-4">
        <label class="d-block col-12 px-0"
            for="canvas-fullname">
          ตัวอย่างชื่อจริง
        </label>
        <canvas #fullNameCanvas 
            class="canvas-signature col-12"
            id="canvas-fullname"
            height="140"
            [class.selected]="selectedSignature === 'full'"
            (click)="onTextSignatureSelect('full')">
        </canvas>
      </div>
    </div>
    <div *ngIf="!onlyFullName"
        class="col-12 col-md-6">
      <div class="form-group mb-4">
        <label class="d-block">ชื่อย่อ</label>
        <input class="form-control"
            [readOnly]="selectOnly"
            [(ngModel)]="_abbreviatedName"
            (ngModelChange)="redrawTextSignature($event, abbreviatedNameCanvas)">
      </div>
      <div class="canvas-group mb-4">
        <label class="d-block px-0 col-12"
            for="canvas-abbreviated-name">
          ตัวอย่างชื่อย่อ
        </label>
        <canvas #abbreviatedNameCanvas
            class="canvas-signature col-12"
            id="canvas-abbreviated-name"
            height="140"
            [class.selected]="selectedSignature === 'abbreviate'"
            (click)="onTextSignatureSelect('abbreviate')">
        </canvas>
      </div>
    </div>
  </div>
  <div class="row justify-content-end px-2">
    <button class="btn btn-outline-danger"
        type="button"
        (click)="onCancelClick()">
      ยกเลิก
    </button>
    <button type="button"
        class="btn btn-outline-green-vru mx-2"
        (click)="onConfirmClick()">
      ตกลก
    </button>
  </div>
</div>