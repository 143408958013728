import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/news-feed', pathMatch: 'full' },
  {
    path: 'news-feed',
    loadChildren: () =>
      import('./modules/news-feed/news-feed.module').then(
        (m) => m.NewsFeedModule
      ),
  },
  {
    path: 'recruitment-system',
    loadChildren: () =>
      import('./modules/recruitment-system/recruitment-system.module').then(
        (m) => m.RecruitmentSystemModule
      ),
  },
  {
    path: 'search-board',
    loadChildren: () =>
      import('./modules/search-board/search-board.module').then(
        (m) => m.SearchBoardModule
      ),
  },
  {
    path: 'payment-slip',
    loadChildren: () =>
      import('./modules/payment-slip/payment-slip.module').then(
        (m) => m.PaymentSlipModule
      ),
  },
  {
    path: 'issued-documents',
    loadChildren: () =>
    import('./modules/student-status/student-status.module').then(
      (m) => m.StudentStatusModule
    )
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class AppRoutingModule {}
