<div class="position-relative">
  <div class="board-white w-100 h-100 px-5 py-4">
    <div class="row no-gutters flex-nowrap mt-2 mb-4">
      <div class="col">
        <h4 class="mb-0">{{ configs.header }}</h4>
      </div>
      <div class="d-flex align-items-end justify-content-end">
        <div ngbDropdown
            placement="bottom-right">
          <button class="btn btn-green-vru dropdown-toggle"
              ngbDropdownToggle
              type="button">
            <span class="bi bi-file-earmark-text h5 mb-0"></span>
            <span class="d-none d-md-inline ml-2">พิมพ์รายงาน</span>
          </button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem
                type="button"
                (click)="printPendingGradeReport()">
              รายงานวิชาที่ยังค้างส่งผลการศึกษา
            </button>
            <button ngbDropdownItem
                type="button"
                (click)="printEnrolledCreditsGPAReport()">
              รายงานนักศึกษาตามช่วง GPA
            </button>
          </div>
        </div>
      </div>
    </div>
    <form #searchForm="ngForm"
        (ngSubmit)="onSearchClick(searchForm)">
      <div class="frame-filter flex-nowrap mx-0 mb-3 pt-3">
        <div class="form-row row-filter input-express-invalid">
          <div class="col-12 col-lg-3 col-xxl-2">
            <label>
              ปีการศึกษา<span class="text-red">*</span>
            </label>
            <input class="form-control"
                name="academic_year"
                placeholder="ระบุ"
                required
                pattern="^[0-9]*$"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted"
                [(ngModel)]="searchParams.academic_year">
          </div>
          <div class="col-12 col-lg-3 col-xxl-2">
            <label>
              ภาคการศึกษา<span class="text-red">*</span>
            </label>
            <ng-select bindLabel="label"
                bindValue="value"
                name="semester"
                placeholder="เลือก"
                required
                vruValidate
                [clearable]="false"
                [items]="semesterYear"
                [showMsg]="false"
                [submitted]="isSubmitted"
                [(ngModel)]="searchParams.semester"
                [searchable]="true">
            </ng-select>
          </div>
          <div *ngIf="configs?.filters?.teacher"
              class="col-12 col-lg-3 col-xxl-2">
            <label>อาจารย์ผู้สอน</label>
            <vru-teacher-typeahead-dropdown name="teacher"
                [clearable]="true"
                [(ngModel)]="searchParams.teacher">
            </vru-teacher-typeahead-dropdown>
          </div>
          <div class="col-12 col-lg-3 col-xxl-2">
            <label>ประเภทนักศึกษา</label>
            <ng-select bindLabel="label"
                bindValue="value"
                name="student_type"
                placeholder="เลือก"
                [clearable]="true"
                [items]="studentTypeDropdown"
                [searchable]="true"
                [(ngModel)]="searchParams.student_type">
            </ng-select>
          </div>
          <div *ngIf="configs?.filters?.status"
              class="col-12 col-lg-3 col-xxl-2">
            <label>สถานะ</label>
            <ng-select bindLabel="label"
                bindValue="value"
                name="grade_submission_status"
                placeholder="เลือก"
                [clearable]="false"
                [items]="statusDropdown"
                [(ngModel)]="searchParams.grade_submission_status"
                [searchable]="true">
            </ng-select>
          </div>
          <div *ngIf="configs?.filters?.course"
              class="col-12 col-lg-3 col-xxl-2">
            <label>รหัสวิชา</label>
            <input class="form-control"
                title="รหัสวิชา"
                type="text"
                name="course_code"
                [(ngModel)]="searchParams.course_code">
          </div>
          <div *ngIf="configs?.filters?.course"
              class="col-12 col-lg-3 col-xxl-2">
            <label>ชื่อวิชา</label>
            <input class="form-control"
                title="ชื่อวิชา"
                type="text"
                name="course_name"
                [(ngModel)]="searchParams.course_name">
          </div>
          <div *ngIf="configs?.filters?.faculty"
              class="col-12 col-lg-3 col-xxl-2">
            <label>คณะ</label>
            <ng-select bindLabel="label"
                bindValue="value"
                name="faculty"
                [items]="facultyDropdown"
                [(ngModel)]="searchParams.faculty"
                (clear)="onFacultyClear()"
                (ngModelChange)="onFacultyChange($event)">
            </ng-select>
          </div>
          <div *ngIf="configs?.filters?.field_of_study"
              class="col-12 col-lg-3 col-xxl-2">
            <label>สาขาวิชา</label>
            <ng-select bindLabel="label"
                bindValue="value"
                name="field_of_study"
                [items]="fieldOfStudyDropdown"
                [loading]="isFieldOfStudyLoading"
                [readonly]="!!configs.filters?.faculty && !searchParams.faculty"
                [(ngModel)]="searchParams.field_of_study">
            </ng-select>
          </div>
          <div *ngIf="configs?.filters?.student_section"
              class="col-12 col-lg-3 col-xxl-2">
            <label>หมู่เรียนนักศึกษา</label>
            <vru-ng-select-typeahead bindLabel="code"
                bindValue="code"
                placeholder="เลือก"
                name="student_section"
                [accessor]="studentSectionDropdownAccessor"
                [dataApiGenerator]="studentSectionDropdownGenerator"
                [(ngModel)]="searchParams.student_section">
              <ng-template vruNgSelectOptionDirective
                  let-item>
                <div class="">
                  <span>{{ item.code }}</span>
                </div>
              </ng-template>
            </vru-ng-select-typeahead>
          </div>
          <div *ngIf="configs?.filters?.student_section"
              class="col-12 col-lg-3 col-xxl-2">
            <label>นักศึกษา</label>
            <vru-student-typeahead-dropdown name="student"
                [clearable]="true"
                [(ngModel)]="searchParams.student">
            </vru-student-typeahead-dropdown>
          </div>
          <div class="col-auto d-flex justify-content-end align-items-end">
            <button class="btn btn-green-vru mr-2"
                type="submit">
              ค้นหา
            </button>
            <button class="btn btn-outline-green-vru"
                type="button"
                (click)="clearFilter()">
              ล้างตัวค้นหา
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="body position-relative">
      <div class="d-flex-column h-100" 
          [class.spinner-blur-effect]="isLoading">
        <div class="table-responsive flex-grow-1">
          <table class="table table-grade-submission table-hover text-center">
            <thead>
              <tr>
                <th scope="col">ปีการศึกษา</th>
                <th scope="col">รหัสวิชา</th>
                <th scope="col">ชื่อวิชา</th>
                <th scope="col">Sec</th>
                <th scope="col">หมู่เรียน</th>
                <th scope="col">เวลาเรียน / ห้องเรียน</th>
                <th scope="col">วันที่ส่งเกรด</th>
                <th scope="col">วันที่คณะยืนยัน</th>
                <th scope="col">วันที่ทะเบียนยืนยัน</th>
              </tr>
            </thead>
            <tbody>
              <tr class="pointer"
                  *ngFor="let gradeDisplays of gradeSubmissionList; index as i"
                  (click)="goToDetail(gradeDisplays!.id)">
                <td>
                  {{ gradeDisplays?.semester_course?.semester || '-' }}/
                  {{gradeDisplays?.semester_course?.academic_year || '-'}}
                </td>
                <td class="text-left">
                  {{gradeDisplays?.semester_course?.course?.code || '-'}}
                </td>
                <td>{{gradeDisplays?.semester_course?.course?.name_thai || '-'}}</td>
                <td>{{gradeDisplays?.section_no || '-'}}</td>
                <td class="text-center">
                  {{ gradeDisplays?.student_section?.code || '-' }}
                </td>
                <td class="text-left">
                  <!-- Tanya: If show this column. It will be good -->
                  <div *ngFor="let timetable of gradeDisplays?.timetable_managements"
                      class="d-flex">
                    <label class="pointer text-nowrap mb-0">
                      {{thDay[enDay[timetable.day]] || '-'}} ({{timetable.start_time || '-'}} - {{timetable.end_time}})
                    </label>
                    <label *ngIf="timetable?.classroom?.code || true"
                        class="pointer text-nowrap mb-0">
                      <span class="mx-1">/</span>
                      {{ timetable?.classroom?.code || 'A0298'}}
                    </label>
                  </div>
                </td>
                <td #professor>
                  <ng-container [ngSwitch]="gradeDisplays.grade_submission_status">
                    <ng-container *ngSwitchCase="'wait_for_professor_submit_grade'">
                      <ng-container *ngTemplateOutlet="waitForLabel; context: { $implicit: 'รอดำเนินการ' }">
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'revised'">
                      <label class="badge-pill bg-orange pointer text-nowrap text-white mb-0"
                          triggers="mouseenter:mouseleave"
                          popoverClass="popover-orange popover-min-md"
                          popoverTitle="สถานะ"
                          [ngbPopover]="statusMsg">
                        รอการแก้ไข
                      </label>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <label class="pointer text-success mb-0">
                        {{
                          gradeDisplays?.grade_submission_date
                          ? parseDateTime(gradeDisplays.grade_submission_date)
                          : '-'
                        }}
                      </label>
                    </ng-container>
                  </ng-container>
                </td>
                <td #dean>
                  <ng-container *ngIf="gradeDisplays.grade_submission_status === 'wait_for_dean_approval';
                    else notWaitDeanTemp">
                    <ng-container *ngTemplateOutlet="waitForLabel"></ng-container>
                  </ng-container>
                  <ng-template #notWaitDeanTemp>
                    <ng-container *ngIf="gradeDisplays?.grade_submission_status === 'revised' &&
                      gradeDisplays?.grade_submission_date != null &&
                      gradeDisplays?.dean_confirm_date == null;
                      else approvalDateTemp">
                      <ng-container *ngTemplateOutlet="rejectTemp"></ng-container>
                    </ng-container>
                    <ng-template #approvalDateTemp>
                      <label class="pointer text-success mb-0">
                        {{
                          gradeDisplays?.dean_confirm_date
                          ? parseDateTime(gradeDisplays.dean_confirm_date)
                          : '-'
                        }}
                      </label>
                    </ng-template>
                  </ng-template>
                </td>
                <td #backoffice>
                  <ng-container *ngIf="gradeDisplays.grade_submission_status === 'wait_for_backoffice_approval';
                    else notWaitBackofficeTemp">
                    <ng-container *ngTemplateOutlet="waitForLabel"></ng-container>
                  </ng-container>
                  <ng-template #notWaitBackofficeTemp>
                    <ng-container *ngIf="gradeDisplays?.grade_submission_status === 'revised' &&
                      gradeDisplays?.grade_submission_date != null &&
                      gradeDisplays?.dean_confirm_date != null &&
                      gradeDisplays?.backoffice_confirm_date == null;
                      else approvalDateTemp">
                      <ng-container *ngTemplateOutlet="rejectTemp"></ng-container>
                    </ng-container>
                    <ng-template #approvalDateTemp>
                      <label class="pointer text-success mb-0 px-2">
                        {{
                          gradeDisplays?.backoffice_confirm_date
                          ? parseDateTime(gradeDisplays.backoffice_confirm_date)
                          : '-'
                        }}
                      </label>
                    </ng-template>
                  </ng-template>
                </td>
                <ng-template #rejectTemp>
                  <vru-carbon-icon class="d-inline-block"
                      iconName="close-filled"
                      presetFilter="fill-red"
                      size="22"
                      popoverClass="popover-red popover-min-md"
                      popoverTitle="สถานะ"
                      triggers="mouseenter:mouseleave"
                      [ngbPopover]="statusMsg">
                  </vru-carbon-icon>
                </ng-template>
                <ng-template #waitForLabel
                    let-customText>
                  <label class="badge-pill bg-orange pointer text-nowrap text-white mb-0"
                      triggers="mouseenter:mouseleave"
                      popoverClass="popover-orange popover-min-md"
                      popoverTitle="สถานะ"
                      [ngbPopover]="statusMsg">
                    {{ customText || 'รอการอนุมัติ' }}
                  </label>
                </ng-template>
                <ng-template #statusMsg>
                  <span>{{ gradeDisplays?.grade_submission_status_display }}</span>
                </ng-template>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center">
          <vru-pagination-table-footer class="flex-grow-1"
              [(page)]="page"
              [(pageSize)]="pageSize"
              [totalCount]="totalCount"
              (selectPageChange)="onPageChange(searchForm)">
          </vru-pagination-table-footer>
        </div>
      </div>
      <div class="container-spinner-float">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
            name="grade-submission"
            color="#3cd070"
            size="medium"
            type="ball-clip-rotate-pulse"
            [fullScreen]="false"
            [showSpinner]="isLoading">
        </ngx-spinner>
      </div>
    </div>
  </div>
</div>

<ng-template #printPendingGradeReportModal
    let-modal>
  <form #form="ngForm">
    <div class="modal-header">
      <h4>พิมพ์รายงานวิชาที่ยังค้างส่งผลการศึกษา</h4>
    </div>
    <div class="modal-body input-express-invalid">
      <div class="d-flex flex-wrap">
        <div class="form-group col-lg-6">
          <label>ภาคการศึกษา</label>
          <ng-select bindLabel="label"
              bindValue="value"
              name="semester"
              placeholder="เลือก"
              required
              vruValidate
              [clearable]="false"
              [items]="semesterYear"
              [showMsg]="false"
              [submitted]="isExportSubmitted"
              [(ngModel)]="exportSemester"
              [searchable]="true">
          </ng-select>
        </div>
        <div class="form-group col-lg-6">
          <label>ปีการศึกษา</label>
          <input class="form-control"
              name="academic_year"
              placeholder="ระบุ"
              required
              pattern="^[0-9]*$"
              vruValidate
              [showMsg]="false"
              [submitted]="isExportSubmitted"
              [(ngModel)]="exportAcademicYear">
        </div>
        <div class="form-group col-lg-6">
          <label>ประเภทนักศึกษา</label>
          <ng-select bindLabel="label"
              bindValue="value"
              name="student_type"
              placeholder="เลือก"
              required
              vruValidate
              [showMsg]="false"
              [submitted]="isExportSubmitted"
              [clearable]="false"
              [items]="studentTypeDropdown"
              [(ngModel)]="exportStudentType"
              [searchable]="true">
            <ng-template ng-label-tmp
                let-item="item"
                let-index="index">
              <div class="d-flex overflow-auto w-100">
                <span class="text-truncate">{{ item.label }}</span>
              </div>
            </ng-template>
            <ng-template ng-option-tmp
                let-item="item"
                let-index="index">
              <div class="d-flex text-wrap">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="d-flex justify-content-end mt-4">
        <button class="btn btn-outline-green-vru mr-2"
            type="button"
            (click)="modal.dismiss()">
          ยกเลิก
        </button>
        <button class="btn btn-green-vru"
            type="submit"
            (click)="onExportPendingGrade(form)">
          ยืนยัน
        </button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #printEnrolledCreditsGPAReportModal
    let-modal>
  <form #form="ngForm">
    <div class="modal-header">
      <h4>พิมพ์รายงานนักศึกษาตามช่วงGPA</h4>
    </div>
    <div class="modal-body input-express-invalid">
      <div class="d-flex flex-wrap">
        <div class="form-group col-lg-6">
          <label>ภาคการศึกษา</label>
          <ng-select bindLabel="label"
              bindValue="value"
              name="semester"
              placeholder="เลือก"
              required
              vruValidate
              [clearable]="false"
              [items]="semesterYear"
              [showMsg]="false"
              [submitted]="isExportSubmitted"
              [(ngModel)]="exportSemesterGPA"
              [searchable]="true"></ng-select>
        </div>
        <div class="form-group col-lg-6">
          <label>ปีการศึกษา</label>
          <input class="form-control"
              name="academic_year"
              placeholder="ระบุ"
              required
              pattern="^[0-9]*$"
              vruValidate
              [showMsg]="false"
              [submitted]="isExportSubmitted"
              [(ngModel)]="exportAcademicYearGPA">
        </div>
        <div class="form-group col-lg-6">
          <label>ช่วง GPA</label>
          <input class="form-control number-non-arrow"
              name="gpa_range_min"
              placeholder="ตั้งแต่ 0.00"
              type="number"
              [(ngModel)]="exportGpaRangeMin">
          <div>
            <input class="form-control number-non-arrow mt-2"
                name="gpa_range_max"
                placeholder="ถึง 4.00"
                type="number"
                [(ngModel)]="exportGpaRangeMax">
          </div>
        </div>
        <div class="form-group col-lg-6">
          <label>หน่วยกิตที่ลงทะเบียน</label>
          <input class="form-control number-non-arrow"
              name="enrolled_credits"
              title="หน่วยกิตที่ลงทะเบียน"
              type="number"
              [(ngModel)]="exportEnrolledCredits">
        </div>
        <div class="form-group col-lg-6">
          <label>หน่วยกิตที่ผ่าน</label>
          <input class="form-control number-non-arrow"
              name="passed_credits"
              title="หน่วยกิตที่ผ่าน"
              type="number"
              [(ngModel)]="exportPassedCredits">
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="d-flex justify-content-end mt-4">
        <button class="btn btn-outline-green-vru mr-2"
            type="button"
            (click)="modal.dismiss()">
          ยกเลิก
        </button>
        <button class="btn btn-green-vru"
            type="submit"
            (click)="onExportRangeGrade(form)">
          ยืนยัน
        </button>
      </div>
    </div>
  </form>
</ng-template>