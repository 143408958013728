import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CanvasWhiteboardComponent,
  CanvasWhiteboardOptions,
} from 'ng2-canvas-whiteboard';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { AsyncSubject, Observable } from 'rxjs';
import { share, take } from 'rxjs/operators';

@Component({
  selector: 'vru-image-drawing',
  templateUrl: './image-drawing.component.html',
  styleUrls: ['./image-drawing.component.scss'],
  viewProviders: [CanvasWhiteboardComponent],
})
export class ImageDrawingComponent implements OnChanges {
  @Input() aspectRatio = 0;
  @Input() startingBgColor = 'transparent';
  @Input() image?: string | null = null;
  @Input() mode: ImageDrawingMode | string = 'preview';
  @Input() options: ImageDrawingOptions = {
    height: '400px',
  };
  @Input() saveButtonEnabled = false;

  @Output() imageChange = new EventEmitter<string>();
  @Output() imageCropped = new EventEmitter<string>();
  @Output() modeChange = new EventEmitter<ImageDrawingMode>();
  @Output() save = new EventEmitter<string>();

  @ViewChild(CanvasWhiteboardComponent) whiteboard!: CanvasWhiteboardComponent;

  canvasOptions: CanvasWhiteboardOptions = {
    drawButtonEnabled: false,
    drawingEnabled: true,
    clearButtonEnabled: true,
    clearButtonClass: 'bi bi-arrow-repeat',
    undoButtonEnabled: true,
    undoButtonClass: 'bi bi-arrow-counterclockwise',
    redoButtonClass: 'bi bi-arrow-clockwise',
    redoButtonEnabled: true,
    colorPickerEnabled: false,
    lineWidth: 10,
    strokeColor: 'rgb(0,0,0)',
    shouldDownloadDrawing: false,
    shapeSelectorEnabled: false,
  };
  croppedImage?: string | null = '';
  observer!: ResizeObserver;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.aspectRatio) {
      this.canvasOptions = {
        ...this.canvasOptions,
        aspectRatio: 1 / changes.aspectRatio.currentValue,
      };
    }
    if (changes.options) {
      window.dispatchEvent(new Event('resize'));
    }
    if (changes.saveButtonEnabled) {
      this.canvasOptions.saveDataButtonEnabled = this.saveButtonEnabled;
    }
  }

  generateImage(): Observable<string | Blob> {
    const generateImage$ = new AsyncSubject<string>();
    this.whiteboard.generateCanvasData((generatedData: string | Blob) => {
      generateImage$.next(generatedData as string);
      generateImage$.complete();
    });
    return generateImage$.pipe(take(1));
  }

  onImageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.imageCropped.emit(this.croppedImage as string);
  }

  imageLoaded(image?: LoadedImage) {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  onSave(image?: string | Blob): Observable<string | Blob> | undefined {
    const save = (data: string) => {
      this.image = data;
      this.save.emit(this.image);
      this.imageChange.emit(this.image);
    };
    if (image) {
      save(image as string);
      return;
    }
    const image$ = this.generateImage().pipe(share());
    image$.subscribe({
      next: (data) => {
        save(data as string);
      },
    });
    return image$;
  }
}

export interface ImageDrawingOptions {
  height?: string | number;
  width?: string | number;
}

export type ImageDrawingMode = 'preview' | 'sign' | 'crop';
