import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { DrfList } from '../common.model';
import {
  Tqf2ErrorValidation,
  Tqf2ListExpand,
  Tqf2ListItem,
  Tqf2Params,
  Tqf2SuccessValidation,
} from '../tqf2.model';
import { Tqf2OverviewComment } from './tqf2-comment.model';
import { Tqf2ApprovalTransaction, Tqf2Results } from './tqf2-results.model';
import { TQF2FileUploadHistory } from './tqf2-upload-history.model';
import { TqfApprovalTransaction } from './tqf.model';

@Injectable({
  providedIn: 'root',
})
export class Tqf2ApiService {
  readonly approvalTransactionPath = 'tqf2-transactions';
  readonly tqf2CommentPath = '/tqf2-approvals/';
  readonly tqf2Section4 = '/tqf2-teacher-section-4'
  tqf2Path = '/tqf2/';
  tqf2TeacherPath = '/tqf2-teachers/';

  constructor(private api: ApiService) {}

  approve(id: number, payload?: { [k: string]: string }): Observable<void> {
    return this.api.post(this.tqf2Path + `${id}/approve/`, payload);
  }

  getApprovalTransaction(
    tqf2Id: number
  ): Observable<DrfList<Tqf2ApprovalTransaction>> {
    return this.api.get([this.approvalTransactionPath], { tqf2: tqf2Id });
  }

  getDetail<Expand extends Tqf2ListExpand | void = void>(
    id: number,
    params?: Tqf2Params
  ): Observable<Tqf2ListItem<Expand>> {
    return this.api.get<Tqf2ListItem<Expand>>(this.tqf2Path + `${id}/`, params);
  }

  getList<Expand extends Tqf2ListExpand | void = void>(
    params?: Tqf2Params
  ): Observable<DrfList<Tqf2ListItem<Expand>>> {
    return this.api.get(this.tqf2Path, params);
  }

  /** Get the results of course. This part is the section 4 of TQF2 */
  getResultsSection(
    id: number,
    payload?: { [expand: string]: string[] }
  ): Observable<Tqf2Results> {
    return this.api.get<Tqf2Results>(
      [this.tqf2Section4, id.toString()],
      payload
    );
  }

  // TODO: verify type with BE
  getTransactionComments(id: number): Observable<Tqf2OverviewComment> {
    return this.api.get<Tqf2OverviewComment>(this.tqf2CommentPath + `${id}/`);
  }

  getValidation(id: number): Observable<Tqf2SuccessValidation> {
    return this.api
      .post<Tqf2SuccessValidation>(this.tqf2Path + `${id}/validate/`, {})
      .pipe(
        catchError((res: Tqf2ErrorValidation) => {
          const errObjs = res?.error.errors;
          if (!errObjs || !Array.isArray(errObjs)) {
            throw res;
          }
          const tqfErrorSection: number[] = [];
          errObjs.forEach((errObj, index) => {
            if (Object.keys(errObj).length) {
              tqfErrorSection.push(index + 1);
            }
          });
          throw {
            ...res,
            errorMessage: `กรุณากรอกข้อมูล มคอ.2 หมวด ${tqfErrorSection.join(
              ', '
            )} ให้ครบถ้วน`,
          };
        })
      );
  }

  patchResultsSection(id: number, payload: Partial<Tqf2Results>) {
    return this.api.patch<Tqf2Results>(
      [this.tqf2Section4, id.toString()],
      payload
    );
  }

  reject(id: number, comment: { comment: {[k: string]: string }}): Observable<void> {
    return this.api.post(this.tqf2Path + `${id}/reject/`, comment);
  }

  getUploadHistory(id: number): Observable<DrfList<TQF2FileUploadHistory>> {
    return this.api.get(this.tqf2TeacherPath + `${id}/file-upload-history/`);
  }

  onUploadFile(id:number, data: FormData): Observable<void> {
    return this.api.post(this.tqf2TeacherPath + `${id}/upload-file/`, data);
  }

  getTQF2PdfFile(id: number) {
    return this.api.postBlob(this.tqf2TeacherPath + `${id}/pdf`);
  }

  getTQF2ConvertPdfFile(id: number, params: {file_upload_id: number}) {
    return this.api.postBlob(this.tqf2TeacherPath + `${id}/convert-to-pdf`, params);
  }
}
