import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DrfList } from '../common.model';
import { LOAGroup } from './loa.model';

@Injectable({
  providedIn: 'root',
})
export class LOAService {
  readonly loaGroupPath = '/loa-groups/';

  constructor(private http: ApiService) {}

  getLOAGroupList(params?: any): Observable<DrfList<LOAGroup>> {
    return this.http.get<DrfList<LOAGroup>>(this.loaGroupPath, params);
  }

  getLOAGroupByID(id: number, params?: any): Observable<LOAGroup> {
    return this.http.get<LOAGroup>(this.loaGroupPath + `${id}/`, params)
  }

  postLOAGroup(params?: any): Observable<LOAGroup> {
    return this.http.post(this.loaGroupPath, params);
  }

  patchLOAGroup(id: number, params?: any): Observable<LOAGroup> {
    return this.http.patch<LOAGroup>(this.loaGroupPath + `${id}/`, params)
  }

  deleteLOAGroup(id: number) {
    return this.http.delete(this.loaGroupPath + `${id}/`)
  }
}