/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { Classroom, ClassroomListParams, Examination, ThaiDay } from '@vru/master-data';
import { DateTimeService } from '@vru/services';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BoundaryTime, Dropdown, Nullable } from '../common.model';
import { DropdownApiService } from '../dropdown/dropdown-api.service';
import { ExaminationService } from '../education/examination.service';
import { ClassroomService } from './classroom.service';
import { RoomAvailability, TimeSlot } from './exam-room.model';

@Injectable({
  providedIn: 'root',
})
export class RoomApiService {
  constructor(
    private classroom: ClassroomService,
    private dateTimeServ: DateTimeService,
    private dropdown: DropdownApiService,
    private exam: ExaminationService
  ) {}

  getClassroomAvailability(
    classrooms: Classroom[],
    timeSlotMap: Map<string, { [timeSlot: string]: TimeSlot }>
  ) {
    return classrooms.map((room) => {
      let available = true;
      timeSlotMap.forEach((dateSlot) => {
        available = !Object.values(dateSlot).every((examTimeSlot) => {
          return !!examTimeSlot.course_sections.length;
        });
      });
      return { ...room, available };
    });
  }

  getExamRoomAvailability(
    startDate: Date,
    endDate: Date,
    params?: Nullable<ClassroomListParams>
  ): Observable<RoomAvailability> {
    return this.classroom.getList(params).pipe(
      switchMap((res) => {
        return forkJoin({
          classrooms: of(res.results),
          examSlots: this.getExamRoomTimeSlots(
            res.results.map((room) => room.id),
            startDate,
            endDate
          ),
        });
      }),
      map((res) => {
        return {
          classrooms: this.getClassroomAvailability(
            res.classrooms,
            res.examSlots
          ),
          dateTimeSlotMap: res.examSlots,
        };
      })
    );
  }

  getExamRoomTimeSlots(
    roomIds: number[],
    startDate: Date,
    endDate: Date
  ): Observable<Map<string, { [timeSlot: string]: TimeSlot<Examination> }>> {
    const date = this.dateTimeServ.getStrDates(startDate, endDate);
    return forkJoin({
      timeSlots: this.dropdown.getDropdown<number, BoundaryTime>(
        'examination_time_slot'
      ),
      reserves: this.exam.getList({ exam_room: roomIds }),
    }).pipe(
      map((res) => {
        return this.getRoomTimeSlots(
          date,
          res.timeSlots as Required<Dropdown<number, BoundaryTime>>[],
          res.reserves.results.map((exam) => {
            return {
              ...exam,
              day: exam.exam_date,
              timeSlotId: exam.time_slot.id,
            };
          })
        );
      })
    );
  }

  getRoomTimeSlots<T>(
    days: string[],
    timeSlots: Required<Dropdown<number, BoundaryTime>>[],
    reserves: ({ day: string; timeSlotId: number } & T)[]
  ): Map<string, { [timeSlot: string]: TimeSlot<T> }> {
    return days.reduce((result, day) => {
      result.set(day, {});
      timeSlots.forEach((timeSlot) => {
        result.get(day)![timeSlot.label] = {
          ...timeSlot,
          available: true,
          course_sections: [],
        };
        const targetTimeSlot = result.get(day)![timeSlot.label];
        reserves.forEach((reserve, inx, examArr) => {
          const matchDate = reserve.day === day;
          const matchTime = reserve.timeSlotId === timeSlot.value;
          if (matchDate && matchTime) {
            targetTimeSlot.course_sections.push(reserve);
            targetTimeSlot.available = false;
            examArr.splice(inx, 1);
          }
        });
      });
      return result;
    }, new Map<string, { [timeSlot: string]: TimeSlot<T> }>());
  }

  getLectureRoomTimeSlots(
    roomIds: number[]
  ): Observable<Map<string, { [timeSlot: string]: TimeSlot<Examination> }>> {
    return forkJoin({
      timeSlots: this.dropdown.getDropdown<number, BoundaryTime>(
        'timetable_time_slot'
      ),
      reserves: this.exam.getList({ exam_room: roomIds }),
    }).pipe(
      map((res) => {
        return this.getRoomTimeSlots(
          Object.keys(ThaiDay).filter((val) => !Number.isInteger(+val)),
          res.timeSlots as Required<Dropdown<number, BoundaryTime>>[],
          res.reserves.results.map((exam) => {
            return {
              ...exam,
              day: exam.exam_date,
              timeSlotId: exam.time_slot.id,
            };
          })
        );
      })
    );
  }

  getLectureRoomAvailability(
    params?: Nullable<ClassroomListParams>
  ): Observable<RoomAvailability> {
    return this.classroom.getList(params).pipe(
      switchMap((res) => {
        return forkJoin({
          classrooms: of(res.results),
          studySlots: this.getLectureRoomTimeSlots(
            res.results.map((room) => room.id)
          ),
        });
      }),
      map((res) => {
        return {
          classrooms: this.getClassroomAvailability(
            res.classrooms,
            res.studySlots
          ),
          dateTimeSlotMap: res.studySlots,
        };
      })
    );
  }
}
