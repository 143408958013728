import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { DrfList, Teacher, TeacherApiService } from '@vru/master-data';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'vru-teacher-typeahead-dropdown',
  templateUrl: './teacher-typeahead-dropdown.component.html',
  styleUrls: ['./teacher-typeahead-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TeacherTypeaheadDropdownComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: TeacherTypeaheadDropdownComponent,
    },
  ],
})
export class TeacherTypeaheadDropdownComponent
  implements ControlValueAccessor, OnChanges, OnInit, Validator
{
  @Input() bindLabel = 'name';
  @Input() clearable = false;
  @Input() loading = false;
  @Input() optionField?: string[] = ['name', 'code', 'faculty'];
  @Input() readonly = false;
  @Input() required: string | boolean = false;
  @Output() selectedChange = new EventEmitter();

  selectedStudent?: Partial<Teacher>;
  showCode = false;
  showFaculty = false;
  showName = false;

  private _onTouch: any;
  private _onChange!: (val: any) => void;

  constructor(
    private cdRef: ChangeDetectorRef,
    private teacherApi: TeacherApiService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.optionField) {
      this.validateOptionField();
    }
    if (changes.required) {
      this.required = changes.required.currentValue != null;
    }
  }

  ngOnInit(): void {
    this.validateOptionField();
  }

  dataApiGenerator = (search: string) => {
    this.loading = true;
    this.cdRef.markForCheck();
    return this.teacherApi.getTeachers({ search, expand: 'faculty' }).pipe(
      map((res) => (res as DrfList<Teacher<void>>).results),
      tap(() => (this.loading = false))
    );
  };

  onValueChange(val: Partial<Teacher>) {
    this._onChange(val);
    this.selectedChange.emit(val);
  }

  registerOnTouched(fn: any) {
    this._onTouch = fn;
  }

  registerOnChange(fn: (val: any) => void) {
    this._onChange = fn;
  }

  validate(): ValidationErrors | null {
    if (!this.required) {
      return null;
    }
    return this.selectedStudent
      ? null
      : {
          teacher: {
            required: true,
          },
        };
  }

  validateOptionField(): void {
    const defaultFields = ['name', 'code', 'faculty'];
    if (!this.optionField || !this.optionField.length) {
      this.optionField = defaultFields;
    }
    this.showCode = this.optionField.includes('code');
    this.showFaculty = this.optionField.includes('faculty');
    this.showName = this.optionField.includes('name');
  }

  writeValue(val: Partial<Teacher>): void {
    this.selectedStudent = val;
  }
}
