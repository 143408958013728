import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Day,
  DrfList,
  Dropdown,
  DropdownApiService,
  GradeSubmission,
  GradeSubmissionExportGPAParams,
  GradeSubmissionExportParams,
  GradeSubmissionParams,
  GradeSubmissionService,
  GradeSubmissionStatus,
  Modify,
  NestedNullable,
  Student,
  StudentSection,
  StudentSectionApiService,
  Teacher,
  ThaiDay,
  UserProfile,
} from '@vru/master-data';
import {
  AlertService,
  ComponentSubscriptionService,
  DateTimeService,
  UserService,
} from '@vru/services';
import { convertError, UtilityService } from '@vru/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { GradeConfigService } from '../../grade-config.service';

@Component({
  selector: 'vru-grade-submission-list',
  templateUrl: './grade-submission-list.component.html',
  styleUrls: ['./grade-submission-list.component.scss'],
  providers: [ComponentSubscriptionService],
})
// TODO: Rename to GradeRecordList
export class GradeSubmissionListComponent implements OnInit, OnDestroy {
  @ViewChild('printPendingGradeReportModal')
  printPendingGradeReportModal!: ElementRef;
  @ViewChild('printEnrolledCreditsGPAReportModal')
  printEnrolledCreditsGPAReportModal!: ElementRef;

  academicYearDropdown: string[] = [];
  teacherId!: number;
  configs: { [k: string]: any };
  gradeSubmissionList!: GradeSubmission[];
  gradeSubmissionList$ = new Subject<GradeSubmissionParams>();
  exportAcademicYear!: number | null;
  exportSemester!: number | null;
  exportStudentType!: number | null;
  exportStudentSection!: number | null;
  exportCourseCode!: string | null;
  exportAcademicYearGPA!: number | null;
  exportSemesterGPA!: number | null;
  exportGpaRangeMin!: number | null;
  exportGpaRangeMax!: number | null;
  exportEnrolledCredits!: number | null;
  exportPassedCredits!: number | null;
  facultyDropdown: Dropdown[] = [];
  fieldOfStudyDropdown: Dropdown[] = [];
  isSubmitted = false;
  isExportSubmitted = false;
  isFieldOfStudyLoading = false;
  searchKeys: { [p in keyof SearchParams]: string } = {
    academic_year: 'string',
    faculty: 'number',
    field_of_study: 'number',
    semester: 'number',
    course_code: 'string',
    course_name: 'string',
    grade_submission_status: 'string',
    page: 'number',
    page_size: 'number',
    student: 'object',
    student_section: 'string',
    student_type: 'number',
    teacher: 'object',
  };
  searchParams: NestedNullable<SearchParams> = {
    academic_year: null,
    semester: 1,
  };
  studentTypeDropdown!: Dropdown[];
  semesterYear: Dropdown<number>[] = [];
  statusDropdown: Dropdown<string>[] = [];
  studentSectionDropdown: Dropdown[] = [];
  isLoading = false;
  thDay = ThaiDay;
  enDay = Day;
  profile!: UserProfile;
  page = 1
  pageSize = 40;
  totalCount = 0;

  studentSectionDropdownAccessor = (res: DrfList<StudentSection>) => {
    return res.results;
  };
  studentSectionDropdownGenerator = (
    search: string
  ): Observable<DrfList<StudentSection>> => {
    return this.studentSectionApiService.getStudentSections({
      code: search,
    }) as Observable<DrfList<StudentSection>>;
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private alert: AlertService,
    private comSubs: ComponentSubscriptionService,
    @Optional() private injectConfig: GradeConfigService,
    private gradeSubmissionService: GradeSubmissionService,
    private dropdownApi: DropdownApiService,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private studentSectionApiService: StudentSectionApiService,
    private util: UtilityService,
    private ngbModal: NgbModal,
    private dateTimeService: DateTimeService,
    private userService: UserService
  ) {
    this.configs = Object.assign(
      { header: 'ส่งผลการเรียน', reportable: false },
      this.injectConfig?.gradeSubmission.snapshot() || {}
    );
    if (this.configs.searchAhead) {
      this.setSearchAhead();
    }
    this.subscribeGradeSubmission();
  }

  ngOnInit(): void {
    this.onSpinnerShow();
    this.onCreateDropdown();
    this.fetchDropdown();
    this.subscribeActivatedRoute();
  }

  ngOnDestroy(): void {
    this.comSubs.destroy();
  }

  fetchDropdown() {
    this.comSubs.store.dropdown = this.dropdownApi
      .getDropdown<number | string>([
        'grade_submission_status',
        'faculty',
        'student_section',
        'student_type',
      ])
      .subscribe({
        next: (res) => {
          this.statusDropdown = res[
            'grade_submission_status'
          ] as Dropdown<string>[];
          this.statusDropdown.unshift({
            label: 'ทั้งหมด',
            value: 'all',
          });
          this.studentSectionDropdown = res['student_section'] as Dropdown[];
          this.studentTypeDropdown = res['student_type'] as Dropdown<number>[];
          this.facultyDropdown = res['faculty'] as Dropdown[];
        },
      });
  }

  goToDetail(id: number): void {
    this.router.navigate(['../', id, { ...this.searchParams }], {
      relativeTo: this.activatedRoute,
    });
  }

  onPageChange(form: NgForm): void {
    if (form.invalid) return;
    this.searchSubmissionList({ ...(this.searchParams as any), ...{ page: this.page, page_size: this.pageSize} });
  }

  onSearchClick(form: NgForm) {
    this.isSubmitted = true;
    if (form.invalid) return;
    this.page = 1;
    this.pageSize = 40;
    this.searchSubmissionList({
      ...form.value,
      page: this.page,
      page_size: this.pageSize,
    });
  }

  onExportPendingGrade(form: NgForm) {
    this.isExportSubmitted = true;
    if (form.invalid) {
      return;
    }
    const payload: GradeSubmissionExportParams = form.value;
    this.gradeSubmissionService.onExportPenddingGrade(payload).subscribe({
      next: (res) => {
        this.isExportSubmitted = false;
        this.util.downloadFile(res, 'รายงานวิชาที่ยังค้างส่งผลการศึกษา');
        this.ngbModal.dismissAll();
        this.exportAcademicYear = null;
        this.exportSemester = null;
        this.exportStudentType = null;
        this.exportStudentSection = null;
        this.exportCourseCode = null;
        form.reset();
      },
      error: async (err) => {
        this.isExportSubmitted = false;
        if (err.error) {
          this.alert.error(await convertError(err), 5000);
        }
      },
    });
  }

  onExportRangeGrade(form: NgForm) {
    this.isExportSubmitted = true;
    if (form.invalid) {
      return;
    }
    const payload: GradeSubmissionExportGPAParams = form.value;
    this.gradeSubmissionService.onExportRangeGrade(payload).subscribe({
      next: (res) => {
        this.isExportSubmitted = false;
        this.util.downloadFile(res, 'รายชื่อนักศึกษาตามช่วง GPA');
        this.ngbModal.dismissAll();
        this.exportAcademicYearGPA = null;
        this.exportSemesterGPA = null;
        this.exportGpaRangeMin = null;
        this.exportGpaRangeMax = null;
        this.exportEnrolledCredits = null;
        this.exportPassedCredits = null;
        form.reset();
      },
      error: async (err) => {
        this.isExportSubmitted = false;
        if (err.error) {
          this.alert.error(await convertError(err), 5000);
        }
      },
    });
  }

  onFacultyChange(faculty: number): void {
    this.isFieldOfStudyLoading = true;
    this.dropdownApi.getDropdown('field_of_study', { faculty }).subscribe({
      next: (res) => {
        this.fieldOfStudyDropdown = res;
        this.isFieldOfStudyLoading = false;
      },
      error: () => {
        this.isFieldOfStudyLoading = false;
        this.alert.errorWithContactAdminTh();
      },
    });
  }

  onFacultyClear(): void {
    this.searchParams.field_of_study = null;
    this.fieldOfStudyDropdown = [];
  }

  searchSubmissionList(params: SearchParams): void {
    const queryParams: Params = { ...params };
    if (params.teacher) {
      Object.assign(queryParams, {
        teacher: params.teacher.id,
        teacherName: params.teacher.name,
      });
    } else {
      delete queryParams.teacher;
      delete queryParams.teacherName;
    }
    if (params.student) {
      Object.assign(queryParams, {
        student: params.student.value,
        studentName: params.student.label,
      });
    } else {
      delete queryParams.student;
      delete queryParams.studentName;
    }
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
    });
  }

  setSearchAhead(): void {
    const academicYear = this.activatedRoute.snapshot.queryParams.academic_year;
    if (academicYear) return;
    this.searchParams.academic_year = new Date().getFullYear() + 543;
    this.router.navigate([], { queryParams: this.searchParams });
  }

  subscribeGradeSubmission(): void {
    this.comSubs.store.getListOfGradeSubmission = this.gradeSubmissionList$
      .pipe(
        filter(({ academic_year, semester }) => {
          return academic_year != null && semester != null;
        }),
        tap(() => (this.isLoading = true)),
        switchMap((params) =>
          this.gradeSubmissionService.getList(params).pipe(
            catchError((err) => {
              console.error(err);
              this.alert.errorWithContactAdmin();
              return of({ results: [], count: 0 });
            })
          )
        )
      )
      .subscribe({
        next: (response) => {
          this.gradeSubmissionList = response.results;
          this.totalCount = response.count;
          this.isLoading = false;
          this.isSubmitted = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  onCreateDropdown() {
    const semesters = [1, 2, 3];
    const academics = [''];
    semesters.forEach((semester) => {
      this.semesterYear = [
        ...this.semesterYear,
        { label: semester.toString(), value: semester },
      ];
    });
    for (let i = 0; i <= 10; i++) {
      academics.forEach((academic) => {
        this.academicYearDropdown = [
          ...this.academicYearDropdown,
          academic + (new Date().getFullYear() + 543 + i).toString(),
        ];
      });
    }
  }

  clearFilter(): void {
    this.searchParams = {
      academic_year: null,
      semester: 1,
    };
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  printPendingGradeReport() {
    const modal = this.ngbModal.open(this.printPendingGradeReportModal, {
      size: 'md',
      backdrop: 'static',
    });
    modal.result.catch(() => {
      this.exportAcademicYear = null;
      this.exportSemester = null;
      this.exportStudentType = null;
      this.exportStudentSection = null;
      this.exportCourseCode = null;
    });
  }

  printEnrolledCreditsGPAReport() {
    const modal = this.ngbModal.open(this.printEnrolledCreditsGPAReportModal, {
      size: 'md',
      backdrop: 'static',
    });
    modal.result.catch(() => {
      this.exportAcademicYearGPA = null;
      this.exportSemesterGPA = null;
      this.exportGpaRangeMin = null;
      this.exportGpaRangeMax = null;
      this.exportEnrolledCredits = null;
      this.exportPassedCredits = null;
    });
  }

  onSpinnerShow(): void {
    this.ngxSpinnerService.show('grade-submission');
  }

  subscribeActivatedRoute(): void {
    const filtersConfig = this.configs.filters;
    let firstEmitted = true;
    this.activatedRoute.queryParams
      .pipe(
        map((rawQuery) => {
          const query = { ...rawQuery };
          // Set first default
          if (firstEmitted) {
            if (this.configs.filters?.status && this.userService.isBackOffice) {
              query.grade_submission_status ||= 'wait_for_backoffice_approval';
            }
            firstEmitted = false;
          }
          (query.grade_submission_status as any) !== 'all' ||
            (query.grade_submission_status = undefined);
          return query;
        }),
      )
      .subscribe({
        next: (query) => {
          const keys = Object.keys(this.searchKeys) as (keyof SearchParams)[];
          const search: Params = {};
          keys.forEach((key) => {
            let queryValue: number | string | null = null;
            if (this.searchKeys[key] === 'number') {
              queryValue = Number(query[key]);
            } else if (this.searchKeys[key] === 'string') {
              queryValue = query[key];
            }
            if (queryValue) {
              search[key] = queryValue;
            }
          });
          // Set default value
          search.semester ||= 1;
          search.page ||= this.page;
          search.page_size ||= this.pageSize;
          // Screening by configuration
          const cleaningKey = [
            'course',
            'faculty',
            'field_of_study',
            'status',
            'student_section',
          ];
          cleaningKey.forEach((key) => {
            this.configs[key] = null;
          });
          if (filtersConfig.field_of_study) {
            let faculty: number | undefined | null;
            if (filtersConfig.faculty) {
              faculty = search.faculty as number;
            } else {
              const fieldOfStudyConfig =
                this.injectConfig.gradeSubmission?.filters?.field_of_study;
              faculty =
                typeof fieldOfStudyConfig === 'object'
                  ? fieldOfStudyConfig.faculty()
                  : null;
            }
            if (faculty) {
              this.onFacultyChange(faculty);
            }
          }
          if (filtersConfig.faculty && !query.faculty) {
            search.field_of_study = null;
          }
          this.searchParams = { ...this.searchParams, ...search };
          // Custom building search form
          if (filtersConfig.teacher && query.teacher) {
            this.searchParams.teacher = {
              id: Number(query.teacher) || null,
              name: query.teacherName,
            };
            search.teacher = this.searchParams.teacher.id;
          }
          if (filtersConfig.student && query.student) {
            this.searchParams.student = {
              label: query.studentName,
              value: Number(query.student) || null,
            };
            search.student = this.searchParams.student.value;
          }
          this.gradeSubmissionList$.next({
            ...search,
          } as GradeSubmissionParams);
        },
      });
  }

  parseDateTime(date: Date) {
    return this.dateTimeService.getStrThaiDateTime(String(date));
  }
}

type SubmissionStatusDropdown = GradeSubmissionStatus | 'all';

type SearchParams = Modify<
  GradeSubmissionParams,
  {
    grade_submission_status?: SubmissionStatusDropdown;
    student?: Dropdown<number, Student>;
    teacher?: Teacher;
    field_of_study: Dropdown;
  }
>;
