import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularResizeEventModule } from 'angular-resize-event';
import { LaddaModule } from 'angular2-ladda';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ImageDrawingModule } from '../image-drawing/image-drawing.module';
import { TextSignatureModule } from '../text-signature/text-signature.module';
import { SignatureSettingComponent } from './signature-setting.component';

@NgModule({
  declarations: [SignatureSettingComponent],
  imports: [
    AngularResizeEventModule,
    CommonModule,
    ImageDrawingModule,
    LaddaModule,
    NgxSpinnerModule,
    TextSignatureModule,
  ],
  exports: [SignatureSettingComponent],
})
export class SignatureSettingModule {}
