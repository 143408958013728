import { Tqf2Teacher } from '../tqf2.model';
import { TqfLoa as Tqf7Loa } from './tqf.model';

export interface Tqf7ListItem {
  academic_year: string;
  comment: string;
  course: null;
  dean_approved_at: Date | null;
  dean_approved_status: string | null;
  degree: string;
  faculty: string;
  field_of_study: string;
  head_of_program_approved_at: Date | null;
  head_of_program_approved_status: string | null;
  id: number;
  loa_group: Tqf7Loa;
  program_type: string;
  semester: string;
  status_display: string;
  status: string;
  submitted_at: Date | null;
  version_type: string;
}

export interface TQF7ListModel {
  id: number;
  can_submit: boolean;
  can_decide: boolean;
  can_revise: boolean;
  status: string;
}

export type TQF7GetModel<Expand extends TQF7Section | void = void> =
  TQF7ListModel & Expand;

export type Tqf7Permission = Omit<TQF7ListModel, 'id'>;

export type TQFPostResponse = TQF7ListModel &
  TQF7General &
  TQF7Teacher &
  TQF7Student &
  TQF7Course &
  TQF7Program &
  TQF7ProgramEffect &
  TQF7Suggestion &
  TQF7Plan;

export type TQF7Section =
  | TQF7General
  | TQF7Teacher
  | TQF7Student
  | TQF7Course
  | TQF7Program
  | TQF7ProgramEffect
  | TQF7Suggestion
  | TQF7Plan;

export interface TQF7General {
  program: number;
  faculty: number;
  program_history: string;
  program_responsible_teachers: Tqf2Teacher[];
  program_teachers: any[];
  special_teachers: any[];
  current_program_lecturers: number[];
  program_previous_year_improvement_results: Result[];
  standard_program_managements: StandardProgramManagement;
  program_management_evaluation_year: number;
  program_management_evaluations: GoalEvaluation;
}

export interface TQF7Teacher {
  lecturer_improvement_and_management: Result[];
  lecturer_improvement_and_management_evaluation_year: number;
  lecturer_improvement_and_management_evaluations: GoalEvaluation;
  phd_lecturer_quantity_evaluation_year: number;
  phd_lecturer_quantity: Result[];
  phd_lecturer_quantity_evaluations: GoalEvaluation;
  academic_position_lecturer_quantity: Result[];
  academic_position_lecturer_quantity_evaluation_year: number;
  academic_position_lecturer_quantity_evaluations: GoalEvaluation;
  program_lecturer_academic_works: Result[];
  academic_work_evaluation_year: number;
  academic_work_quality: WorkQuality;
  creative_work_quality: WorkQuality;
  academic_work_evaluations: GoalEvaluation;
  phd_lecturer_articles: Result[];
  phd_lecturer_article_evaluation_year: number;
  phd_lecturer_article_evaluations: GoalEvaluation;
  lecturer_effects: Result[];
  lecturer_effect_evaluation_year: number;
  lecturer_effect_evaluations: GoalEvaluation;
}

export interface TQF7Student {
  student_numbers_data: StudentNumberData[];
  passed_student_numbers: PassedStudentNumber[];
  student_numbers_changing_rate: string;
  student_numbers_affecting_factor: string;
  graduated_student_numbers: string;
  graduated_rate_details: string;
  student_admissions: Result[];
  student_admission_evaluations: GoalEvaluation;
  student_improvement: Result[];
  student_improvement_evaluations: GoalEvaluation;
  student_effects: Result[];
  student_effect_evaluations: GoalEvaluation;
  graduate_quality: Result[];
  graduate_quality_evaluations: GoalEvaluation;
  employed_or_freelancing_graduated_students: Result[];
  employed_or_freelancing_graduated_student_evaluations: GoalEvaluation;
  result_analysis: string;
  master_degree_student_published_works: Result[];
  master_degree_student_published_work_evaluations: GoalEvaluation;
  phd_degree_student_published_works: Result[];
  phd_degree_student_published_work_evaluations: GoalEvaluation;
  student_admission_evaluation_year: number;
  student_improvement_evaluation_year: number;
  student_effect_evaluation_year: number;
  graduate_quality_evaluation_year: number;
  employed_or_freelancing_graduated_student_evaluation_year: number;
  master_degree_student_published_work_evaluation_year: number;
  phd_degree_student_published_work_evaluation_year: number;
}

export interface TQF7Course {
  courses_summary: CourseSummary[];
  courses_with_abnormal_grades_analysis: CourseAnalysis[];
  unoffered_courses: UnofferedCourse[];
  incomplete_course_content_corrections: IncompleteCourse[];
  quality_evaluated_courses: QualityEvaluated[];
  teaching_strategy_effectiveness: TeachingEffectiveness[];
  has_course_orientation: boolean;
  new_lecturer_numbers: number;
  joined_orientation_lecturer_numbers: number;
  key_summary: string;
  joined_orientation_lecturer_evaluation_summary: string;
  not_has_orientation_reasons: string;
  lecturer_development_activities: LecturerActivity[];
  course_evaluations: GoalEvaluation;
  course_advantages: Result[];
  course_advantage_evaluations: GoalEvaluation;
  teaching_system_implementations: Result[];
  teaching_system_implementation_evaluations: GoalEvaluation;
  student_assessments: Result[];
  student_assessment_evaluations: GoalEvaluation;
  program_performance: ProgramPerformance[];
  program_performance_evaluations: GoalEvaluation;
  course_advantage_evaluation_year: number;
  teaching_system_implementation_evaluation_year: number;
  student_assessment_evaluation_year: number;
  program_performance_evaluation_year: number;
}

export interface TQF7Program {
  program_management: ProgramManagement[];
  learning_support: Result[];
  learning_support_evaluations: GoalEvaluation;
  learning_support_evaluation_year: number;
}

export interface TQF7Suggestion {
  program_quality_suggestions: {
    evaluator_comment: string;
    dean_comment: string;
  };
  survey_date: string;
  evaluation_summary_from_graduated_student: GoalEvaluation[];
  evaluation_summary_from_others: GoalEvaluation[];
}

export interface TQF7ProgramEffect {
  program_effects: { internal_effect: string; external_effect: string }[];
}

export interface TQF7Plan {
  new_plan_year: string;
  new_plans: Plan[];
  program_improvement_progress: Plan[];
  program_improvement_suggestions: string;
  course_updating_suggestions: string;
  lecturer_improvement_activity: string;
  signing_details: Signing[];
  program_president: Signature;
  associate_dean: Signature;
  dean: Signature;
}
export interface Result {
  observation: string;
  result: string;
}

export interface GoalEvaluation {
  indicator: string;
  goal: string;
  action_result_passed: boolean;
  evaluation_score_passed: boolean;
  goal_achievement: boolean;
}

export interface StandardProgramManagement {
  completed: boolean;
  evaluation_factor: string;
  evidence: string;
  result: string;
}

export interface WorkQuality {
  place: string;
  detail: string;
  score: string;
  full_name: string;
  name_of_work: string;
}

export interface StudentNumberData {
  registered_year: number;
  student_data: Pick<StudentData, 'year' | 'remain'>;
}

export interface PassedStudentNumber {
  registered_year: number;
  student_data: Pick<StudentData, 'year' | 'passed'>;
}

export interface StudentData {
  year: number;
  remain: number;
  passed: number;
}

export interface CourseSummary {
  semester: number;
  detail: CourseSummaryDetail[];
}

export interface CourseSummaryDetail
  extends Record<keyof typeof GradeKey, number> {
  course_code: string;
  course_name: string;
  register: number;
  passed: number;
}

export const TQF7Grades = [
  'A',
  'B+',
  'B',
  'C+',
  'C',
  'D+',
  'D',
  'F',
  'I',
  'W',
  'PD',
  'P',
  'P',
  'NP',
  'S',
  'U',
];

export const GradeKey = TQF7Grades.map(
  (grade) => `grade_${grade.toLocaleLowerCase().replace('+', '_plus')}`
);

export interface CourseAnalysis {
  course_code: string;
  course_name: string;
  semester: number;
  abnormal: string;
  checking: string;
  reason: string;
  solution: string;
}

export interface UnofferedCourse {
  course_detail: string;
  reason: string;
  solution: string;
}

export interface IncompleteCourse {
  course_detail: string;
  missing_content: string;
  reason: string;
  solution: string;
}

export interface QualityEvaluated {
  course_code: string;
  course_name: string;
  semester: number;
  evaluation_result: string;
}

export interface TeachingEffectiveness {
  standard_learning_result: string;
  comment: string;
  solution: string;
}

export interface LecturerActivity {
  name: string;
  lecturer_joined: string;
  support_joined: string;
  comment: string;
}

export interface CourseEvaluation {
  course_detail: string;
  evaluation_from_student: string;
  teaching_quality_evaluation: string;
  development_plan: string;
}

export interface ProgramPerformance {
  indicator: string;
  result: string;
  passed: boolean;
  evidence: string;
}

export interface ProgramManagement {
  problem: string;
  effect: string;
  solution: string;
}

export interface Plan {
  plan: string;
  due_date: string;
  responsible_person: string;
  progress: string;
}

export interface Signing {
  teacher: Partial<Tqf2Teacher>;
  responsible_position: string;
  signed_at: string;
}

export interface Signature {
  full_name: string;
  signature: string;
  signed_at: string;
}
