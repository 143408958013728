export enum Gender {
  male,
  female
}

export enum ThaiGender {
  ชาย,
  หญิง
}

export enum BloodType {
  A,
  B,
  AB,
  O
}

export enum FamilyIncomeRanges {
  below_fifteen_thousand,
  fifteen_thousand_to_nineteen_thousand,
  twenty_thousand_to_twenty_nine_thousand,
  thirty_thousand_to_thirty_nine_thousand,
  forty_thousand_to_forty_nine_thousand,
  fifty_thousand_up,
}

export enum LifeStatuses {
  no_status,
  alive,
  died,
}

export enum FamilyIncomeRangeTexts {
  '< 15,000',
  '15,000 - 19,999',
  '20,000 - 29,999',
  '30,000 - 39,999',
  '40,000 - 49,999',
  '> 50,000',
}

export enum MaritalStatuses {
  'single',
  'married',
  'widowed',
  'separated',
  'divorced',
}

export enum  MaritalStatusesTh {
  'โสด',
  'สมรส',
  'หม้าย',
  'แยกกันอยู่',
  'หย่า',
}
export type Genders = keyof typeof Gender;
