// TODO: Move this file and folder to lib/person/student

import { Degree } from '../../admission/admission.model';
import { Modify, OnExpand } from '../../common.model';
import {
  FamilyIncomeRanges,
  Genders,
  LifeStatuses,
} from '../../person/person.constant';
import { NameTitle } from '../../person/person.model';
import { GuardianType, StudentStatusCode } from '../../person/student.model';
import { Address, AddressUpdate } from '../../thai-location/thai-location.model';
import { Qualification } from '../../tqf2.model';
import { Faculty } from '../department/faculty.model';
import { Program } from '../syllabus.model';
import { Teacher } from '../teacher/teacher.model';
import { StudentSection } from './student-section.model';

export interface GraduateStudentListParams extends StudentListParams {
  academic_year?: number | null;
  order_by?: `${'-' | ''}${keyof GraduateStudent}`;
}

// TODO: Move interface to person.model.ts
export interface Guardian {
  first_name: string;
  last_name: string;
  address: string;
  address_selected: AddressSelection;
  phone_number: string;
  profile: number | null;
  occupation: string;
  income: keyof typeof FamilyIncomeRanges;
  student?: number;
  email: string;
  student_id: number; 
  remark: string;
}

export interface Father {
  first_name: string;
  last_name: string;
  address: string;
  address_selected: AddressSelection;
  phone_number: string;
  profile: number | null;
  occupation: string;
  income: keyof typeof FamilyIncomeRanges;
  status: keyof typeof LifeStatuses;
  marital_status: string;
  student?: number;
  email: string;
  remark: string;
}

export interface Mother {
  first_name: string;
  last_name: string;
  address: string;
  address_selected: AddressSelection;
  phone_number: string;
  profile: number | null;
  occupation: string;
  income: keyof typeof FamilyIncomeRanges;
  status: keyof typeof LifeStatuses;
  marital_status: string;
  student?: number;
  email: string;
  remark: string;
}

export interface Supporter {
  first_name: string;
  last_name: string;
  address: string;
  address_selected: AddressSelection;
  phone_number: string;
  email: string;
  occupation: string;
  income: keyof typeof FamilyIncomeRanges;
  status: keyof typeof LifeStatuses;
  student?: number;
  remark: string;
}
export interface PreviousAcademy {
  id: number;
  province: string;
  code: number;
  value_thai: string;
  value_eng: string;
}

// TODO: Implement missing expand type
// TODO: Move interface to person/student.model.ts
export interface Student<expand extends StudentExpandField | void = void> {
  address_current: Address | null;
  address_id_card: string;
  advisors: OnExpand<Pick<Teacher, 'id' | 'name'>, 'advisors', expand>[];
  birth_date: null | string;
  blood_type: string;
  campus: { id: number; name: string };
  can_enroll: boolean;
  code: string;
  college_year: string;
  congenital_disease: string;
  degree: OnExpand<Degree, 'degree', expand>;
  disability: string;
  email: string;
  faculty: OnExpand<Faculty, 'faculty', expand>;
  first_name_eng: string;
  first_name_thai: string;
  full_name_thai: string;
  full_name_eng: string;
  gpax: number | null;
  graduated_date: string | null;
  graduated_year: string;
  gender: Genders;
  guardian_type: GuardianType;
  honor: string;
  id_card_expired: null | string;
  id_card_issued: null | string;
  id_card: string;
  id: number;
  is_credit_bank_student: boolean;
  join_graduation_ceremony: boolean;
  last_name_eng: string;
  last_name_thai: string;
  medical_history: string;
  mhesi_number: string;
  name_of_brothers_sisters: string[] | null;
  name_title: OnExpand<NameTitle, 'name_title', expand>;
  nationality: number;
  number_of_brothers_sisters: number;
  old_code: number;
  old_faculty: string;
  old_field_of_study: string;
  old_program: string;
  old_student_type: string;
  phone_number: string;
  photo: string | null;
  previous_academy_gpax: number | null;
  previous_academy: PreviousAcademy | string | null;
  previous_education_level_name: string;
  previous_education_level: string;
  previous_field_of_study: string;
  profile: number;
  program: OnExpand<Program, 'program', expand>;
  province: null | string;
  qualification: OnExpand<Qualification, 'qualification', expand>;
  race: number;
  religion: number;
  running_number: string;
  send_grade_status: string;
  special_interest: string;
  start_academic_year: number;
  status_detail: null | string;
  status: StudentStatusCode | null;
  student_file_uploads: StudentFileUpload[];
  student_section: OnExpand<StudentSection, 'student_section', expand>;
  student_type: OnExpand<StudentType, 'student_type', expand>;
  study_plan: number;
  subject_area: null;
  total_credits: number | null;
  guardian?: Guardian | null;
  father?: OnExpand<Father, 'father', expand> | null;
  mother?: OnExpand<Mother, 'mother', expand> | null;
  supporter?: OnExpand<Supporter, 'supporter', expand> | null;
  fullName?: string;
}

export interface StudentListParams {
  advisor?: number;
  campus?: number;
  code?: string;
  college_year?: string;
  companies?: string;
  course_sections?: number[];
  degree?: number;
  email?: string;
  enrollment_approve_status?: string;
  expand?: StudentExpandField | StudentExpandField[];
  faculty?: number;
  field_of_study?: number;
  file_type?: 'word' | 'pdf';
  gender?: string;
  graduation_day?: string;
  graduated_year?: number;
  graduation_registered?: boolean;
  id_card?: string;
  join_graduation_ceremony?: boolean;
  name?: string;
  program?: number;
  province?: number;
  qualification?: number;
  register_status?: string;
  send_grade_status?: string;
  school?: number;
  start_academic_year?: number;
  status_id?: number;
  student_section_code?: string;
  student_section?: string;
  status?: string;
  student_type?: number;
}

export interface StudentFileUpload {
  id: number;
  file_name: string;
  file_upload: string;
}

export interface StudentGraduateCertificateParams {
  file_type: 'pdf' | 'word';
  language: 'th' | 'en';
  person: number;
  person_position: string;
  students: number[];
}

export interface TranscriptIssuedDocContent {
  campus: string;
  full_name_program_with_abbreviation_th: string;
  graduated_date: string | null;
  status: string;
  student_code: string;
  student_name: string;
}

export type AddressSelection = 'address_id_card' | 'address_current';

export interface StudentType {
  code: string;
  id: number;
  name: string;
  type: string;
}

export interface CoursesListParam {
  course_code?: string | null;
  course_group_name?: string | null;
  course_name?: string | null;
  credit_hour?: string | null;
  grade?: string | null;
  evaluated?: boolean;
}

export type StudentExpandField =
  | 'advisors'
  | 'campus'
  | 'degree'
  | 'faculty'
  | 'father'
  | 'guardian'
  | 'mother'
  | 'name_title'
  | 'nationality'
  | 'program'
  | 'program.field_of_study'
  | 'old_faculty'
  | 'old_field_of_study'
  | 'old_program'
  | 'old_student_type'
  | 'qualification'
  | 'race'
  | 'religion'
  | 'status'
  | 'student_section'
  | 'student_type'
  | 'study_plan'
  | 'subject_area'
  | 'supporter'
  | 'previous_academy';

export type StudentUpdate = Modify<StudentWithFamily, { address_current: AddressUpdate }>
export type StudentWithFamily = Student<
  'father' | 'mother' | 'supporter' | 'guardian'
>;
export type GraduateStudent = Pick<
  Student,
  | 'code'
  | 'full_name_eng'
  | 'full_name_thai'
  | 'graduated_date'
  | 'honor'
  | 'id'
  | 'mhesi_number'
  | 'program'
>;

export interface CurrentSemester {
  semester: number;
  academic_year: number;
}
