import { Injectable } from '@angular/core';
import {
  AuthenticationApiService,
  AuthToken,
  Nullable,
} from '@vru/master-data';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  readonly accessTokenName = 'access_token';
  readonly refreshTokenName = 'refresh_token';
  readonly rememberMeKey = 'remember_me';

  constructor(
    private authApi: AuthenticationApiService,
    private cookie: CookieService
  ) {}

  clearAccessToken(): void {
    sessionStorage.removeItem(this.accessTokenName);
  }

  clearAuthToken(): void {
    sessionStorage.removeItem(this.accessTokenName);
    this.cookie.delete(this.refreshTokenName, '/');
  }

  refreshAccessToken(): Observable<{ access: string }> {
    return this.refreshToken
      ? this.authApi
          .refreshToken(this.refreshToken)
          .pipe(tap((token) => (this.accessToken = token.access)))
      : throwError({ error: 'There is no token' });
  }

  private get nonTokenError(): Error {
    return new Error(
      'Cannot set token with undefined or null. If you want to remove, please use remove method'
    );
  }

  get authToken(): Omit<Nullable<AuthToken>, 'user'> {
    return {
      access: this.accessToken,
      refresh: this.refreshToken,
    };
  }

  set authToken(authToken: Omit<Nullable<AuthToken>, 'user'>) {
    this.refreshToken = authToken.refresh;
    this.accessToken = authToken.access;
  }

  get accessToken(): string | null {
    return sessionStorage.getItem(this.accessTokenName);
  }

  set accessToken(token: string | undefined | null) {
    if (token == null) {
      throw this.nonTokenError;
    }
    sessionStorage.setItem(this.accessTokenName, token);
  }

  get refreshToken(): string | undefined | null {
    const token = this.cookie.get(this.refreshTokenName);
    return token !== '' ? token : null
  }

  set refreshToken(token: string | undefined | null) {
    if (token == null) {
      throw this.nonTokenError;
    }
    const expires = new Date();
    if (this.rememberMe) {
      expires.setFullYear(expires.getFullYear() + 1);
    } else {
      expires.setHours(expires.getHours() + 1);
    }
    this.cookie.set(this.refreshTokenName, token, {
      expires,
      path: '/',
      sameSite: 'Strict',
    });
  }

  get rememberMe(): boolean {
    return this.cookie.get(this.rememberMeKey) === 'true';
  }

  set rememberMe(value: boolean) {
    this.cookie.set(this.rememberMeKey, String(value), {
      path: '/',
    });
  }
}
