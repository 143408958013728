<div class="position-relative mt-3">
  <div class="board-white w-100 h-100 p-5 overflow-auto">
    <div class="row" [style.height.px]="250">
      <div class="col-3">
        <label>ย้ายหลักสูตร</label>
        <ng-select
          [(ngModel)]="program"
          bindLabel="label"
          [items]="programs"
          placeholder="เลือก"
          (change)="handleProgramChange()"
        ></ng-select>
      </div>
      <div class="col-3" *ngIf="program">
        <label>หมู่เรียน</label
        ><ng-select
          [(ngModel)]="student_section"
          bindLabel="label"
          bindValue="value"
          placeholder="เลือก"
          [items]="student_sections"
          (change)="handleStudentSectionChange()"
        ></ng-select>
      </div>
      <div class="col-3" *ngIf="study_plans?.length">
        <label>วิชาเอก</label
        ><ng-select
          [(ngModel)]="study_plan"
          bindLabel="label"
          bindValue="value"
          placeholder="เลือก"
          [items]="study_plans"
        ></ng-select>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button
        class="btn btn-outline-green-vru"
        type="button"
        (click)="cancel()"
      >
        ยกเลิก
      </button>
      <button
        class="btn btn-green-vru ml-2"
        [disabled]="!this.student_section"
        (click)="onSubmit()"
        type="submit"
      >
        บันทึก
      </button>
    </div>
  </div>
</div>
