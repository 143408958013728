import { Router } from '@angular/router';
import { ErrorHeader, News, NewsFeedService, NewsFeedParams } from '@vru/master-data';
import { Component, OnInit } from '@angular/core';
import { AlertService } from '@vru/services';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'homepage-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {

  page = 1;
  pageSize = 20;
  totalCount = 0;
  isLoading = false;

  newsList: News[] = [];

  constructor(
    private newsFeedService: NewsFeedService,
    private ngxSpinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getNewsList()
  }

  getNewsList() {
    this.isLoading = true;
    this.onSpinnerShow();
    const payload: NewsFeedParams = {
      type: 'announcement',
      page: this.page,
      page_size: this.pageSize
    }
    this.newsFeedService.getNews(payload).subscribe({
      next: (res) => {
        this.newsList = res.results;
        this.isLoading = false;
      },
      error: (err: ErrorHeader) => {
        this.isLoading = false;
        if (err.error) {
          this.alertService.error(err.error.detail, 10000);
        }
      }
    })
  }

  onClickNews(id: number) {
    this.router.navigate(['/news-feed/news/' + id])
  }

  goBack() {
    this.router.navigate(['/news-feed/']);
  }

  onSpinnerShow() {
    this.ngxSpinnerService.show('news-list-table');
  }
}
