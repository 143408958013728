<h5 class="mb-3">ยื่นจบการศึกษา</h5>
<div class="form-check">
  <input class="form-check-input"
      type="checkbox"
      id="get-degree"
      [formControl]="joinToCeremonyForm">
  <label class="form-check-label"
      for="get-degree">
    ต้องการเข้ารับพระราชทานปริญญาบัตร และยืนยันเข้าร่วมการซ้อมรับพระราชทานปริญญาบัตร
  </label>
</div>
<div class="d-flex justify-content-end">
  <button *ngIf="joinToCeremonyForm.dirty"
      class="btn btn-outline-green-vru mr-3"
      data-spinner-color="#2d7054"
      type="button"
      [ladda]="loading"
      (click)="onCancel()">
    ยกเลิก
  </button>
  <button class="btn btn-green-vru"
      type="button"
      [disabled]="joinToCeremonyForm.pristine"
      [ladda]="loading"
      (click)="onSubmit()">
    ยืนยัน
  </button>
</div>