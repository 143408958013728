import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor() {}

  /**
   * When you want to create FormGroup with a lot of keys.
   * This function help to generate initial object for FormGroup.
   */
  buildFormGroupInitializer<T extends string>(
    keys: T[],
    valueSource?: { [k: string]: any } | null,
    validators?: any[] | null
  ): { [P in T]: any[] } {
    return keys.reduce((obj, key) => {
      obj[key] = [valueSource?.[key], validators];
      return obj;
    }, {} as { [P in T]: any[] });
  }

  retrieveFormErrors(form: AbstractControl) {
    let initialError: ValidationErrors;
    if (form instanceof FormGroup || form instanceof FormArray) {
      initialError = form.errors || {};
    } else {
      return form.errors || null;
    }
    const errors = Object.keys(form.controls).reduce((obj, key) => {
      const control = form.get(key);
      if (!control) {
        return obj;
      }
      const retrieveErrors = this.retrieveFormErrors(control);
      if (retrieveErrors && Object.values(retrieveErrors).length) {
        obj[key] = retrieveErrors;
      }
      return obj;
    }, initialError);
    if (!!form.errors || Object.values(errors).length) {
      return { ...form.errors, ...errors };
    }
    return null;
  }
}
