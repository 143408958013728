import { Component, Inject, OnInit, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { API_CONFIG, ApiConfig } from '@vru/master-data';

@Component({
  selector: 'homepage-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  navbarMenu: NavbarMenu[] = [
    {
      label: 'หน้าแรก',
      value: 'homepage',
      path: 'news-feed'
    },
    {
      label: 'สมัครเรียน',
      value: 'news-feed',
      path: 'recruitment-system'
    },
    {
      label: 'หลักสูตร',
      value: 'program',
      path: 'search-board'
    },
    {
      label: 'เข้าสู่ระบบ',
      value: 'login',
      path: 'register/'
    },
  ]

  activeMenu!: NavbarMenu;

  constructor(
    @SkipSelf() @Inject(API_CONFIG) private apiConfig: ApiConfig,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.activeMenu = this.navbarMenu[0];
    this.router.events.subscribe(() => {
      if (this.location.path() != '') {
        const path = this.location.path().split('/')[1];
        this.activeMenu = this.navbarMenu.find((obj) => obj.path === path) as NavbarMenu;
      }
    })
  }

  onSelectMenu(menu: NavbarMenu) {
    this.activeMenu = menu;
    if (menu.value === 'login') {
      const domain = this.apiConfig.apiEndpoint;
      window.location.href = new URL('/register/login', domain).href;
    } else if (menu.value === 'program') {
      window.location.href = `${menu.path + '/search-syllabus' }`;
    } else {
      this.router.navigate([menu.path])
    }
  }
}

type NavbarMenu = {
  label: string;
  value: string;
  path: string;
}
