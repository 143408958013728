import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { UtilsModule } from '@vru/utils';
import { LaddaModule } from 'angular2-ladda';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SignatureSettingModule } from '../signature-setting/signature-setting.module';
import { UiModule } from '../ui.module';
import { UserInformationFormComponent } from './user-information-form/user-information-form.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserRoutingModule } from './user-rounting.module';
import { UserListReportModalComponent } from './components/user-list-report-modal/user-list-report-modal.component';

@NgModule({
  declarations: [UserListComponent, UserInformationFormComponent, UserListReportModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    LaddaModule,
    NgbDatepickerModule,
    NgbModule,
    NgSelectModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    SignatureSettingModule,
    UiModule,
    UserRoutingModule,
    UtilsModule,
  ],
})
export class UserModule {}
