import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UtilityService } from '@vru/utils';
import { Observable } from 'rxjs';
import urlJoin from 'url-join';
import { environment } from '../../../environments/environment';
import { API_CONFIG, ApiConfig } from './api.config';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl: string;
  graphql: string;

  constructor(
    @Inject(API_CONFIG) @Optional() apiConfig: ApiConfig,
    private http: HttpClient,
    private util: UtilityService
  ) {
    const baseUrl = apiConfig?.apiEndpoint || environment.baseUrl;
    this.baseUrl = baseUrl + '/backend/api';
    this.graphql = baseUrl + '/graphql';
  }

  requestHeader(): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Accept-Language', 'th');
    return headers;
  }

  get<T>(url: string | string[], params?: any, headers?: any): Observable<T> {
    const _url = Array.isArray(url) ? url : [url];
    _url.push('/');
    _url.unshift(this.baseUrl);
    return this.http.get<T>(urlJoin(_url), {
      headers: headers ? headers : this.requestHeader(),
      params: this.getValidHttpParams({ ...params }),
    });
  }

  getBlob<T>(url: string | string[], params?: any): Observable<T> {
    const _url = Array.isArray(url) ? url : [url];
    _url.push('/');
    _url.unshift(this.baseUrl);
    return this.http.get<T>(urlJoin(_url), {
      headers: this.requestHeader(),
      params: this.getValidHttpParams({ ...params }),
      responseType: 'blob' as 'json',
    });
  }

  getValidHttpParams(params: { [k: string]: any }): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (value == null) {
        return;
      }
      const isPrimitiveType = this.util.isPrimitiveType(value);
      const isArray = Array.isArray(value);
      if (isArray) {
        const isValidArray = (value as any[]).every((item) =>
          this.util.isPrimitiveType(item)
        );
        if (!isValidArray) {
          return;
        }
      } else if (!isPrimitiveType) {
        return;
      }
      httpParams = httpParams.append(key, value);
    });
    return httpParams;
  }

  post<T>(url: string | string[], item: any, headers?: any): Observable<T> {
    const _url = Array.isArray(url) ? url : [url];
    _url.push('/');
    _url.unshift(this.baseUrl);
    return this.http.post<T>(urlJoin(_url), item, {
      headers: headers ? headers : this.requestHeader(),
    });
  }

  postBlob(url: string | string[], data?: any): Observable<Blob> {
    const _url = Array.isArray(url) ? url : [url];
    _url.push('/');
    _url.unshift(this.baseUrl);
    return this.http.post(urlJoin(_url), data, {
      headers: this.requestHeader(),
      responseType: 'blob',
    });
  }

  graphqlPost<T>(item: any, headers?: any): Observable<T> {
    return this.http.post<T>(this.graphql, item, {
      headers: headers ? headers : this.requestHeader(),
    });
  }

  put<T>(url: string, item: any): Observable<T> {
    return this.http.put<T>(this.baseUrl + url, item, {
      headers: this.requestHeader(),
    });
  }

  patch<T>(url: string | string[], item: any, headers?: any): Observable<T> {
    const _url = Array.isArray(url) ? url : [url];
    _url.push('/');
    _url.unshift(this.baseUrl);
    return this.http.patch<T>(urlJoin(_url), item, {
      headers: headers ? headers : this.requestHeader(),
    });
  }

  delete<T>(url: string | string[]): Observable<T> {
    const _url = Array.isArray(url) ? url : [url];
    _url.push('/');
    _url.unshift(this.baseUrl);
    return this.http.delete<T>(urlJoin(_url), {
      headers: this.requestHeader(),
    });
  }
}
