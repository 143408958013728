import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppealLoaComponent } from './appeal-loa.component';
import { CarbonIconModule } from '../carbon-icon/carbon-icon.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@vru/utils';

@NgModule({
  imports: [
    CommonModule,
    CarbonIconModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    UtilsModule
  ],
  declarations: [
    AppealLoaComponent
  ],
  exports: [
    AppealLoaComponent
  ]
})
export class AppealLoaModule { }
