<div class="d-flex flex-wrap no-gutters bg-lightgreen-pagination">
  <div class="col-12 col-sm-auto border-lightgray">
    <div class="d-flex px-3 py-2">
      <label class="my-auto mr-2">จำนวนต่อหน้า:</label>
      <ng-select bindValue="value"
          bindLabel="value"
          [dropdownPosition]="'top'"
          [searchable]="true"
          [items]="pageSizeList"
          [clearable]="false"
          [(ngModel)]="pageSize"
          (change)="changeDropdownPageSize()">
      </ng-select>
    </div>
  </div>
  <div class="col-12 col-sm border-lightgray">
    <div class="d-flex px-3 py-3">
      <label *ngIf="(pageSize * page) < collectionSize"
          class="my-auto">{{((page-1) * pageSize) + 1}}-{{pageSize * page}} จาก {{collectionSize}} รายการ</label>
      <label *ngIf="(pageSize * page) >= collectionSize"
          class="my-auto">{{((page-1) * pageSize) + 1}}-{{collectionSize}} จาก {{collectionSize}} รายการ</label>
    </div>
  </div>
  <div class="col-12 col-lg-auto d-flex flex-column no-gutters">
    <div class="d-flex flex-wrap no-gutters flex-fill">
      <div class="col border-lightgray">
        <div class="row px-3 py-2">
          <ng-select class="mr-2 ml-2"
              bindValue="value"
              bindLabel="value"
              [dropdownPosition]="'top'"
              [items]="totalPageList"
              [clearable]="false"
              [(ngModel)]="page"
              (change)="changeDropdownPage()">
          </ng-select>
          <label class="my-auto mr-2">จาก {{totalPage}} หน้า</label>
        </div>
      </div>
      <div class="col-auto border-x-lightgray px-3 py-3">
        <label class="pointer"
            (click)="onPagePrevious()">
          <
        </label>
      </div>
      <div class="col-auto border-x-lightgray px-3 py-3">
        <label class="pointer"
            (click)="onPageNext()">
          >
        </label>
      </div>
    </div>
  </div>
</div>