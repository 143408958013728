import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  constructor() {}

  idIdCard(text: string, format?: 'with-hyphen' | 'number-only'): boolean {
    let criteria: RegExp;
    if (format === 'number-only') {
      criteria = new RegExp(/[0-9]{13}/);
    } else if (format === 'with-hyphen') {
      criteria = new RegExp(/^[0-9]-[0-9]{4}-[0-9]{5}-[0-9]{2}-[0-9]$/);
    } else {
      criteria = new RegExp(
        /([0-9]{13})|(^[0-9]-[0-9]{4}-[0-9]{5}-[0-9]{2}-[0-9]$)/
      );
    }
    return criteria.test(text);
  }

  thaiPhone(text: string | number) {
    const textPattern = new RegExp(
      /^0(([1-9]\d{7,8})|([1-9]\d{1}-\d{3}-?\d{3,4})|([1-9]?-\d{4}-?\d{4}))$/gm
    );
    const numberPattern = new RegExp(/^(\+?66)[1-9]\d{7,8}$/gm);
    const textValid = textPattern.test(String(text));
    const numberValid = numberPattern.test(String(text));
    return textValid || numberValid;
  }
}
