<div class="board-white position-relative h-100 p-5">
  <div class="row no-gutters justify-content-center justify-content-md-end">
    <div *ngIf="configs.showHeader"
        class="col mb-3">
      <div class="d-flex align-items-center">
        <h4 class="mb-0">ผลการเรียน</h4>
      </div>
    </div>
    <div *ngIf="configs.enableGradeChangeHistory"
        class="col-auto mb-3">
      <div *ngIf="gradeDetails.length && !isLoading"
          class="d-flex justify-content-end align-items-end w-100">
        <button class="btn btn-green-vru"
            type="button"
            [ladda]="isGradeChangeRecordLoading"
            (click)="onOpenModalGradeChange()">
          ประวัติการเปลี่ยนเกรด
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!configs.showStudentInfo && gradeDetails?.length"
      class="row no-gutters justify-content-center justify-content-md-end">
    <ng-container *ngTemplateOutlet="GradeDetailTemplate"></ng-container>
  </div>
  <div *ngIf="configs.showStudentInfo"
      class="row no-gutters">
    <div class="col mb-4">
      <div class="board-white shadow-none border p-3">
        <vru-information-display header="ข้อมูลนักศึกษา"
            [dataMap]="studentDisplayConfigs"
            [source]="studentInfo">
        </vru-information-display>
        <div *ngIf="gradeDetails.length"
            class="row align-items-center justify-content-end mx-0">
            <ng-container *ngTemplateOutlet="GradeDetailTemplate"></ng-container>
        </div>
      </div>
    </div>
  </div>

  <ng-template #GradeDetailTemplate>
    <div class="d-flex flex-nowrap no-gutters align-items-center justify-content-center justify-content-md-endr gpax-alone">
      <div class="col-6 col-xl-auto text-center mb-3">
        <label class="mb-0">
          หน่วยกิตที่ลง
        </label>
      </div>
      <div class="col-6 col-xl-auto text-center mb-3">
        <label class="green-card text-center w-100 mb-0">
          {{ gradeGPAX?.registered_credits ? gradeGPAX?.registered_credits : '-' }}
        </label>
      </div>
    </div>
    <div class="d-flex flex-nowrap no-gutters align-items-center justify-content-center justify-content-md-end gpax-alone">
      <div class="col-6 col-xl-auto text-center mb-3">
        <label class="mb-0">
          หน่วยกิตที่ผ่าน
        </label>
      </div>
      <div class="col-6 col-xl-auto text-center mb-3">
        <label class="green-card text-center w-100 mb-0">
          {{ gradeGPAX?.program_credits ? gradeGPAX?.program_credits : '-' }}
        </label>
      </div>
    </div>
    <div class="d-flex flex-nowrap no-gutters align-items-center justify-content-center justify-content-md-end gpax-alone">
      <div class="col-6 col-xl-auto text-center mb-3">
        <label class="mb-0">
          เกรดเฉลี่ยสะสม (GPAX)
        </label>
      </div>
      <div class="col-6 col-xl-auto text-center mb-3">
        <label class="green-card text-center w-100 mb-0">
          {{ gradeGPAX?.gpax ? (gradeGPAX?.gpax | number: '1.2-2') : '-' }}
        </label>
      </div>
    </div>
  </ng-template>
  
  <div class="row no-gutters justify-content-end mb-4">
    <div class="position-relative w-100"
        [class.spinner-blur-effect]="isLoading">
      <ng-container *ngFor="let gradingCourse of gradeDetails">
        <table class="table table-grade-record text-center shadow-sm mb-0">
          <thead>
            <tr>
              <th class="custom-table-header text-white"
                  colspan="6">
                <h5 class="mb-0 py-1">
                  ภาคการศึกษาที่ {{gradingCourse.semester}}/{{gradingCourse.academic_year}}
                </h5>
              </th>
            </tr>
            <tr>
              <th scope="col"
                  width="15%">รหัสวิชา</th>
              <th class="text-left"
                  scope="col">ชื่อวิชา</th>
              <th scope="col">หน่วยกิต</th>
              <th scope="col">หมวดวิชา</th>
              <th scope="col">ผลการเรียน</th>
              <th *ngIf="editView"
                  scope="col">การประเมิน</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let course of gradingCourse.courseRecords">
              <tr>
                <td>{{course.course_code}}</td>
                <td class="text-left">{{course.course_name}}</td>
                <td>{{course.credit_hour}}</td>
                <td>{{ $any(course)?.course_group_name || '-' }}</td>
                <td *ngIf="editView"
                    class="position-relative py-1">
                  <div class="d-flex align-items-center justify-content-center"
                      [class.spinner-blur-effect]="courseGradeEdit[course.id]?.isUpdating">
                    <div class="icon-grade-edit">
                      <span *ngIf="courseGradeEdit[course.id]?.editing"
                          class="bi-x-circle-fill h4 text-red pointer mb-0"
                          [class.invisible]="!courseGradeEdit[course.id]?.editing"
                          (click)="onGradeEditCancel(course)">
                      </span>
                    </div>
                    <div class="flex-grow-1">
                      <span *ngIf="!courseGradeEdit[course.id]?.editing">{{ course.grade }}</span>
                      <ng-select *ngIf="courseGradeEdit[course.id]?.editing"
                          class="select-grade-change text-center"
                          placeholder="เลือก"
                          [clearable]="false"
                          [searchable]="false"
                          [items]="gradeOptions"
                          [(ngModel)]="course.grade">
                      </ng-select>
                    </div>
                    <div class="icon-grade-edit">
                      <span *ngIf="courseGradeEdit[course.id]?.editing"
                          class="bi-check-circle-fill h4 text-green pointer mb-0"
                          (click)="onEditSubmit(course)">
                      </span>
                      <vru-carbon-icon *ngIf="!courseGradeEdit[course.id]?.editing && course.grade !== 'T'"
                          class="d-inline-block icon-grade-edit pointer mx-2"
                          description="แก้ไข"
                          iconName="edit"
                          presetFilter="fill-dark-gray"
                          size="24"
                          (click)="onGradeEditClick(course)">
                      </vru-carbon-icon>
                    </div>
                  </div>
                  <div class="container-spinner-float">
                    <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
                        name="course-grade-edit-spinner"
                        color="#3cd070"
                        size="default"
                        type="ball-grid-pulse"
                        [fullScreen]="false"
                        [showSpinner]="courseGradeEdit[course.id]?.isUpdating || false">
                    </ngx-spinner>
                  </div>
                </td>
                <td>
                  <ng-container *ngIf="editView && course.grade !== 'T'">
                    <ng-container *ngIf="$any(course)?.evaluated; else noEvaluated">
                      <vru-carbon-icon class="d-inline-block mx-2"
                          description="นักศึกษาทำแบบประเมินแล้ว"
                          iconName="checkmark-filled"
                          presetFilter="fill-lightgreen-vru"
                          [size]="24">
                      </vru-carbon-icon>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="editView && course.grade === 'T'">
                    <span class="bi bi-skip-end-circle-fill text-gray-gainsboro h4 mb-0"
                        popoverClass="popover-body-dark-gray popover-max-md"
                        triggers="mouseenter:mouseleave"
                        [ngbPopover]="descriptionTemp">
                    </span>
                    <ng-template #descriptionTemp>
                      <label class="text-white text-center w-100 mb-0">
                        ไม่มีการประเมิน เนื่องจากเป็นรายวิชาเทียบโอน
                      </label>
                    </ng-template>
                  </ng-container>
                  <ng-container *ngIf="!editView">
                    <ng-container *ngIf="$any(course)?.evaluated || course.grade === 'T'; else noEvaluated">
                      <span>
                        {{ course.grade || '-' }}
                      </span>
                    </ng-container>
                  </ng-container>
                  <ng-template #noEvaluated>
                    <vru-carbon-icon class="d-inline-block mx-2"
                        description="นักศึกษายังไม่ได้ทำแบบประเมิน"
                        iconName="in-progress"
                        presetFilter="fill-warning"
                        [size]="24">
                    </vru-carbon-icon>
                  </ng-template>
                </td>
              </tr>
            </ng-container>
          </tbody>
          <tbody>
            <tr>
              <td colspan="6"
                  class="py-4 px-0">
                <div class="d-flex no-gutters">
                  <div class="col-lg-6 custom-border-left">
                    <div class="d-flex custom-table-footer no-gutters py-2">
                      <div class="col-lg-4">
                        <label class="font-weight-bold text-secondary mr-t-5">
                          หน่วยกิตที่ใช้คำนวนเกรด
                        </label>
                      </div>
                      <div class="col-lg-4">
                        <label class="font-weight-bold text-secondary mr-t-5">
                          ผลรวมค่าคะแนน
                        </label>
                      </div>
                      <div class="col-lg-4">
                        <label class="font-weight-bold text-secondary mr-t-5">
                          เกรดเฉลี่ย
                        </label>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="col-lg-4">
                        <label>{{gradingCourse.gpa_calculated_credits}}</label>
                      </div>
                      <div class="col-lg-4">
                        <label class="mr-t-5">{{gradingCourse.grade_points}}</label>
                      </div>
                      <div class="col-lg-4">
                        <label class="mr-t-5">{{gradingCourse.gpa}}</label>
                      </div>
                    </div>
                    <div class="d-flex custom-table-footer no-gutters py-2">
                      <div class="col-lg-4">
                        <label class="font-weight-bold text-secondary mr-t-5">
                          หน่วยกิตลงทะเบียน
                        </label>
                      </div>
                      <div class="col-lg-4">
                        <label class="font-weight-bold text-secondary mr-t-5">
                          หน่วยกิตที่สอบได้
                        </label>
                      </div>
                      <div class="col-lg-4">
                        <label class="font-weight-bold text-secondary mr-t-5">
                          หน่วยกิตเทียบโอน/ยกเว้น
                        </label>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="col-lg-4">
                        <label>{{gradingCourse.registered_credits}}</label>
                      </div>
                      <div class="col-lg-4">
                        <label class="mr-t-5">{{gradingCourse.earned_credits}}</label>
                      </div>
                      <div class="col-lg-4">
                        <label class="mr-t-5">{{gradingCourse.transferred_credits}}</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="d-flex custom-table-footer no-gutters py-2">
                      <div class="col-lg-6">
                        <label class="font-weight-bold text-secondary mr-t-5">
                          หน่วยกิตที่สอบได้สะสม
                        </label>
                      </div>
                      <div class="col-lg-6">
                        <label class="font-weight-bold text-secondary mr-t-5">
                          เกรดเฉลี่ยสะสม
                        </label>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="col-lg-6">
                        <label class="mr-t-5">{{gradingCourse.cumulative_earned_credits}}</label>
                      </div>
                      <div class="col-lg-6">
                        <label class="mr-t-5">{{gradingCourse.cumulative_gpa}}</label>
                      </div>
                    </div>

                    <div class="d-flex custom-table-footer no-gutters py-2">
                      <div class="col-lg-6">
                        <label class="font-weight-bold text-secondary mr-t-5">
                          หน่วยกิตสะสมที่ใช้คำนวนเกรด
                        </label>
                      </div>
                      <div class="col-lg-6">
                        <label class="font-weight-bold text-secondary mr-t-5">
                          ผลรวมค่าคะแนนสะสม
                        </label>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="col-lg-6">
                        <label class="mr-t-5">{{gradingCourse.cumulative_gpa_calculated_credits}}</label>
                      </div>
                      <div class="col-lg-6">
                        <label class="mr-t-5">{{gradingCourse.cumulative_grade_points}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <div *ngIf="!gradeDetails.length && !isLoading"
          class="image-blank">
        <div class="text-center">
          <vru-carbon-icon class="d-inline-block"
              iconName="document-unknown"
              presetFilter="fill-gray"
              size="120">
          </vru-carbon-icon>
          <h5 class="text-gray">ไม่พบรายวิชาที่ลงทะเบียน</h5>
        </div>
      </div>
    </div>
    <div class="container-spinner-float">
      <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
          name="loading-student-record-spinner"
          color="#3cd070"
          size="medium"
          type="ball-clip-rotate-pulse"
          [fullScreen]="false"
          [showSpinner]="isLoading">
      </ngx-spinner>
    </div>
  </div>
</div>

<ng-template #gradeChangeHistoryTemp
    let-modalOpen>
  <div class="modal-header">
    <h4 class="text-center my-1">ประวัติการเปลี่ยนเกรด</h4>
  </div>
  <div class="modal-body p-3 p-md-5">
    <div class="table-responsive input-express-invalid shadow-sm border-radius-md bg-white-smoke h-100">
      <table *ngIf="gradeChangeRecords.length; else noGradeChangeRecordTemp"
          class="table table-grade-change table-green-munsell text-center border-radius-sm">
        <thead>
          <tr>
            <th>วันที่และเวลา</th>
            <th>ผู้บันทึก</th>
            <th>วิชา</th>
            <th>Sec</th>
            <th>เกรดเดิม</th>
            <th>เกรดใหม่</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let record of gradeChangeRecords">
            <td>{{ record.created_at }}</td>
            <td>{{ record.actor_name }}</td>
            <td>{{ record.course_name }}</td>
            <td>{{ record.section_no }}</td>
            <td>{{ record.old_grade }}</td>
            <td>{{ record.new_grade }}</td>
          </tr>
        </tbody>
      </table>
      <ng-template #noGradeChangeRecordTemp>
        <div class="d-flex align-items-center justify-content-center h-100">
          <h3 class="text-gray mb-0">ไม่พบประวัติการเปลี่ยนเกรด</h3>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <button class="btn btn-green-vru mr-2"
          type="button"
          (click)="modalOpen.dismiss()">
        ปิด
      </button>
    </div>
  </div>

</ng-template>