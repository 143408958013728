import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StyleConfig, TabConfig } from './navbar-arrow-style.model';

@Component({
  selector: 'vru-navbar-arrow-style',
  templateUrl: './navbar-arrow-style.component.html',
  styleUrls: ['./navbar-arrow-style.component.scss'],
})
export class NavbarArrowStyleComponent {
  @Input() backgroundColor = '#777';
  @Input() labelHeight = 40;
  @Input() labelWidth = 175;
  @Input() title!: string;
  @Input() titleSize = 20;
  @Input() titleColor = 'white';
  @Input() tabConfigs: TabConfig[] = [];
  @Input() selectedTab: any;
  @Input() styleConfig: Partial<StyleConfig> = {};

  @Output() selectedTabChange = new EventEmitter();

  onTabClick(tabIndex: number) {
    this.selectedTab = this.tabConfigs[tabIndex].value;
    this.selectedTabChange.emit(this.tabConfigs[tabIndex]);
  }
}
