import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';

@Component({
  selector: 'vru-file-dropbox',
  templateUrl: './file-dropbox.component.html',
  styleUrls: ['./file-dropbox.component.scss'],
})
export class FileDropboxComponent implements OnInit {
  @Input() accept = '*';
  @Input() content = 'Browse or Drop file here';
  /** You can put the content style here. */
  @Input() dropboxStyle = {
    backgroundColor: '#e5f4ee',
    border: '1px solid #2d7054',
    borderRadius: '0.5rem',
    color: '#2d7054',
  };
  @Input() files: File[] = [];
  /** Set carbon icon before content, This is optional */
  @Input() iconName?: string;
  @Input() iconSize = 20;
  @Input() multiFiles = false;
  @Output() filesChange = new EventEmitter<File[]>();
  @Output() filePushed = new EventEmitter<File[]>();
  @Output() fileRemoved = new EventEmitter<File[]>()

  ngOnInit(): void {
    if (this.multiFiles && this.multiFiles == null) {
      this.content = 'Browse or Drop files here';
    }
  }

  onFileDrop(ngxFileDrop: NgxFileDropEntry[]): void {
    for (const droppedFile of ngxFileDrop) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.files.push(file);
        });
      } else {
        // Future: For empty directories
      }
    }
    this.filePushed.emit(this.files);
    this.filesChange.emit(this.files);
  }

  onSelectFile(input: Event): void {
    const files = (input.target as HTMLInputElement).files;
    if (files == null) {
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (file == null) {
        return;
      }
      this.files.push(file);
    }
    (input.target as HTMLInputElement).value = '';
    this.filePushed.emit(this.files);
    this.filesChange.emit(this.files);
  }

  onFileRemove(fileIndex: number, event?: Event): void {
    if (event) {
      event.stopPropagation();
    }
    this.files.splice(fileIndex, 1);
    this.fileRemoved.emit(this.files);
    this.filesChange.emit(this.files);
  }
}
