import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'vru-pagination-table-footer',
  templateUrl: './pagination-table-footer.component.html',
  styleUrls: ['./pagination-table-footer.component.scss']
})
export class PaginationTableFooterComponent implements OnChanges {

  @Input() page!: number;
  @Input() pageSize = 100;
  @Input() totalCount!: number;

  @Output() pageChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>()
  @Output() selectPageChange = new EventEmitter();

  collectionSize = 0;
  totalPage!: number;
  totalPageSize!: number;
  totalPageList!: Array<{ value: number }>;
  pageSizeList!: Array<{ value: number }>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalCount?.currentValue || changes.totalCount?.currentValue === 0 || changes.pageSize?.currentValue) {
      this.collectionSize = this.totalCount;
      this.totalPage = Math.ceil((this.collectionSize / this.pageSize));
      this.totalPageSize = Math.ceil((this.collectionSize / 10));
      this.totalPageList = Array.from(Array(this.totalPage).keys()).map((i) => {
        return { value: i + 1 }
      });
      this.pageSizeList = Array.from(Array(this.totalPageSize).keys()).map((i) => {
        return { value: (i + 1) * 10 }
      });
    }
  }

  onPageNext(): void {
    if (this.page < this.totalPage) {
      this.page = +this.page + 1;
      this.pageChange.emit(this.page);
      this.selectPageChange.emit();
    }
  }

  onPagePrevious(): void {
    if (this.page > 1) {
      this.page = this.page - 1;
      this.pageChange.emit(this.page);
      this.selectPageChange.emit();
    }
  }

  changeDropdownPage(): void {
    this.pageChange.emit(this.page);
    this.selectPageChange.emit();
  }

  changeDropdownPageSize(): void {
    this.page = 1;
    this.pageChange.emit(this.page)
    this.pageSizeChange.emit(this.pageSize)
    this.selectPageChange.emit();
  }
}
