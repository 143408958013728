<vru-ng-select-typeahead ngbTooltip="ค้นหาด้วยชื่อผู้ใช้"
    [bindLabel]="bindLabel"
    [clearable]="false"
    [loading]="isLoading"
    [dataApiGenerator]="dataApiGenerator"
    [openDelay]="300"
    [readonly]="readonly"
    [(ngModel)]="selectedStudent"
    (ngModelChange)="onValueChange($event)">
  <ng-template vruNgSelectOptionDirective
      let-item>
    <label class="d-block pointer mb-0">
      {{ item.label }}
      <span *ngIf="showRole">({{ $any(roles)[item.context.role] }})</span>
    </label>
  </ng-template>
</vru-ng-select-typeahead>