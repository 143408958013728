import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: '[formSubmitValidation]',
})

export class UtilFormSubmitValidation {
  constructor(
    private control: ControlContainer
  ) { }

  @HostListener('click', ['$event'])
  handleClickEvent() {
    const form = this.control.formDirective as FormGroupDirective;
    this.markAsTouched(form.form);
  }

  private markAsTouched(formGroup: FormGroup) {
    formGroup.markAsTouched();
    formGroup.updateValueAndValidity();
    (Object as any).values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      control.updateValueAndValidity({ onlySelf: false, emitEvent: true });
      if (control.controls) {
        this.markAsTouched(control);
      }
    })
  }

}