import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  ApiService,
  RoleGroups,
  UserProfile,
  UserRole,
} from '@vru/master-data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly roleUrl = '/me/';

  constructor(private apiService: ApiService, private store: Store) {}

  checkBeingOfficer(role: string): boolean {
    const roles: readonly string[] = RoleGroups.officer;
    return roles.includes(role)
  }

  checkBeingTeacher(role: string): boolean {
    const roles: readonly string[] = RoleGroups.teacher;
    return roles.includes(role)
  }

  getLocalUser(): Observable<UserProfile> {
    return this.store.selectOnce((state) => state.auth);
  }

  getRole(): Observable<UserRole> {
    return this.apiService.get<UserRole>(this.roleUrl);
  }

  get isAdmin(): boolean {
    return this.localUser.role === 'admin';
  }

  get isBackOffice(): boolean {
    return this.localUser.role === 'backoffice';
  }

  get isGuardian(): boolean {
    return this.localUser.role === 'guardian';
  }

  get isOfficer(): boolean {
    const roles: readonly string[] = RoleGroups.officer;
    return roles.includes(this.localUser.role);
  }

  get isStudent(): boolean {
    return this.localUser.role === 'student';
  }

  get isTeacher(): boolean {
    return this.localUser.role === 'teacher';
  }

  get localUser(): UserProfile {
    return this.store.selectSnapshot((state) => state.auth);
  }
}
