import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vru-student-subject',
  templateUrl: './student-subject.component.html',
  styleUrls: ['./student-subject.component.scss'],
})
export class StudentSubjectComponent implements OnInit {
  ngOnInit(): void {
    console.log('subject works');
  }
}
