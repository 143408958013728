<div>
  <vru-ng-select-typeahead #subjectSelection
      bindLabel="code"
      placeholder="โปรดเลือกรายวิชา"
      ngbTooltip="ค้นหาด้วยชื่อหรือรหัสรายวิชา"
      [accessor]="subjectCodeAccessor"
      [clearable]="false"
      [formControl]="formControl"
      [dataApiGenerator]="subjectCodeApiGenerator"
      [openDelay]="300"
      [readonly]="readonly"
      (selectedValueChange)="onCourseChange($event)">
    <ng-template vruNgSelectOptionDirective
        let-item>
      <div class="d-flex justify-content-between">
        <span>{{ item?.code }}</span>
        <span>{{ item?.credit_hour }}</span>
        <span>ปี {{ item?.year }}</span>
      </div>
      <div class="line-height-fit">
        <small>{{ item?.name_thai }}</small>
      </div>
      <div class="line-height-fit">
        <small>{{ item?.name_eng }}</small>
      </div>
    </ng-template>
  </vru-ng-select-typeahead>
</div>