import { Injectable } from '@angular/core';
import { Modify } from '@vru/master-data';

@Injectable()
export abstract class GradeConfigService {
  backPagePath?: string;
  gradeSubmission!: GradeSubmissionConfig;

  constructor() {
    this.gradeSubmission = new GradeSubmissionConfig();
  }
}

export class GradeSubmissionConfig {
  filters?: FilterConfig;
  header?: string | (() => string);
  reportable?: boolean | (() => boolean);
  searchAhead?: boolean | (() => boolean);

  snapshotFilter(): Record<keyof FilterConfig, boolean> | undefined {
    if (!this.filters) {
      return;
    }
    const configs = { ...this.filters };
    const keys = Object.keys(configs) as (keyof FilterConfig)[];
    const snapshot = keys.reduce<{ [k in keyof FilterConfig]: boolean }>(
      (result, key) => {
        const value =
          typeof configs[key] === 'object'
            ? (configs[key] as DetailConfig)?.enable || false
            : (configs[key] as boolean | (() => boolean));
        result[key] = this.retrieveConfigValue(value);
        return result;
      },
      {}
    );
    return snapshot as Record<keyof FilterConfig, boolean>;
  }

  snapshot() {
    const filters = this.snapshotFilter();
    return {
      header: this.retrieveConfigValue<string>(this.header || ''),
      reportable: this.retrieveConfigValue(this.reportable || false),
      searchAhead: this.retrieveConfigValue(this.searchAhead || false),
      filters,
    };
  }

  private retrieveConfigValue<V = boolean>(value: V | (() => V)): V {
    if (value && value instanceof Function) {
      return value();
    } else {
      return value;
    }
  }
}

export interface DetailConfig {
  enable: boolean | (() => boolean);
}

export interface FilterConfig {
  course?: FilterEnableConfig;
  faculty?: FilterEnableConfig;
  field_of_study?:
    | FilterEnableConfig
    | Modify<DetailConfig, { faculty: () => number }>;
  teacher?: FilterEnableConfig;
  student_section?: FilterEnableConfig;
  student?: FilterEnableConfig;
}

export type FilterEnableConfig = boolean | (() => boolean);
