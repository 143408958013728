import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageDrawingComponent } from './image-drawing.component';

@NgModule({
  declarations: [ImageDrawingComponent],
  imports: [CommonModule, ImageCropperModule, CanvasWhiteboardModule],
  exports: [ImageDrawingComponent],
})
export class ImageDrawingModule {}

export * from './image-drawing.component';
