export enum EmployeeTypes {
  'government_officer',
  'permanent_employee',
  'university_officer',
  'government_employee',
  'contract_employee',
  'temporary_employee',
  'temporary_foreign_employee',
  'academic_staff',
  'support_staff',
}

export enum EmployeeTypesTh {
  'ข้าราชการ',
  'ลูกจ้างประจำ',
  'พนักงานมหาวิทยาลัย',
  'พนักงานราชการ',
  'เจ้าหน้าที่ประจำตามสัญญาจ้าง',
  'ลูกจ้างชั่วคราว',
  'ลูกจ้างชั่วคราวชาวต่างชาติ',
  'บุคลากรสายวิชาการ',
  'บุคลากรสายสนับสนุน',
}

export enum WorkStatuses {
  'working',
  'suspended',
  'resign',
  'lay_off',
}

export enum WorkStatusesTh {
  'ทำงาน',
  'พักงาน',
  'ลาออก',
  'เลิกจ้าง',
}
