import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { PaymentSlip } from './payment-slip.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentSlipService {
  readonly paymentSlipUrl = '/receipts/payment-slip/';

  constructor(private http: ApiService) {}

  getPaymentSlip(uuid: string): Observable<PaymentSlip> {
    return this.http.get<PaymentSlip>(this.paymentSlipUrl, {
      uuid: uuid,
    });
  }
}
