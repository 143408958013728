import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DrfList } from '../common.model';
import { TranscriptIssuedDocContent } from '../education/student/student.model';
import {
  ExportRequestResult,
  IssuedDocument,
  PaymentReceiptBulkRequest,
  TranscriptRequest,
} from './export.model';

@Injectable({
  providedIn: 'root',
})
export class ExportApiService {
  readonly exportTaskPath = 'export-tasks';
  readonly issuedDocPath = 'issued-documents';
  readonly transcriptPath = 'transcripts';
  readonly paymentReceiptsPath = 'receipts/bulk-print/';

  constructor(private api: ApiService) {}

  getDocumentExportTask(
    docType?: string | null
  ): Observable<DrfList<ExportRequestResult>> {
    return this.api.get(this.exportTaskPath, { document_type: docType });
  }

  getIssuedDocument(
    uuid: string
  ): Observable<IssuedDocument<TranscriptIssuedDocContent>> {
    return this.api.get([this.issuedDocPath, uuid]);
  }

  requestTranscript(
    payload: TranscriptRequest
  ): Observable<{ task_id: string }> {
    return this.api.post(this.transcriptPath, payload);
  }

  requestPaymentReceipt(
    payload: PaymentReceiptBulkRequest
  ): Observable<{ task_id: string }> {
    return this.api.post(this.paymentReceiptsPath, payload);
  }
}
