import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'vru-year-select',
  templateUrl: './year-select.component.html',
  styleUrls: ['./year-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: YearSelectComponent,
      multi: true,
    },
  ],
})
export class YearSelectComponent implements ControlValueAccessor, OnInit {
  @Input() clearable = true;
  @Input() endYear?: number | null;
  @Input() placeholder?: string;
  @Input() startYear?: number | null;

  yearDropdown: number[] = [];
  selectedYear?: number | null;
  #onChange!: (year: number | null) => void;
  #onTouch!: () => void;

  ngOnInit(): void {
    this.diffYear;
  }

  getYearDropdown(): void {
    const arrayYear = this.yearDropdown;
    let addedNumberOfYear = this.yearDropdown.length;
    const nRemainYears = this.diffYear - this.yearDropdown.length;
    const step = 10;
    if (nRemainYears <= 0) {
      return;
    }
    const startYear = this.#startYear;
    const increaseAmount = nRemainYears > step ? step : nRemainYears;
    for (let index = 0; index < increaseAmount; index++) {
      arrayYear.push(startYear - addedNumberOfYear);
      addedNumberOfYear++;
    }
    this.yearDropdown = [...arrayYear];
  }

  onChange(year: number | null) {
    this.#onChange(year);
  }

  onScroll(end: { start: number; end: number }) {
    if (end.end === this.yearDropdown.length) {
      this.getYearDropdown();
    }
  }

  registerOnChange(fn: any): void {
    this.#onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.#onTouch = fn;
  }

  writeValue(year: number | null | undefined): void {
    this.selectedYear = year;
  }

  get diffYear(): number {
    const diff = this.#startYear - this.#endYear + 1;
    if (diff < 0) {
      throw new Error('The start year is less than end year');
    }
    return diff;
  }

  get #endYear(): number {
    return this.endYear || new Date().getFullYear() + 543 - 53;
  }

  get #startYear(): number {
    return this.startYear || new Date().getFullYear() + 543;
  }
}
