import { Component, Input } from '@angular/core';
import { Size } from 'ngx-spinner';

@Component({
  selector: 'vru-spinner-global',
  templateUrl: './spinner-global.component.html',
  styleUrls: ['./spinner-global.component.scss'],
})
export class SpinnerGlobalComponent {
  @Input() fullScreen = false;
  @Input() name = 'spinner';
  @Input() size: Size = 'medium';
}
