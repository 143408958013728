<div [formGroup]="form"
    [class.spinner-blur-effect]="isLoading">
  <div class="card d-flex w-100 h-100 p-5 mb-4 shadow-sm input-express-invalid">
    <div class="position-relative w-100 mb-4">
      <div class="back-button">
        <div class="d-flex align-items-center"
            (click)="onBack()">
          <vru-carbon-icon iconName="back"
              class="pointer"
              size="22">
          </vru-carbon-icon>
          <h5 class="pointer mt-1">
            ย้อนกลับ
          </h5>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center">
        <vru-upload-profile-photo radius="50%"
            [canEdit]="isGuardian ? false : true"
            [src]="studentInfo?.photo"
            (photoChange)="onPhotoChange($event)">
        </vru-upload-profile-photo>
      </div>
    </div>
    <div class="card shadow-sm p-4 mb-4">
      <h5 class="mb-3">ข้อมูลนักศึกษา</h5>
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label for="input-code">รหัสนักศึกษา</label>
          <input class="form-control events-none"
              formControlName="code"
              id="input-code"
              placeholder="สร้างอัตโนมัติ"
              type="text">
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label>คำนำหน้า
            <small class="text-red">*</small>
          </label>
          <ng-select class="custom"
              bindLabel="value_thai"
              bindValue="id"
              formControlName="name_title"
              placeholder="เลือก"
              vruValidate
              [clearable]="false"
              [items]="nameTitlesModel$ | async"
              [submitted]="isSubmitted"
              [showMsg]="false">
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label for="input-first-name-thai">ชื่อ (ภาษาไทย)
            <small class="text-red">*</small>
          </label>
          <input formControlName="first_name_thai"
              type="text"
              class="form-control"
              id="input-first-name-thai"
              vruValidate
              [submitted]="isSubmitted"
              [showMsg]="false">
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label for="input-last-name-thai">นามสกุล (ภาษาไทย)
            <small class="text-red">*</small>
          </label>
          <input formControlName="last_name_thai"
              class="form-control"
              id="input-last-name-thai"
              type="text"
              vruValidate
              [submitted]="isSubmitted"
              [showMsg]="false">
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label for="input-first-name-eng">ชื่อ (ภาษาอังกฤษ)
            <small class="text-red">*</small>
          </label>
          <input formControlName="first_name_eng"
              type="text"
              class="form-control"
              id="input-first-name-eng"
              vruValidate
              [submitted]="isSubmitted"
              [showMsg]="false">
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label for="input-last-name-eng">นามสกุล (ภาษาอังกฤษ)
            <small class="text-red">*</small>
          </label>
          <input formControlName="last_name_eng"
              type="text"
              class="form-control"
              id="input-last-name-eng"
              vruValidate
              [submitted]="isSubmitted"
              [showMsg]="false">
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label>หมู่เรียนนักศึกษา
            <small class="text-red">*</small>
          </label>
          <ng-select bindLabel="code"
              bindValue="id"
              formControlName="student_section"
              placeholder="เลือก"
              vruValidate
              [showMsg]="false"
              [items]="studentSectionDropdown$ | async"
              [submitted]="isSubmitted"
              (change)="onChangeStudentSection($event)">
            <ng-template ng-option-tmp
                let-item="item">
              <div class="d-flex text-wrap">{{item.code}}</div>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label>ประเภทนักศึกษา
            <small class="text-red">*</small>
          </label>
          <ng-select [items]="studentTypesModel$ | async"
              bindLabel="name"
              bindValue="id"
              formControlName="student_type"
              [showMsg]="false"
              vruValidate
              [readonly]="true"
              [submitted]="isSubmitted">
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label>ระดับการศึกษา
            <small class="text-red">*</small>
          </label>
          <ng-select [items]="degreesModel$ | async"
              bindLabel="name_thai"
              bindValue="id"
              formControlName="degree"
              [showMsg]="false"
              vruValidate
              [readonly]="true"
              [submitted]="isSubmitted">
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label>คณะ
            <small class="text-red">*</small>
          </label>
          <ng-select [items]="facultyModel$ | async"
              bindLabel="name_thai"
              bindValue="id"
              formControlName="faculty"
              [showMsg]="false"
              vruValidate
              [readonly]="true"
              [submitted]="isSubmitted">
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label>หลักสูตร
            <small class="text-red">*</small>
          </label>
          <ng-select [items]="programDropdown$ | async"
              bindLabel="label"
              bindValue="value"
              formControlName="program"
              [showMsg]="false"
              vruValidate
              [readonly]="true"
              [submitted]="isSubmitted">
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label>วุฒิการศึกษา
            <small class="text-red">*</small>
          </label>
          <ng-select [items]="qualificationModel$ | async"
              bindLabel="full_name_thai"
              bindValue="id"
              formControlName="qualification"
              [showMsg]="false"
              vruValidate
              [readonly]="true"
              [submitted]="isSubmitted">
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label for="input-start-academic-year">ปีการศึกษาที่เข้า
            <small class="text-red">*</small>
          </label>
          <input formControlName="start_academic_year"
              type="text"
              class="form-control"
              id="input-start-academic-year"
              [showMsg]="false"
              vruValidate
              [readonly]="true"
              [submitted]="isSubmitted">
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label>อาจารย์ที่ปรึกษา</label>
          <ng-select bindLabel="label"
              bindValue="value"
              formControlName="advisors"
              [showMsg]="false"
              vruValidate
              [items]="teacherDropdown$ | async"
              [multiple]="true"
              [readonly]="true"
              [submitted]="isSubmitted">
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label>ชั้นปี
            <small class="text-red">*</small>
          </label>
          <input class="form-control"
              formControlName="college_year"
              type="number"
              min="1"
              oninput="validity.valid||(value='')"
              [showMsg]="false"
              vruValidate
              [submitted]="isSubmitted">
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 pt-2">
          <label>วิทยาเขต
            <small class="text-red">*</small>
          </label>
          <ng-select placeholder="เลือก"
              bindLabel="label"
              bindValue="value"
              formControlName="campus"
              [showMsg]="false"
              vruValidate
              [items]="campusDropdown$ | async"
              [submitted]="isSubmitted">
            <ng-template ng-option-tmp
                let-item="item">
              <div class="d-flex text-wrap">{{item.label}}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="card shadow-sm p-4 mb-4">
      <h5>ข้อมูลทั่วไป</h5>
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label>รหัสบัตรประจำตัวประชาชน
            <small class="text-red">*</small>
          </label>
          <input type="text"
              formControlName="id_card"
              maxlength="13"
              class="form-control"
              vruValidate
              [submitted]="isSubmitted"
              [showMsg]="false">
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label>วันออกบัตรประชาชน</label>
          <input class="form-control input-container"
              formControlName="id_card_issued"
              name="dp"
              ngbDatepicker
              onkeydown="false"
              #dp="ngbDatepicker"
              autocomplete="off"
              vruValidate
              [submitted]="isSubmitted"
              [showMsg]="false"
              (click)="dp.toggle()">
          <span class="span-calendar">
            <vru-carbon-icon class="i-calendar"
                iconName="calendar"
                (click)="dp.toggle()">
            </vru-carbon-icon>
          </span>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label>วันหมดอายุบัตรประชาชน</label>
          <input class="form-control input-container"
              formControlName="id_card_expired"
              invalidMsg="ข้อมูลนี้จำเป็น"
              name="dp1"
              ngbDatepicker
              onkeydown="false"
              #dp1="ngbDatepicker"
              autocomplete="off"
              vruValidate
              [submitted]="isSubmitted"
              [showMsg]="false"
              (click)="dp1.toggle()">
          <span class="span-calendar">
            <vru-carbon-icon class="i-calendar"
                iconName="calendar"
                (click)="dp1.toggle()">
            </vru-carbon-icon>
          </span>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label>เชื้อชาติ</label>
          <ng-select placeholder="เลือก"
              [items]="racesModel$ | async"
              bindLabel="value_thai"
              bindValue="id"
              formControlName="race"
              vruValidate
              [clearable]="false"
              [submitted]="isSubmitted"
              [showMsg]="false">
            <ng-template ng-option-tmp
                let-item="item">
              <div class="d-flex text-wrap">{{item.value_thai}}</div>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label>สัญชาติ</label>
          <ng-select placeholder="เลือก"
              [items]="nationalityModel$ | async"
              bindLabel="value_thai"
              bindValue="id"
              formControlName="nationality"
              vruValidate
              [clearable]="false"
              [showMsg]="false"
              [submitted]="isSubmitted">
            <ng-template ng-option-tmp
                let-item="item">
              <div class="d-flex text-wrap">{{item.value_thai}}</div>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label>ศาสนา</label>
          <ng-select placeholder="เลือก"
              [items]="religionsModel$ | async"
              bindLabel="value_thai"
              bindValue="id"
              formControlName="religion"
              vruValidate
              [clearable]="false"
              [submitted]="isSubmitted"
              [showMsg]="false">
            <ng-template ng-option-tmp
                let-item="item">
              <div class="d-flex text-wrap">{{item.value_thai}}</div>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label>เพศ
            <small class="text-red">*</small>
          </label>
          <ng-select placeholder="เลือก"
              [items]="genderDropdown"
              bindLabel="label"
              bindValue="value"
              formControlName="gender"
              vruValidate
              [showMsg]="false"
              [clearable]="false"
              [submitted]="isSubmitted"
              [showMsg]="false">
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label>วัน/เดือน/ปีเกิด</label>
          <input class="form-control input-container"
              formControlName="birth_date"
              name="dp2"
              ngbDatepicker
              onkeydown="false"
              #dp2="ngbDatepicker"
              autocomplete="off"
              vruValidate
              [submitted]="isSubmitted"
              [showMsg]="false"
              (click)="dp2.toggle()">
          <span class="span-calendar">
            <vru-carbon-icon class="i-calendar"
                iconName="calendar"
                (click)="dp2.toggle()">
            </vru-carbon-icon>
          </span>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label>หมู่เลือด</label>
          <ng-select formControlName="blood_type"
              type="text"
              vruValidate
              [clearable]="false"
              [items]="bloodTypeDropdown"
              [submitted]="isSubmitted">
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label>เบอร์โทรศัพท์ที่ติดต่อได้
            <small class="text-red">*</small>
          </label>
          <input formControlName="phone_number"
              maxlength="10"
              type="text"
              class="form-control"
              vruValidate
              [showMsg]="false"
              [submitted]="isSubmitted">
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label>Email
            <small class="text-red">*</small>
          </label>
          <input formControlName="email"
              type="email"
              class="form-control"
              vruValidate
              [showMsg]="false"
              [submitted]="isSubmitted">
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label>โรคประจำตัว</label>
          <input formControlName="congenital_disease"
              type="text"
              class="form-control"
              vruValidate
              [showMsg]="false"
              [submitted]="isSubmitted">
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label>เคยได้รับการรักษาจาก</label>
          <input formControlName="medical_history"
              type="text"
              class="form-control"
              vruValidate
              [showMsg]="false"
              [submitted]="isSubmitted">
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label>ความพิการ</label>
          <input formControlName="disability"
              type="text"
              class="form-control"
              vruValidate
              [showMsg]="false"
              [submitted]="isSubmitted">
        </div>
        <div class="col-12 mt-2">
          <label for="textarea-special-interest">ความสนใจพิเศษ</label>
          <textarea class="form-control resize-none"
              formControlName="special_interest"
              id="textarea-special-interest"
              type="text"
              vruValidate
              [showMsg]="false"
              [submitted]="isSubmitted">
          </textarea>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12 mt-4 mb-3">
              <h5 class="mb-0"
                  for="textarea-address-current">ที่อยู่ที่สามารถติดต่อได้</h5>
            </div>
            <ng-container formGroupName="address_current">
              <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
                <label for="textarea-address-current">บ้านเลขที่</label>
                <input class="form-control"
                    formControlName="house_no"
                    id="input-house-no"
                    title="บ้านเลขที่"
                    type="text"
                    vruValidate
                    [showMsg]="false"
                    [submitted]="isSubmitted">
              </div>
              <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
                <label for="textarea-address-current">หมู่บ้าน</label>
                <input class="form-control"
                    formControlName="village"
                    id="input-village"
                    title="หมู่บ้าน"
                    type="text"
                    vruValidate
                    [showMsg]="false"
                    [submitted]="isSubmitted">
              </div>
              <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
                <label for="input-village-no">หมู่ที่</label>
                <input class="form-control"
                    formControlName="moo"
                    id="input-village-no"
                    title="หมู่บ้าน"
                    type="text"
                    vruValidate
                    [showMsg]="false"
                    [submitted]="isSubmitted">
              </div>
              <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
                <label for="input-soi">ซอย</label>
                <input class="form-control"
                    formControlName="soi"
                    id="input-soi"
                    title="ซอย"
                    type="text"
                    vruValidate
                    [showMsg]="false"
                    [submitted]="isSubmitted">
              </div>
              <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
                <label for="input-address-road">ถนน</label>
                <input class="form-control"
                    formControlName="road"
                    id="input-address-road"
                    title="ถนน"
                    type="text"
                    vruValidate
                    [showMsg]="false"
                    [submitted]="isSubmitted">
              </div>
              <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
                <label for="select-sub-district">แขวง/ตำบล</label>
                <vru-sub-district-typeahead #subDistrict
                    formControlName="sub_district"
                    readonly
                    [showAsPostalCode]="false"
                    (subDistrictChange)="onSubDistrictChange($event)">
                </vru-sub-district-typeahead>
              </div>
              <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
                <label for="textarea-address-current">เขต/อำเภอ</label>
                <input class="form-control"
                    disabled
                    title="เขต/อำเภอ"
                    [value]="subDistrict.selected?.district?.value_thai">
              </div>
              <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
                <label for="input-province">จังหวัด</label>
                <input class="form-control"
                    id="input-province"
                    disabled
                    title="เขต/อำเภอ"
                    [value]="subDistrict.selected?.province?.value_thai">
              </div>
              <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
                <label for="textarea-address-current">รหัสไปรษณีย์</label>
                <vru-sub-district-typeahead readonly
                    name="postal_code"
                    [showAsPostalCode]="true"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="postalCode"
                    (ngModelChange)="onPostalCodeChange($event)">
                </vru-sub-district-typeahead>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-12 mt-2">
          <label for="textarea-address-id-card">ที่อยู่ตามบัตรประชาชน</label>
          <textarea class="form-control resize-none"
              formControlName="address_id_card"
              id="textarea-address-id-card"
              type="text"
              vruValidate
              [showMsg]="false"
              [submitted]="isSubmitted">
          </textarea>
        </div>
      </div>
    </div>
    <div class="card shadow-sm p-4 mb-4">
      <h5 class="mb-4">ประวัติครอบครัว</h5>
      <div class="card d-flex w-100 h-100 p-4 p-2 shadow-sm">
        <div class="row no-gutters align-items-center justify-content-between">
          <h6 class="mb-0">ผู้ปกครอง
          </h6>
          <div>
            <ng-select bindLabel="label"
                bindValue="value"
                formControlName="guardian_type"
                id="select-guardian-type"
                [clearable]="false"
                (change)="onSelectGuardianStatus($event)"
                [items]="guardianDropdown"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
            </ng-select>
          </div>
        </div>
        <ng-container formGroupName="guardian">
          <div *ngIf="!['father', 'mother'].includes(form.get('guardian_type')?.value)"
              class="row mt-4">
            <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
              <label for="input-grardian-name">ชื่อ
                <ng-container *ngIf="checkRequiredField('guardian.first_name')">
                  <small class="text-red">*</small>
                </ng-container>
              </label>
              <input class="form-control"
                  formControlName="first_name"
                  id="input-grardian-first-name"
                  type="text"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted">
            </div>
            <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
              <label for="input-grardian-name">นามสกุล
                <ng-container *ngIf="checkRequiredField('guardian.last_name')">
                  <small class="text-red">*</small>
                </ng-container>
              </label>
              <input class="form-control"
                  formControlName="last_name"
                  id="input-grardian-last-name"
                  type="text"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted">
            </div>
            <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
              <label for="input-grardian-phone-number">เบอร์โทรศัพท์
                <ng-container *ngIf="checkRequiredField('guardian.phone_number')">
                  <small class="text-red">*</small>
                </ng-container>
              </label>
              <input class="form-control"
                  maxlength="10"
                  formControlName="phone_number"
                  id="input-grardian-phone-number"
                  type="text"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted">
            </div>
            <div class="col-12 mt-2">
              <label for="input-grardian-address">ที่อยู่
                <ng-container *ngIf="checkRequiredField('guardian.address')">
                  <small class="text-red">*</small>
                </ng-container>
              </label>
              <input class="form-control"
                  formControlName="address"
                  id="input-grardian-address"
                  type="text"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted">
            </div>
            <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
              <label for="input-grardian-occupation">อาชีพ
                <ng-container *ngIf="checkRequiredField('guardian.occupation')">
                  <small class="text-red">*</small>
                </ng-container>
              </label>
              <input class="form-control"
                  formControlName="occupation"
                  id="input-grardian-occupation"
                  type="text"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted">
            </div>
            <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
              <label for="select-grardian-income">รายได้ต่อเดือน
                <ng-container *ngIf="checkRequiredField('guardian.income')">
                  <small class="text-red">*</small>
                </ng-container>
              </label>
              <ng-select [items]="incomeDropdown$ | async"
                  placeholder="โปรดเลือก"
                  formControlName="income"
                  bindValue="value"
                  bindLabel="label"
                  id="select-grardian-income"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted">
              </ng-select>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
              <label>Email
                <ng-container *ngIf="checkRequiredField('guardian.email')">
                  <small class="text-red">*</small>
                </ng-container>
              </label>
              <input formControlName="email"
                  type="email"
                  class="form-control"
                  id="input-grardian-email"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted">
            </div>
            <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
              <label for="input-grardian-remark">หมายเหตุ
                <ng-container *ngIf="checkRequiredField('guardian.remark')">
                  <small class="text-red">*</small>
                </ng-container>
              </label>
              <input class="form-control"
                  formControlName="remark"
                  id="input-grardian-remark"
                  type="text"
                  placeholder="เช่นความสัมพันธ์"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted">
            </div>
          </div>
        </ng-container>
      </div>
      <div class="card d-flex w-100 h-100 p-4 p-2 mt-4 shadow-sm"
          formGroupName="father">
        <h6 class="mb-0">บิดา</h6>
        <div class="row mt-4">
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-father-name">ชื่อ
              <ng-container *ngIf="checkRequiredField('father.first_name')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <input class="form-control"
                formControlName="first_name"
                id="input-father-first-name"
                type="text"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-father-name">นามสกุล
              <ng-container *ngIf="checkRequiredField('father.first_name')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <input class="form-control"
                formControlName="last_name"
                id="input-father-last-name"
                type="text"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-father-phone-number">เบอร์โทรศัพท์
              <ng-container *ngIf="checkRequiredField('father.phone_number')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <input class="form-control"
                maxlength="10"
                formControlName="phone_number"
                id="input-father-phone-number"
                type="text"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
          </div>
          <div class="col-12 mt-2">
            <label for="input-father-address">ที่อยู่
              <ng-container *ngIf="checkRequiredField('father.address')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <input class="form-control"
                formControlName="address"
                id="input-father-address"
                type="text"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-fatehr-occupation">อาชีพ
              <ng-container *ngIf="checkRequiredField('father.occupation')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <input class="form-control"
                formControlName="occupation"
                id="input-fatehr-occupation"
                type="text"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="select-father-income">รายได้ต่อเดือน
              <ng-container *ngIf="checkRequiredField('father.income')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <ng-select [items]="incomeDropdown$ | async"
                placeholder="โปรดเลือก"
                bindValue="value"
                bindLabel="label"
                formControlName="income"
                id="select-father-income"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
            </ng-select>
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label>Email
              <ng-container *ngIf="checkRequiredField('father.email')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <input formControlName="email"
                type="email"
                class="form-control"
                id="input-father-email"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-father-remark">หมายเหตุ
              <ng-container *ngIf="checkRequiredField('father.remark')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <input class="form-control"
                formControlName="remark"
                id="input-father-remark"
                type="text"
                placeholder="เช่นความสัมพันธ์"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
          </div>
        </div>
      </div>
      <div class="card d-flex w-100 h-100 p-4 p-2 mt-4 shadow-sm"
          formGroupName="mother">
        <h6 class="mb-0">มารดา</h6>
        <div class="row mt-4">
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-mother-name">ชื่อ
              <ng-container *ngIf="checkRequiredField('mother.first_name')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <input class="form-control"
                formControlName="first_name"
                id="input-mother-first-name"
                type="text"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-mother-name">นามสกุล
              <ng-container *ngIf="checkRequiredField('mother.last_name')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <input class="form-control"
                formControlName="last_name"
                id="input-mother-last-name"
                type="text"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-mother-phone-number">เบอร์โทรศัพท์
              <ng-container *ngIf="checkRequiredField('mother.phone_number')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <input class="form-control"
                maxlength="10"
                formControlName="phone_number"
                id="input-mother-phone-number"
                type="text"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
          </div>
          <div class="col-12 mt-2">
            <label for="input-mother-address">ที่อยู่
              <ng-container *ngIf="checkRequiredField('mother.address')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <input class="form-control"
                formControlName="address"
                id="input-mother-address"
                type="text"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-mother-occupation">อาชีพ
              <ng-container *ngIf="checkRequiredField('mother.last_name')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <input class="form-control"
                formControlName="occupation"
                id="input-mother-occupation"
                type="text"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="select-mother-income">รายได้ต่อเดือน
              <ng-container *ngIf="checkRequiredField('mother.income')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <ng-select [items]="incomeDropdown$ | async"
                placeholder="โปรดเลือก"
                bindValue="value"
                bindLabel="label"
                formControlName="income"
                id="select-mother-income"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
              <ng-template ng-option-tmp
                  let-item="item">
                <div class="d-flex text-wrap">{{item.label}}</div>
              </ng-template>
            </ng-select>
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label>Email
              <ng-container *ngIf="checkRequiredField('mother.email')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <input formControlName="email"
                type="email"
                class="form-control"
                id="input-mother-email"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-mother-remark">หมายเหตุ
              <ng-container *ngIf="checkRequiredField('mother.remark')">
                <small class="text-red">*</small>
              </ng-container>
            </label>
            <input class="form-control"
                formControlName="remark"
                id="input-mother-remark"
                type="text"
                placeholder="เช่นความสัมพันธ์"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
          </div>
        </div>
      </div>
      <div class="card d-flex w-100 h-100 p-4 p-2 mt-4 shadow-sm">
        <div class="row no-gutters align-items-center justify-content-between"
            [class.mb-3]="siblingFormArray.controls?.length">
          <h6 class="mb-0">พี่-น้อง</h6>
          <button *ngIf="!isGuardian" class="btn btn-green-vru"
              type="button"
              (click)="addItem()">
            + เพิ่ม
          </button>
        </div>
        <div class="row"
            formArrayName="name_of_brothers_sisters">
          <div *ngFor="let item of siblingFormArray.controls; let i = index"
              class="col-12 col-lg-6 col-xxl-3 mb-3 pt-2 pb-4">
            <div class="d-flex align-items-center">
              <label class="mb-0">{{ i + 1 }}.</label>
              <input class="form-control mx-2"
                  type="text"
                  placeholder="ชื่อ-นามสกุล"
                  vruValidate
                  [formControlName]="i"
                  [submitted]="isSubmitted"
                  [showMsg]="false">
              <vru-carbon-icon class="px-1"
                  iconName="trash-can"
                  presetFilter="fill-red"
                  size="20"
                  (click)="deleteItem(i)">
              </vru-carbon-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="card d-flex w-100 h-100 p-4 p-2 mt-4 shadow-sm"
          formGroupName="supporter">
        <h6 class="mb-0">ผู้สนับสนุนการศึกษา</h6>
        <div class="row mt-4">
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-supporter-name">ชื่อ</label>
            <input class="form-control"
                formControlName="first_name"
                id="input-supporter-first-name"
                type="text"
                vruValidate
                [submitted]="isSubmitted"
                [showMsg]="false"
                (change)="onSupporterChange()">
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-supporter-name">นามสกุล</label>
            <input class="form-control"
                formControlName="last_name"
                id="input-supporter-last-name"
                type="text"
                vruValidate
                [submitted]="isSubmitted"
                [showMsg]="false"
                (change)="onSupporterChange()">
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-supporter-phone-number">เบอร์โทรศัพท์</label>
            <input class="form-control"
                maxlength="10"
                formControlName="phone_number"
                id="input-supporter-phone-number"
                type="text"
                vruValidate
                [submitted]="isSubmitted"
                [showMsg]="false"
                (change)="onSupporterChange()">
          </div>
          <div class="col-12 mt-2">
            <label for="input-supporter-address">ที่อยู่</label>
            <input class="form-control"
                formControlName="address"
                id="input-supporter-address"
                type="text"
                vruValidate
                [submitted]="isSubmitted"
                [showMsg]="false"
                (change)="onSupporterChange()">
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-supporter-occupation">อาชีพ</label>
            <input class="form-control"
                formControlName="occupation"
                id="input-supporter-occupation"
                type="text"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted"
                (change)="onSupporterChange()">
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-supporter-income">รายได้ต่อเดือน</label>
            <ng-select [items]="incomeDropdown$ | async"
                placeholder="โปรดเลือก"
                bindValue="value"
                bindLabel="label"
                formControlName="income"
                id="input-supporter-income"
                vruValidate
                [submitted]="isSubmitted"
                [showMsg]="false"
                (change)="onSupporterChange()">
            </ng-select>
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label>Email</label>
            <input formControlName="email"
                type="email"
                class="form-control"
                id="input-supporter-email"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted">
          </div>
          <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
            <label for="input-supporter-remark">หมายเหตุ</label>
            <input class="form-control"
                formControlName="remark"
                id="input-supporter-remark"
                type="text"
                placeholder="เช่นความสัมพันธ์"
                vruValidate
                [showMsg]="false"
                [submitted]="isSubmitted"
                [showMsg]="false">
          </div>
        </div>
      </div>
    </div>
    <div class="card shadow-sm p-4 mb-4">
      <h5 class="mb-4">ประวัติการศึกษา</h5>
      <div class="row mb-3">
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label class="text-nowrap" for="input-previous-academy">ชื่อโรงเรียน/มหาวิทยาลัยเดิม
            <small class="text-red">*</small>
          </label>
          <ng-select #previousSchoolSelect
              [items]="schoolDropdown"
              bindLabel="label"
              bindValue="value"
              [searchable]="true"
              id="input-previous-academy"
              formControlName="previous_academy"
              placeholder="โปรดเลือก"
              [showMsg]="false"
              vruValidate
              (change)="onSelectProvince($event)"
              [submitted]="isSubmitted"
              [clearable]="true">
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label for="select-province">จังหวัด</label>
          <ng-select [items]="provincesModel$ | async"
              [readonly]="true"
              placeholder="เลือก"
              bindValue="id"
              bindLabel="value_thai"
              formControlName="province"
              id="select-province">
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label for="select-previous-education-level">วุฒิการศึกษา/ปริญญาที่จบ
            <small class="text-red">*</small>
          </label>
          <ng-select [items]="educationLevelDropdown$ | async"
              bindLabel="label"
              bindValue="value"
              formControlName="previous_education_level"
              id="select-previous-education-level"
              [showMsg]="false"
              placeholder="เลือก"
              vruValidate
              [clearable]="false"
              [submitted]="isSubmitted">
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label for="input-previouse-education-level-name">ชื่อวุฒิการศึกษาที่จบ</label>
          <input formControlName="previous_education_level_name"
              class="form-control"
              id="input-previouse-education-level-name"
              [showMsg]="false"
              type="text"
              vruValidate
              [submitted]="isSubmitted">
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label for="input-previous-field-of-study">สาขา/วิชาที่เรียน</label>
          <input formControlName="previous_field_of_study"
              class="form-control"
              id="input-previous-field-of-study"
              [showMsg]="false"
              type="text"
              vruValidate
              [submitted]="isSubmitted">
        </div>
        <div class="col-12 col-sm-6 col-lg-3 col-xxl-2 mt-2">
          <label for="input-gpax">ผลการเรียนเฉลี่ย (GPAX)</label>
          <input formControlName="previous_academy_gpax"
              type="text"
              class="form-control"
              id="input-gpax"
              [showMsg]="false"
              vruValidate
              [submitted]="isSubmitted">
        </div>
      </div>
      <div class="row mb-3">
        <h6 class="mb-3 px-3">เอกสารแนบ</h6>
        <div class="col-lg-12">
          <label class="ml-2">1.สำเนาทะเบียนบ้าน</label>
        </div>
        <div class="col-lg-12">
          <label class="ml-2">2.หลักฐานการเปลี่ยนชื่อ-สกุล (ถ้ามี)</label>
        </div>
        <div class="col-lg-12">
          <label class="ml-2">3.สำเนาบัตรประชาชน</label>
        </div>
        <div class="col-lg-12">
          <label class="ml-2">4.ใบแสดงผลการเรียน (Transript), (หน้าและหลัง)</label>
        </div>
        <div class="col-lg-12">
          <label class="ml-2">5.สำเนาปริญญาบัตร</label>
        </div>
        <div class="col-lg-12">
          <label class="ml-2">6.หนังสือรับรองคุณวุฒิ</label>
        </div>
        <div class="col-lg-12">
          <label class="ml-2">7.หัวข้อวิทยานิพนธ์ / ดุษฎีนิพนธ์ (เฉพาะปริญญาเอก)</label>
        </div>
      </div>
      <ng-container *ngIf="!isGuardian; else noneDropFiles">
        <div class="row">
          <div class="col">
            <div class="center mt-3 pointer">
              <ngx-file-drop class="file-drop"
                  (click)="multipleFileUpload.click()">
                <ng-template ngx-file-drop-content-tmp
                    let-openFileSelector="openFileSelector">
                  Browse or Drop files here
                </ng-template>
              </ngx-file-drop>
              <input type="file"
                  multiple
                  (change)="selectFile($event)"
                  hidden
                  accept="*"
                  #multipleFileUpload>
            </div>
            <div class="mt-4"
                style="word-break: break-all;"
                formArrayName="student_file_uploads"
                *ngIf="fileLength.controls.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th id="blank1">รายละเอียดไฟล์</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody class="upload-name-style">
                  <tr *ngFor="let item of getFileFormArray().controls; let i = index">
                    <td>ไฟล์ที่ {{i + 1}} : {{item?.value?.file_name || item?.value?.name}}
                    </td>
                    <td class="text-center">
                      <a class="bi bi-trash pointer"
                          (click)="deleteFile(i, item?.value?.id)"
                          style="color: red;">
                        ลบไฟล์ที่ {{i+ 1}}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noneDropFiles>
        <div class="row">
          <div class="col">
            <div class="center mt-3 p-5 bg-light rounded rounded-pill text-center none-drop-file">
              <h5 class="text-secondary text-center mb-0">ไฟล์เอกสารแนบ</h5>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="row justify-content-end">
      <button class="btn btn-outline-green-vru"
          type="button"
          routerLink="..">
        ยกเลิก
      </button>
      <button class="btn btn-green-vru mx-3"
          type="button"
          [ladda]="isSaving"
          (click)="onSubmit()">
        บันทึก
      </button>
    </div>
  </div>
</div>
<div class="container-spinner-float">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
      name="grade-assessment"
      color="#3cd070"
      size="medium"
      type="ball-clip-rotate-pulse"
      [fullScreen]="false"
      [showSpinner]="isLoading">
  </ngx-spinner>
</div>