import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DrfList, Nullable } from '../common.model';
import { Classroom, ClassroomListParams } from './classroom.model';

@Injectable({
  providedIn: 'root',
})
export class ClassroomService {
  readonly classroomPath = '/classrooms/';

  constructor(private http: ApiService) {}

  delete(id: number): Observable<unknown> {
    return this.http.delete(this.classroomPath + `${id}/`);
  }

  getDetail(id: number, params?: any): Observable<Classroom> {
    return this.http.get<Classroom>(this.classroomPath + `${id}/`, params);
  }

  getList(params?: any): Observable<DrfList<Classroom>> {
    return this.http.get<DrfList<Classroom>>(this.classroomPath, params);
  }

  patch(id: number, payload: Partial<Classroom>): Observable<Classroom> {
    return this.http.patch(this.classroomPath + `${id}/`, payload);
  }

  post(payload: Omit<Classroom, 'id'>): Observable<Classroom> {
    return this.http.post(this.classroomPath, payload);
  }

  getTimeManagementList(params?: any) {
    return this.http.get('/timetable-managements/', params);
  }
}
