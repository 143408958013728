import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DrfList } from '../common.model';
import {
  SubDistrict,
  ThaiLocationParam,
  UnitLocation,
} from './thai-location.model';

@Injectable({
  providedIn: 'root',
})
export class ThaiLocationService {
  readonly provincePath = 'provinces';
  
  constructor(private http: ApiService) {}

  getDistricts(params?: ThaiLocationParam): Observable<DrfList<UnitLocation>> {
    return this.http.get('/districts/', params);
  }

  getProvinceDetail(id: number): Observable<UnitLocation> {
    return this.http.get([this.provincePath, id.toString()]);
  }

  getProvinces(params?: ThaiLocationParam): Observable<DrfList<UnitLocation>> {
    return this.http.get(this.provincePath, params);
  }

  getSubDistricts(
    params?: ThaiLocationParam
  ): Observable<DrfList<SubDistrict>> {
    return this.http.get('/sub-districts/', params);
  }
}
