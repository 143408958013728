import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  CreditBankStudentContext,
  Guardian,
  Roles,
  Student,
  UserProfile,
} from '@vru/master-data';
import { map } from 'rxjs/operators';


@Component({
  selector: 'vru-student-accessor',
  template: '',
})
export abstract class StudentAccessorComponent implements OnInit {
  studentId!: number;
  backPagePath?: string;
  private _user!: UserProfile;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected store: Store
  ) {
    this.getSelfUser();
  }

  ngOnInit(): void {
    this.fetchStudentId();
  }

  goToBackPage(): void {
    if (this.backPagePath) {
      this.router.navigate([this.backPagePath]);
    }
    this.router.navigate(['']);
  }

  fetchStudentId() {
    try {
      const parentParams$ = this.route.parent?.params;
      if (!parentParams$) {
        throw new Error('StudentProfileComponent locate on incorrect route');
      }
      parentParams$
        ?.pipe(
          map((params) => {
            let id: number = +params['id'];
            if (!id) {
              const user = this.store.selectSnapshot(
                (state) => state.auth
              ) as UserProfile<{ student: Student }>;
              id = user.context['student']?.id;  
            }
            if (!id) {
              const user = this.store.selectSnapshot(
                (state) => state.auth
              ) as UserProfile<{ 'guardian': Guardian }>;
              id = user.context['guardian']?.student_id;  
            }
            if (!id) {
              const user = this.store.selectSnapshot(
                (state) => state.auth
              ) as UserProfile<{ 'credit_bank_student': CreditBankStudentContext }>;
              id = user.context['credit_bank_student']?.student_id;  
            }
            if (!id) {
              throw new Error(
                'Cannot get student information because of the student id route is invalid'
              );
            }
            return id;
          })
        )
        .subscribe({
          next: (id) => {
            this.studentId = id;
            this.resolveAccessStudentId(id);
          },
          error: (err) => {
            console.error(err);
            this.rejectAccessStudentId(err);
          },
        });
    } catch (error) {
      console.error(error);
      this.rejectAccessStudentId(error);
    }
  }

  getSelfUser(): void {
    this._user = this.store.selectSnapshot((state) => state.auth);
  }

  get role(): Roles {
    return this._user.role;
  }

  get user(): UserProfile {
    return this._user;
  }

  abstract rejectAccessStudentId(err: any): void;

  abstract resolveAccessStudentId(studentId: number): void;
}
