import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class GraduateEmploymentService {
  readonly graduateEmploymentStatusPath = '/graduate-employments/by-student/';

  constructor(private http: ApiService) {}

  getGraduateEmploymentStatus():Observable<any>{
    return this.http.get(this.graduateEmploymentStatusPath)
    
  }
}