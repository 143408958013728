import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CourseStructure, Nullable, Course, Modify, SubjectGroup } from '@vru/master-data';
import { v4 as uuidV4 } from 'uuid';
import { SubjectService } from './subject.service';

@Injectable({
  providedIn: 'root',
})
export class CourseStructureService {
  constructor(
    private fb: FormBuilder,
    private subjectService: SubjectService
  ) {}

  buildCourseStructureForm(
    course?: CourseStructureForm,
    uuidRequired = false
  ): FormGroup {
    const courseForm = this.fb.group({
      applied_subject_area: course?.applied_subject_area || false,
      credit: course?.credit,
      indent: course?.indent || 0,
      parent_uuid: course?.parent_uuid || null,
      number: course?.number,
      sequence: course?.sequence || 0,
      course_group_name: course?.course_group_name,
      course_group: [course?.course_group, Validators.required],
    } as Record<keyof CourseStructure, any>);
    if (course?.courses != null && Array.isArray(course.courses)) {
      courseForm.addControl(
        'courses',
        this.subjectService.buildSubjectFormArray(course.courses)
      );
    }
    if (uuidRequired) {
      courseForm.addControl('uuid', new FormControl(course?.uuid || uuidV4()));
    }
    if (course?.id) {
      courseForm.addControl('id', new FormControl(course.id));
    }
    return courseForm;
  }

  buildCourseStructureFormArr(
    courseStructures?: CourseStructure[],
    uuidRequired = false
  ): FormArray {
    let seqCoursesStruc: CourseStructure[] = [];
    if (courseStructures) {
      seqCoursesStruc = courseStructures.sort((a, b) => a.sequence - b.sequence);
    }
    const formGroupArr = seqCoursesStruc.map((course) =>
      this.buildCourseStructureForm(course, uuidRequired)
    );
    return this.fb.array(formGroupArr);
  }
}

export type CourseStructureForm = Modify<
  Nullable<CourseStructure>,
  {
    courses: (Course | number | null)[] | null;
    course_group?: SubjectGroup | null;
    uuid?: string;
  }
>;

export interface UuidCourseStructure extends CourseStructure {
  uuid: string;
}
