import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  Address,
  Dropdown,
  DropdownApiService,
  FamilyIncomeRanges,
  FamilyIncomeRangeTexts,
  Father,
  Gender,
  Guardian,
  IMasterDataDegrees,
  IMasterDataFaculty,
  IMasterDataNationality,
  IMasterDataQualifications,
  IMasterDataRaces,
  IMasterDataReligions,
  IMasterDataStudentTypes,
  Modify,
  Mother,
  Program,
  Student,
  StudentApiService,
  StudentExpandField as StudentExpandField,
  Supporter,
  Teacher,
  ThaiGender,
} from '@vru/master-data';
import * as _ from 'lodash';
import { AsyncSubject, Observable } from 'rxjs';
import { MapConfigs } from '../../information-display/information-display.model';
import { SpinnerService } from '../../spinner/spinner.service';
import { StudentAccessorComponent } from '../student-accessor/student-accessor.component';
import { StudentInformationConfig } from '../student-information.config';

@Component({
  selector: 'vru-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentProfileComponent
  extends StudentAccessorComponent
  implements OnInit
{
  @ViewChild('imgaeData') image!: ElementRef;

  familyInfoMap: MapConfigs[] = [
    { label: 'ชื่อ', path: 'first_name', maxSize: 'half' },
    { label: 'นามสกุล', path: 'last_name', maxSize: 'half' },
    { label: 'เบอร์โทรศัพท์', path: 'phone_number', maxSize: 'half' },
    { label: 'อาชีพ', path: 'occupation', maxSize: 'half' },
    { label: 'รายได้', path: 'incomeDisplay', maxSize: 'half' },
    { label: 'ที่อยู่', path: 'address', maxSize: 'half' },
    { label: 'Email', path: 'email', maxSize: 'half' },
    { label: 'หมายเหตุ', path: 'remark', maxSize: 'half' },
  ];
  incomeRanges = FamilyIncomeRanges;
  incomeRangeTexts = FamilyIncomeRangeTexts;
  studentId!: number;
  editButtonForm = false;
  studentForm!: FormGroup;
  studentUpload!: File;
  studentImage: any;
  itemsStudentGroups: any;
  brother: any;
  degree$!: Observable<string>;
  studentType$!: Observable<string>;
  studentGroup$!: Observable<string>;
  faculty$!: Observable<string>;
  advisor$!: Observable<string>;
  qualification$!: Observable<string>;
  syllabus$!: Observable<string>;
  race$!: Observable<string>;
  nationality$!: Observable<string>;
  religion$!: Observable<string>;

  degreesDropdown$!: Observable<IMasterDataDegrees[]>;
  educationProfileMap: MapConfigs[] = [
    {
      label: 'ชื่อโรงเรียน / มหาวิทยาลัยเดิม',
      path: 'previous_academy.value_thai',
      maxSize: 'full',
    },
    {
      label: 'จังหวัด',
      path: 'previous_academy.province.value_thai',
      maxSize: 'trimester',
    },
    {
      label: 'วุฒิการศึกษา / ปริญญาที่จบ',
      path: 'previous_education_level_display',
      maxSize: 'trimester',
    },
    {
      label: 'ชื่อวุฒิการศึกษาที่จบ',
      path: 'previous_education_level_name',
      maxSize: 'trimester',
    },
    {
      label: 'สาขา / วิชาที่เรียน',
      path: 'previous_field_of_study',
      maxSize: 'trimester',
    },
    {
      label: 'ผลการเรียนเฉลี่ย (GPAX)',
      path: 'previous_academy_gpax',
      maxSize: 'trimester',
    },
  ];
  studentTypeDropdown$!: Observable<IMasterDataStudentTypes[]>;
  facultyDropdown$!: Observable<IMasterDataFaculty[]>;
  incomeDropdown$ = new AsyncSubject<Dropdown<string>>();
  qualificationDropdown$!: Observable<IMasterDataQualifications[]>;
  advisorDropdown$!: Observable<Teacher[]>;
  raceDropdown$!: Observable<IMasterDataRaces[]>;
  nationalityDropdown$!: Observable<IMasterDataNationality[]>;
  religionDropdown$!: Observable<IMasterDataReligions[]>;
  syllabusDropdown$!: Observable<Program[]>; // TODO: Remove because it's not used
  studentInfo?: StudentInfo;
  studentInfoExpandField: StudentExpandField[] = [
    'advisors',
    'campus',
    'degree',
    'faculty',
    'father',
    'mother',
    'name_title',
    'nationality',
    'guardian',
    'program',
    'program.field_of_study',
    'old_faculty',
    'old_field_of_study',
    'old_program',
    'old_student_type',
    'race',
    'religion',
    'qualification',
    'student_section',
    'student_type',
    'supporter',
    'previous_academy',
  ];
  studentInformationConfigs: MapConfigs[] = [
    { label: 'รหัสนักศึกษา', path: 'code' },
    { label: 'คำนำหน้าชื่อ', path: 'name_title.value_thai' },
    { label: 'ชื่อ (ภาษาไทย)', path: 'first_name_thai' },
    { label: 'นามสกุล (ภาษาไทย)', path: 'last_name_thai' },
    { label: 'ชื่อ (ภาษาอังกฤษ)', path: 'first_name_eng' },
    { label: 'นามสกุล (ภาษาอังกฤษ)', path: 'last_name_eng' },
    { label: 'หมู่เรียนนักศึกษา', path: 'student_section.code' },
    { label: 'ประเภทนักศึกษา', path: 'student_type.name' },
    { label: 'ระดับการศึกษา', path: 'degree.name_thai' },
    { label: 'คณะ', path: 'faculty.name_thai' },
    { label: 'สาขา', path: 'program.field_of_study.name_thai' },
    { label: 'หลักสูตร', path: 'program.name' },
    { label: 'วุฒิการศึกษา', path: 'qualification.full_name_thai' },
    { label: 'ปีการศึกษาที่เข้า', path: 'start_academic_year' },
    { label: 'อาจารย์ที่ปรึกษา', path: 'advisors', arrayPath: 'name' },
    { label: 'ชั้นปี', path: 'college_year' },
    { label: 'วิทยาเขต', path: 'campus.name' },
    { label: 'รหัสนักศึกษาเดิม', path: 'old_code' },
    { label: 'คณะเดิม', path: 'old_faculty.name_thai' },
    { label: 'สาขาวิชาเดิม', path: 'old_field_of_study.name_thai' },
    { label: 'หลักสูตรเดิม', path: 'old_program.name' },
    { label: 'ประเภทนักศึกษาเดิม', path: 'old_student_type.name' },
    { label: 'สถานะนักศึกษา', path: 'status_detail' },
  ];
  studentProfileConfigs: MapConfigs[] = [
    { label: 'รหัสบัตรประจำตัวประชาชน', path: 'id_card' },
    {
      label: 'วันออกบัตรประชาชน',
      path: 'id_card_issued',
      pipe: { type: 'date' },
    },
    {
      label: 'วันหมดอายุบัตรประชาชน',
      path: 'id_card_expired',
      pipe: { type: 'date' },
    },
    { label: 'เชื้อชาติ', path: 'race.value_thai' },
    { label: 'สัญชาติ', path: 'nationality.value_thai' },
    { label: 'ศาสนา', path: 'religion.value_thai' },
    { label: 'เพศ', path: 'gender' },
    {
      label: 'วัน / เดือน / ปีเกิด',
      path: 'birth_date',
      pipe: { type: 'date' },
    },
    { label: 'หมู่เลือด', path: 'blood_type' },
    { label: 'เบอร์โทรศัพท์ที่ติดต่อได้', path: 'phone_number' },
    { label: 'Email', path: 'email' },
    { label: 'โรคประจำตัว', path: 'congenital_disease' },
    { label: 'เคยได้รับการรักษาจาก', path: 'medical_history' },
    { label: 'ความพิการ', path: 'disability' },
    { label: 'ความสนใจพิเศษ', path: 'special_interest' },
    {
      label: 'ที่อยู่ตามบัตรประชาชน ',
      path: 'address_current',
      maxSize: 'full',
    },
    { label: 'ที่อยู่ที่ติดต่อได้', path: 'address_id_card', maxSize: 'full' },
  ];
  degreeDropdown: Dropdown[] = [];
  studentTypeDropdown: Dropdown[] = [];
  facultyDropdown: Dropdown[] = [];
  qualificationDropdown: Dropdown[] = [];
  schoolDropdown: Dropdown[] = [];

  constructor(
    private cdRef: ChangeDetectorRef,
    protected config: StudentInformationConfig,
    protected route: ActivatedRoute,
    protected router: Router,
    private studentApi: StudentApiService,
    private spinner: SpinnerService,
    protected store: Store,
    private dropdownApiService: DropdownApiService
  ) {
    super(route, router, store);
    this.studentInformationConfigs.forEach((config) => {
      config.maxSize = 'trimester';
    });
    this.studentProfileConfigs.forEach((config) => {
      config.maxSize = 'trimester';
    });
    this.studentProfileConfigs[15].maxSize = 'full';
    this.studentProfileConfigs[16].maxSize = 'full';
  }

  ngOnInit(): void {
    this.getDropdown();
    super.ngOnInit();
  }

  getDropdown() {
    this.dropdownApiService.getPublicDropdown('school').subscribe({
      next: (response) => (this.schoolDropdown = response),
    });
  }

  getAddressText(address: Address): string {
    const isCapitalCity =
      !!address.sub_district?.province?.value_eng.match(/bangkok/i);
    const prefixMap = [
      { path: 'house_no', prefix: '' },
      { path: 'village', prefix: 'หมู่บ้าน' },
      { path: 'moo', prefix: 'หมู่ที่ ' },
      { path: 'road', prefix: 'ถนน' },
      {
        path: 'sub_district.value_thai',
        prefix: isCapitalCity ? 'แขวง' : 'ตำบล',
      },
      {
        path: 'sub_district.district.value_thai',
        prefix: isCapitalCity ? 'เขต' : 'อำเภอ',
      },
      { path: 'sub_district.province.value_thai', prefix: 'จังหวัด' },
      { path: 'sub_district.postal_code', prefix: '' },
    ];
    return prefixMap.reduce((text, config) => {
      const value = _.get(address, config.path);
      if (!value) return text;
      return text + ' ' + config.prefix + value;
    }, '');
  }

  getAttribute(path: string) {
    return _.get(this.studentInfo, path);
  }

  getStudentInfo(id: number): void {
    const expand = this.studentInfoExpandField;
    this.spinner.isShow(true);
    this.studentApi.getDetail<typeof expand[number]>(id, { expand }).subscribe(
      (res) => {
        const setFamilyIncomeFn = (
          person?: Father | Mother | Guardian | Supporter | null
        ) => {
          const familyPersonInfoDefault = {
            address: '',
            income: '',
            first_name: '',
            last_name: '',
            occupation: '',
            phone_number: '',
            incomeDisplay: '',
            remark: '',
          } as FamilyPersonInfo;
          return person
            ? {
                ...person,
                incomeDisplay: person?.income
                  ? this.incomeRangeTexts[this.incomeRanges[person.income]]
                  : '-',
              }
            : familyPersonInfoDefault;
        };
        this.studentInfo = {
          ...res,
          address_current:
            res.address_current == null
              ? ''
              : this.getAddressText(res.address_current),
          advisorsStr: res.advisors.map((advisor) => advisor.name).join(', '),
          father: setFamilyIncomeFn(res?.father),
          mother: setFamilyIncomeFn(res?.mother),
          guardian: setFamilyIncomeFn(res?.guardian),
          supporter: res?.supporter ? setFamilyIncomeFn(res.supporter) : null,
          gender: ThaiGender[Gender[res.gender]] as keyof typeof Gender,
        };
        this.cdRef.detectChanges();
        this.spinner.isShow(false);
      },
      () => this.spinner.isShow(false)
    );
  }

  getLabelFromDropdown(dropdown: Dropdown[], value: any): string | null {
    return dropdown.find((data) => data.value === value)?.label || null;
  }

  formName(): FormArray {
    return this.studentForm.get('formName') as FormArray;
  }

  getValue(name: string) {
    return this.studentForm.get(name)?.value;
  }

  getFile(fileInput: any) {
    this.studentUpload = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.studentUpload);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reader.onload = (_event) => {
      this.studentImage = reader.result;
      this.studentForm.controls.photo.setValue(reader.result);
    };
  }

  dropdownConvertFn(array: any, label: string, id: string, key: string) {
    return array.map((edges: any) => ({
      label: edges.node[label],
      value: edges.node[id],
      key: edges.node[key],
    }));
  }

  updateForm() {
    this.editButtonForm = !this.editButtonForm;
  }

  cancelUpdateForm() {
    this.editButtonForm = false;
    this.getStudentInfo(this.studentId);
  }

  rejectAccessStudentId(): void {
    this.goToBackPage();
  }

  resolveAccessStudentId(studentId: number): void {
    this.getStudentInfo(studentId);
  }
}

export interface FamilyPersonInfo {
  address: string;
  income: string;
  first_name: string;
  last_name: string;
  occupation: string;
  phone_number: string;
  incomeDisplay?: string;
  remark: string;
}

export type StudentInfo = Modify<
  Student<StudentProfileComponent['studentInfoExpandField'][number]>,
  {
    address_current: string;
    advisorsStr: string;
    father: FamilyPersonInfo;
    mother: FamilyPersonInfo;
    guardian: FamilyPersonInfo;
    supporter?: FamilyPersonInfo | null;
  }
>;
