import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { Roles } from '@vru/master-data';
import { RoleBaseGuard } from './role-base.guard';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard
  extends RoleBaseGuard
  implements CanActivate, CanActivateChild, CanLoad
{
  permissive: Roles[] = ['admin'];
}
