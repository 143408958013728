import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
import { SubscriptionService } from './subscription.service';

@Injectable()
export class ComponentSubscriptionService implements OnDestroy {
  _subscriptionToken: string;
  private _subscription: { [k: string]: Subscription };

  constructor(private subsService: SubscriptionService) {
    this._subscriptionToken = uuidV4();
    this._subscription = this.subsService.registerGroup(this._subscriptionToken);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  /** 
   * Add subscription with no serious about register key.
   * The register key is created according to number of keys in subscription store.
   */
  addSubs(subscription: Subscription): string {
    const totalKey = Object.keys(this._subscription).length;
    this._subscription[totalKey] = subscription;
    return totalKey.toString();
  }

  destroy(): void {
    this.subsService.deregisterGroup(this._subscriptionToken);
  }

  unsubscribe(subscribeName: string): void {
    const subs = this.store[subscribeName];
    if (subs?.closed === false) {
      subs.unsubscribe();
    }
  }

  /** A settle of addSubs method */
  set add(subscription: Subscription) {
    this.addSubs(subscription);
  }

  get store(): { [k: string]: Subscription } {
    return this._subscription;
  }

  get subscriptionToken(): string {
    return this._subscriptionToken;
  }
}
