import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  HostBinding,
  Input,
  IterableDiffer,
  IterableDiffers,
  Output,
} from '@angular/core';
import { Primitive } from '@vru/master-data';

@Component({
  selector: 'vru-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements DoCheck {
  @Input() activeTabClass = 'tab-active';
  @Input() direction = 'row';
  @Input() widthStyle: 'fix' | 'flex-equal' | 'flex-auto' = 'fix';
  @Input() tabs: Tab[] = [];
  @Input() tabClass = '';
  @Input() selected: Primitive = '';
  @Output() selectedChange = new EventEmitter<any>();
  @HostBinding('style.width') width = 'auto';

  iterableDiffer!: IterableDiffer<unknown>;

  constructor(
    private cdRef: ChangeDetectorRef,
    private iterableDiffers: IterableDiffers
  ) {
    this.iterableDiffer = iterableDiffers.find([]).create(undefined);
  }


  ngDoCheck() {
    const changes = this.iterableDiffer.diff(this.tabs);
    this.width = this.widthStyle !== 'fix' ? '100%' : 'auto';
    if (changes) {
      this.cdRef.markForCheck();
    }
  }

  onTabClick(value: Primitive): void {
    this.selected = value;
    this.selectedChange.emit(value);
  }
}

export interface Tab {
  label: string;
  value: Primitive;
  classIcon?: string;
}
