import {
  TeacherChoice,
} from './teacher.model';

export const teacherChoice: TeacherChoice = {
  teacherStatus: [
    { value: 'working', label: 'ทำงานปกติ' },
    { value: 'resigned', label: 'ลาออก' },
    { value: 'special_teacher', label: 'ลาออกสอนเป็นอาจารย์พิเศษ' },
  ],
  teacherType: [
    { value: 'full_time', label: 'ประจำ' },
    { value: 'special', label: 'พิเศษ' },
  ],
  maritalStatus: [
    { value: 'single', label: 'โสด' },
    { value: 'married', label: 'แต่งงาน' },
    { value: 'divorced', label: 'หย่าร้าง' },
  ],
};
