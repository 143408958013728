import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Dropdown,
  DropdownApiService,
  ErrorHeader,
  exportProfileListReportParam,
  Nullable,
  ProfileParams,
  Roles,
  UserApiService,
  UserProfileListItem,
} from '@vru/master-data';
import { AlertService, ComponentSubscriptionService } from '@vru/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UtilityService } from '@vru/utils';
import { UserListReportModalComponent } from '../components/user-list-report-modal/user-list-report-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'vru-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  providers: [ComponentSubscriptionService],
})
export class UserListComponent implements OnInit, OnDestroy {
  userPermissionList: UserProfileListItem[] = [];
  userPermissionList$ = new Subject<Nullable<ProfileParams>>();
  facultyDropdown: Dropdown[] = [];
  fieldOfStudyDropdown: Dropdown[] = [];
  employeeTypeDropdown: Dropdown[] = [];
  workStatusDropdown: Dropdown[] = [];
  roleDropdown: Dropdown[] = [];
  searchUsername?: string | null;
  searchName?: string | null;
  selectedFaculty?: number | null;
  selectedFieldOfStudy?: number | null;
  searchPersonnelType?: string | null;
  selectedAgency?: Roles | null;
  isLoading = true;
  isFieldOfStudyLoading = false;
  page = 1;
  pageSize = 40;
  totalCount = 0;

  constructor(
    private comSubs: ComponentSubscriptionService,
    private dropdownApiService: DropdownApiService,
    private ngxSpinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private router: Router,
    private userApi: UserApiService,
    private utilService: UtilityService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.onLoading();
    this.subscribeDropdown();
    this.subscribeUserPermission();
    this.takeUserPermission();
  }

  ngOnDestroy(): void {
    this.comSubs.destroy();
  }

  onLoading(): void {
    this.ngxSpinnerService.show('user-list');
  }

  subscribeUserPermission(): void {
    this.comSubs.store.onGetUserPermission = this.userPermissionList$
      .pipe(
        distinctUntilChanged(),
        debounceTime(300),
        switchMap((params) => this.userApi.getList(params))
      )
      .subscribe({
        next: (response) => {
          this.userPermissionList = response.results;
          this.totalCount = response.count;
          this.isLoading = false;
        },
        error: (err: ErrorHeader) => {
          if (err.error) {
            this.alertService.error(String(err.error));
          }
          this.isLoading = false;
        },
        complete: () => (this.isLoading = false),
      });
  }

  takeUserPermission(): void {
    const params: Nullable<ProfileParams> = {
      can_create_on_user_management: true,
      employment_type: this.searchPersonnelType,
      faculty: this.selectedFaculty,
      field_of_study: this.selectedFieldOfStudy,
      name: this.searchName,
      page_size: this.pageSize,
      page: this.page,
      role: this.selectedAgency,
      username: this.searchUsername,
    };
    this.isLoading = true;
    this.userPermissionList$.next(params);
  }

  subscribeDropdown(): void {
    const type = ['faculty', 'employee_type', 'role', 'work_status'];
    this.dropdownApiService
      .getDropdown(type, { can_create_on_user_management: 'true' })
      .subscribe({
        next: (response) => {
          this.roleDropdown = response.role;
          this.facultyDropdown = response.faculty;
          this.employeeTypeDropdown = response.employee_type;
          this.workStatusDropdown = response.work_status;
        },
      });
  }

  onSelectFaculty(faculty: Dropdown) {
    this.selectedFieldOfStudy = null;
    this.fieldOfStudyDropdown = [];
    if (!faculty?.value) {
      this.selectedFaculty = null;
      return;
    }
    const param = {
      type: 'field_of_study',
      faculty: faculty.value,
    };
    this.isFieldOfStudyLoading = true;
    this.dropdownApiService.getDropdown(param).subscribe({
      next: (res) => {
        this.fieldOfStudyDropdown = res.field_of_study;
        this.isFieldOfStudyLoading = false;
      },
      error: () => {
        this.alertService.errorWithContactAdmin();
        this.isFieldOfStudyLoading = false;
      },
    });
  }

  onDeleteUserPermission(id: number, name: string): void {
    this.alertService
      .confirm(
        'ยืนยันการลบข้อมูล',
        `ท่านต้องการยืนยันการลบ Username ${name} ใช่หรือไม่ ?`,
        'warning',
        'ยืนยัน',
        'ยกเลิก'
      )
      .then((results) => {
        if (results.value) {
          this.userApi.delete(id).subscribe({
            next: () => {
              this.alertService.success(`ลบ Username ${name} สำเร็จ`, 4000);
              this.takeUserPermission();
            },
            error: (err: ErrorHeader) => {
              console.error(err);
            },
          });
        }
      });
  }

  clearFilter(): void {
    this.selectedAgency = null;
    this.selectedFaculty = null;
    this.selectedFieldOfStudy = null;
    this.searchName = null;
    this.searchPersonnelType = null;
    this.searchUsername = null;
    this.takeUserPermission();
  }

  printProfileAccountReport(params: exportProfileListReportParam) {
    this.isLoading = true;
    this.userApi.exportProfileListReport(params).subscribe({
      next: (res) => {
        this.utilService.downloadFile(res, 'Profile account report');
        this.isLoading = false;
      },
      error: (err: ErrorHeader) => {
        if (err.error) {
          console.error(err.error);
          this.alertService.error(err.error.detail, 10000);
          this.isLoading = false;
        }
      },
    });
  }

  printUserAccountReport(params: exportProfileListReportParam) {
    this.isLoading = true;
    this.userApi.exportUserListReport(params).subscribe({
      next: (res) => {
        this.utilService.downloadFile(res, 'User account report');
        this.isLoading = false;
      },
      error: (err: ErrorHeader) => {
        if (err.error) {
          console.error(err.error);
          this.alertService.error(err.error.detail, 10000);
          this.isLoading = false;
        }
      },
    });
  }

  openUserListReportModal(type: 'user-list' | 'profile-list'): void {
    const userListReportModalComponent = this.modalService.open(
      UserListReportModalComponent,
      { size: 'md' }
    );
    userListReportModalComponent.componentInstance.facultyDropdown =
      this.facultyDropdown;
    userListReportModalComponent.componentInstance.fieldOfStudyDropdown =
      this.fieldOfStudyDropdown;
    userListReportModalComponent.componentInstance.type = type;
    userListReportModalComponent.componentInstance.selectedFaculty =
      this.selectedFaculty;
    userListReportModalComponent.componentInstance.selectedFieldOfStudy =
      this.selectedFieldOfStudy;
    userListReportModalComponent.componentInstance.selectEmployeeType =
      this.searchPersonnelType;
    userListReportModalComponent.componentInstance.workStatusDropdown =
      this.workStatusDropdown;
    userListReportModalComponent.componentInstance.employeeTypeDropdown =
      this.employeeTypeDropdown;
    userListReportModalComponent.result.then((selected) => {
      const params = {
        faculty: selected?.faculty,
        field_of_study: selected?.field_of_study,
        employee_type: selected?.employee_type,
        work_status: selected?.work_status,
      };
      if (type === 'profile-list') {
        if (selected.role === 'both') {
          this.printProfileAccountReport({
            role: 'teacher',
            ...params,
          });
          this.printProfileAccountReport({
            role: 'officer',
            ...params,
          });
        } else {
          this.printProfileAccountReport({
            role: selected.role,

            ...params,
          });
        }
      } else if (type === 'user-list') {
        this.printUserAccountReport({
          ...params,
        });
      }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    }).catch(() => {});
  }
}
