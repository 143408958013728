import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { BoundaryTime, DrfList } from '../common.model';

@Injectable({
  providedIn: 'root',
})
export class StudyService {
  readonly studyTimeSlotPath = '/timetable-time-slots/';

  constructor(private api: ApiService) {}

  getStudyTimeSlot(): Observable<DrfList<{ id: number } & BoundaryTime>> {
    return this.api.get(this.studyTimeSlotPath);
  }
}