<div class="component-wrapper"
    [ngClass]="[widthStyle]">
  <div class="d-flex"
      [class.flex-column]="direction === 'col'">
    <div *ngFor="let tab of tabs"
        class="tab shadow-sm {{ tabClass }}"
        [ngClass]="[tab.value === selected ? activeTabClass : '' ]"
        [routerLinkActive]="activeTabClass"
        (click)="onTabClick(tab.value)">
      <div class="d-flex align-items-center h-100">
        <ng-container *ngIf="tab.classIcon">
          <span class="icon {{ tab.classIcon }} mr-2"></span>
        </ng-container>
        <span>{{ tab.label }}</span>
      </div>
    </div>
  </div>
</div>