<div class="view-container">
  <div class="row">
    <div class="col-5">
      <div class="row">
        <div class="col-auto width-label mb-3">
          <label>ระดับการศึกษา</label>
        </div>
        <div class="col mb-3">
          <ng-select></ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col-auto width-label mb-3">
          <label>ปีการศึกษา</label>
        </div>
        <div class="col mb-3">
          <ng-select></ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col-auto width-label mb-3">
          <label>ภาคเรียน</label>
        </div>
        <div class="col mb-3">
          <ng-select></ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col-auto width-label mb-3">
          <label>คณะ</label>
        </div>
        <div class="col mb-3">
          <ng-select></ng-select>
        </div>
      </div>
    </div>
    <div class="col-7 px-0 d-flex bg-calendar">
      <ngb-datepicker></ngb-datepicker>
      <div class="p-3">
        <div>วันสุดท้ายของการประเมินอาจารย์</div>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-center">
      <button class="btn btn-outline-green-vru my-3"
          title="Search"
          type="button">
        ค้นหา
      </button>
    </div>
  </div>
</div>