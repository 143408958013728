import { Component, OnInit, ViewChild } from '@angular/core';
import { ErrorMessages, ProfileSignature, UserApiService } from '@vru/master-data';
import { AlertService } from '@vru/services';
import {
  ImageDrawingComponent,
  ImageDrawingMode,
  ImageDrawingOptions,
  TextSignatureComponent,
} from '@vru/ui';
import { UtilityService } from '@vru/utils';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'vru-signature-setting',
  templateUrl: './signature-setting.component.html',
  styleUrls: ['./signature-setting.component.scss'],
})
export class SignatureSettingComponent implements OnInit {
  @ViewChild(ImageDrawingComponent) drawingComp!: ImageDrawingComponent;
  @ViewChild(TextSignatureComponent) textSignComp!: TextSignatureComponent;

  aspectRatio = 4 / 3;
  croppedImage?: string | null = null;
  drawingOptions: ImageDrawingOptions = {
    height: '400px',
  };
  previousImage?: string | null = null;
  statusLabel: { [p in SignMode]: string } = {
    crop: 'ตัดรูปลายเซ็น',
    preview: 'ลายเซ็นปัจจุบัน',
    sign: 'เซ็นใหม่',
    textSign: 'เซ็นด้วยการพิมพ์',
  };
  signatureImage?: string | null = null;
  signMode: SignMode = 'preview';
  isLoading = true;
  isUpdating = false;
  signContainerSize?: { width: number; height: number };

  constructor(
    private alert: AlertService,
    private ngxSpinnerService: NgxSpinnerService,
    private userApi: UserApiService,
    private utils: UtilityService
  ) {}

  ngOnInit(): void {
    this.ngxSpinnerService.show('signature-setting-loading');
    this.getOwnSignature();
  }

  getOwnSignature(): void {
    this.userApi
      .getOwnProfileDetail<ProfileSignature | null>('signature/')
      .subscribe({
        next: (res) => {
          this.signatureImage = res?.signature;
          this.previousImage = res?.signature;
          this.isLoading = false;
        },
        error: () => {
          this.alert.error(ErrorMessages.common.plsContactAdmin);
          this.isLoading = false;
        },
      });
  }

  onCancelClick(): void {
    if (this.signMode === 'textSign') {
      this.textSignComp.onCancelClick();
    } else if (this.signMode === 'sign') {
      this.signMode = 'preview';
      this.signatureImage = this.previousImage;
    } else if (this.signMode === 'crop') {
      this.signMode = 'sign';
    }
  }

  onConfirmClick(): void {
    if (this.signMode === 'textSign') {
      this.textSignComp.onConfirmClick();
    } else if (this.signMode === 'sign') {
      this.snapToCrop();
    }
  }

  onResize(e: any): void {
    const defaultHeight = 400;
    const maxWidth = defaultHeight * this.aspectRatio;
    const newHeight =
      e.newRect.width < maxWidth
        ? e.newRect.width * (1 / this.aspectRatio)
        : defaultHeight;
    this.drawingOptions = {
      ...this.drawingOptions,
      width: `${e.newRect.width < maxWidth ? e.newRect.width : maxWidth}px`,
      height: `${newHeight}px`,
    };
  }

  onSignWithTextClick(): void {
    this.signMode = 'textSign';
  }

  onTextSignCancelClick(): void {
    this.textSignComp.onCancelClick();
  }

  onTextSignConfirmClick(): void {
    this.textSignComp.onConfirmClick();
  }

  onTextSignSave(image: Blob): void {
    this.utils.convertImageToUrl(new File([image], 'signature.png')).subscribe({
      next: (res) => {
        this.submit(res as string);
      },
    });
  }

  snapToCrop(): void {
    this.drawingComp.onSave();
    this.signMode = 'crop';
  }

  onImageCropped(image: string): void {
    this.croppedImage = image;
  }

  submit(signature: string | null): void {
    this.isUpdating = true;
    this.userApi.updateOwnSignature(signature).subscribe({
      next: (res) => {
        this.signatureImage = res.signature;
        this.previousImage = res.signature;
        this.signMode = 'preview';
        this.isUpdating = false;
      },
      error: () => {
        this.alert.error(ErrorMessages.common.errUploadFile);
        this.isUpdating = false;
      },
    });
  }
}

export type SignMode = ImageDrawingMode | 'textSign';
