import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { ApiService } from '../../api/api.service';
import { CommonApiRes, DrfList } from '../../common.model';
import {
  ExportGradeFormParams,
  GradeDeadline,
  GradeDeadlineParams,
  GradeSetting,
  GradeSubmission,
  GradeSubmissionExportGPAParams,
  GradeSubmissionExportParams,
  GradeSubmissionParams,
  GradeSubmissionStatus,
  StudentScore,
} from './grade-submission.model';

@Injectable({
  providedIn: 'root',
})
// TODO: Rename to GradeRecordService
export class GradeSubmissionService {
  private readonly gradeSubmissionUrl = '/grade-submissions/';
  private readonly gradeSettingUrl = '/grade-settings/';
  private readonly gradeRecordUrl = '/record-grade/';
  private readonly gradeDeadlineUrl = 'deadline/';
  private readonly gradeSubmitUrl = 'submit-grade/';
  private readonly exportPendingGradeFormUrl = 'export-pending-grade-form/';
  private readonly exportRangeGradeFormUrl = 'export-range-grade-form/';
  private readonly TeacherUrl = '/teachers/';
  private readonly exportStudentGradeFormUrl = 'enrolled-student-report/';

  constructor(private apiService: ApiService, private store: Store) {}

  approve(id: number, status: GradeSubmissionStatus): Observable<CommonApiRes> {
    let endpoint: 'backoffice' | 'dean';
    if (status === 'wait_for_backoffice_approval') {
      endpoint = 'backoffice';
    } else if (status === 'wait_for_dean_approval') {
      endpoint = 'dean';
    } else {
      return throwError(
        new Error('The grade submission status is incorrect for approval')
      );
    }
    return this.apiService.post(
      this.gradeSubmissionUrl + `${id}/${endpoint}-approve/`,
      null
    );
  }

  getList(
    params?: GradeSubmissionParams
  ): Observable<DrfList<GradeSubmission>> {
    return this.apiService.get<DrfList<GradeSubmission>>(
      this.gradeSubmissionUrl,
      params
    );
  }

  getDetail(
    id: number,
    params?: Partial<GradeSubmissionParams>
  ): Observable<GradeSubmission> {
    return this.apiService.get<GradeSubmission>(
      this.gradeSubmissionUrl + `${id}`,
      params
    );
  }

  onCreateGradeSetting(data: GradeSetting): Observable<GradeSetting> {
    return this.apiService.post<GradeSetting>(this.gradeSettingUrl, data);
  }

  onUpdateGradeSetting(
    data: GradeSetting,
    id: number
  ): Observable<GradeSetting> {
    return this.apiService.patch<GradeSetting>(
      this.gradeSettingUrl + `${id}/`,
      data
    );
  }

  getDeadLine(params?: GradeDeadlineParams): Observable<GradeDeadline[]> {
    return this.apiService.get<GradeDeadline[]>(
      this.gradeSubmissionUrl + this.gradeDeadlineUrl,
      params
    );
  }

  onUpdateEnrollment(id: number, data: StudentScore): Observable<StudentScore> {
    return this.apiService.post<StudentScore>(
      this.gradeSubmissionUrl + `${id}` + this.gradeRecordUrl,
      data
    );
  }

  onSubmitGrade(id: number, data: null) {
    return this.apiService.post(
      this.gradeSubmissionUrl + `${id}/` + this.gradeSubmitUrl,
      data
    );
  }

  onPrintGrade(params: ExportGradeFormParams): Observable<File> {
    return this.apiService.getBlob(
      this.TeacherUrl + this.exportStudentGradeFormUrl,
      params
    );
  }

  onExportPenddingGrade(params: GradeSubmissionExportParams): Observable<File> {
    return this.apiService.getBlob(
      this.gradeSubmissionUrl + this.exportPendingGradeFormUrl, params
    );
  }

  onExportRangeGrade(params: GradeSubmissionExportGPAParams): Observable<File> {
    return this.apiService.getBlob(
      this.gradeSubmissionUrl + this.exportRangeGradeFormUrl, params
    )
  }

  reject(id: number, status: GradeSubmissionStatus): Observable<CommonApiRes> {
    let endpoint: 'backoffice' | 'dean';
    if (status === 'wait_for_backoffice_approval') {
      endpoint = 'backoffice';
    } else if (status === 'wait_for_dean_approval') {
      endpoint = 'dean';
    } else {
      return throwError(
        new Error('The grade submission status is incorrect for rejection')
      );
    }
    return this.apiService.post(
      this.gradeSubmissionUrl + `${id}/${endpoint}-reject/`,
      null
    );
  }
}
