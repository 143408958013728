<div class="page-container position-relative pt-5 d-flex flex-fill flex-column no-gutters">
  <div class="board-white flex-fill p-5 d-flex flex-column no-gutters"
      [class.spinner-blur-effect]="isLoading">
    <div class="d-flex flex-column flex-fill">
      <div class="d-flex flex-wrap">
        <vru-carbon-icon iconName="back"
            size="24"
            presetFilter="fill-green-vru"
            (click)="goBack()"></vru-carbon-icon>
        <h4 class="mb-3 text-green-vru pointer"
            (click)="goBack()">ข่าวสารและกิจกรรม</h4>
      </div>
      <div class="d-flex flex-fill flex-column no-gutters pt-3">
        <ng-container *ngIf="newsList; else noNewsTemplate">
          <ng-container *ngFor="let news of newsList; let i=index">
            <div class="card mb-2 shadow-sm pointer fade-in-top"
                (click)="onClickNews(news.id)">
              <div class="card-body">
                <div class="d-flex flex-wrap no-gutters flex-fill">
                  <h6 class="text-truncate">{{ news.title }}</h6>
                </div>
                <div class="d-flex flex-wrap no-gutters justify-content-end">
                  <vru-carbon-icon iconName="calendar"></vru-carbon-icon>
                  <span>{{ news.since }}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #noNewsTemplate>
          <div class="d-flex flex-wrap justify-content-center">
            <span class="text-secondary">ไม่มีข่าวสารและกิจกกรม</span>
          </div>
        </ng-template>
      </div>
      <div class="d-flex justify-content-center mt-auto">
        <ngb-pagination size="sm"
            [(page)]="page"
            [pageSize]="pageSize"
            [collectionSize]="totalCount"
            [maxSize]="4"
            [ellipses]="false"
            [rotate]="true"
            [boundaryLinks]="true"
            (pageChange)="getNewsList()">
        </ngb-pagination>
      </div>
    </div>
  </div>
  <div class="container-spinner-float">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
        name="news-list-table"
        color="#3cd070"
        size="medium"
        type="ball-clip-rotate-pulse"
        [fullScreen]="false"
        [showSpinner]="isLoading">
    </ngx-spinner>
  </div>
</div>