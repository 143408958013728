export interface News {
  id: number;
  type: NewsType;
  target: NewsTarget;
  shared_users: number[];
  cover_photo: string;
  title: string;
  detail: string;
  created_at: string;
  updated_at: string;
  since: string;
}

export enum NewsTargetDetail {
  public = 'สาธารณะ',
  teacher = 'อาจารย์',
  student = 'นักศึกษา',
  executive = 'ผู้บริหาร',
  individual = 'รายบุคคล'
}

export enum NewsTypeDetail {
  announcement = 'ข่าวประชาสัมพันธ์',
  education = 'ข่าวศึกษาต่อ',
  hiring = 'รับสมัครงาน',
  activity = 'กิจกรรมของมหาวิทยาลัย',  
}

export type NewsTarget = keyof typeof NewsTargetDetail;

export type NewsType = keyof typeof NewsTypeDetail;

export const NewsTargetDropdown = [
  {
    label: 'สาธารณะ',
    value: 'public'
  },
  {
    label: 'อาจารย์',
    value: 'teacher'
  },
  {
    label: 'นักศึกษา',
    value: 'student'
  },
  {
    label: 'ผู้บริหาร',
    value: 'executive'
  },
  {
    label: 'รายบุคคล',
    value: 'individual'
  },
];

export const NewsTypeDropdown = [
  {
    label: 'ข่าวประชาสัมพันธ์',
    value: 'announcement'
  },
  {
    label: 'ข่าวศึกษาต่อ',
    value: 'education'
  },
  {
    label: 'รับสมัครงาน',
    value: 'hiring'
  },
  {
    label: 'กิจกรรมของมหาวิทยาลัย',
    value: 'activity'
  }
];
export interface NewsFeedParams {
  order_by?: NewsFeedReOrder | null;
  page?: number | null;
  page_size?: number | null;
  title?: string | null;
  type?: NewsType | null;
  target?: NewsTarget | null;
}

export type NewsFeedReOrder = 
  '-created_at' |
  '-updated_at' |
  'created_at' |
  'updated_at'
;

export interface UniversitySummaryCount {
  program_count: number;
  faculty_count: number;
  student_count: number;
  alumni_count: number;
}