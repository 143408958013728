<div [formGroup]="formGroup">
  <table class="table table-subject w-100"
      [class.invalid]="submitted && (isInvalidCredit || isInvalidCourse)">
    <thead class="text-center">
      <tr>
        <th scope="col"></th>
        <th scope="col">รหัส</th>
        <th scope="col">ชื่อวิชา</th>
        <th scope="col">น(ท-ป-ศ)</th>
        <th scope="col">
          <span *ngIf="!submitted && isInvalidCredit"
              class="bi bi-exclamation-triangle-fill text-warning h5 mb-0"
              popoverClass="popover-warning"
              popoverTitle="หน่วยกิตของรายวิชาน้อยกว่าที่กำหนด"
              ngbPopover="หน่วยกิตของรายวิชาทั้งหมดต้องรวมกันไม่น้อยกว่า {{ this.maxCredit }} หน่วยกิต"
              triggers="mouseenter:mouseleave"
              [openDelay]="300">
          </span>
          <span *ngIf="submitted && isInvalidCredit"
              class="bi bi-exclamation-circle-fill text-white h5 mb-0"
              popoverClass="popover-alert"
              popoverTitle="หน่วยกิตของรายวิชาน้อยกว่าที่กำหนด"
              ngbPopover="หน่วยกิตของรายวิชาทั้งหมดต้องรวมกันไม่น้อยกว่า {{ this.maxCredit }} หน่วยกิต"
              triggers="mouseenter:mouseleave"
              [openDelay]="300">
          </span>
        </th>
      </tr>
    </thead>
    <tbody formArrayName="courses">
      <tr *ngFor="let course of courseFormArr.controls; index as i"
          [class.invalid]="submitted && course.getError('duplicateCourse')">
        <td>
          <vru-carbon-icon *ngIf="courseFormArr.length > 1 && !readOnly"
              class="d-block mt-1"
              iconName="subtract-alt"
              presetFilter="fill-red"
              [size]="22"
              (click)="removeSubject(i)">
          </vru-carbon-icon>
        </td>
        <td class="align-top">
          <vru-course-select #subjectSelection
              class="mt-2"
              [formControlName]="i"
              [readonly]="readOnly"
              [validateRegistry]="{ courseStrcIndex: courseStructureIndex || 0, courseIndex: i }"
              [unavailableCourses]="unavailableCourses"
              (selectedCourseChange)="onCourseChange($event, i)">
          </vru-course-select>
        </td>
        <td>
          <input class="form-control"
              placeholder="ชื่อรายวิชาภาษาไทย"
              readonly
              type="text"
              [value]="subjectSelection.selectedCourse?.name_thai">
          <input class="form-control mt-2"
              placeholder="ชื่อรายวิชาภาษาอังกฤษ"
              readonly
              type="text"
              [value]="subjectSelection.selectedCourse?.name_eng">
        </td>
        <td class="align-top">
          <input class="form-control text-center"
              placeholder="น(ท-ป-ศ)"
              readonly
              type="text"
              [value]="course.value?.credit_hour">
        </td>
        <td>
          <div class="pt-2">
            <span *ngIf="submitted && course.getError('duplicateCourse')"
                class="bi bi-exclamation-circle-fill text-red h5 mb-0"
                popoverClass="popover-alert"
                popoverTitle="รายวิชานี้ถูกเลือกซ้ำ"
                ngbPopover="ไม่สามารถเลือกรายวิชาเลือกซ้ำกันได้ กรุณาตรวจสอบข้อมูล"
                triggers="mouseenter:mouseleave"
                [openDelay]="300">
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="!readOnly">
    <button class="btn btn-green-vru"
        type="button"
        (click)="onAddClick()">
      <span>+ เพิ่มรายวิชา</span>
    </button>
  </div>
</div>