import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import {
  AlertMessages,
  Dropdown,
  DropdownApiService,
  EducationRecord,
  FieldOfStudyPermission,
  FieldOfStudyPermissionList,
  Modify,
  Nullable,
  Officer,
  ProgramResponsibleTeacherOfTeacher,
  ReceiptPermission,
  Roles,
  Teacher,
  TeacherApiService,
  User,
  UserApiService,
  UserPatch,
  UserPermission,
  UserPermissionSetting,
  UserProfile,
  UserProfilePatch,
  UserProfileRetrieve,
} from '@vru/master-data';
import {
  AlertService,
  ComponentSubscriptionService,
  ErrorService,
  UserService,
  ValidatorService,
} from '@vru/services';
import { convertError } from '@vru/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';

@Component({
  selector: 'vru-user-information-form',
  templateUrl: './user-information-form.component.html',
  styleUrls: ['./user-information-form.component.scss'],
  providers: [ComponentSubscriptionService],
})
export class UserInformationFormComponent implements OnInit {
  @ViewChild('receiptSettingTemplate') receiptSettingTemplate!: ElementRef;

  createMode = false;
  teacherId!: number;
  userPermissionForm!: FormGroup;
  permissionForm!: FormGroup;
  nameTitleDropdown: Dropdown[] = [];
  workStatusDropdown: Dropdown[] = [];
  maritalDropdown: Dropdown[] = [];
  employeeTypeDropdown: Dropdown[] = [];
  facultyDropdown: Dropdown[] = [];
  fieldOfStudyDropdown: Dropdown[] = [];
  initialFieldOfStudyDropdown: Dropdown[] = [];
  degreeDropdown: Dropdown[] = [];
  prototypeProfile!: UserProfileRetrieve;
  teacherStatusDropdown: Dropdown[] = [];
  teacherTypeDropdown: Dropdown[] = [];
  roleDropdown: Dropdown<number | string>[] = [];
  teacherRole: ProgramResponsibleTeacherOfTeacher[] = [];
  activeStatusDropdown = [
    {
      label: 'ใช้งานปกติ',
      value: true,
    },
    {
      label: 'ยกเลิกการใช้งาน',
      value: false,
    },
  ];
  selectedRole?: Roles;
  selectedRoleName!: string;
  imageUpload!: File | string;
  isLoading = true;
  isOfficer = false;
  isSubmitted = false;
  isSubmitting = false;
  isSelectFaculty = false;
  selfRole!: Roles;
  selfUser!: UserProfile;
  otherUserId?: number;
  receiptPrototype: ReceiptPermission[] = [];
  isSelectAllReceipt = false;

  thaiPhoneValidator = (control: AbstractControl): ValidationErrors | null => {
    const val = control.value;
    const valid = this.validate.thaiPhone(val);
    return valid
      ? null
      : {
          phoneNumber: {
            pattern: false,
          },
        };
  };

  idCardValidator = (control: AbstractControl): ValidationErrors | null => {
    const val = control.value;
    const valid = this.validate.idIdCard(val);
    return valid
      ? null
      : {
          idCard: {
            pattern: false,
          },
        };
  };

  constructor(
    private compSub: ComponentSubscriptionService,
    private dropdownApiService: DropdownApiService,
    private ngxSpinnerService: NgxSpinnerService,
    private fb: FormBuilder,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
    private teacherApi: TeacherApiService,
    private user: UserService,
    private userApi: UserApiService,
    private validate: ValidatorService,
    private ngbModal: NgbModal,
    private errorService: ErrorService
  ) {
    this.getSelfUser();
    this.initialForm();
  }

  ngOnInit(): void {
    this.subscribeRoute();
    this.subscribeDropdown();
    this.onSetSelectedRoleNameValue();
    this.setRoleChange();
  }

  changeFormByRole(role: Roles): void {
    const teacherControls = this.userPermissionForm.get('teacher');
    const officerControls = this.userPermissionForm.get('officer');
    if (this.checkBeingOfficer(role) || this.checkBeingTeacher(role)) {
      this.isOfficer = true;
      if (['dean', 'teacher'].includes(role)) {
        this.userPermissionForm
          .get('faculty')
          ?.setValidators(Validators.required);
        this.userPermissionForm.get('faculty')?.updateValueAndValidity();
        teacherControls?.enable();
        officerControls?.disable();
      } else {
        this.userPermissionForm.get('faculty')?.setValidators(null);
        this.userPermissionForm.get('faculty')?.updateValueAndValidity();
        teacherControls?.disable();
        officerControls?.enable();
      }
    } else {
      this.isOfficer = false;
      this.userPermissionForm.get('faculty')?.setValidators(null);
      this.userPermissionForm.get('faculty')?.updateValueAndValidity();
      officerControls?.disable();
      teacherControls?.disable();
    }
  }

  getSelfUser(): void {
    this.selfUser = this.store.selectSnapshot((state) => state.auth);
    this.selfRole = this.selfUser.role;
  }

  subscribeDropdown(): void {
    const types = [
      'work_status',
      'marital_status',
      'employee_type',
      'name_title',
      'faculty',
      'degree',
      'teacher_status',
      'teacher_type',
      'role',
      'field_of_study',
    ];
    this.dropdownApiService
      .getDropdown(types, { can_create_on_user_management: 'true' })
      .subscribe({
        next: (response) => {
          this.roleDropdown = response.role.filter(
            (role) => String(role.value) !== 'general'
          );
          this.workStatusDropdown = response.work_status;
          this.nameTitleDropdown = response.name_title;
          this.maritalDropdown = response.marital_status;
          this.employeeTypeDropdown = response.employee_type;
          this.facultyDropdown = response.faculty;
          this.degreeDropdown = response.degree;
          this.teacherStatusDropdown = response.teacher_status;
          this.teacherTypeDropdown = response.teacher_type;
          this.initialFieldOfStudyDropdown = response.field_of_study;
        },
      });
  }

  onSubmit(): void {
    this.isSubmitted = true;
    if (this.userPermissionForm.invalid || this.permissionForm?.invalid) {
      this.alertService.error(AlertMessages.validation.plsFillValid);
      return;
    }
    const data = this.userPermissionForm.getRawValue();
    const permissionData = this.permissionForm?.getRawValue();
    delete data.photo;
    if (!this.createMode) {
      delete data.user.username;
      delete data.user.password;
    }
    if (['teacher', 'dean'].includes(data.role)) {
      delete data.officer;
    } else {
      delete data.teacher;
    }
    if (permissionData?.field_of_study_permissions) {
      permissionData.field_of_study_permissions.forEach(
        (obj: {
          dropdown_list?: Dropdown[];
          field_of_studies: any[];
          permissions?: any[];
          id?: number;
        }) => {
          delete obj.dropdown_list;
          delete obj.permissions;
          delete obj.id;
          const studiesList: any[] = [];
          for (let index = 0; index < obj.field_of_studies.length; index++) {
            if (obj.field_of_studies[index].id) {
              studiesList.push({ id: obj.field_of_studies[index].id });
            }
          }
          obj.field_of_studies = studiesList;
        }
      );
    }
    if (this.isAdmin && this.createMode) {
      this.createUser(data, permissionData);
    } else {
      this.updateUser(this.userId, data, permissionData);
    }
  }

  getUserProfile(id: number): void {
    this.isLoading = true;
    const preparingData = forkJoin({
      userProfile: this.userApi.getDetail(id),
      userPermission: this.userApi.getUserPermission(id),
    });
    preparingData.subscribe({
      next: (res) => {
        if (res.userProfile) {
          const response = res.userProfile;
          this.prototypeProfile = response;
          const payload: Modify<
            UserProfileRetrieve,
            { user: Nullable<UserPatch> }
          > = { ...response, user: response.user };
          const formArray = payload.educational_records.map((item) => {
            return this.buildEducationalRecordsForm(item);
          });
          this.userPermissionForm.patchValue(payload);
          this.userPermissionForm.setControl(
            'educational_records',
            this.fb.array(formArray)
          );
          if (response.teacher) {
            const teacher$ = this.teacherApi.getTeachers(
              undefined,
              response.teacher.id
            ) as Observable<Teacher>;
            teacher$.subscribe({
              next: (res) => {
                this.teacherRole = res.program_responsible_teachers;
              },
            });
          }
          if (response.role === 'student') {
            this.roleForm.disable();
          }
          if (res.userPermission) {
            const payload = res.userPermission;
            const userPermissionArray = payload.user_permissions.map((item) => {
              return this.buildUserPermissionForm(item);
            });
            const FieldOfStudyPermissionArray =
              payload.field_of_study_permissions.map((item) => {
                return this.buildFieldOfStudyPermissionForm(item);
              });
            const receiptPermissionsArray = payload.receipt_permissions.map(
              (item) => {
                return this.buildReceiptPermissionForm(item);
              }
            );
            this.permissionForm = this.fb.group({
              user_permissions: this.fb.array(userPermissionArray),
              field_of_study_permissions: this.fb.array(
                FieldOfStudyPermissionArray
              ),
              receipt_permissions: this.fb.array(receiptPermissionsArray),
            });
            this.fieldOfStudyPermissionFormArray.controls.forEach((obj) => {
              (obj.get('field_of_studies') as FormArray).controls.forEach(
                (data) => {
                  const facultyID = data.get('faculty')?.value;
                  if (facultyID) {
                    this.dropdownApiService
                      .getDropdown({
                        type: 'field_of_study',
                        faculty: facultyID,
                      })
                      .subscribe({
                        next: (res) => {
                          data
                            .get('dropdown_list')
                            ?.setValue(res.field_of_study);
                        },
                        error: (err) => {
                          data.get('dropdown_list')?.setValue(null);
                          console.error(err);
                        },
                      });
                  } else {
                    data.get('dropdown_list')?.setValue(null);
                    this.isSelectFaculty = false;
                  }
                }
              );
            });
          }
          if (!this.isAdmin) {
            this.userPermissionForm.disable();
            if (!this.isBackoffice) {
              this.permissionForm.disable();
            }
            if (!this.otherUserId && this.selfUser.profile) {
              this.userPermissionForm.get('name_title')?.enable();
              this.userPermissionForm.get('address')?.enable();
            }
          }
          if (response?.faculty) {
            this.onGetFieldOfStudyDropdown(response?.faculty);
          }
          this.setRoleChange();
          this.isLoading = false;
        }
      },
      error: async (err) => {
        if (err.error) {
          this.alertService.error(await convertError(err), 5000);
        }
      },
    });
  }

  createUser(
    data: UserProfilePatch,
    permissionData?: UserPermissionSetting
  ): void {
    this.isLoading = true;
    this.isSubmitting = true;
    this.userApi.create(data).subscribe({
      next: (res) => {
        if (this.imageUpload) {
          this.onSaveImage(res.id);
        }
        if ((this.isBackoffice || this.isAdmin) && permissionData) {
          this.updateUserPermission(this.userId, permissionData);
        }
        this.alertService.alertSuccessSubmit();
        this.isSubmitting = false;
        this.isLoading = false;
        this.onBackToListPage();
      },
      error: (err) => {
        this.isLoading = false;
        this.isSubmitting = false;
        if (err.status === 400) {
          if (typeof err.error === 'object') {
            const errorMsg = this.errorService.getDrfErrMsgHtml(err.error);
            this.alertService.error(errorMsg);
          } else {
            this.alertService.errorWithContactAdminTh();
          }
        } else if (err.status === 403) {
          this.alertService.noPermit();
        } else {
          this.alertService.errorWithContactAdminTh();
        }
      },
    });
  }

  updateUser(
    id: number,
    data: UserProfilePatch,
    permissionData?: UserPermissionSetting
  ): void {
    this.isLoading = true;
    this.isSubmitting = true;
    this.userApi.update(id, data).subscribe({
      next: (res) => {
        this.isLoading = false;
        if (this.imageUpload) {
          this.onSaveImage(res.id);
        }
        if ((this.isBackoffice || this.isAdmin) && permissionData) {
          this.updateUserPermission(this.userId, permissionData);
        }
        this.alertService.alertSuccessSubmit();
        this.isSubmitting = false;
        this.onBackToListPage();
      },
      error: (err) => {
        this.isLoading = false;
        this.isSubmitting = false;
        if (err.status === 400) {
          if (typeof err.error === 'object') {
            const errorMsg = this.errorService.getDrfErrMsgHtml(err.error);
            this.alertService.error(errorMsg);
          } else {
            this.alertService.errorWithContactAdminTh();
          }
        } else if (err.status === 403) {
          this.alertService.noPermit();
        } else {
          this.alertService.errorWithContactAdminTh();
        }
      },
    });
  }

  updateUserPermission(id: number, permission: UserPermissionSetting) {
    this.userApi.updateUserPermissionSetting(id, permission).subscribe({
      next: () => {
        this.alertService.success('บันทึกข้อมูลการกำหนดสิทธิ์เรียบร้อย', 5000);
      },
      error: async (err) => {
        if (err.error) {
          this.alertService.error(await convertError(err), 5000);
        }
      },
    });
  }

  initialForm(): void {
    this.userPermissionForm = this.fb.group({
      id: [{ value: null, disabled: this.createMode }, Validators.required],
      user: this.buildUserForm(),
      name_title: [null, [Validators.required]],
      id_card: [
        null,
        [Validators.required, Validators.compose([this.idCardValidator])],
      ],
      phone_number: [
        null,
        [Validators.required, Validators.compose([this.thaiPhoneValidator])],
      ],
      address: [null, [Validators.required]],
      work_status: [null, [Validators.required]],
      marital_status: [null, [Validators.required]],
      start_date: [null, [Validators.required]],
      resignation_date: [null],
      employment_types: [null, Validators.required],
      faculty: [null],
      field_of_study: [null],
      photo: [null],
      educational_records: this.fb.array([this.buildEducationalRecordsForm()]),
      role: [null, [Validators.required]],
      teacher: this.buildTeacherForm(),
      officer: this.buildOfficerForm(),
    });
  }

  buildUserForm(initial?: Partial<User> | null): FormGroup {
    const userForm: Record<keyof UserPatch, any> = {
      username: [initial?.username, [Validators.required]],
      password: [null, [Validators.required]],
      id: [{ disabled: initial?.id, value: initial?.id }],
      email: [initial?.email, [Validators.required, Validators.email]],
      first_name: [initial?.first_name, [Validators.required]],
      last_name: [initial?.last_name, [Validators.required]],
      first_name_eng: [initial?.first_name_eng, [Validators.required]],
      last_name_eng: [initial?.last_name_eng, [Validators.required]],
      is_active: [initial?.is_active, [Validators.required]],
    };
    return this.fb.group(userForm);
  }

  buildEducationalRecordsForm(
    initial?: Partial<EducationRecord> | null
  ): FormGroup {
    const educationalRecords: Record<keyof EducationRecord, any> = {
      educational_background: [initial?.educational_background],
      academy: [initial?.academy],
      year: [initial?.year],
      degree: [initial?.degree],
    };
    return this.fb.group(educationalRecords);
  }

  buildTeacherForm(initial?: Partial<Teacher> | null): FormGroup {
    const teacherForm: { [p in keyof Teacher]?: any } = {
      id: [initial?.id],
      code: [initial?.code, [Validators.required, Validators.maxLength(10)]],
      short_polite_name_title_thai: [
        initial?.short_polite_name_title_thai,
        [Validators.required],
      ],
      full_polite_name_title_thai: [
        initial?.full_polite_name_title_thai,
        [Validators.required],
      ],
      short_polite_name_title_eng: [
        initial?.short_polite_name_title_eng,
        [Validators.required],
      ],
      full_polite_name_title_eng: [
        initial?.full_polite_name_title_eng,
        [Validators.required],
      ],
      status: [initial?.status, [Validators.required]],
      short_academic_position: [
        initial?.short_academic_position,
        [Validators.required],
      ],
      full_academic_position: [
        initial?.full_academic_position,
        [Validators.required],
      ],
      teacher_type: [initial?.teacher_type, [Validators.required]],
      book: [initial?.book, [Validators.required]],
      research: [initial?.research, [Validators.required]],
      academic_article: [initial?.academic_article, [Validators.required]],
      invention_and_creativity: [
        initial?.invention_and_creativity,
        [Validators.required],
      ],
    };
    return this.fb.group(teacherForm);
  }

  buildOfficerForm(initial?: Partial<Officer> | null): FormGroup {
    const officerForm: Record<keyof Officer, any> = {
      id: [{ value: initial?.id, disabled: !initial?.id }],
      code: [initial?.code, [Validators.required, Validators.maxLength(10)]],
    };
    return this.fb.group(officerForm);
  }

  buildUserPermissionForm(initial?: Partial<UserPermission>): FormGroup {
    const permissionForm: Record<keyof UserPermission, any> = {
      id: [initial?.id],
      permission: [initial?.permission],
      code_name_th: [initial?.code_name_th, [Validators.required]],
      code_name_eng: [initial?.code_name_eng, [Validators.required]],
      value: [initial?.value, [Validators.required]],
    };
    return this.fb.group(permissionForm);
  }

  buildFieldOfStudyPermissionForm(
    initial?: Partial<FieldOfStudyPermission>
  ): FormGroup {
    const fieldOfStudyArrayForm: any[] = [];
    if (initial?.field_of_studies) {
      if (initial?.field_of_studies.length > 0) {
        initial.field_of_studies.forEach((element) => {
          fieldOfStudyArrayForm.push(this.buildFieldOfStudyDetailForm(element));
        });
      } else {
        fieldOfStudyArrayForm.push(this.buildFieldOfStudyDetailForm());
      }
    }
    const permissionForm: Record<keyof FieldOfStudyPermission, any> = {
      id: [initial?.id],
      permission: [initial?.permission],
      field_of_studies: this.fb.array(fieldOfStudyArrayForm),
    };
    return this.fb.group(permissionForm);
  }

  buildFieldOfStudyDetailForm(
    initial?: Partial<FieldOfStudyPermissionList>
  ): FormGroup {
    const fieldOfStudyPermission = {
      id: [initial?.id],
      faculty: [initial?.faculty?.id],
      dropdown_list: [],
    };
    return this.fb.group(fieldOfStudyPermission);
  }

  buildReceiptPermissionForm(initial?: Partial<ReceiptPermission>): FormGroup {
    const receiptPermission = {
      id: [initial?.id],
      permission: [initial?.permission],
      code_name_th: [initial?.code_name_th],
      code_name_eng: [initial?.code_name_eng],
      value: [initial?.value],
    };
    return this.fb.group(receiptPermission);
  }

  passwordMatcher(c: AbstractControl): { [key: string]: boolean } | null {
    const passwordControl = c.get('password');
    const passwordConfirm = c.get('check_password');

    if (passwordControl?.pristine || passwordConfirm?.pristine) {
      return null;
    }

    if (passwordControl?.value === passwordConfirm?.value) {
      return null;
    }
    return { match: true };
  }

  addDynamicEducationalForms(): void {
    this.educationalRecords.controls.push(this.buildEducationalRecordsForm());
  }

  deleteDynamicEducationalForms(index: number): void {
    return this.educationalRecords.removeAt(index);
  }

  checkBeingOfficer(role: Roles): boolean {
    return this.user.checkBeingOfficer(role);
  }

  checkBeingTeacher(role: Roles): boolean {
    return this.user.checkBeingTeacher(role);
  }

  onUploadImage(fileInput: File): void {
    this.imageUpload = fileInput;
    this.userPermissionForm.get('photo')?.setValue(this.imageUpload);
  }

  onSaveImage(id: number): void {
    const imageData = new FormData();
    imageData.append('photo', this.imageUpload);
    this.userApi.update(id, imageData).subscribe({
      next: () => {
        this.alertService.success();
        this.isLoading = false;
      },
      error: () => {
        this.alertService.errorWithContactAdmin();
        this.isLoading = false;
      },
    });
  }

  onSelectFaculty(faculty: Dropdown) {
    if (faculty) {
      this.userPermissionForm.get('field_of_study')?.setValue(null);
      this.onGetFieldOfStudyDropdown(faculty.value);
    } else {
      this.userPermissionForm.get('field_of_study')?.setValue(null);
      this.isSelectFaculty = false;
    }
  }

  onGetFieldOfStudyDropdown(faculty?: number) {
    if (faculty) {
      this.dropdownApiService
        .getDropdown({ type: 'field_of_study', faculty: faculty })
        .subscribe({
          next: (res) => {
            this.fieldOfStudyDropdown = res.field_of_study;
            this.isSelectFaculty = true;
          },
          error: (err) => {
            console.error(err);
          },
        });
    } else {
      this.dropdownApiService
        .getDropdown({ type: 'field_of_study' })
        .subscribe({
          next: (res) => {
            this.fieldOfStudyDropdown = res.field_of_study;
          },
          error: (err) => {
            console.error(err);
          },
        });
    }
  }

  onSetSelectedRoleNameValue(): void {
    this.userPermissionForm.valueChanges.subscribe({
      next: (response) => {
        if (response.role !== 'dean' && response.role !== 'teacher') {
          this.selectedRoleName = 'เจ้าหน้าที่';
        }
      },
    });
  }

  onOpenReceiptModal(): void {
    this.receiptPrototype = this.receiptPermissionFormArray.getRawValue();
    this.isSelectAllReceipt = this.isSelectAllReceiptToggle;
    this.ngbModal.open(this.receiptSettingTemplate, {
      size: 'lg',
      backdrop: 'static',
    });
  }

  onSubmitReceiptToggle() {
    this.ngbModal.dismissAll();
  }

  onClearReceiptToggle() {
    this.receiptPermissionFormArray.patchValue(this.receiptPrototype);
    this.receiptPrototype = [];
    this.ngbModal.dismissAll();
  }

  onToggleAllReceipt() {
    if (this.isSelectAllReceipt) {
      this.receiptPermissionFormArray.controls.forEach((control) => {
        control.get('value')?.patchValue(true);
      });
    } else {
      this.receiptPermissionFormArray.controls.forEach((control) => {
        control.get('value')?.patchValue(false);
      });
    }
  }

  onToggleReceipt() {
    this.isSelectAllReceipt = this.isSelectAllReceiptToggle;
  }

  onRoleChange(prevRole: Roles, newRole: Roles): void {
    if (!newRole) {
      throw new Error('The new selected role is undefined');
    }
    if (this.prototypeProfile?.role === 'student') {
      this.alertService.error('นักศึกษาไม่สามารถเปลี่ยนบทบาทได้');
      this.roleForm.setValue(this.prototypeProfile.role, { emitEvent: false });
      return;
    }
    if (newRole === 'student') {
      this.alertService.error('บทบาทนักศึกษาไม่สามารถเลือกได้');
      this.roleForm.setValue(prevRole, { emitEvent: true });
      return;
    }
    const role = (this.selectedRole = newRole);
    this.selectedRoleName = !this.checkBeingOfficer(role)
      ? 'เจ้าหน้าที่'
      : newRole;
    this.changeFormByRole(role);
  }

  onBackToListPage(): void {
    this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
  }

  onSelectFacultyPermission(
    index: number,
    fieldOfStudy: number,
    faculty?: number
  ): void {
    this.fieldOfStudyDetailPermissionFormArray(index)
      .at(fieldOfStudy)
      .get('id')
      ?.setValue(null);
    const dropdownForm = this.fieldOfStudyDetailPermissionFormArray(index)
      .at(fieldOfStudy)
      .get('dropdown_list');
    dropdownForm?.setValue(null);
    if (!faculty) {
      return;
    }
    const type = {
      type: 'field_of_study',
      faculty: faculty,
    };
    this.dropdownApiService.getDropdown(type).subscribe({
      next: (res) => {
        dropdownForm?.setValue(res.field_of_study);
      },
      error: async (err) => {
        if (err.error) {
          this.alertService.error(await convertError(err), 5000);
        }
      },
    });
  }

  setRoleChange(): void {
    this.compSub.store.roleFormValueChange?.unsubscribe();
    this.compSub.store.roleFormValueChange = this.roleForm?.valueChanges
      .pipe(startWith(this.roleForm.value), pairwise())
      .subscribe({
        next: (res) => {
          this.onRoleChange(res[0], res[1]);
        },
      });
  }

  startLoading(): void {
    this.ngxSpinnerService.show('user-detail');
  }

  subscribeRoute(): void {
    this.startLoading();
    combineLatest([
      this.activatedRoute.url,
      this.activatedRoute.params,
    ]).subscribe({
      next: (res) => {
        const url = res[0];
        this.createMode = !!res.length && url[0]?.path === 'create';
        const params = res[1];
        this.otherUserId = +params.id || undefined;
        if (this.createMode) {
          this.initialForm();
          this.isLoading = false;
          this.userPermissionForm.get('user.username')?.enable();
          this.userPermissionForm.get('user.password')?.enable();
          if (!this.isAdmin) {
            this.userPermissionForm.disable();
          }
        } else {
          this.getUserProfile(this.userId);
          this.userPermissionForm.get('user.username')?.disable();
          this.userPermissionForm.get('user.password')?.disable();
        }
      },
    });
  }

  fieldOfStudyDetailPermissionFormArray(index: number): FormArray {
    return this.fieldOfStudyPermissionFormArray
      .at(index)
      .get('field_of_studies') as FormArray;
  }

  addFieldOfStudyDetail(index: number) {
    this.fieldOfStudyDetailPermissionFormArray(index).push(
      this.buildFieldOfStudyDetailForm()
    );
  }

  deleteFieldOfStudyDetail(index: number, fieldOfStudy: number) {
    this.fieldOfStudyDetailPermissionFormArray(index).removeAt(fieldOfStudy);
  }

  get userId(): number {
    return this.otherUserId || this.selfUser.profile;
  }

  get isAdmin(): boolean {
    return this.selfUser.role === 'admin';
  }

  get isBackoffice(): boolean {
    return this.selfUser.role === 'backoffice';
  }

  get roleSelected() {
    return this.userPermissionForm.get('role')?.value;
  }

  get educationalRecords(): FormArray {
    return this.userPermissionForm.get('educational_records') as FormArray;
  }

  get userPermissionFormArray(): FormArray {
    return this.permissionForm.get('user_permissions') as FormArray;
  }

  get fieldOfStudyPermissionFormArray(): FormArray {
    return this.permissionForm.get('field_of_study_permissions') as FormArray;
  }

  get receiptPermissionFormArray(): FormArray {
    return this.permissionForm.get('receipt_permissions') as FormArray;
  }

  get isSelectAllReceiptToggle(): boolean {
    const receiptToggleList = this.receiptPermissionFormArray
      .getRawValue()
      .map((item) => item.value);
    return receiptToggleList.every((item) => item === true);
  }

  get f() {
    return this.userPermissionForm.controls;
  }

  get fUser() {
    return (this.userPermissionForm.get('user') as FormGroup).controls;
  }

  get roleForm(): FormControl {
    return this.userPermissionForm.get('role') as FormControl;
  }

  get isDeanOrTeacher() {
    return ['dean', 'teacher'].includes(
      this.userPermissionForm.get('role')?.value
    );
  }
}
