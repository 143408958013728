<div class="canvas-wrapper position-relative p-1"
    [style.width]="options.width"
    [style.height]="options.height">
  <image-cropper *ngIf="mode === 'crop' && image"
      class="h-100"
      format="png"
      [aspectRatio]="aspectRatio"
      [imageBase64]="image"
      [maintainAspectRatio]="!!aspectRatio"
      (imageCropped)="onImageCropped($any($event))"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()">
  </image-cropper>
  <canvas-whiteboard *ngIf="mode === 'sign'"
      #canvasWhiteboard
      class="transparent-style"
      [startingColor]="startingBgColor"
      [options]="canvasOptions"
      (onSave)="onSave($event)">
  </canvas-whiteboard>
  <img *ngIf="mode === 'preview'"
      class="h-100 w-100"
      title="Image Preview"
      [src]="image">
</div>