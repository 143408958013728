<div class="card d-flex w-100 h-100 shadow-sm border-0 p-4 p-2">
  <form class="d-inline-flex flex-column h-100">
    <div class="row p-2">
      <div class="col-8">
        <div class="row mt-2">
          <div class="col m-auto d-flex align-items-center pointer"
              (click)="goToBackPage()">
            <vru-carbon-icon iconName="back"
                class="pointer"
                size="22">
            </vru-carbon-icon>
            <h5 class="mt-1">ย้อนกลับ</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="mt-4">
        <ng-container *ngFor="let menu of menuConfigsDisplay; index as i">
          <label *vruDisplayPermission="menu.permissible"
              class="bg-white-p shadow-sm pointer px-4 py-3 mb-0"
              routerLinkActive="tab-active"
              [routerLink]="menu.path"
              [queryParams]="{
                backPage: backPagePath
              }"
              (click)="onTabClick(i)">
            {{ menu.label }}
          </label>
        </ng-container>
      </div>
      <div class="container-outlet">
        <router-outlet></router-outlet>
      </div>
    </div>
  </form>
</div>