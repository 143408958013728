import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserProfile } from '@vru/master-data';
import { interval, Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class AutoSaveService {
  isIndividual = true;
  trigger = new Subject();
  intervalTime = 10000;
  name = uuid();
  private _prefix = 'autoSave';
  private user!: UserProfile;

  constructor(private store: Store) {
    interval(this.intervalTime).subscribe({
      next: () => {
        this.trigger.next();
      },
    });
    this.getSelfUser();
  }

  clear(): void {
    localStorage.removeItem(this.localStorageName);
  }

  fire(): void {
    this.trigger.next();
  }

  getAllName(): string[] {
    return Object.keys(localStorage).reduce((keys, key) => {
      if (new RegExp(/^autoSave\//).test(key)) {
        keys.push(key.replace('autoSave/', ''));
      }
      return keys;
    }, [] as string[]);
  }

  getData(name: string): any {
    const data = localStorage.getItem(this._prefix + '/' + name);
    return data ? JSON.parse(data) : null;
  }

  private getSelfUser(): void {
    this.user = this.store.selectSnapshot((state) => state.auth);
  }

  load(): any {
    const data = localStorage.getItem(this.localStorageName);
    return data ? JSON.parse(data) : null;
  }

  remove(name: string): void {
    localStorage.removeItem(`${this._prefix}/` + name);
  }

  save(data: any): void {
    localStorage.setItem(this.localStorageName, JSON.stringify(data));
  }

  get localStorageName(): string {
    return this.isIndividual
      ? `${this._prefix}/${this.user.id}/${this.name}`
      : `${this._prefix}/${this.name}`;
  }

  get hasDraft(): boolean {
    return localStorage.getItem(this.localStorageName) != null;
  }
}
