import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Course, DrfList, SubjectApiService } from '@vru/master-data';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'vru-course-select',
  templateUrl: './course-select.component.html',
  styleUrls: ['./course-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CourseSelectComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CourseSelectComponent,
    },
  ],
})
export class CourseSelectComponent
  implements ControlValueAccessor, OnChanges, Validator
{
  @Input() readonly = false;
  @Input() validateRegistry?: { courseStrcIndex: number; courseIndex: number };
  @Input() unavailableCourses: { courses: Course[] }[] = [];
  @Output() selectedCourseChange = new EventEmitter<Course>();

  formControl = new FormControl(null);
  selectedCourse?: Course;
  subjectCodeSearch$ = new Subject<string>();
  subjectCodeAccessor!: (res: DrfList<Course>) => Course[];
  subjectCodeApiGenerator!: (search: string) => Observable<DrfList<Course>>;
  onTouch!: () => void;

  constructor(private subjectApi: SubjectApiService) {
    this.subjectCodeApiGenerator = (search: string) =>
      this.subjectApi.getSubjects({ search });
    this.subjectCodeAccessor = (res) => res.results;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.unavailableCourses) {
      this.formControl.updateValueAndValidity();
    }
  }

  onCourseChange(course: Course): void {
    this.selectedCourseChange.emit(course);
  }

  registerOnChange(fn: () => void): void {
    this.formControl.valueChanges
      .pipe(tap((res) => (this.selectedCourse = res)))
      .subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  validate(): ValidationErrors | null {
    const course = this.formControl.value as Course;
    const duplicate: number[] = [];
    if (!course) return null;
    if (!this.validateRegistry) return null;
    const registry = this.validateRegistry;
    const invalid = this.unavailableCourses.some(
      (unavailableCourse, courseStrcIndex) => {
        const dupCourse = unavailableCourse.courses.findIndex(
          (targetCourse, courseIndex) =>
            targetCourse?.id === course.id &&
            (!(courseStrcIndex === registry.courseStrcIndex) ||
              courseIndex !== registry.courseIndex)
        );
        if (dupCourse !== -1) {
          this.formControl.setErrors({
            duplicateCourse: {
              courseId: course.id,
              duplicateWith: [courseStrcIndex, dupCourse],
            },
          });
          duplicate.push(course.id as number);
          return true;
        }
        return false;
      }
    );
    if (!invalid) {
      this.formControl.setErrors(null);
    }
    return !duplicate.length
      ? null
      : { duplicateCourse: { coursesId: duplicate } };
  }

  writeValue(course: Course): void {
    this.selectedCourse = course;
    this.formControl.setValue(course, { emitEvent: false });
  }
}
