import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { ReceiptBookExportParams } from './receipt.model';

@Injectable({
  providedIn: 'root',
})
export class ReceiptApiService {
  readonly receiptBookPath = 'receipt-books';

  constructor(private api: ApiService) {}

  exportReceiptReports(
    params: ReceiptBookExportParams
  ): Observable<File> {
    return this.api.getBlob([this.receiptBookPath, 'export-receipt'], params);
  }
}
