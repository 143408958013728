<div class="footer-container">
  <div class="footer-content d-flex no-gutters align-items-center">
    <div class="col-auto p-2">
      <img src="../../../assets/utils/images/vru-official-logo.png"
          title="VRU Official Logo"
          width="70">
    </div>
    <div class="col d-flex flex-wrap p-2">
      <div class="col-12 col-md">
        <label class="mb-1 information-text">มหาวิทยาลัยราชภัฏ วไลยอลงกรณ์ ในพระบรมราชูปถัมภ์</label>
        <label class="mb-0 information-text">เลขที่ 1 หมู่ 20 ต.คลองหนึ่ง อ.คลองหลวง ปทุมธานี 13180</label>
      </div>
      <div class="col-12 col-md-auto d-flex group-icon-link pt-2 pt-sm-0 px-1">
        <vru-carbon-icon iconName="logos/facebook"
          [presetFilter]="iconColor"
          [size]="iconSize">
        </vru-carbon-icon>
        <vru-carbon-icon iconName="logos/instagram"
          [presetFilter]="iconColor"
          [size]="iconSize">
        </vru-carbon-icon>
        <vru-carbon-icon iconName="logos/twitter"
          [presetFilter]="iconColor"
          [size]="iconSize">
        </vru-carbon-icon>
        <vru-carbon-icon iconName="logos/youtube"
          [presetFilter]="iconColor"
          [size]="iconSize">
        </vru-carbon-icon>
      </div>
    </div>
  </div>
</div>