export const BACHELOR_PROGRAM_TYPE = {
  academic_bachelor_program: 'หลักสูตรปริญญาตรีทางวิชาการ',
  academic_advancements_bachelor_program:
    'หลักสูตรปริญญาตรีแบบก้าวหน้าทางวิชาการ',
  vocational_bachelor_program: 'หลักสูตรปริญญาตรีทางวิชาชีพ',
  vocational_advancements_bachelor_program:
    'หลักสูตรปริญญาตรีแบบก้าวหน้าทางวิชาชีพ',
  workshop_bachelor_program: 'หลักสูตรปริญญาตรีปฏิบัติการ',
  workshop_advancements_bachelor_program:
    'หลักสูตรปริญญาตรีแบบก้าวหน้าทางปฏิบัติการ',
} as const;
