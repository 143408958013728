import { UniversitySummaryCount } from '@vru/master-data';
import { AlertService } from '@vru/services';
import { News, NewsFeedService, NewsFeedParams, ErrorHeader, MasterDataApiService, IMasterDataFaculty } from '@vru/master-data';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'homepage-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss'],
})
export class NewsFeedComponent implements OnInit {

  newsList: News[] = [];
  facultyList: IMasterDataFaculty[] = [];
  summaryCountData!: UniversitySummaryCount;
  isNewsLoading = false;
  isFacultyLoading = false;

  constructor(
    private router: Router,
    private newsFeedService: NewsFeedService,
    private alertService: AlertService,
    private masterDataService: MasterDataApiService
  ) {}

  ngOnInit(): void {
    this.getNewsList();
    this.getFacultyList();
    this.getUniversitySummary();
  }

  getUniversitySummary() {
    this.newsFeedService.getSummaryCount().subscribe({
      next: (res) => {
        this.summaryCountData = res;
      },
      error: (err: ErrorHeader) => {
        if (err.error) {
          this.alertService.error(err.error.detail, 10000)
        }
      }
    })
  }

  getNewsList() {
    this.isNewsLoading = true;
    const payload: NewsFeedParams = {
      order_by: '-created_at',
      page: 1,
      page_size: 6
    }
    this.newsFeedService.getNews(payload).subscribe({
      next: (res) => {
        this.newsList = res.results;
        this.isNewsLoading = false;
      },
      error: (err: ErrorHeader) => {
        if (err.error) {
          this.alertService.error(err.error.detail, 10000)
        }
        console.error(err);
        this.isNewsLoading = false;
      }
    })
  }

  getFacultyList() {
    this.isFacultyLoading = true;
    this.masterDataService.getDropdownFaculty().subscribe({
      next: (res) => {
        this.facultyList = res;
        this.isFacultyLoading = false;
      },
      error: (err: ErrorHeader) => {
        if (err.error) {
          this.alertService.error(err.error.detail, 10000)
        }
        this.isFacultyLoading = false;       
      }
    })
  }

  goNewsDetail(newsId: number) {
    this.router.navigate(['news-feed/news/' + newsId]);
  }

  goNewsList() {
    this.router.navigate(['news-feed/news-list']);
  }

  goSearchInformation(type: string) {
    this.router.navigate(['search-board/' + type]);
  }
}

export interface SearchButtonConfig {
  navigatePath: string;
  title: string;
  icon?: string;
}