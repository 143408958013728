import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import {
  AlertMessages,
  CommonApiRes,
  Day,
  Dropdown,
  DropdownApiService,
  ErrorMessages,
  ExportGradeFormParams,
  GradeCalculationMethod,
  GradeDeadline,
  GradeRange,
  GradeSetting,
  GradeSubmission,
  GradeSubmissionService,
  GradeSubmissionStatus,
  Roles,
  StudentScore,
  ThaiShortDay,
} from '@vru/master-data';
import {
  AlertService,
  ComponentSubscriptionService,
  DisplayPermissionService,
  GradeScoreLowerBoundary,
  GradeService,
  StatisticsService,
} from '@vru/services';
import { UtilityService, convertError } from '@vru/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  AsyncSubject,
  combineLatest,
  EMPTY,
  from,
  Observable,
  of,
  Subject,
  throwError,
} from 'rxjs';
import { share, startWith, switchMap } from 'rxjs/operators';
import { ConfirmationModalComponent } from '../../../confirmation-modal/confirmation-modal.component';
import { GradeDisplayPermissionService } from './grade-display-permission.service';
import { GradeSubmissionResolve } from './grade-submission-detail.resolver';

@Component({
  selector: 'vru-grade-submission-detail',
  templateUrl: './grade-submission-detail.component.html',
  styleUrls: ['./grade-submission-detail.component.scss'],
  providers: [
    ComponentSubscriptionService,
    {
      provide: DisplayPermissionService,
      useClass: GradeDisplayPermissionService,
    },
  ],
})
export class GradeSubmissionDetailComponent implements OnInit, OnDestroy {
  submissionId!: number;
  gradeMethodDropdown!: Dropdown[];
  gradeDeadlines!: GradeDeadline[];
  gradeSettingForm!: FormGroup;
  enrollmentForm!: FormGroup;
  options?: { ignorePayment?: boolean } = { ignorePayment: false };
  pnpGradeRangeForm!: FormArray;
  scaleGradeRangeForm!: FormArray;
  isApproving = false;
  isCanSubmit = true;
  isLoading = false;
  isRejecting = false;
  isInSubmitPeriod = false;
  isGradeSettingEditMode = false;
  thaiShortDays = ThaiShortDay;
  days = Day;

  readonly gradeRangesDefault: InitialGradeRange[] = [
    {
      name: 'A',
      max_value: 100,
    },
    {
      name: 'B+',
      max_value: 80,
    },
    {
      name: 'B',
      max_value: 75,
    },
    {
      name: 'C+',
      max_value: 70,
    },
    {
      name: 'C',
      max_value: 65,
    },
    {
      name: 'D+',
      max_value: 60,
    },
    {
      name: 'D',
      max_value: 55,
    },
    {
      name: 'F',
      max_value: 50,
    },
  ];
  readonly gradeRangePassDefault: InitialGradeRange[] = [
    {
      name: 'PD',
      max_value: 100,
    },
    {
      name: 'P',
      max_value: 80,
    },
    {
      name: 'NP',
      max_value: 50,
    },
  ];
  readonly numberInputValidator = [
    Validators.pattern(/^-?(\d{0,5}$|([0-9.]{1,6})$)/),
    Validators.pattern(/^-?(\d{0,5}$|\d*\.\d{1,2}$)/), 
  ];
  readonly numberInputValidatorWithInf = [
    Validators.pattern(/^-?(\d{0,5}$|([0-9.]{1,6})$)|Infinity/),
    Validators.pattern(/^-?(\d{0,5}$|\d*\.\d{1,2}$)|Infinity/), 
  ];

  gradeSubmissionDetail?: GradeSubmission;
  params!: Params;
  prevGradeSetting?: GradeSetting;

  orderScoreValidator = (control: AbstractControl): ValidationErrors | null => {
    const gradeRanges = control.value as GradeRange[];
    const test = gradeRanges.reduce((errObj, gradeRange, index, arr) => {
      const nextVal = arr[index + 1];
      if (gradeRange.max_value <= (nextVal && nextVal.max_value)) {
        if (errObj == null) {
          errObj = { orderScore: {} };
        }
        errObj.orderScore[index] = { scoreLessThanNextGrade: true };
      }
      return errObj;
    }, null as { [k: string]: any } | null);
    return test;
  };

  constructor(
    private comSubs: ComponentSubscriptionService,
    private grade: GradeService,
    private modal: NgbModal,
    private gradeSubmission: GradeSubmissionService,
    private apiDropdownService: DropdownApiService,
    private ngxSpinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private statistics: StatisticsService,
    private store: Store,
    private util: UtilityService
  ) {
    this.initialScaleGradeRangesForm();
    this.initialPnpGradeRangeForm();
    this.initializeGradeSettingForm();
    this.initialEnrollmentForm();
    this.initializeGradeRangeValidators();
  }

  ngOnInit(): void {
    this.subscribeActivatedRoute();
    this.fetchGradeMethodDropdown();
    this.onSpinnerShow();
  }

  ngOnDestroy(): void {
    this.comSubs.destroy();
  }

  approveGradeSubmission(): Observable<CommonApiRes> {
    if (!this.submissionStatus) {
      return throwError({ error: 'The submissionStatus is undefined' });
    }
    this.isApproving = true;
    const subject = new AsyncSubject<CommonApiRes>();
    subject.subscribe({
      next: (res) => {
        this.isApproving = false;
        this.alertService.success(res.detail_th || AlertMessages.success.approval);
      },
      error: (err: HttpErrorResponse) => {
        this.isApproving = false;
        if (err.status === 500) return;
        this.alertService.error(
          err.error['detail_th'] || ErrorMessages.common.plsCheckOrAdmin
        );
      },
    });
    this.gradeSubmission
      .approve(this.submissionId, this.submissionStatus)
      .subscribe(subject);
    return subject;
  }

  checkInPeriod(): boolean {
    const today = new Date().getTime();
    return this.gradeDeadlines.some((deadline) => {
      const startDate = new Date(deadline.start_date).setHours(0,0,0,0)
      const endDate = new Date(deadline.end_date).setHours(23,59,59,59)
      return startDate < today && endDate >= today;
    });
  }

  checkPermission(): void {
    if (!this.canEdit) {
      this.enrollmentForm.disable({ emitEvent: false });
    } else if (this.enrollmentForm.disabled) {
      this.enrollmentForm.enable({ emitEvent: false });
    }
  }

  initialize(data: GradeSubmissionResolve): void {
    const gradeSub = data.detail;
    this.initializeGradeSettingForm(
      { ...gradeSub.grade_setting, course_section: gradeSub.id },
      { recalculate: false }
    );
    this.initialEnrollmentForm(gradeSub.enrollments);
    const method = gradeSub.grade_setting?.calculate_grade_method || 'pass_or_not_pass';
    this.setGradeRangeFormByMethod(method, { emitEvent: false });
    this.gradeRangeFormArr.patchValue([
      Object.assign(gradeSub.grade_setting?.grade_ranges[0] || {}, {
        max_value: ['t_score', 'mead_sd'].includes(method)
          ? Infinity
          : gradeSub.grade_setting?.total_score || 100,
      }),
      ...(gradeSub.grade_setting?.grade_ranges?.slice(1) || []),
    ]);
    this.checkPermission();
    this.isInSubmitPeriod = this.checkInPeriod();
    if (!this.isInSubmitPeriod) {
      this.enrollmentForm.disable({ emitEvent: false });
      this.gradeSettingForm.disable({ emitEvent: false });
    }
  }

  getMaxScoreValidator(limitControl?: AbstractControl | null) {
    return (control: AbstractControl): ValidationErrors | null => {
      const maxScore = limitControl?.value;
      return control.value > maxScore
        ? {
            maxScore: { excessMaxScore: true },
          }
        : null;
    };
  }

  getMeanSdGradeRange(): Partial<GradeRange>[] {
    const zScoreGradeBoundaries = this.grade.zScoreGradeBoundaries;
    const gradeRanges = this.scaleGradeRangeForm.value as GradeRange[];
    const gradeBoundaries = gradeRanges.map((gradeRange, index, arr) => {
      return {
        grade: gradeRange.name,
        zScore: arr[index + 1]?.max_sd || zScoreGradeBoundaries[index]?.zScore,
      } as GradeScoreLowerBoundary;
    });
    return this.getNormCurveGradeRange(gradeBoundaries);
  }

  /**
   * Get grade ranges by normal distribution (Z-scores) or (T-scores).
   * @param gradeBoundary The lower boundary of each grade.
   */
  getNormCurveGradeRange(
    gradeBoundary: GradeScoreLowerBoundary[]
  ): Partial<GradeRange>[] {
    let enrollments = this.enrollmentDetailFormArr.value as StudentScore[];
    if (!this.options?.ignorePayment) {
      enrollments = enrollments.filter(
        (enrollment) => enrollment.payment_status !== 'unpaid'
      );
    }
    const sampleScores = enrollments.map(
      (enrollment) => enrollment.total_score
    );
    const mean = this.statistics.getMean(sampleScores);
    const sd = this.statistics.getStandardDeviation(sampleScores, mean);
    gradeBoundary.forEach((boundary) => {
      if (boundary.zScore != null) {
        const score = boundary.zScore * sd + mean;
        boundary.score = this.util.roundDecimals(score, 2)
        return;
      }
    });
    const gradeRanges = gradeBoundary.map(
      (boundary, index, arr) =>
        ({
          name: boundary.grade,
          max_sd: index === 0 ? null : arr[index - 1]?.zScore,
          max_value: index === 0 ? Infinity : arr[index - 1]?.score,
        } as Partial<GradeRange>)
    );
    return gradeRanges;
  }

  getZScoreGradeRange(): Partial<GradeRange>[] {
    const zScoreGradeBoundaries = this.grade.zScoreGradeBoundaries;
    return this.getNormCurveGradeRange([
      ...zScoreGradeBoundaries,
      {
        grade: 'F',
        score: NaN,
        zScore: null,
      },
    ]);
  }

  onApproveClick(): void {
    const modal = this.alertService
      .confirm(
        'ยืนยันการอนุมัติเกรด',
        'ท่านต้องการยืนยันการอนุมัติเกรดใช่หรือไม่ ?',
        'warning',
        'Confirm'
      )
    from(modal)
      .pipe(
        switchMap((res) => {
          if (res.isDismissed) return EMPTY;
          return this.approveGradeSubmission();
        })
      )
      .subscribe({
        next: () => {
          this.reactivateRoute();
        },
      });
  }

  onGradeSettingCancel(): void {
    this.isGradeSettingEditMode = false;
    if (!this.prevGradeSetting) {
      throw new Error(
        'The previous grade setting is undefined. The issue should not be happened'
      );
    }
    this.setGradeRangeFormByMethod(
      this.prevGradeSetting.calculate_grade_method
    );
    this.gradeSettingForm.patchValue(this.prevGradeSetting);
    this.gradeRangeFormArr.controls.forEach((control) =>
      control.get('max_value')?.updateValueAndValidity({ emitEvent: false })
    );
    this.updateStudentGrades();
  }

  onGradeSettingSubmit(): void {
    const modal = this.modal.open(ConfirmationModalComponent);
    modal.componentInstance.body =
      'การบันทึกการตั้งค่าเกรดจะถูกปรับใช้กับนักศึกษาในระบบโดยอัติโนมัติ\nคุณแน่ใจหรือไม่';
    modal.componentInstance.theme = 'warning';
    modal.result
      .then(() => {
        this.updateStudentGrades();
        this.updateGradeSetting();
      })
      .catch(() => {
        // It's dismiss
      });
  }

  onGradeCalculationMethodChange(method: keyof typeof GradeCalculationMethod) {
    this.setGradeRangeFormByMethod(method);
    this.updateStudentGrades();
  }

  onGradeSettingEditModeClick(): void {
    this.isGradeSettingEditMode = true;
    this.prevGradeSetting = this.gradeSettingForm.value as GradeSetting;
  }

  fetchGradeMethodDropdown() {
    this.comSubs.add = this.apiDropdownService
      .getDropdown('calculate_grade_method')
      .subscribe({
        next: (response) => (this.gradeMethodDropdown = response),
      });
  }

  onRejectClick(): void {
    const modal = this.alertService
      .confirm(
        'ยืนยันการปฏิเสธการอนุมัติเกรด',
        'ท่านต้องการยืนยันการปฏิเสธการอนุมัติเกรดใช่หรือไม่ ?',
        'error',
        'Confirm'
      )
    from(modal)
      .pipe(
        switchMap((res) => {
          if (res.isDismissed) return EMPTY;
          return this.rejectGradeSubmission();
        })
      )
      .subscribe({
        next: () => {
          this.reactivateRoute();
        },
      });
  }

  onSpinnerShow() {
    this.ngxSpinnerService.show('grade-results-detail');
  }

  onSubmitGrade() {
    this.alertService
      .confirm(
        'ยืนยันการส่งเกรด',
        'ท่านต้องการยืนยันการส่งเกรดใช่หรือไม่ ?',
        'warning',
        'Confirm'
      )
      .then((res) => {
        if (!res.isConfirmed) {
          return;
        }
        this.gradeSubmission.onSubmitGrade(this.submissionId, null).subscribe({
          next: () => {
            this.alertService.success('ดำเนินการส่งเกรดแล้ว');
            this.isLoading = false;
            this.reactivateRoute();
          },
          error: (err) => {
            console.error(err);
            this.isLoading = false;
            this.alertService.error('ไม่สามารถบันทึกเกรดได้');
          },
        });
      });
  }

  submitPrint(type: 'pdf') {
    const payload: ExportGradeFormParams = {
      export_as: type,
      course_section: this.submissionId,
    };
    this.gradeSubmission.onPrintGrade(payload).subscribe({
      next: (res) => {
        this.util.downloadFile(res, 'ใบกรอกผลการศึกษา');
      },
      error: async (err) => {
        if (err.error) {
          this.alertService.error(await convertError(err), 5000);
        }
      },
    });
  }

  onUpdateEnrollment(): void {
    if (this.enrollmentForm.invalid) {
      this.alertService.error('กรุณากรอกข้อมูลให้ถูกต้อง');
      return;
    }
    if (!this.hasGradeRange) {
      this.updateGradeSetting();
      return;
    }
    this.updateStudentInfos();
  }

  onCreateGradeSetting(data: GradeSetting): Observable<GradeSetting> {
    const subject = new Subject<GradeSetting>();
    this.gradeSubmission.onCreateGradeSetting(data).subscribe(subject);
    subject.subscribe({
      next: (res) => {
        this.gradeSettingForm.setControl(
          'id',
          this.formBuilder.control(res.id)
        );
        if (this.gradeSubmissionDetail) {
          this.gradeSubmissionDetail.grade_setting = {
            ...res,
            total_score: res.final_score + res.midterm_score,
          };
        }
        this.alertService.success('บันทึกเกรดสำเร็จ');
        this.isLoading = false;
      },
      error: (err) => {
        const errMsg =
          err.error?.detail_th ||
          err.error?.detail ||
          this.savingGradeFailedErrMsg;
        this.alertService.error(errMsg, 3000);
        this.isLoading = false;
        console.error(err);
      },
    });
    return subject;
  }

  onUpdateGradeSetting(data: GradeSetting, id: number) {
    const call = this.gradeSubmission
      .onUpdateGradeSetting(data, id)
      .pipe(share());
    call.subscribe({
      next: () => {
        this.alertService.success('บันทึกเกรดสำเร็จ');
        this.isLoading = false;
      },
      error: (err) => {
        const errMsg =
          err.error?.detail_th ||
          err.error?.detail ||
          this.savingGradeFailedErrMsg;
        this.alertService.error(errMsg, 3000);
        this.isLoading = false;
        console.error(err);
      },
    });
    return call;
  }

  onBackToListGrade(): void {
    this.router.navigate(['../'], {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
    });
  }

  /**
   *
   * @param gradeSetting
   * @param options
   */
  initializeGradeSettingForm(
    gradeSetting?: Partial<GradeSetting> | null,
    options?: { recalculate: boolean }
  ) {
    const midtermScore = gradeSetting?.midterm_score || 50;
    const finalScore = gradeSetting?.final_score || 50;
    const numberValidator = [
      Validators.required,
      ...this.numberInputValidator,
    ];
    this.gradeSettingForm = this.formBuilder.group({
      course_section: [gradeSetting?.course_section, [Validators.required]],
      midterm_score: [midtermScore, numberValidator],
      final_score: [finalScore, numberValidator],
      total_score: [midtermScore + finalScore, numberValidator],
      calculate_grade_method: [
        gradeSetting?.calculate_grade_method || 'pass_or_not_pass',
        [Validators.required],
      ],
      calculate_grade_method_display: [
        gradeSetting?.calculate_grade_method_display,
      ],
    });
    if (options?.recalculate !== false) {
      this.setGradeRangeFormByMethod(
        gradeSetting?.calculate_grade_method || 'pass_or_not_pass',
        { emitEvent: false }
      );
    }
    if (gradeSetting?.id != null) {
      this.gradeSettingForm.setControl('id', new FormControl(gradeSetting.id));
    }
    this.subscribeMaxScoreSetting();
  }

  initialScaleGradeRangesForm(): FormArray {
    this.scaleGradeRangeForm = this.formBuilder.array(
      this.gradeRangesDefault.map((item, index) => {
        return this.buildGradeRangesForm(item, {
          requiredThreshold: index !== 0,
        });
      })
    );
    this.scaleGradeRangeForm.valueChanges.subscribe({
      next: () => {
        this.updateStudentGradeAsScale();
      },
    });
    return this.scaleGradeRangeForm;
  }

  initialPnpGradeRangeForm(): FormArray {
    this.pnpGradeRangeForm = this.formBuilder.array(
      this.gradeRangePassDefault.map((item) => {
        return this.buildGradeRangesForm(item);
      })
    );
    this.pnpGradeRangeForm.valueChanges.subscribe({
      next: () => {
        this.updateStudentGradeAsPnp();
      },
    });
    return this.pnpGradeRangeForm;
  }

  buildGradeRangesForm(
    initialForm?: Partial<GradeRange> | null,
    options: { requiredThreshold: boolean } = { requiredThreshold: true }
  ): FormGroup {
    const thresholdValidator = [...this.numberInputValidatorWithInf];
    if (options.requiredThreshold) {
      thresholdValidator.push(Validators.required);
    }
    const gradeRanges = this.formBuilder.group({
      id: [initialForm?.id],
      name: [initialForm?.name, [Validators.required]],
      max_sd: [initialForm?.max_sd],
      max_value: [initialForm?.max_value, thresholdValidator],
    });
    return gradeRanges;
  }

  initialEnrollmentForm(initEnrollment?: Partial<StudentScore>[]) {
    this.enrollmentForm = this.formBuilder.group({
      enrollments: this.formBuilder.array(
        (initEnrollment || []).map((item) => this.buildEnrollmentForm(item))
      ),
    });
  }

  initializeGradeRangeValidators(): void {
    this.resetGradeRangeValidator('pnpGradeRangeForm', {
      requiredMaxScore: true,
      requiredScoreThreshold: true,
    });
    const method = this.gradeSettingForm.get('calculate_grade_method')
      ?.value as keyof typeof GradeCalculationMethod;
    this.resetGradeRangeValidator('scaleGradeRangeForm', {
      requiredMaxScore: method === 'fix_rate',
      requiredScoreThreshold: method === 'fix_rate',
      requiredSdThreshold: method === 'mean_sd',
    });
  }

  onGradeSdChange(typing: string): void {
    if (typing === '') {
      return;
    }
    const maxValues = this.getMeanSdGradeRange().map((gradeRange, index) => {
      if (index === 0) {
        return {};
      }
      return {
        max_value: index ? gradeRange.max_value : undefined,
      };
    });
    this.gradeRangeFormArr.patchValue(maxValues, {
      emitEvent: false,
    });
    this.updateStudentGrades();
  }

  reactivateRoute(): void {
    this.router.navigate(['./'], {
      relativeTo: this.activatedRoute,
      queryParams: { refresh: Date.now() },
      replaceUrl: true,
    });
  }

  rejectGradeSubmission(): Observable<CommonApiRes> {
    if (!this.submissionStatus) {
      return throwError({ error: 'The submissionStatus is undefined' });
    }
    this.isRejecting = true;
    const subject = new AsyncSubject<CommonApiRes>();
    subject.subscribe({
      next: (res) => {
        this.isRejecting = false;
        this.alertService.success(res.detail_th || AlertMessages.success.reject);
      },
      error: (err: HttpErrorResponse) => {
        this.isRejecting = false;
        if (err.status === 500) {
          return;
        } else if (err.status === 403) {
          this.alertService.noPermit();
          return;
        }
        console.error(err);
        this.alertService.error(ErrorMessages.common.plsCheckOrAdmin);
      },
    })
    this.gradeSubmission
      .reject(this.submissionId, this.submissionStatus)
      .subscribe(subject);
    return subject;
  }

  resetGradeRange(): void {
    if (!this.isGradeSettingEditMode) {
      this.onGradeSettingEditModeClick();
    }
    const gradeMethod = this.gradeMethodForm
      .value as keyof typeof GradeCalculationMethod;
    if (gradeMethod === 'pass_or_not_pass') {
      this.pnpGradeRangeForm.patchValue(this.gradeRangePassDefault);
    } else {
      this.scaleGradeRangeForm.patchValue(this.gradeRangesDefault);
    }
    this.onGradeCalculationMethodChange(gradeMethod);
  }

  buildEnrollmentForm(item?: Partial<StudentScore>): FormGroup {
    const form = this.formBuilder.group({
      id: [],
      has_scholarship: [],
      payment_status: [],
      payment_status_display: [],
      student: [],
      midterm_score: [
        null,
        [
          this.getMaxScoreValidator(this.gradeSettingForm.get('midterm_score')),
          ...this.numberInputValidator,
        ],
      ],
      final_score: [
        null,
        [
          this.getMaxScoreValidator(this.gradeSettingForm.get('final_score')),
          ...this.numberInputValidator,
        ],
      ],
      total_score: [
        null,
        this.getMaxScoreValidator(this.gradeSettingForm.get('total_score')),
      ],
      grade: [],
      is_incomplete_grade: [false],
    });
    combineLatest([
      form.get('midterm_score')?.valueChanges || of(0),
      form.get('final_score')?.valueChanges || of(0),
    ]).subscribe({
      next: (res) => {
        const round = this.util.roundDecimals;
        const total_score =
          round(parseFloat(res[0]) || 0, 2) + round(parseFloat(res[1]) || 0, 2);
        form.patchValue({ total_score }, { emitEvent: false });
        const gradeSetting = this.gradeSettingForm.value as GradeSetting;
        if (gradeSetting.calculate_grade_method === 'pass_or_not_pass') {
          this.updateStudentGradeAsPnp(form);
          return;
        } else if (gradeSetting.calculate_grade_method === 'mean_sd') {
          this.setGradeRangeFormByMethod('mean_sd');
        } else if (gradeSetting.calculate_grade_method === 't_score') {
          this.setGradeRangeFormByMethod('t_score');
        }
        this.updateStudentGradeAsScale(form);
      },
    });
    form.patchValue(item || {});
    return form;
  }

  /**
   * Reset scaleGradeRangeForm to default validation (only pattern validator)
   * or according to options configuration.
   * @param options the default following of all option is `false`
   */
  resetGradeRangeValidator(
    formName: 'scaleGradeRangeForm' | 'pnpGradeRangeForm',
    options: ResetScaleValidatorParams = {
      requiredMaxScore: false,
      requiredScoreThreshold: false,
      requiredSdThreshold: false,
    }
  ) {
    const targetForm = this[formName];
    const scoreValidators = [...this.numberInputValidatorWithInf];
    const sdValidators = [
      // REGEX to validate number pattern
      ...this.numberInputValidator,
    ];
    if (options.requiredScoreThreshold) {
      scoreValidators.push(Validators.required);
    }
    if (options.requiredMaxScore) {
      const maxScoreCtrl = this.gradeSettingForm.get('total_score');
      scoreValidators.push(this.getMaxScoreValidator(maxScoreCtrl));
    }
    targetForm.controls.forEach((control, i) => {
      if (i === 0) return;
      control.get('max_value')?.setValidators(scoreValidators);
      if (options.requiredSdThreshold) {
        sdValidators.push(Validators.required);
      }
      control.get('max_sd')?.setValidators(sdValidators);
    });
    targetForm.setValidators(this.orderScoreValidator);
    targetForm.updateValueAndValidity({ emitEvent: false });
  }

  setGradeRangeFormByMethod(
    method: keyof typeof GradeCalculationMethod,
    options?: { emitEvent?: boolean }
  ) {
    const forms = this.gradeSettingForm as FormGroup;
    if (method === 'pass_or_not_pass') {
      forms.setControl('grade_ranges', this.pnpGradeRangeForm, options);
    } else if (method === 'fix_rate') {
      // Set on-top condition to equal max score of setting.
      this.resetGradeRangeValidator('scaleGradeRangeForm', {
        requiredMaxScore: true,
        requiredSdThreshold: false,
        requiredScoreThreshold: true,
      });
      this.scaleGradeRangeForm.patchValue(
        [{ max_value: this.gradeSettingForm.get('total_score')?.value }],
        { emitEvent: false }
      );
      forms.setControl('grade_ranges', this.scaleGradeRangeForm, options);
    } else {
      forms.setControl('grade_ranges', this.scaleGradeRangeForm, {
        emitEvent: false,
      });
      try {
        const gradeRanges =
          method === 'mean_sd'
            ? this.getMeanSdGradeRange()
            : this.getZScoreGradeRange();
        gradeRanges[0] = { max_value: Infinity };
        this.gradeRangeFormArr.patchValue(gradeRanges, options);
      } catch (error) {
        console.warn(
          (error instanceof Error ? error.message : error) +
            '. So, could be not calculate grade ranges by mean-SD or Z-scores.'
        );
        this.gradeRangeFormArr.patchValue([
          { name: 'A', max_sd: null, max_value: null },
          { name: 'B+', max_sd: null, max_value: null },
          { name: 'B', max_sd: null, max_value: null },
          { name: 'C+', max_sd: null, max_value: null },
          { name: 'C', max_sd: null, max_value: null },
          { name: 'D+', max_sd: null, max_value: null },
          { name: 'D', max_sd: null, max_value: null },
          { name: 'F', max_sd: null, max_value: null },
        ]);
      }
      this.resetGradeRangeValidator('scaleGradeRangeForm', {
        requiredMaxScore: false,
        requiredSdThreshold: method === 'mean_sd',
        requiredScoreThreshold: false,
      });
    }
  }

  checkDeadlineEmpty(): void {
    if (!this.gradeDeadlines.length) {
      this.alertService.warn('ไม่พบวันส่งเกรดในปฏิทินการศึกษา', 5000);
    }
  }

  private subscribeActivatedRoute(): void {
    this.activatedRoute.data.subscribe({
      next: (data) => {
        const info = data['result'] as GradeSubmissionResolve;
        this.gradeSubmissionDetail = info.detail;
        this.gradeDeadlines = info.deadline;
        this.checkDeadlineEmpty();
        this.initialize(info);
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        if (err.status === 500) return;
        if (err.status === 401) {
          this.alertService.noPermit();
        }
        this.alertService.errorWithContactAdmin();
      },
    });
    this.activatedRoute.params.subscribe({
      next: (params) => {
        this.params = params;
        this.submissionId = params.id;
      },
    });
  }

  subscribeMaxScoreSetting(): void {
    const midtermScoreForm = this.gradeSettingForm.get('midterm_score');
    const finalScoreForm = this.gradeSettingForm.get('final_score');
    const totalScoreForm = this.gradeSettingForm.get('total_score');
    if (!midtermScoreForm) {
      throw new Error(
        'Not found formControl of midterm score setting. The total score will not able to update'
      );
    }
    if (!finalScoreForm) {
      throw new Error(
        'Not found formControl of final score setting. The total score will not able to update'
      );
    }
    if (!totalScoreForm) {
      throw new Error(
        'Not found formControl of total score setting. The total score setting input will be not updated'
      );
    }
    combineLatest([
      midtermScoreForm.valueChanges.pipe(startWith(midtermScoreForm.value)),
      finalScoreForm.valueChanges.pipe(startWith(finalScoreForm.value)),
    ]).subscribe({
      next: (values) => {
        const totalScore = values[0] + values[1];
        totalScoreForm.setValue(totalScore, { emitEvent: false });
        if (this.enrollmentForm && this.enrollmentDetailFormArr) {
          this.enrollmentDetailFormArr.controls.forEach((control) =>
            control
              .get('total_score')
              ?.updateValueAndValidity({ emitEvent: false })
          );
        }
      },
    });
  }

  /**
   * Update the grade of enrollment control by calculating from grade ranges.
   * @param options.enrollmentControl The control or index of enrollment control wanted to update.
   * If the function is called without argument. It will update all enrollment.
   * @param options.gradeRangeCondition The grade range condition is used for grade calculation.
   */
  updateGrade(options: {
    enrollmentControl?: number | FormGroup;
    gradeRangeCondition?: GradeRange[];
  }) {
    const gradeRangeCondition =
      options.gradeRangeCondition ||
      (this.gradeRangeFormArr.value as GradeRange[]);
    let enrollmentForms: FormGroup[] = [];
    if (typeof options.enrollmentControl == 'number') {
      const oneUpdateForm = this.enrollmentForm.get(
        `enrollment.${options.enrollmentControl}`
      ) as FormGroup | null;
      if (!oneUpdateForm) {
        console.warn(
          `Update PNP grade is ignored because of not found form at index ${options.enrollmentControl}`
        );
        return;
      }
      enrollmentForms = [oneUpdateForm];
    } else if (typeof options.enrollmentControl === 'object') {
      enrollmentForms = [options.enrollmentControl];
    } else {
      enrollmentForms = this.enrollmentDetailFormArr.controls as FormGroup[];
    }
    enrollmentForms.forEach((form) => {
      const enrollment = form.value as StudentScore;
      const paymentStatus = enrollment.payment_status;
      if (!this.options?.ignorePayment && paymentStatus === 'unpaid') {
        form.patchValue({ grade: '' });
        return;
      }
      for (let i = gradeRangeCondition.length - 1; i >= 0; i--) {
        const maxScore = gradeRangeCondition[i].max_value;
        if (maxScore != null) {
          if (maxScore > enrollment.total_score) {
            form.patchValue({ grade: gradeRangeCondition[i].name });
            break;
          }
        }
      }
      // For case max score less than or equal total score of student.
      if (gradeRangeCondition[0].max_value <= enrollment.total_score) {
        form.patchValue({ grade: gradeRangeCondition[0].name });
      }
    });
  }

  updateGradeSetting(): void {
    of(this.gradeSettingForm.value)
      .pipe(
        switchMap((data) => {
          if (data.id == null) {
            return this.onCreateGradeSetting(data);
          }
          return this.onUpdateGradeSetting(data, data.id);
        })
      )
      .subscribe({
        next: () => {
          this.isGradeSettingEditMode = false;
          this.updateStudentInfos();
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  updateStudentGrades(enrollmentControl?: number | FormGroup): void {
    const gradeRangeCondition = this.gradeRangeFormArr.value as GradeRange[];
    this.updateGrade({ enrollmentControl, gradeRangeCondition });
  }

  updateStudentGradeAsPnp(enrollmentControl?: number | FormGroup): void {
    const gradeRangeCondition = this.pnpGradeRangeForm.value as GradeRange[];
    this.updateGrade({ enrollmentControl, gradeRangeCondition });
  }

  updateStudentGradeAsScale(enrollmentControl?: number | FormGroup): void {
    const gradeRangeCondition = this.scaleGradeRangeForm.value as GradeRange[];
    this.updateGrade({ enrollmentControl, gradeRangeCondition });
  }

  updateStudentInfos(): void {
    this.isLoading = true;
    const data = this.enrollmentForm.getRawValue();
    if (!this.gradeSubmissionDetail) {
      throw new Error(
        'The gradeSubmissionDetail is undefined. Cannot update enrollment'
      );
    }
    this.gradeSubmission
      .onUpdateEnrollment(this.gradeSubmissionDetail.id, data)
      .subscribe({
        next: (res) => {
          this.isCanSubmit = res.can_submit_grade;
          this.enrollmentForm.patchValue(res);
          this.enrollmentForm.markAsPristine();
          this.alertService.success('บันทึกเกรดนักศึกษาสำเร็จ', 2000);
          this.isLoading = false;
        },
        error: (err: HttpErrorResponse) => {
          const errMsg =
            err.error?.detail_th ||
            err.error?.detail ||
            this.savingStudentInfoFailedMsg;
          this.alertService.error(errMsg, 3000);
          this.isLoading = false;
          console.error(err);
        },
      });
  }

  get canEdit(): boolean {
    return (
      (
        this.role === 'teacher' &&
        (
          this.submissionStatus === 'wait_for_professor_submit_grade' ||
          this.submissionStatus === 'revised'
        )
      ) ||
      (
        this.role === 'backoffice' &&
        this.submissionStatus === 'wait_for_backoffice_approval'
      ) ||
      this.role === 'admin'
    );
  }

  get enrollmentDetailFormArr(): FormArray {
    return this.enrollmentForm.get('enrollments') as FormArray;
  }

  get gradeMethodForm(): FormControl {
    return this.gradeSettingForm.get('calculate_grade_method') as FormControl;
  }

  get gradeRangeFormArr(): FormArray {
    return this.gradeSettingForm.get('grade_ranges') as FormArray;
  }

  get hasGradeRange(): boolean {
    return !!this.gradeSubmissionDetail?.grade_setting?.grade_ranges?.length;
  }

  get isWaitForApproval(): boolean {
    return ['wait_for_backoffice_approval', 'wait_for_dean_approval'].includes(
      this.submissionStatus || ''
    );
  }

  get isFixGradeMethod(): boolean {
    return ['fix_rate', 'pass_or_not_pass'].includes(this.gradeMethodForm.value)
  }

  get role(): Roles {
    return this.store.selectSnapshot((state) => state.auth?.role);
  }

  get savingGradeFailedErrMsg(): string {
    return (
      ErrorMessages.gradeSubmissionError.updateGradeSetting +
      ' ' +
      ErrorMessages.common.plsCheckOrAdmin
    );
  }

  get savingStudentInfoFailedMsg(): string {
    return (
      ErrorMessages.gradeSubmissionError.updateStudent +
      ' ' +
      ErrorMessages.common.plsCheckOrAdmin
    );
  }

  get submissionStatus(): GradeSubmissionStatus | undefined {
    return this.gradeSubmissionDetail?.grade_submission_status;
  }
}

interface ResetScaleValidatorParams { 
  requiredScoreThreshold?: boolean;
  requiredMaxScore?: boolean;
  requiredSdThreshold?: boolean; 
}

export type InitialGradeRange = Pick<GradeRange, 'name' | 'max_value'>;
