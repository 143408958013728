import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Program, ProgramModel } from '@vru/master-data';
import { ProgramTeacherService } from './program-teacher.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  constructor(
    private fb: FormBuilder,
    private programTeacher: ProgramTeacherService
  ) {}

  buildProgramForm(initial?: ProgramModel): FormGroup {
    return this.fb.group({
      academic_council_approved_date: [
        { value: initial?.academic_council_approved_date, disabled: true },
      ],
      code: [{ value: initial?.code, disabled: true }],
      credit: [
        { value: initial?.credit || 0, disabled: true },
        [Validators.required],
      ],
      degree: [
        { value: initial?.degree, disabled: true },
        [Validators.required],
      ],
      faculty: [
        { value: initial?.faculty, disabled: true },
        [Validators.required],
      ],
      first_class_grade: [
        { value: initial?.first_class_grade, disabled: true },
        [Validators.required],
      ],
      id: [{ value: initial?.id, disabled: !initial?.id }, Validators.required],
      in_use: [{ value: initial?.in_use, disabled: true }, Validators.required],
      lecture_seat: [
        { value: initial?.lecture_seat, disabled: true },
        [Validators.required],
      ],
      min_grade: [
        { value: initial?.min_grade, disabled: true },
        [Validators.required],
      ],
      name: [{ value: initial?.name, disabled: true }, [Validators.required]],
      ocsc_approve_date: [
        { value: initial?.ocsc_approve_date, disabled: true },
      ],
      ops_approved_date: [
        { value: initial?.ops_approved_date, disabled: true },
      ],
      otepc_approve_date: [
        { value: initial?.otepc_approve_date, disabled: true },
      ],
      practice_seat: [
        { value: initial?.practice_seat, disabled: true },
        [Validators.required],
      ],
      program_responsible_teachers: this.fb.array([
        this.programTeacher.buildProgramResponsibleForm(),
      ]),
      program_teachers: this.fb.array([
        this.programTeacher.buildProgramResponsibleForm(),
      ]),
      program_type: [
        { value: initial?.program_type, disabled: true },
        [Validators.required],
      ],
      qualification: [
        { value: initial?.qualification, disabled: true },
        [Validators.required],
      ],
      retire_grade_1: [
        { value: initial?.retire_grade_1 || 0, disabled: true },
        [Validators.required],
      ],
      retire_grade_2: [
        { value: initial?.retire_grade_2 || 0, disabled: true },
        [Validators.required],
      ],
      second_class_grade: [
        { value: initial?.second_class_grade || 0, disabled: true },
        [Validators.required],
      ],
      subcommittee_approved_date: [
        { value: initial?.subcommittee_approved_date, disabled: true },
      ],
      technical_committee_approved_date: [
        { value: initial?.technical_committee_approved_date, disabled: true },
      ],
      university_council_approved_date: [
        { value: initial?.university_council_approved_date, disabled: true },
      ],
      version_type: [
        { value: initial?.version_type, disabled: true },
        [Validators.required],
      ],
      year_to_revise: [{ value: initial?.year_to_revise, disabled: true }],
      year: [{ value: initial?.year, disabled: true }, [Validators.required]],
    } as Record<keyof Program, any>);
  }
}
