/**
 * Admission status enum. Its order is **irreversible**.
 */
export enum AdmissionStatuses {
  'waiting_for_payment',
  'waiting_for_consideration',
  'waiting_for_interview',
  'not_qualified',
  'selection_passed',
  'selection_failed',
  'waiting_for_applicant_confirmation',
  'confirm_with_others',
  'reject_offer',
  'eligible_to_study',
}

export const ThaiAdmissionStatuses: {
  [P in keyof typeof AdmissionStatuses]: string;
} = {
  confirm_with_others: 'ยืนยันสิทธิ์ที่อื่นแล้ว',
  eligible_to_study: 'มีสิทธิ์เข้าศึกษา',
  not_qualified: 'คุณสมบัติไม่ผ่าน',
  reject_offer: 'สละสิทธิ์',
  selection_failed: 'ผ่านการคัดเลือก',
  selection_passed: 'ไม่ผ่านการคัดเลือก',
  waiting_for_applicant_confirmation: 'รอการพิจารณา',
  waiting_for_consideration: 'รอรายงานตัว',
  waiting_for_interview: 'รอสอบสัมภาษณ์',
  waiting_for_payment: 'รอชำระเงิน'
};
