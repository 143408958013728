<nav class="navbar navbar-expand-lg navbar-light shadow-sm">
  <img class="navbar-brand mr-1"
      src="../../../../assets/vru-logo-thai.svg"
      alt="vru-logo">
  <button class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse"
      id="navbarNav">
    <div class="d-flex justify-content-end w-100">
      <ul class="navbar-nav">
        <li *ngFor="let menu of navbarMenu">
          <span class="nav-link pb-lg-0 px-lg-4 text-right text-lg-left pointer"
              [ngClass]="activeMenu?.value === menu?.value ? 'active' : ''"
              (click)="onSelectMenu(menu)">{{ menu.label }}</span>
        </li>
      </ul>
    </div>
  </div>
</nav>