export interface ReceiptModel {
  academic_year: number;
  code: string;
  fiscal_year: number;
  id: number;
  invoice: number;
  name: string;
  payment_channel: string;
  payment_details: Pick<PaymentDetail, 'amount' | 'fee_type' | 'label'>[];
  payment_method: string;
  payment_type: number;
  payment: number;
  printed: boolean;
  receipt_book_code: number;
  receipt_book: number;
  semester: number;
  total_amount: number;
  user_payment: number;
  user: number;
}

export interface TuitionLateFinesParams {
  academic_year?: number;
  degree?: number;
  semester?: number;
  student_type?: number;
}

export interface Payment {
  code: string;
  fee_collection_regulation: FeeCollectionRegulationType;
  id: number;
  name: string;
  payment_details: PaymentDetail[];
  payment_type: PaymentType;
}

export interface PaymentDetail {
  id: number;
  label: string;
  fee_type: string;
  fee_type_label: string;
  is_a_copy: boolean;
  amount?: number;
  receipt: number;
}

export interface PaymentReceiptListItem {
  id: number;
  receipt_date: string;
  code: number;
  name: string;
  total_amount: number;
  student_semester: number;
  receipt_file: string;
  cancelled: boolean;
}

export interface PaymentReceiptParams {
  code?: string;
  user?: number;
}

export interface PaymentReceiptRequest {
  academic_year: number;
  name: string;
  payment_channel: string;
  payment_details: Pick<PaymentDetail, 'amount' | 'fee_type'>[];
  payment_method: string;
  payment_type: number;
  semester: number;
  total_amount: number;
  user: number;
  appeal?: number;
  receipt?: number;
  user_payment?: number;
}

export interface PaymentType {
  category: PaymentTypeCategory;
  code: string;
  credit_fee_required: boolean;
  id: number;
  name: string;
}

export interface PendingPayment {
  user_payments: UserPayment[];
  receipts: UserPayment[];
}

export interface PendingPaymentParams {
  academic_year: number;
  semester: number;
  user: number;
}

export interface TuitionLateFines {
  days_late_payment: number;
  end_date: string;
  fine: number;
  today: string;
}

export interface PaymentInfo {
  cancelled: boolean;
  id: number;
  payment_channel: string;
  payment_channel_display: string;
  payment_method: string;
  payment_method_display: string;
  payment: Pick<Payment, 'name' | 'payment_details'> & { type: PaymentType };
  total_amount: number;
}

export type FeeCollectionRegulationType =
  keyof typeof FeeCollectionRegulationTypes;
export type PaymentTypeCategory = keyof typeof PaymentTypeCategories;

export enum PaymentTypeCategories {
  'examination_fee',
  'tuition_fee',
  'add_course_fee',
  'withdraw_course_fee',
  'drop_fee',
  'transfer_course_fee',
  'exemption_fee',
  'dormitory_fee',
  'graduation_registration_fee',
  'graduation_request_fee',
  'new_student_tuition_fee',
  'entrance_fee',
  'dormitory_insurance_fee',
  'graduation_rehearsal_fee',
  'appeal_fee',
  'credit_bank_fee',
}

export enum FeeCollectionRegulationTypes {
  'every_semester',
  'yearly',
  'at_entrance',
  'once',
}

export interface PaymentReceipt {
  id: number;
  payment_datetime: string;
  student_code: string;
  status: string;
  status_display: string;
  payment_type?: PaymentType;
  payment_type_category_display: string;
  error: string;
  created_at: string;
  updated_at: string;
  amount: number;
  isSelected?: boolean;
  receipt_file: string;
}

export interface CsvImportParams {
  imported_source: string;
  file: string;
  status: string;
  type: string;
  payment_type: number;
  semester: number;
  academic_year: number;
}

export interface TuitionFee {
  enrollment_round: number | null;
  total_payment_to_be_paid_amount: number;
  total_scholarship_receive_amount: number;
  total_payment_amount: number;
  fine: number;
}

export interface UserPayment {
  id: number;
  payment: Payment;
  total_amount: number;
  type?: string;
}

export type Receipt = Pick<
  ReceiptModel,
  | 'academic_year'
  | 'id'
  | 'name'
  | 'payment_channel'
  | 'payment_details'
  | 'payment_method'
  | 'payment_type'
  | 'semester'
  | 'total_amount'
  | 'user'
>;

export type ReceiptPost = Pick<
  ReceiptModel,
  | 'academic_year'
  | 'name'
  | 'payment_channel'
  | 'payment_details'
  | 'payment_method'
  | 'payment_type'
  | 'semester'
  | 'total_amount'
  | 'user'
>;
