import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { DisplayPermissionService } from '@vru/services';

@Injectable()
export class GradeDisplayPermissionService extends DisplayPermissionService {
  constructor(private store: Store) {
    super();
  }

  get selfPermission(): string {
    return this.store.selectSnapshot((state) => state.auth.role) as string;
  }
}
