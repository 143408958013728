<div class="card d-flex w-100 h-100 p-4 p-2 shadow-sm mt-4" style="border: none;">
    <div class="row col">
        <div class="col-lg-2">
            <label>รหัสวิชา</label>
            <input class="form-control">
        </div>

    </div>

    <div class="d-flex w-100 h-100 p-2 mt-3">
        <table class="table text-center">
            <thead>
                <tr>
                    <th width="5%">เทอม</th>
                    <th width="5%">รหัสวิชา</th>
                    <th width="5%">ประเภท</th>
                    <th width="10%">Sect</th>
                    <th width="15%">อาจารย์</th>
                    <th width="10%">วัน-เวลา</th>
                    <th width="5%">นก.เต็ม</th>
                    <th width="10%"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="vt-middle">1/64</td>
                    <td class="vt-middle">SIT101</td>
                    <td class="vt-middle">1</td>
                    <td class="vt-middle">1</td>
                    <td class="vt-middle">สมิธ หน้าหมี</td>
                    <td class="vt-middle">อ.(8.30-11.30)</td>
                    <td class="vt-middle">3(3-0-6)</td>
                    <td>
                        <button class="btn btn-outline-danger">ย้ายรายวิชา</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>



</div>
