import { Injectable } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { BloodType } from '@vru/master-data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  names,
  NumberDictionary,
  uniqueNamesGenerator,
} from 'unique-names-generator';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class DictGeneratorService {
  constructor(private util: UtilityService) {}

  getAddress(): Observable<string> {
    return this.util
      .getYamlFile('assets/utils/constant/address-sample.yaml')
      .pipe(map((res) => this.randomFrom(res as string[])));
  }

  getBloodType(): keyof typeof BloodType {
    return this.randomFrom(['A', 'B', 'AB', 'O']) as keyof typeof BloodType;
  }

  getEmail(): string {
    return (
      uniqueNamesGenerator({
        dictionaries: [names, names],
        length: 2,
        style: 'lowerCase',
        separator: this.randomFrom(['.', '_']),
      }) + '@vru.cdm'
    );
  }

  getLikeThaiIdCard(splitter = ''): string {
    const cardId = [
      this.randomFrom(['1', '3']),
      this.randomNumber(4),
      this.randomNumber(5),
      this.randomNumber(2),
      this.randomNumber(1),
    ];
    return cardId.join(splitter);
  }

  getName(): string {
    return uniqueNamesGenerator({
      dictionaries: [names],
    });
  }

  getThaiNameTitle(): string {
    const nameTitles = ['นาย', 'นาง', 'นางสาว'];
    return uniqueNamesGenerator({
      dictionaries: [nameTitles],
    });
  }

  getThPhoneNumber(): string {
    return (
      '0' +
      this.randomFrom(['8', '6']) +
      uniqueNamesGenerator({
        dictionaries: [NumberDictionary.generate({ length: 8 })],
      })
    );
  }

  randomDate(start: Date, end: Date) {
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );
  }

  randomFrom(dict: string[]): string {
    return uniqueNamesGenerator({
      dictionaries: [dict],
    });
  }

  randomNumber(length: number): number;
  randomNumber(min: number, max: number, decimal?: number): number;
  randomNumber(p1: number, p2?: number, decimal = 0): number {
    let _max: number, _min: number;
    if (p2 != null) {
      if (p2 > p1) {
        _max = p2;
        _min = p1;
      } else {
        _max = p1;
        _min = p2;
      }
    } else {
      _min = 0;
      _max = Math.pow(10, p1);
    }
    const shift = Math.pow(10, decimal);
    return _min + Math.round(Math.random() * (_max - _min) * shift) / shift;
  }
}
