import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { Course, DrfList, SubjectApiService } from '@vru/master-data';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'vru-course-selection-table',
  templateUrl: './course-selection-table.component.html',
  styleUrls: ['./course-selection-table.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CourseSelectionTableComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CourseSelectionTableComponent,
    },
  ],
})
export class CourseSelectionTableComponent
  implements OnChanges, OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
  /** Use only by selectedValues two-way binding */
  @Input() bindValue!: keyof Course;
  @Input() courseStructureIndex?: number;
  @Input() maxCredit!: number;
  @Input() readOnly = false;
  @Input() submitted = false;
  @Input() unavailableCourses: { courses: Course[] }[] = [];
  @Output() selectedCourseChange = new EventEmitter<CourseChange>();
  @Output() selectedValuesChange = new EventEmitter<CourseSubjectValue[]>();

  formGroup = new FormGroup({ courses: new FormArray([]) });
  isInvalidCourse = false;
  isInvalidCredit = false;
  selectedValues: CourseSubjectValue[] = [];
  touched = false;
  subjectCodeSearch$ = new Subject<string>();
  subjectCodeAccessor!: (res: DrfList<Course>) => Course[];
  subjectCodeApiGenerator!: (code: string) => Observable<DrfList<Course>>;
  error: { [k: string]: any } = {};

  onChangeSubs: Subscription[] = [];
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  constructor(
    private cdRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private subjectApi: SubjectApiService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxCredit) {
      this.formGroup.updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    this.setSubjectCodeTypeahead();
  }

  ngAfterViewInit(): void {
    this.formGroup.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    for (const subs of this.onChangeSubs) {
      subs.unsubscribe();
    }
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onAddClick(): void {
    this.courseFormArr.push(new FormControl(null));
  }

  onCourseChange(course: Course, index: number): void {
    this.selectedCourseChange.emit({ course, index });
  }

  registerOnChange(onChange: any): void {
    const subs = this.formGroup.valueChanges
      .pipe(
        map((res) => res.courses),
        tap((subjects: Course[]) => {
          if (this.bindValue) {
            this.selectedValues = subjects.map(
              (subject) => subject?.[this.bindValue] || subject
            );
          } else {
            this.selectedValues = subjects;
          }
          this.selectedValuesChange.emit(this.selectedValues);
        })
      )
      .subscribe(onChange);
    this.onChangeSubs.push(subs);
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  removeSubject(index: number): void {
    const course = this.courseFormArr.at(index).value;
    this.courseFormArr.removeAt(index);
    this.onCourseChange(course, index);
  }

  setSubjectCodeTypeahead(): void {
    this.subjectCodeApiGenerator = (code: string) =>
      this.subjectApi.getSubjects({ code });
    this.subjectCodeAccessor = (res) => res.results;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const courseErrors =
      this.courseFormArr.controls.reduce<ValidationErrors | null>(
        (err, ctrl) => {
          if (ctrl.errors) {
            if (err == null) {
              err = { duplicateCourses: [] };
            }
            err['duplicateCourses'].push(ctrl.errors);
          }
          return err;
        },
        null
      );
    this.isInvalidCourse = !!courseErrors;
    const creditErrors = this.validateCredit(control);
    return creditErrors || courseErrors
      ? Object.assign(courseErrors || {}, creditErrors || {})
      : null;
  }

  validateCredit(control: AbstractControl): ValidationErrors | null {
    const courses = control.value as Course[];
    const totalCredit = courses.reduce<number>((total, course) => {
      total += course?.credit || 0;
      return total;
    }, 0);
    if (this.maxCredit != null && totalCredit < this.maxCredit) {
      this.isInvalidCredit = true;
      return {
        maxCredit: {
          max: this.maxCredit,
          actual: totalCredit,
        },
      };
    }
    this.isInvalidCredit = false;
    return null;
  }

  writeValue(values: CourseSubjectValue[]): void {
    this.selectedValues = values;
    this.formGroup.setControl('courses', this.fb.array(values), {
      emitEvent: false,
    });
  }

  get courseFormArr(): FormArray {
    return this.formGroup.get('courses') as FormArray;
  }
}

export interface CourseChange {
  index: number;
  course: Course;
}

type CourseSubjectValue = Course | Course[keyof Course];
