import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  SkipSelf,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  API_CONFIG,
  ApiConfig,
  Notification,
  NotificationApiService,
} from '@vru/master-data';
import { AlertService, ErrorService } from '@vru/services';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'vru-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {

  pagePopover = 1;
  pageSizePopOver = 7;
  popoverTotalCount = 0;
  isLoading = false;
  unreadCount = 0;
  notificationList: Notification[] = [];

  DOMAIN = '';

  constructor(
    @SkipSelf()
    @Inject(API_CONFIG)
    private apiConfig: ApiConfig,
    private notificationService: NotificationApiService,
    private ngxSpinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private errorService: ErrorService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.DOMAIN = this.apiConfig.apiEndpoint.split('//')[1];
    this.onNotificationSpinnerShow();
    this.getNotificationList();
  }

  getNotificationList() {
    this.isLoading = true;
    const payload = {
      page: this.pagePopover,
      page_size: this.pageSizePopOver,
    };
    this.notificationService.getNotification(payload).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.unreadCount = res.unread_count;
        this.notificationList = res.results;
        this.popoverTotalCount = res.count;
        this.cd.detectChanges();
      },
      error: (err) => {
        this.isLoading = false;
        if (err.status === 400) {
          if (typeof err.error === 'object') {
            const errorMsg = this.errorService.getDrfErrMsgHtml(err.error);
            this.alertService.error(errorMsg);
          } else {
            this.alertService.errorWithContactAdminTh();
          }
        } else if (err.status === 403) {
          this.alertService.noPermit();
        } else {
          this.alertService.errorWithContactAdminTh();
        }
      },
    });
  }

  onClickNotification(notification: Notification) {
    if (!notification.action) {
      return;
    }
    this.notificationService
      .postReadOneNotification(notification.id)
      .subscribe({
        next: () => {
          this.getNotificationList();
          switch (notification.action) {
            case 'deadline_arriving':
              localStorage.setItem('from_backoffice', 'true');
              window.location.href = `${
                'https://' + this.DOMAIN + '/register/tqf/list/'
              }`;
              break;
            case 'tqf_rejected':
              localStorage.setItem('from_backoffice', 'true');
              window.location.href = `${
                'https://' + this.DOMAIN + '/register/tqf/list/'
              }`;
              break;
            case 'tqf_approved':
              localStorage.setItem('from_backoffice', 'true');
              window.location.href = `${
                'https://' + this.DOMAIN + '/register/tqf/list/'
              }`;
              break;
            case 'submit_grade':
              this.router.navigate(['/grade/submission/']);
              break;
            case 'class_start':
              this.router.navigate(['/student/timetable/']);
              break;
            case 'exam_date_arrival':
              this.router.navigate(['/student/timetable/']);
              break;
            case 'outstanding_debt':
              this.router.navigate(['/student/student-debt/']);
              break;
            case 'payment_paid':
              this.router.navigate(['/student/student-debt/']);
              break;
            default:
              break;
          }
        },
        error: (err) => {
          if (err.status === 400) {
            if (typeof err.error === 'object') {
              const errorMsg = this.errorService.getDrfErrMsgHtml(err.error);
              this.alertService.error(errorMsg);
            } else {
              this.alertService.errorWithContactAdminTh();
            }
          } else if (err.status === 403) {
            this.alertService.noPermit();
          } else {
            this.alertService.errorWithContactAdminTh();
          }
        },
      });
  }

  readAllNotification() {
    this.isLoading = true;
    this.notificationService.postReadAllNotification().subscribe({
      next: () => {
        this.isLoading = false;
        this.getNotificationList();
      },
      error: (err) => {
        this.isLoading = false;
        if (err.status === 400) {
          if (typeof err.error === 'object') {
            const errorMsg = this.errorService.getDrfErrMsgHtml(err.error);
            this.alertService.error(errorMsg);
          } else {
            this.alertService.errorWithContactAdminTh();
          }
        } else if (err.status === 403) {
          this.alertService.noPermit();
        } else {
          this.alertService.errorWithContactAdminTh();
        }
      },
    });
  }

  onNotificationSpinnerShow() {
    this.ngxSpinnerService.show('notification-detail');
  }
}
