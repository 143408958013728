import { Classroom } from '../../building/classroom.model';
import { PaginationParams } from '../../common.model';
import {
  ExaminationManagement,
  SemesterCourse,
} from '../../subject/course-section.model';
import { Day } from '../../thai-calendar/thai-calendar.constant';
import { Faculty } from '../department/faculty.model';
import { StudentSection } from '../student/student-section.model';
import { Student, StudentType } from '../student/student.model';
import { GradeRange } from './grade.model';

export interface GradeSubmissionParams extends PaginationParams {
  academic_year: string | number;
  semester: number | string;
  course?: number | null;
  course_code?: string | null;
  course_name?: string | null;
  faculty?: number | null;
  field_of_study?: number | null;
  grade_submission_status?: GradeSubmissionStatus;
  student_section?: number | null;
  student_type?: string | number | null;
  student?: number | null;
  teacher?: number | null;
}

export interface GradeSubmissionExportParams {
  academic_year: number;
  semester: number;
  student_type: number;
  student_section?: number;
  course_code?: string;
}

export interface GradeSubmissionExportGPAParams {
  academic_year: number;
  semester: number;
  gpa_range_min?: number;
  gpa_range_max?: number;
  enrolled_credits?: number;
  passed_credits?: number;
}

export interface GradeSubmission {
  approve_dean: Pick<Faculty, 'id' | 'name_thai'>;
  backoffice_confirm_date: Date;
  can_submit_grade: boolean;
  dean_confirm_date: Date;
  examination_managements: Pick<
    ExaminationManagement<void>,
    'start_time' | 'end_time'
  > &
    { datetime_as_text: string }[];
  enrollments: StudentScore[];
  id: number;
  grade_setting: GradeSetting | null;
  grade_submission_date: Date;
  grade_submission_status_display: string;
  grade_submission_status: keyof typeof GradeSubmissionStatuses;
  semester_course: Pick<
    SemesterCourse,
    'id' | 'semester' | 'academic_year' | 'course'
  >;
  section_no: string;
  student_section: Pick<StudentSection, 'id' | 'code'>;
  student_type: StudentType;
  teachers: StudentType[];
  timetable_managements: TimetableManagement[];
}

export interface GradeDeadline {
  id: number;
  start_date_display: string;
  end_date_display: string;
  start_date: Date;
  end_date: Date;
}

export interface StudentScore {
  id: number;
  has_scholarship: boolean;
  payment_status: string;
  student: Pick<Student, 'id' | 'code' | 'full_name_thai'>;
  midterm_score: number;
  final_score: number;
  total_score: number;
  grade: string;
  evaluated: boolean;
  can_submit_grade: boolean;
}

export interface GradeDeadlineParams {
  course_section: number;
}

export interface GradeSetting {
  id: number;
  course_section: number;
  midterm_score: number;
  final_score: number;
  total_score: number;
  calculate_grade_method: keyof typeof GradeCalculationMethod;
  calculate_grade_method_display: string;
  grade_ranges: GradeRange[];
}

export interface ExportGradeFormParams {
  export_as: 'pdf'
  course_section?: number
}

interface TimetableManagement {
  id: number;
  classroom: Pick<Classroom, 'id' | 'code'>;
  day: keyof typeof Day;
  start_time: string;
  end_time: string;
  date_time_as_text: string;
}

export enum GradeCalculationMethod {
  fix_rate,
  mean_sd,
  pass_or_not_pass,
  t_score,
}

export enum GradeSubmissionStatuses {
  'wait_for_professor_submit_grade',
  'wait_for_dean_approval',
  'wait_for_backoffice_approval',
  'revised',
  'approved',
}

export type GradeSubmissionStatus = keyof typeof GradeSubmissionStatuses;
