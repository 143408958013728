import { InjectionToken } from '@angular/core';

export const VALIDATION_LABEL_CONFIG =
  new InjectionToken<ValidationLabelConfig>('validation.config');

export interface ValidationLabelConfig {
  fontSizeRem?: number;
  prefixMsg?: string;
  showMsg?: boolean;
}
