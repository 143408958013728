<div class="position-relative">
  <div class="board-white w-100 h-100 p-5 mt-5 overflow-auto">
    <div class="frame-filter w-100 h-100 flex-nowrap mx-0"
        [class.spinner-blur-effect]="isLoading">
      <div class="row no-gutters d-flex justify-content-between">
        <div>
          <h4>จัดการผู้ใช้งานระบบ</h4>
        </div>
        <div class="btn-group ml-auto"
            role="group">
          <button data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              class="btn btn-outline-green-vru dropdown-toggle"
              id="studyBtnGroupDrop"
              type="button">พิมพ์รายงาน</button>
          <div class="dropdown-menu"
              aria-labelledby="studyBtnGroupDrop">
            <span class="dropdown-item" (click)="openUserListReportModal('user-list')">รหัสประจำตัวและรหัสผ่าน</span>
            <span class="dropdown-item" (click)="openUserListReportModal('profile-list')">รายชื่อบุคลากร</span>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-3 mt-4">
          <label for="filter-username">Username</label>
          <input type="text"
              class="form-control"
              id="filter-username"
              (keyup.enter)="takeUserPermission()"
              [(ngModel)]="searchUsername">
        </div>
        <div class="col-lg-3 mt-4">
          <label for="filter-name">ชื่อผู้ใช้งาน</label>
          <input type="text"
              class="form-control"
              id="filter-name"
              [(ngModel)]="searchName"
              (keyup.enter)="takeUserPermission()">
        </div>
        <div class="col-lg-3 mt-4">
          <label>คณะ</label>
          <ng-select [items]="facultyDropdown"
              bindLabel="label"
              bindValue="value"
              placeholder="เลือก"
              [clearable]="true"
              [searchable]="true"
              [(ngModel)]="selectedFaculty"
              (change)="onSelectFaculty($event)"></ng-select>
        </div>
        <div class="col-lg-3 mt-4">
          <label>สาขาวิชา</label>
          <ng-select [items]="fieldOfStudyDropdown"
              bindLabel="label"
              bindValue="value"
              placeholder="เลือก"
              [readonly]="!selectedFaculty"
              [clearable]="true"
              [searchable]="true"
              [(ngModel)]="selectedFieldOfStudy"
              [loading]="isFieldOfStudyLoading"></ng-select>
        </div>
        <div class="col-lg-3 mt-4">
          <label>ประเภทบุคลากร</label>
          <ng-select [items]="employeeTypeDropdown"
              bindLabel="label"
              bindValue="value"
              placeholder="เลือก"
              [clearable]="true"
              [searchable]="true"
              [(ngModel)]="searchPersonnelType"></ng-select>
        </div>
        <div class="col-lg-3 mt-4">
          <label>หน่วยงาน</label>
          <ng-select [items]="roleDropdown"
              bindLabel="label"
              bindValue="value"
              placeholder="เลือก"
              [clearable]="true"
              [searchable]="true"
              [(ngModel)]="selectedAgency"></ng-select>
        </div>
        <div class="col-lg-3 mt-4 d-flex align-items-end">
          <button class="btn btn-outline-green-vru mr-2"
              type="button"
              (click)="clearFilter()">ล้างรายการ</button>
          <button class="btn btn-green-vru mr-2"
              type="button"
              (click)="takeUserPermission()">ค้นหา</button>
        </div>
        <div class="d-flex justify-content-end align-items-end ml-auto mr-3">
          <button class="btn btn-green-vru"
              *vruDisplayPermission="['admin']"
              type="button"
              [routerLink]="['../', 'create']">+ เพิ่มรายการ</button>
        </div>
      </div>
      <table class="table text-center mt-3">
        <thead>
          <tr>
            <th scope="col">ลำดับ</th>
            <th scope="col">Username</th>
            <th scope="col">ชื่อผู้ใช้งาน</th>
            <th scope="col">หน่วยงาน</th>
            <th scope="col"
                width="1%"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let userDisplays of userPermissionList; index as i">
            <td class="align-middle">{{i + 1 + ((page-1)*pageSize)}}</td>
            <td class="align-middle">{{userDisplays.username || '-'}}</td>
            <td class="align-middle">{{userDisplays.first_name || '-'}} {{userDisplays.last_name || '-'}}</td>
            <td class="align-middle">{{userDisplays.role_display || '-'}}</td>
            <td class="align-middle">
              <vru-carbon-icon *vruDisplayPermission="['admin']"
                  iconName="trash-can"
                  presetFilter="fill-red"
                  size="20"
                  (click)="onDeleteUserPermission(userDisplays!.id, userDisplays.username)">
              </vru-carbon-icon>
            </td>
            <td class="align-middle">
              <span class="bi bi-pencil-square h6 pointer text-dark-gray mb-0"
                  [routerLink]="['../', userDisplays.id]">
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-center mt-4">
        <vru-pagination-table-footer class="col"
            [(page)]="page"
            [(pageSize)]="pageSize"
            [totalCount]="totalCount"
            (selectPageChange)="takeUserPermission()"></vru-pagination-table-footer>
      </div>
    </div>
    <div class="container-spinner-float">
      <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
          name="user-list"
          color="#3cd070"
          size="medium"
          type="ball-clip-rotate-pulse"
          [fullScreen]="false"
          [showSpinner]="isLoading">
      </ngx-spinner>
    </div>
  </div>
</div>