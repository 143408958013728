<div class="center mt-3 pointer">
  <ngx-file-drop class="file-drop"
      [accept]="accept"
      (click)="multiFiles ? multipleFileUpload.click() : singleFileUpload.click()"
      (onFileDrop)="onFileDrop($event)">
    <ng-template ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector">
      <div class="template-content d-flex align-items-center justify-content-center w-100 h-100"
          [ngStyle]="dropboxStyle">
        <ng-container *ngIf="!multiFiles && files.length > 0; else placeholderDropboxTemplate">
          <div *ngFor="let file of files; index as i"
              class="d-flex align-items-center">
            <span class="mx-2">{{ file.name }}</span>
            <vru-carbon-icon iconName="close"
              [presetFilter]="'fill-red'"
              [size]="28"
              (click)="onFileRemove(i, $event)">
            </vru-carbon-icon>
          </div>
        </ng-container>
        <ng-template #placeholderDropboxTemplate>
          <ng-container *ngIf="iconName">
            <vru-carbon-icon [iconName]="iconName"
                [presetFilter]="'fill-green-vru'"
                [size]="20">
            </vru-carbon-icon>
          </ng-container>
          <span class="mx-2">{{ content }}</span>
        </ng-template>
      </div>
    </ng-template>
  </ngx-file-drop>
  <input #singleFileUpload
      hidden
      type="file"
      [accept]="accept"
      (change)="onSelectFile($event)">
  <input #multipleFileUpload
      hidden
      multiple
      type="file"
      [accept]="accept"
      (change)="onSelectFile($event)">
  <div *ngIf="multiFiles"
      class="w-100 py-3">
    <table class="w-100">
      <tr *ngFor="let file of files; index as i">
        <td>
          <span *ngIf="file.name; else noFileNameTemplate">{{ file.name }}</span>
          <ng-template #noFileNameTemplate>
            <span ><i>'No file name'</i></span>
          </ng-template>
        </td>
        <td>
          <div class="d-flex justify-content-end">
            <vru-carbon-icon iconName="close"
                [presetFilter]="'fill-red'"
                [size]="28"
                (click)="onFileRemove(i)">
            </vru-carbon-icon>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
