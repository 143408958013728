import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'vru-joining-graduation-ceremony-block',
  templateUrl: './joining-graduation-ceremony-block.component.html',
  styleUrls: ['./joining-graduation-ceremony-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: JoiningGraduationCeremonyBlockComponent,
      multi: true,
    },
  ],
})
export class JoiningGraduationCeremonyBlockComponent
  implements ControlValueAccessor
{
  @Input() loading = false;
  @Output() submitJoining = new EventEmitter<boolean>();

  joinToCeremonyForm = new FormControl();
  prototypeValue = false;

  private _onTouch!: () => void;

  markAsPristine(): void {
    this.joinToCeremonyForm.markAsPristine();
  }

  onCancel(): void {
    this.joinToCeremonyForm.setValue(this.prototypeValue);
    this.joinToCeremonyForm.markAsPristine();
  }

  onSubmit(): void {
    this.submitJoining.emit(this.joinToCeremonyForm.value);
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  registerOnChange(fn: () => void): void {
    this.joinToCeremonyForm.valueChanges.subscribe(fn);
  }

  writeValue(val: boolean): void {
    this.joinToCeremonyForm.setValue(val);
    this.prototypeValue = val;
  }
}
