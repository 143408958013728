import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DisplayPermissionDirective } from './directives/display-permission.directive';
import { SubjectFormGroupRetrieverPipe } from './directives/subject-retriever.directive';
import { SyncControlDirective } from './directives/sync-control.directive';
import { EditorComponent } from './editor/editor.component';
import { ArrayReversionPipe } from './pipes/array-reversion.pipe';
import { BuddhaDatePipe } from './pipes/date-buddha.pipe';
import { NArrayPipe } from './pipes/n-array.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SecondToHourPipe } from './pipes/second-to-hour.pipe';
import { UtilFocusOnShowDirective } from './validations/focus-Directive';
import { UtilFormSubmitValidation } from './validations/form-submit-validation.directive';
import { UtilFormValidationDirective } from './validations/form-validation.directive';
import { UtilValidationDirective } from './validations/validation.directive';
import { LanguageValidatorDirective } from './validations/language-validator.directive';

@NgModule({
  declarations: [
    ArrayReversionPipe,
    BuddhaDatePipe,
    DisplayPermissionDirective,
    EditorComponent,
    LanguageValidatorDirective,
    NArrayPipe,
    SubjectFormGroupRetrieverPipe,
    UtilFormSubmitValidation,
    UtilFormValidationDirective,
    UtilFocusOnShowDirective,
    UtilValidationDirective,
    SafeHtmlPipe,
    SecondToHourPipe,
    SyncControlDirective,
  ],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, EditorModule],
  exports: [
    ArrayReversionPipe,
    BuddhaDatePipe,
    DisplayPermissionDirective,
    EditorComponent,
    LanguageValidatorDirective,
    NArrayPipe,
    ReactiveFormsModule,
    FormsModule,
    SubjectFormGroupRetrieverPipe,
    UtilFormSubmitValidation,
    UtilFormValidationDirective,
    UtilFocusOnShowDirective,
    UtilValidationDirective,
    SafeHtmlPipe,
    SecondToHourPipe,
    SyncControlDirective,
  ],
})
export class UtilsModule {}
