import { ElementRef, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DateTimeService } from '@vru/services';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AppealRequestDetail,
  AppealStatusBadgeColor,
  AppealThaiStatus,
  AppealStatusDropdown,
  AppealEditStatusParam,
  EnumValue,
} from '@vru/master-data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'vru-appeal-loa',
  templateUrl: './appeal-loa.component.html',
  styleUrls: ['./appeal-loa.component.scss'],
})
export class AppealLoaComponent implements OnChanges {
  @ViewChild('editAppealStatusModal') editAppealStatusModal!: ElementRef;

  @Input() appealRequestDetail!: AppealRequestDetail;
  @Input() canPay = false;

  @Output() editStatusAppealRequest = new EventEmitter<AppealEditStatusParam>();
  @Output() paymentStatusAppealRequest = new EventEmitter();

  firstLevel: number[] = [];
  secondLevel: number[] = [];
  paymentLevel: number | null = null;
  currentLevel: number | null = null;
  currentAvailableLevel: number[] = [];

  selectedStatus: string | null = null;
  selectedLevel: number | null = null;

  appealStatusDropdown: EnumValue[] = [];
  appealPaymentDropdown = [
    {
      name: 'รอชำระเงิน',
      description: 'wait_for_payment',
    },
  ];

  appealStatusList = AppealThaiStatus;
  appealStatusBadgeColorList = AppealStatusBadgeColor;

  constructor(
    private dateTimeService: DateTimeService,
    private ngbModal: NgbModal
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appealRequestDetail.currentValue) {
      this.getCurrentLevel();
      this.checkIsPaymentLOA();
    }
  }

  editStatusAction() {
    if (this.selectedLevel && this.selectedStatus) {
      this.editStatusAppealRequest.emit({
        status: this.selectedStatus,
        level: this.selectedLevel,
      });
    }
  }

  paymentStatusAction() {
    this.paymentStatusAppealRequest.emit();
  }

  getLabelDate(label: string) {
    return this.dateTimeService.getStrThaiDateTime(label);
  }

  checkIsPaymentLOA() {
    if (this.appealRequestDetail.loa_group) {
      const sortLOALevel = this.appealRequestDetail.loa_group.levels.sort(
        (a, b) => a.level - b.level
      );
      this.paymentLevel = sortLOALevel.filter(
        (level) => level.payment_required
      )[0]?.level;
      const paymentLevelIndex = sortLOALevel.findIndex(
        (level) => level.payment_required
      );
      const firstLevel = sortLOALevel
        .slice(0, paymentLevelIndex + 1)
        .map((obj) => obj.level);
      const secondLevel = sortLOALevel
        .slice(paymentLevelIndex + 1, sortLOALevel.length)
        .map((obj) => obj.level);
      if (this.currentLevel) {
        if (firstLevel.includes(this.currentLevel)) {
          this.currentAvailableLevel = firstLevel;
        }
        if (secondLevel.includes(this.currentLevel)) {
          this.currentAvailableLevel = secondLevel;
        }
      }
    }
  }

  onAvailableEditPhase(level: number): boolean {
    if (this.currentAvailableLevel.includes(level)) {
      return true;
    }
    return false;
  }

  getCardHeaderColorAppealStatus(level: number, status?: string): string {
    if (!this.currentLevel) {
      return '';
    }
    if (status) {
      if (this.currentLevel === level && status === 'rejected') {
        return 'bg-danger';
      }
      if (this.currentLevel === level && status === 'success') {
        return 'bg-success';
      }
    }
    if (this.currentLevel === level) {
      return 'bg-info';
    }
    if (this.currentLevel > level) {
      return 'bg-success';
    } else {
      return '';
    }
  }

  getLabelColorAppealStatus(level: number): string {
    if (!this.currentLevel) {
      return '';
    }
    if (this.currentLevel >= level) {
      return 'text-white';
    } else {
      return '';
    }
  }

  getIconColorAppealStatus(level: number): string {
    if (!this.currentLevel) {
      return '';
    }
    if (this.paymentLevel) {
      if (this.currentAvailableLevel.includes(level)) {
        return '';
      } else {
        return 'fill-gray';
      }
    }
    return '';
  }

  getCurrentLevel() {
    if (!this.appealRequestDetail.loa_group) {
      return;
    }
    const sortLOALevel = this.appealRequestDetail.loa_group.levels.sort(
      (a, b) => a.level - b.level
    );
    const result = sortLOALevel.filter(
      (level) => level.id == this.appealRequestDetail.loa_level
    )[0].level;
    if (result) {
      this.currentLevel = result;
    } else {
      this.currentLevel = null;
    }
  }

  editAppealStatus(level: number) {
    this.selectedLevel = level;
    this.appealStatusDropdown = [];
    if (this.paymentLevel) {
      if (level < this.paymentLevel) {
        this.appealStatusDropdown = AppealStatusDropdown.filter(
          (status) =>
            !['success', 'rejected', 'cancel_by_student', 'draft', 'wait_for_payment'].includes(
              status.description
            )
        );
      }
      if (level === this.paymentLevel) {
        this.appealStatusDropdown = AppealStatusDropdown.filter(
          (status) =>
            status.description === 'wait_for_payment' ||
            status.description === 'cancel_by_staff'
        );
      }
      if (level > this.paymentLevel) {
        this.appealStatusDropdown = AppealStatusDropdown.filter(
          (status) =>
            !['wait_for_payment', 'cancel_by_student', 'draft'].includes(
              status.description
            )
        );
      }
    } else {
      this.appealStatusDropdown = AppealStatusDropdown.filter(
        (status) =>
          !['wait_for_payment', 'cancel_by_student', 'draft'].includes(
            status.description
          )
      );
    }
    this.ngbModal.open(this.editAppealStatusModal, {
      size: 'md',
      backdrop: 'static',
    });
  }

  closeAppealStatusModal() {
    this.selectedStatus = null;
    this.selectedLevel = null;
    this.ngbModal.dismissAll();
  }
}
