import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { UtilsModule } from '@vru/utils';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LaddaModule } from 'angular2-ladda';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CarbonIconModule } from '../../carbon-icon/carbon-icon.module';
import { GradeSubmissionDetailComponent } from './grade-submission-detail/grade-submission-detail.component';
import { GradeSubmissionDetailResolver } from './grade-submission-detail/grade-submission-detail.resolver';
import { GradeSubmissionListComponent } from './grade-submission-list/grade-submission-list.component';
import { GradeSubmissionRoutingModule } from './grade-submission-routing.module';
import { UiModule } from '../../ui.module';

@NgModule({
  declarations: [GradeSubmissionListComponent, GradeSubmissionDetailComponent],
  imports: [
    AngularSvgIconModule,
    CarbonIconModule,
    CommonModule,
    GradeSubmissionRoutingModule,
    LaddaModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgSelectModule,
    NgxSpinnerModule,
    UtilsModule,
    NgbModule,
    UiModule,
  ],
  providers: [GradeSubmissionDetailResolver],
})
export class GradeSubmissionModule {}
