import { ErrorHeader, News, NewsFeedService } from '@vru/master-data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '@vru/services';

@Component({
  selector: 'homepage-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit {

  isLoading = false;

  newsId!: number;
  newsInformation!: News;
  newsDescription!: string;

  constructor(
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private newsFeedService: NewsFeedService,
    private alertService: AlertService,
    private activateRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.setNewsId();
  }

  setNewsId() {
    const targetId = this.activateRoute.snapshot.paramMap.get('id');
    if (targetId) {
      this.getNewsInformation(+targetId);
    } else {
      this.goBack()
    }
  }

  getNewsInformation(id: number) {
    this.newsFeedService.getNewsDetail(id).subscribe({
      next: (res) => {
        this.newsInformation = res;
        this.newsDescription = res.detail
      },
      error: (err: ErrorHeader) => {
        if (err.error)
          this.alertService.error(err.error.detail, 10000)
      }
    })
  }

  onSpinnerShow() {
    this.ngxSpinnerService.show('appeal-list-table');
  }

  goBack() {
    this.router.navigate(['/news-feed/']);
  }
}
