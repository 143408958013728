import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import {
  GradeDeadline,
  GradeSubmissionService,
  GradeSubmission,
} from '@vru/master-data';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class GradeSubmissionDetailResolver
  implements Resolve<GradeSubmissionResolve> {
  constructor(private gradeSubmission: GradeSubmissionService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<GradeSubmissionResolve> {
    const id = Number(route.paramMap.get('id'));
    return this.gradeSubmission.getDetail(id).pipe(
      switchMap((res) => {
        const course_section = res.id;
        return forkJoin({
          deadline: this.gradeSubmission.getDeadLine({
            course_section,
          }),
          detail: of(res),
        });
      })
    );
  }
}

export type GradeSubmissionResolve = {
  deadline: GradeDeadline[];
  detail: GradeSubmission;
};
