<div class="position-relative">
  <div class="board-white w-100 h-100 p-4 p-lg-5 overflow-auto">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <h5>โครงสร้างหลักสูตร</h5>
      <ng-container *ngIf="!isSelectCourse">
        <button *vruDisplayPermission="['admin', 'backoffice']"
            class="btn btn-green-vru"
            type="button"
            (click)="onChangeCategoryClick()">ปัดรายวิชา</button>
      </ng-container>
      <ng-container *ngIf="isSelectCourse">
        <button class="btn btn-outline-green-vru ml-auto"
            data-spinner-color="#2d7054"
            type="button"
            [ladda]="isLoadingCategoryChange"
            (click)="cancelChangeCategorySelection()">ยกเลิก</button>
        <button class="btn btn-green-vru ml-2"
            type="button"
            [ladda]="isLoadingCategoryChange"
            (click)="submitChangeCategorySelection()">ยืนยันการเลือกวิชา</button>
      </ng-container>
    </div>
    <div class="position-relative frame-filter mt-3 mb-4">
      <h5 class="mb-3">ผลการตรวจสอบ</h5>
      <div class="row credit-information">
        <div *ngFor="let creditInfo of creditInformationKeyPair"
            class="col-12 col-md-6 col-lg-4 col-xxl-3">
          <label class="label-credit col-form-label text-dark-gray">
            {{ creditInfo.label }}
          </label>
          <label class="col col-form-label text-nowrap">
            {{ graduateQualification?.[creditInfo.key] != null ? graduateQualification?.[creditInfo.key] : '-' }}
          </label>
        </div>
      </div>
    </div>
    <div *ngIf="canSubmitGraduateReq"
        class="position-relative frame-filter mb-4 p-4">
      <vru-joining-graduation-ceremony-block #joiningCeremonyComp
          [loading]="isLoadingJoiningCeremony"
          [ngModel]="graduateQualification?.join_graduation_ceremony"
          (submitJoining)="updateJoiningCeremony($event, joiningCeremonyComp)">
      </vru-joining-graduation-ceremony-block>
    </div>
    <div *ngIf="canSaveEmployment"
        class="position-relative frame-filter mb-4 d-flex flex-column">
      <div class="row no-gutters align-items-center justify-content-between mb-3">
        <div class="col-auto">
          <h5 class="mb-0"
              (click)="navigateToStudentEmployment()">
            บันทึกภาวะการมีงานทำสำหรับบัณฑิต
          </h5>
        </div>
        <ng-container *ngIf="employmentStatus?.id; else employmentStatusTemp">
          <div class="col d-flex justify-content-end py-2">
            <span class="badge badge-pill badge-employed-status text-center">
              สำเร็จ
            </span>
          </div>
        </ng-container>
        <ng-template #employmentStatusTemp>
          <div class="col d-flex justify-content-end py-2">
            <span class="badge badge-pill badge-unemployed-status text-center">
              ยังไม่สำเร็จ
            </span>
          </div>
        </ng-template>
      </div>
      <div class="d-flex flex-wrap">
        <p>แบบสอบถามทำรายการขึ้นทะเบียนบัณฑิต</p>
        <button class="btn btn-outline-secondary ml-auto"
            type="button"
            (click)="navigateToStudentEmployment()">ทำแบบสอบถาม</button>
      </div>
    </div>
    <div class="position-relative frame-filter mb-4">
      <div class="row no-gutters align-items-center justify-content-between mb-3">
        <div class="d-flex align-items-center mb-2 mb-md-0">
          <h5 class="mb-0">รายวิชา</h5>
          <div class="px-2">
            <vru-carbon-icon container="body"
                iconName="information-filled"
                popoverClass="popover-max-lg"
                popoverTitle="คำอธิบาย"
                placement="right"
                presetFilter="fill-blue-sapphire"
                size="24"
                triggers="mouseenter:mouseleave"
                [ngbPopover]="descriptionTemp">
            </vru-carbon-icon>
          </div>
          <ng-template #descriptionTemp>
            <div>
              <div class="d-flex mb-1">
                <label class="label-description course-enrolled text-nowrap">
                  วิชา
                </label>
                <label class="label-description text-nowrap">รายวิชาตามหลักสูตร</label>
              </div>
              <div class="d-flex mb-1">
                <label class="label-description course-out-structure">
                  วิชา
                </label>
                <label class="label-description text-nowrap">รายวิชาที่ไม่ได้อยู่ในหลักสูตรหรือรายวิชาทางเลือก</label>
              </div>
              <div class="d-flex mb-1">
                <label class="label-description course-not-enrolled">
                  วิชา
                </label>
                <label class="label-description text-nowrap">รายวิชาตามหลักสูตรที่ยังไม่ได้ลงทะเบียนเรียน</label>
              </div>
              <div class="d-flex mb-1">
                <label class="label-description course-out-structure course-not-enrolled">
                  วิชา
                </label>
                <label class="label-description text-nowrap">รายวิชาทางเลือกตามหลักสูตรที่ยังไม่ได้ลงทะเบียนเรียน</label>
              </div>
            </div>
          </ng-template>
        </div>
        <div class="col-12 col-md-auto">
          <ng-select bindLabel="label"
              bindValue="value"
              id="select-course-display"
              [clearable]="false"
              [items]="courseDisplayDropdown"
              [(ngModel)]="selectedDisplay">
          </ng-select>
        </div>
      </div>
      <div *ngFor="let record of graduateQualification?.grade_record"
          class="overflow-auto mb-5">
        <table class="table text-center mb-0"
            id="table-student-graduate-qualification">
          <ng-container *ngTemplateOutlet="courseGroupTable;
              context: { $implicit: record, openSummary: !record.children?.length }">
          </ng-container>
          <ng-template #courseGroupTable
              let-record
              let-openSummary="openSummary"
              let-isChild="isChild">
            <thead>
              <tr>
                <th class="table-header-green-vru py-1"
                    colspan="10"
                    scope="col"
                    [class.child]="isChild">
                  <div class="d-flex align-items-center justify-content-between h-100">
                    <h5 class="mt-2"
                        [class.main]="record.course_structure?.indent === 0"
                        [style.padding-left.rem]="record.course_structure?.indent">
                      <span>{{ record.course_structure?.number }} </span>
                      <span>{{ record.course_structure?.course_group_name }}</span>
                    </h5>
                    <div class="badge-outline-credit-status text-center mb-0"
                        [class.badge-green]="record.program_credits - record.minimum_credits_required >= 0
                          && record.completed_compulsory_courses"
                        [class.badge-red]="record.program_credits - record.minimum_credits_required < 0
                          || !record.completed_compulsory_courses">
                      <label class="mb-0">
                        <span *ngIf="record.program_credits - record.minimum_credits_required >= 0
                            && record.completed_compulsory_courses; else notPassGradeRequirementTemp">
                          ผ่าน
                        </span>
                        <ng-template #notPassGradeRequirementTemp>
                          <span>ไม่ผ่าน</span>
                        </ng-template>
                      </label>
                    </div>
                  </div>
                </th>
              </tr>
              <tr *ngIf="record.course_enrollments?.length
                  || (record.not_enrolled_courses?.length && selectedDisplay === 'whole_program')"
                  class="border-green-munsell border-left-0 border-right-0 border-bottom-0">
                <ng-container *ngIf="isSelectCourse">
                  <th scope="col">
                    <input title="เลือกวิชาทั้งหมดในหมวดนี้"
                        type="checkbox"
                        [indeterminate]="record.allSelected === null"
                        [(ngModel)]="record.allSelected"
                        (ngModelChange)="onCheckAllCourseClick(record)">
                  </th>
                </ng-container>
                <th class="text-secondary"
                    scope="col">รหัสวิชา</th>
                <th class="text-secondary text-left"
                    scope="col">ชื่อวิชา</th>
                <th class="text-secondary"
                    scope="col">ชั่วโมงหน่วยกิต</th>
                <th class="text-secondary"
                    scope="col">หน่วยกิตที่ลง</th>
                <th class="text-secondary"
                    scope="col">คะแนนคำนวนเกรด (PT)</th>
                <th class="text-secondary"
                    scope="col">AVG</th>
                <th class="text-secondary"
                    scope="col">MAX</th>
                <th class="text-secondary"
                    scope="col">เกรด</th>
              </tr>
            </thead>
            <tbody *ngIf="!record.children || record.course_enrollments?.length">
              <tr *ngFor="let courseEnrollment of record.course_enrollments"
                  class="border-green-munsell border-left-0 border-right-0"
                  [class.text-gray]="!courseEnrollment.is_compulsory">
                <ng-container *ngIf="isSelectCourse">
                  <td>
                    <input title="เลือกวิชาที่ต้องการย้าย"
                        type="checkbox"
                        [(ngModel)]="courseEnrollment.selected"
                        (ngModelChange)="onCheckCourseClick(record)">
                  </td>
                </ng-container>
                <td>
                  {{ courseEnrollment.course?.code || '-' }}
                </td>
                <td class="text-left">
                  {{ courseEnrollment.course?.name_thai || courseEnrollment.course?.name_eng || '-' }}
                </td>
                <td>{{ courseEnrollment.course?.credit_hour || '-' }}</td>
                <td>{{ courseEnrollment.course?.credit || '-' }}</td>
                <td>{{ courseEnrollment.enrollments[0]?.grade_points }}</td>
                <td>{{ courseEnrollment.average_grade }}</td>
                <td>{{ courseEnrollment.max_grade }}</td>
                <td triggers="mouseenter:mouseleave"
                    [ngbPopover]="courseEnrollment.records.length > 0 && courseEnrollment.records[0]?.grade
                      ? enrollmentsTemp
                      : null">
                  {{ courseEnrollment.records?.[courseEnrollment.records.length - 1]?.grade }}
                </td>
                <ng-template #enrollmentsTemp>
                  <table class="table table-green-shamrock text-center mb-0">
                    <thead>
                      <tr>
                        <th class="table-header-green-vru">ปีการศึกษา</th>
                        <th class="table-header-green-vru">เกรด</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let enrollment of courseEnrollment.records">
                        <td>{{ enrollment.semester }}/{{ enrollment.academic_year }}</td>
                        <td>{{ enrollment.grade || '-' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </ng-template>
              </tr>
              <ng-container *ngIf="record.not_enrolled_courses?.length
                  && selectedDisplay === 'whole_program'">
                <tr *ngFor="let notEnrolledCourse of record.not_enrolled_courses"
                    class="row-remaining-course border-green-munsell border-left-0 border-right-0"
                    [class.text-gray]="!notEnrolledCourse.is_compulsory">
                  <ng-container *ngIf="isSelectCourse">
                    <td>
                    </td>
                  </ng-container>
                  <td>{{ notEnrolledCourse.course?.code || '-' }}</td>
                  <td class="text-left">
                    {{ notEnrolledCourse.course?.name_thai || notEnrolledCourse.course?.name_eng || '-' }}
                  </td>
                  <td>{{ notEnrolledCourse.course?.credit_hour || '-' }}</td>
                  <td>{{ notEnrolledCourse.course?.credit || '-' }}</td>
                  <td colspan="4"></td>
                </tr>
              </ng-container>
              <tr *ngIf="!record.children?.length
                  && !record.course_enrollments?.length
                  && (selectedDisplay !== 'whole_program' || !record.not_enrolled_courses?.length)">
                <td colspan="9">ไม่พบรายวิชา</td>
              </tr>
            </tbody>
            <tbody *ngIf="openSummary">
              <tr class="border-green-munsell border-left-0 border-right-0">
                <td colspan="10">
                  <div class="row no-gutters">
                    <div class="col-6 d-flex flex-wrap no-gutters">
                      <div class="col-6 d-flex flex-column no-gutters">
                        <div class="summary-height summary-header">
                          <span class="font-weight-bold text-secondary">หน่วยกิตขั้นต่ำ</span>
                        </div>
                        <div class="summary-height">
                          <span>
                            {{ record.minimum_credits_required == null ? '-' : record.minimum_credits_required }}
                          </span>
                        </div>
                      </div>
                      <div class="col-6 d-flex flex-column no-gutters">
                        <div class="summary-height summary-header">
                          <span class="font-weight-bold text-secondary">หน่วยกิตที่ลง</span>
                        </div>
                        <div class="summary-height">
                          <span>{{ record.registered_credits }} </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 d-flex flex-wrap no-gutters">
                      <div class="col-6 d-flex flex-column no-gutters">
                        <div class="summary-height summary-header">
                          <span class="font-weight-bold text-secondary">หน่วยกิต</span>
                        </div>
                        <div class="summary-height">
                          <div class="badge-credit-status badge-pill badge-success text-center"
                              [class.badge-green]="record.registered_credits - record.minimum_credits_required >= 0"
                              [class.badge-red]="record.registered_credits - record.minimum_credits_required < 0">
                            <span *ngIf="record.registered_credits - record.minimum_credits_required >= 0;
                                else notPassLiteralTemp">
                              ผ่าน
                            </span>
                            <ng-template #notPassLiteralTemp>
                              <span>ไม่ผ่าน</span>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 d-flex flex-column no-gutters">
                        <div class="summary-height summary-header">
                          <span class="font-weight-bold text-secondary">เกรดเฉลี่ย (GPA)</span>
                        </div>
                        <div class="summary-height">
                          <span>
                            {{ record.gpa == null ? '-' : record.gpa | number: '1.2-2' }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <ng-container *ngFor="let childRecord of record.children">
              <ng-container *ngTemplateOutlet="courseGroupTable;
                  context: {
                    $implicit: childRecord,
                    openSummary: !childRecord.children?.length,
                    isChild: true
                  }">
              </ng-container>
            </ng-container>
          </ng-template>
        </table>
      </div>
      <div *ngIf="!graduateQualification?.grade_record?.length">
        <p class="d-inline-block mb-2"
            id="text-structure-not-found">
          ไม่พบโครงสร้างหลักสูตร
        </p>
        <label class="d-inline-block mb-0 px-2">
          <vru-carbon-icon iconName="warning-alt-filled"
              ngbPopover="โปรดติดต่อผู้ดูแลระบบ"
              placement="right"
              presetFilter="fill-warning"
              size="20"
              triggers="mouseenter:">
          </vru-carbon-icon>
        </label>
      </div>
    </div>
  </div>
</div>

<ng-template #adjustCourseModal
    let-modal>
  <div class="modal-header">
    <h4>ปัดรายวิชา</h4>
  </div>
  <div class="modal-body py-4">
    <div class="form-group">
      <label for="select-course-group">กลุ่มวิชาใหม่</label>
      <ng-select #courseGroupSelect
          bindValue="id"
          id="select-course-group"
          placeholder="เลือก"
          [clearable]="false"
          [items]="courseGroupDropdown">
        <ng-template ng-option-tmp
            ng-label-tmp
            let-item="item">
          <div>{{ item.number }} {{ item.course_group_name }}</div>
        </ng-template>
      </ng-select>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <button class="btn btn-outline-green-vru mr-2"
          type="button"
          (click)="modal.dismiss()">
        ยกเลิก
      </button>
      <button class="btn btn-green-vru"
          type="button"
          [disabled]="!courseGroupSelect.hasValue"
          (click)="modal.close(courseGroupSelect.selectedItems[0].value)">
        ยืนยัน
      </button>
    </div>
  </div>
</ng-template>