import { Component } from '@angular/core';

@Component({
  selector: 'homepage-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  iconColor = 'fill-white';
  iconSize = 28;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

}
