import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DrfList } from '../common.model';
import {
  AvailableSyllabusParams,
  AdmissionProgram,
  ProgramParams,
  OpenedProgram,
  Program,
  ProgramParamsExpand,
  ProgramWrite,
} from './syllabus.model';

@Injectable({
  providedIn: 'root',
})
// TODO: Rename to ProgramApiService
export class SyllabusApiService {
  readonly admissionProgramUrl = '/admission-programs/';
  readonly programUrl = '/programs/';

  constructor(private http: ApiService) { }

  getAdmissionSyllabuses(params?: AvailableSyllabusParams): Observable<DrfList<AdmissionProgram>> {
    return this.http.get(this.admissionProgramUrl, params);
  }

  getAdmissionProgramDetails(id: number, params?: { [t: string]: string }): Observable<OpenedProgram> {
    return this.http.get(this.admissionProgramUrl + id + '/', params);
  }

  getPrograms(params?: ProgramParams): Observable<DrfList<Program>> {
    return this.http.get<DrfList<Program>>(this.programUrl, params);
  }

  // TODO: Rename to getProgramDetail
  getProgramsDetail<expand extends ProgramParamsExpand>(
    id: number,
    params?: ProgramParams
  ): Observable<Program<expand>> {
    return this.http.get(this.programUrl + id + '/', params);
  }

  patch(id: number, payload: Partial<ProgramWrite>): Observable<Program> {
    return this.http.patch([this.programUrl, id.toString()], payload);
  }
}
