<div class="page-container d-flex flex-column no-gutters pt-4">
  <div class="bg-white d-flex no-gutters flex-fill shadow-lg messages-chat-size overflow-auto">
    <div class="col-12 col-md-3 flex-column border"
        [ngClass]="isOpenChatRoom ? 'd-flex' : 'd-none d-md-flex'">
      <div class="h-100 d-flex no-gutters flex-column">
        <div class="d-flex justify-content-between align-items-center p-3 bg-green-vru">
          <h6 class="mb-0 text-white">กล่องข้อความ</h6>
          <div ngbDropdown>
            <vru-carbon-icon iconName="add-comment"
                presetFilter="fill-white"
                size="22"
                id="dropdownIcon"
                ngbDropdownToggle></vru-carbon-icon>
            <div ngbDropdownMenu
                aria-labelledby="dropdownIcon">
              <button ngbDropdownItem
                  (click)="onOpenNewChatModal('person')">รายบุคคล</button>
              <button ngbDropdownItem
                  (click)="onOpenNewChatModal('role')">ตำแหน่ง</button>
            </div>
          </div>
        </div>
        <ul class="list-group overflow-auto"
            (scroll)="onMessageScrollEnd($event)">
          <ng-container *ngFor="let room of chatRoomList">
            <li class="list-group-item list-group-item-action d-flex no-gutters align-items-center chat-room-height pointer p-2"
                [ngClass]="selectedChatRoom?.id === room.id ? 'active' : ''"
                (click)="onSelectChatRoom(room)">
              <div class="d-flex pl-2 pr-3">
                <ng-container *ngIf="room.users[0]?.photo; else elseTemplate">
                  <img class="profile-photo rounded rounded-circle"
                      [src]="room.users[0].photo"
                      alt="profile-photo">
                </ng-container>
                <ng-template #elseTemplate>
                  <ng-container *ngIf="room.users.length < 2; else groupIcon">
                    <vru-carbon-icon class="bg-white rounded rounded-circle"
                        iconName="user-avatar-filled"
                        presetFilter="fill-green-vru"
                        size="42"></vru-carbon-icon>
                  </ng-container>
                  <ng-template #groupIcon>
                    <div
                        class="d-flex justify-content-center align-items-center profile-photo bg-green-vru rounded rounded-circle border-icon border-2">
                      <vru-carbon-icon iconName="people-fill"
                          presetFilter="fill-white"
                          size="27"></vru-carbon-icon>
                    </div>
                  </ng-template>
                </ng-template>
              </div>
              <div class="d-flex flex-column flex-fill overflow-auto">
                <span class="text-truncate"
                    [ngClass]="!room.read ? 'font-weight-bolder' : 'font-weight-normal'">
                  <ng-container *ngIf="room.users.length === 1; else moreThanTwoUserTemplate">
                    <ng-container *ngFor="let user of room.users">
                      {{ user.first_name + ' ' + user.last_name + ' ' }}
                    </ng-container>
                  </ng-container>
                  <ng-template #moreThanTwoUserTemplate>
                    <ng-container *ngFor="let user of room.users; let j=index">
                      <ng-container *ngIf="(room.users.length - 1) !== j; else lastUserTemplate">
                        {{ user.first_name + ' ' + user.last_name + ', ' }}
                      </ng-container>
                      <ng-template #lastUserTemplate>
                        {{ user.first_name + ' ' + user.last_name }}
                      </ng-template>
                    </ng-container>
                  </ng-template>
                </span>
                <span class="text-truncate">{{ room.latest_message || '-' }}</span>
              </div>
              <div class="read-dot bg-info shadow"
                  *ngIf="!room.read"></div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <div class="col-12 col-md-9 flex-column"
        [ngClass]="isOpenChatRoom ? 'd-none d-md-flex' : 'd-flex'">
      <vru-chat-message class="h-100"
          [user]="user.id"
          [chatRoom]="selectedChatRoom"
          (closeChatRoom)="onCloseChatRoom()"></vru-chat-message>
    </div>
  </div>
</div>

<ng-template #AddNewChatModal>
  <div class="modal-header">
    <div class="d-flex justify-content-between align-items-center no-gutters w-100">
      <div class="d-flex flex-wrap no-gutters align-items-center">
        <vru-carbon-icon iconName="back"
            presetFilter="fill-white"
            (click)="onCloseModal()"></vru-carbon-icon>
        <span class="pointer"
            (click)="onCloseModal()">ย้อนกลับ</span>
      </div>
      <span>สร้างแชทกลุ่ม</span>
      <button class="btn btn-outline-green-vru bg-light"
          type="button"
          (click)="onClickCreateChat()">สร้าง</button>
    </div>
  </div>
  <div class="modol-body h-50">
    <div class="d-flex p-1 flex-column no-gutters">

      <vru-ng-select-typeahead *ngIf="selectNewChatType === 'person'"
          class="col-12 px-1 pt-2"
          [accessor]="userDropdownAccessor"
          [clearable]="false"
          [dataApiGenerator]="userDropdownGenerator"
          [startWith]="initialUserDropdown"
          [multiple]="true"
          [(ngModel)]="targetUsers"
          [class.spinner-blur-effect]="isInitialLoading">
        <ng-template vruNgSelectLabelDirective
            let-item>
          <span>{{ (item.first_name || '-') + ' ' + (item.last_name || '-') }}</span>
        </ng-template>
        <ng-template vruNgSelectOptionDirective
            let-item>
          <div class="">
            <h6>{{ item.first_name + ' ' + item.last_name }}</h6>
            <span>ตำแหน่ง: {{ item.role_display }}</span>
          </div>
        </ng-template>
      </vru-ng-select-typeahead>

      <ng-select *ngIf="selectNewChatType === 'role'"
          [items]="roleGroupDropdownList"
          bindLabel="label"
          bindValue="value"
          [multiple]="true"
          [(ngModel)]="targetRole"></ng-select>

      <div class="d-flex p-2 justify-content-between">
        <p class="mt-2 mb-1">คำเชิญ ({{ targetUsers.length || '0' }})</p>
      </div>
      <div class="d-flex flex-column container-users no-gutters p-1">
        <div class="overflow-auto"
            *ngIf="targetUsers.length; else noUserTemplate">
          <ng-container *ngFor="let user of targetUsers; let i=index">
            <div class="border bg-white p-3 px-4 col-12 d-flex align-items-center">
              <div class="d-flex flex-column">
                <span>{{ (user.first_name || '-') + ' ' + (user.last_name || '-') }}</span>
                <span class="text-secondary">ตำแหน่ง: {{ user.role_display || '-' }}</span>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-template #noUserTemplate>
          <div class="flex-fill none-users border-secondary d-flex align-items-center justify-content-center bg-light">
            <h1 class="text-secondary">เลือกผู้ใช้งาน</h1>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>