import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UtilityService } from '@vru/utils';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { DrfList, Nullable } from '../common.model';
import { exportProfileListReportParam, UserPermissionSetting } from '../security/permission.model';
import {
  ProfileParams,
  ProfileSignature,
  UserProfile,
  UserProfileDropdownItem,
  UserProfileListItem,
  UserProfilePatch,
  UserProfileRetrieve,
} from './user.model';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  readonly profile = '/profiles/';
  readonly publicProfile = '/profiles-dropdown/';
  readonly meUrl = '/me-v2/';
  readonly userPermissionUrl = '/settings/'

  constructor(
    private api: ApiService,
    private util: UtilityService,
    private store: Store
  ) {}

  create(payload: Partial<UserProfilePatch>): Observable<UserProfileRetrieve> {
    return this.api.post(this.profile, payload);
  }

  delete(id: number): Observable<unknown> {
    return this.api.delete([this.profile, id.toString()]);
  }

  getDetail(id: number): Observable<UserProfileRetrieve> {
    return this.api.get([this.profile, id.toString()]);
  }

  getList(
    params?: Nullable<ProfileParams>
  ): Observable<DrfList<UserProfileListItem>> {
    return this.api.get(this.profile, params);
  }

  getPublicList(
    params?: ProfileParams
  ): Observable<DrfList<UserProfileDropdownItem>> {
    return this.api.get(this.publicProfile, params);
  }

  getMyProfile(): Observable<UserProfile> {
    return this.api.get(this.meUrl);
  }

  getOwnProfileDetail<T = UserProfile>(subParams = ''): Observable<T> {
    return this.store
      .selectOnce((state) => state.auth)
      .pipe(
        switchMap((user) =>
          this.api.get<T>(this.profile + `${user.profile}/` + subParams)
        )
      );
  }

  getUserPermission(id: number): Observable<UserPermissionSetting>  {
    return this.api.get<UserPermissionSetting>(this.profile + id + this.userPermissionUrl)
  }

  update(
    id: number,
    payload: UserProfilePatch | FormData
  ): Observable<UserProfileRetrieve> {
    return this.api.patch([this.profile, id.toString()], payload);
  }

  updateOwnProfile(data: UserProfile): Observable<UserProfile> {
    return this.store
      .selectOnce((state) => state.auth)
      .pipe(
        switchMap((res) =>
          this.api.patch<UserProfile>(`${this.profile}${res.profile}/`, data)
        )
      );
  }

  updateOwnSignature(
    signature: string | Blob | null
  ): Observable<ProfileSignature> {
    return this.store
      .selectOnce((state) => state.auth)
      .pipe(
        map((res) => {
          let blob: Blob = signature as Blob;
          if (typeof signature === 'string') {
            blob = this.util.convertBase64ToBlob(signature.split(',')[1]);
          }
          const imageFile = signature
            ? new File([blob], 'profile_' + res.profile + '.png', {
                type: 'image/png',
              })
            : null;
          return { id: res.profile, signature: imageFile };
        }),
        switchMap((data) => {
          const formData = new FormData();
          formData.append('signature', data.signature as File);
          return this.api.patch<ProfileSignature>(
            `${this.profile}${data.id}/upload-signature/`,
            formData
          );
        })
      );
  }

  updateUserPermissionSetting(id: number, params: UserPermissionSetting) {
    return this.api.patch(this.profile + id + this.userPermissionUrl, params)
  }

  exportProfileListReport(params: exportProfileListReportParam): Observable<File> {
    return this.api.getBlob<File>(
      this.profile + 'export-profile-list/',
      params
    );
  }

  exportUserListReport(params: exportProfileListReportParam): Observable<File> {
    return this.api.getBlob<File>(
      this.profile + 'export-user-list/',
      params
    );
  }
}
