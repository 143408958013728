import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FileConfig,
  FileInputChangeEvent,
  UploadFile,
} from './upload-file-list.model';

@Component({
  selector: 'vru-upload-file-list',
  templateUrl: './upload-file-list.component.html',
  styleUrls: ['./upload-file-list.component.scss'],
})
export class UploadFileListComponent implements OnChanges, OnInit {
  /**
   * `fileConfigs` is preferred than `uploadFiles`.
   * if it is valid. the `uploadFiles` is reset with configuration.
   */
  @Input() fileConfigs!: FileConfig[];
  @Input() bindFileName = 'name';
  @Input() bindFileType = 'type';
  @Input() bindLabel = 'label';
  @Input() bindUrl!: string;
  @Input() headerTitle = 'Title';
  @Input() headerFilename = 'Filename';
  @Input() readonly = false;
  @Input() showHeader = false;
  @Input() showNumber = false;
  @Input() uploadFiles: UploadFile[] = [];
  @Output() uploadFileChange = new EventEmitter<FileInputChangeEvent>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileConfigs?.firstChange === false) {
      this.refreshUploadFiles();
    }
  }

  ngOnInit(): void {
    if (this.fileConfigs) {
      this.refreshUploadFiles();
    }
  }

  onFileInputChange(event: Event, uploadFile: UploadFile): void {
    uploadFile.file = (event.target as HTMLInputElement)?.files?.[0];
    this.uploadFileChange.emit({ files: this.uploadFiles, target: uploadFile });
  }

  onFileNameClick(url?: string, ev?: MouseEvent): void {
    if (ev) {
      ev.stopPropagation();
    }
    if (url) {
      window.open(url);
    }
  }

  refreshUploadFiles(): void {
    this.uploadFiles = this.fileConfigs.map((config) => {
      const uploadFile = this.uploadFiles.find(
        (uploadFile) =>
          uploadFile[this.bindFileType] === config[this.bindFileType]
      );
      return uploadFile ?? Object.assign({ file: null }, config);
    });
  }
}
