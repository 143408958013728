import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../api/api.service';
import { User, UserProfile } from '../../user/user.model';
import {
  AuthToken,
  ChangePasswordRequest,
  LoginPayload,
  ResetPasswordConfirmRequest,
  ResetPasswordRequest,
} from '../auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApiService {
  readonly refreshAuthPath = 'token-refresh';
  readonly tokenAuthPath = 'token-auth';

  constructor(private api: ApiService) {}

  changePassword(request: ChangePasswordRequest): Observable<User> {
    return this.api.post('/change-default-password/', request);
  }

  confirmResetPassword(request: ResetPasswordConfirmRequest) {
    return this.api.post('/password-reset/confirm/', request);
  }

  getAuthToken(payload: LoginPayload): Observable<AuthToken<UserProfile>> {
    return this.api.post(this.tokenAuthPath, payload);
  }

  refreshToken(token: string): Observable<{ access: string }> {
    return this.api.post(this.refreshAuthPath, {
      refresh: token,
    });
  }

  resetPassword(request: ResetPasswordRequest) {
    return this.api.post('/password-reset/', request);
  }
}
