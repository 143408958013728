import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { GradeSubmissionModule } from './grade-submission/grade-submission.module';

const routes: Route[] = [
  {
    path: 'submission',
    loadChildren: () => GradeSubmissionModule,
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GradeRoutingModule {}
