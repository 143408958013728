<div class="position-relative input-express-invalid"
    [formGroup]="userPermissionForm">
  <div class="board-white w-100 h-100 p-5 overflow-auto">
    <div class="col-12 px-0">
      <div class="row no-gutters align-items-center">
        <h6 *ngIf="otherUserId || createMode"
            class="pointer mb-0"
            (click)="onBackToListPage()">
          <span class="bi bi-chevron-left"></span>
          กลับ
        </h6>
        <div *ngIf="!createMode && !otherUserId"
            class="col text-right">
          <button class="btn btn-outline-white-smoke text-dark-gray shadow-sm px-3 py-1"
              type="button"
              [routerLink]="['./signature']">
            <label class="d-flex align-items-center pointer mb-0">
              <vru-carbon-icon iconName="../open-source/signature"
                  presetFilter="fill-blue-sapphire"
                  size="26">
              </vru-carbon-icon>
              <span class="ml-2">ตั้งค่าลายเซ็น</span>
            </label>
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 d-flex justify-content-center">
        <vru-upload-profile-photo radius="50%"
            [canEdit]="isAdmin"
            [src]="userPermissionForm.get('photo')?.value"
            (photoChange)="onUploadImage($event)"></vru-upload-profile-photo>
      </div>
    </div>
    <div class="frame-filter w-100 h-100 flex-nowrap mx-0 mt-5"
        [class.spinner-blur-effect]="isLoading">
      <h6 class="col-12 my-3">ข้อมูลผู้ใช้</h6>
      <div class="col-lg-12">
        <div class="row">
          <ng-container formGroupName="user">
            <div class="col-lg-3 mb-2 pb-4">
              <label for="username">Username<span class="text-red">*</span></label>
              <input type="text"
                  class="form-control"
                  formControlName="username"
                  id="username"
                  invalidMsg="กรุณาระบุชื่อบัญชีผู้ใช้งาน"
                  vruValidate
                  [ngClass]="{
                    'is-invalid': isSubmitted && fUser.username.errors
                  }"
                  autocomplete="off"
                  [readonly]="!createMode"
                  [submitted]="isSubmitted">
            </div>
            <div class="col-lg-3 mb-2 pb-4">
              <label for="password">รหัสผ่าน<span class="text-red">*</span></label>
              <input *ngIf="createMode"
                  autocomplete="off"
                  class="form-control"
                  formControlName="password"
                  id="password"
                  invalidMsg="กรุณาระบุรหัสผ่าน"
                  type="password"
                  vruValidate
                  [ngClass]="{
                    'is-invalid': isSubmitted && fUser.password.errors
                  }"
                  [submitted]="isSubmitted">
              <input *ngIf="!createMode"
                  class="form-control"
                  id="password"
                  readonly
                  type="password"
                  value="xxxxxxxxxxxx">
            </div>
          </ng-container>
          <div class="col-lg-3 mb-2 pb-4">
            <label>บทบาท<span class="text-red">*</span></label>
            <ng-select #roleSelect
                [items]="roleDropdown"
                formControlName="role"
                bindLabel="label"
                bindValue="value"
                invalidMsg="กรุณาเลือกบทบาท"
                placeholder="เลือก"
                vruValidate
                [clearable]="false"
                [submitted]="isSubmitted"
                [readonly]="!createMode"
                [ngClass]="{ 'is-invalid': isSubmitted && f.role.errors }">
              <ng-template ng-option-tmp
                  let-item="item">
                <div class="d-flex text-wrap">{{ item.label }}</div>
              </ng-template>
            </ng-select>
          </div>
          <div class="col-lg-3 mb-2 pb-4"
              formGroupName="user">
            <label>สถานะการใช้งาน<span class="text-red">*</span> </label>
            <ng-select [items]="activeStatusDropdown"
                bindLabel="label"
                bindValue="value"
                invalidMsg="กรุณาเลือกสถานะการใช้งาน"
                vruValidate
                [clearable]="false"
                [submitted]="isSubmitted"
                formControlName="is_active"
                placeholder="เลือก"
                [ngClass]="{
                  'is-invalid': isSubmitted && fUser.is_active.errors
                }">
              <ng-template ng-option-tmp
                  let-item="item">
                <div class="d-flex text-wrap">{{ item.label }}</div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </div>

    <div class="frame-filter w-100 h-100 flex-nowrap mx-0 mt-3"
        [class.spinner-blur-effect]="isLoading">
      <h6 class="col-12 my-3">ข้อมูลส่วนตัว</h6>
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 mb-2 pb-4">
            <label>คำนำหน้าชื่อ<span class="text-red">*</span></label>
            <ng-select [items]="nameTitleDropdown"
                bindLabel="label"
                bindValue="value"
                formControlName="name_title"
                invalidMsg="กรุณาเลือกคำนำหน้าชื่อ"
                placeholder="เลือก"
                vruValidate
                [clearable]="false"
                [ngClass]="{ 'is-invalid': isSubmitted && f.name_title.errors }"
                [submitted]="isSubmitted">
              <ng-template ng-option-tmp
                  let-item="item">
                <div class="d-flex text-wrap">{{ item.label }}</div>
              </ng-template>
            </ng-select>
          </div>
          <ng-container formGroupName="user">
            <div class="col-lg-3 mb-2 pb-4">
              <label for="first-name-thai">ชื่อ (ภาษาไทย)<span class="text-red">*</span></label>
              <input type="text"
                  autocomplete="off"
                  class="form-control"
                  id="first-name-thai"
                  invalidMsg="กรุณาระบุชื่อ (ภาษาไทย)"
                  formControlName="first_name"
                  vruValidate
                  [ngClass]="{
                    'is-invalid': isSubmitted && fUser.first_name.errors
                  }"
                  [submitted]="isSubmitted">
            </div>
            <div class="col-lg-3 mb-2 pb-4">
              <label for="last-name-thai">สกุล (ภาษาไทย)<span class="text-red">*</span></label>
              <input type="text"
                  autocomplete="off"
                  class="form-control"
                  id="last-name-thai"
                  invalidMsg="กรุณาระบุสกุล (ภาษาไทย)"
                  formControlName="last_name"
                  vruValidate
                  [ngClass]="{
                    'is-invalid': isSubmitted && fUser.last_name.errors
                  }"
                  [submitted]="isSubmitted">
            </div>
            <div class="col-lg-3 mb-2 pb-4">
              <label for="first-name-eng">ชื่อ (ภาษาอังกฤษ)<span class="text-red">*</span></label>
              <input type="text"
                  autocomplete="off"
                  class="form-control"
                  id="first-name-eng"
                  invalidMsg="กรุณาระบุชื่อ (ภาษาอังกฤษ)"
                  formControlName="first_name_eng"
                  vruValidate
                  [ngClass]="{
                    'is-invalid': isSubmitted && fUser.first_name_eng.errors
                  }"
                  [submitted]="isSubmitted">
            </div>
            <div class="col-lg-3 mb-2 pb-4">
              <label for="last-name-eng">สกุล (ภาษาอังกฤษ)<span class="text-red">*</span></label>
              <input type="text"
                  autocomplete="off"
                  class="form-control"
                  id="last-name-eng"
                  invalidMsg="กรุณาระบุสกุล (ภาษาอังกฤษ)"
                  formControlName="last_name_eng"
                  vruValidate
                  [ngClass]="{
                    'is-invalid': isSubmitted && fUser.last_name_eng.errors
                  }"
                  [submitted]="isSubmitted">
            </div>
          </ng-container>

          <div class="col-lg-3 mb-2 pb-4">
            <label for="marital-status">สถานภาพ<span class="text-red">*</span></label>
            <ng-select [items]="maritalDropdown"
                bindLabel="label"
                bindValue="value"
                id="marital-status"
                invalidMsg="กรุณาเลือกสถานภาพ"
                placeholder="เลือก"
                vruValidate
                [clearable]="false"
                formControlName="marital_status"
                [ngClass]="{
                  'is-invalid': isSubmitted && f.marital_status.errors
                }"
                [submitted]="isSubmitted">
              <ng-template ng-option-tmp
                  let-item="item">
                <div class="d-flex text-wrap">{{ item.label }}</div>
              </ng-template>
            </ng-select>
          </div>
          <div class="col-lg-3 mb-2 pb-4">
            <label for="phone">เบอร์ติดต่อ<span class="text-red">*</span></label>
            <input type="text"
                autocomplete="off"
                class="form-control"
                formControlName="phone_number"
                id="phone"
                [invalidMsg]="isSubmitted && f.phone_number.errors ? 'กรุณาระบุเบอร์ติดต่อ 10 ตัวหรือเบอร์ติดต่อข้ามประเทศ' : 'กรุณาระบุเบอร์ติดต่อ'"
                vruValidate
                [maxlength]="12"
                [ngClass]="{ 'is-invalid': isSubmitted && f.phone_number.errors }"
                [submitted]="isSubmitted">
          </div>
          <div formGroupName="user"
              class="col-lg-3 mb-2">
            <label for="email">E-mail<span class="text-red">*</span></label>
            <input type="email"
                autocomplete="off"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                class="form-control"
                id="email"
                formControlName="email"
                vruValidate
                [ngClass]="{ 'is-invalid': isSubmitted && fUser.email.errors }"
                [showMsg]="false"
                [submitted]="isSubmitted">
            <div class="invalid-feedback d-block"
                [class.invisible]="!(isSubmitted && fUser?.email?.errors)">
              <span *ngIf="fUser?.email?.errors?.required; else otherAlertTemp">
                กรุณาระบุ Email
              </span>
              <ng-template #otherAlertTemp>
                <span *ngIf="fUser.email?.errors?.pattern">
                  กรุณาระบุรูปแบบ Email ที่ถูกต้อง
                </span>
              </ng-template>
            </div>
          </div>
          <div class="col-lg-3 mb-2">
            <label>รหัสบัตรประชาชน<span class="text-red">*</span></label>
            <input type="text"
                autocomplete="off"
                class="form-control"
                formControlName="id_card"
                vruValidate
                [maxLength]="17"
                [ngClass]="{ 'is-invalid': isSubmitted && f.id_card.errors }"
                [showMsg]="false"
                [submitted]="isSubmitted">
            <div class="invalid-feedback d-block"
                [class.invisible]="!(isSubmitted && f?.id_card?.errors)">
              <ng-container *ngIf="!f?.id_card?.errors?.required; else requiredIdCardTemp">
                <span *ngIf="f.id_card?.errors?.idCard.pattern === false">
                  กรุณาระบุเลขบัตรประชาชนที่ถูกต้อง
                </span>
              </ng-container>
              <ng-template #requiredIdCardTemp>
                <span>กรุณาระบุเลขบัตรประชาชน</span>
              </ng-template>
            </div>
          </div>
          <div class="col-lg-12 mb-2 pb-4">
            <label for="address">ที่อยู่<span class="text-red">*</span></label>
            <textarea class="form-control"
                autocomplete="off"
                cols="30"
                rows="5"
                formControlName="address"
                id="address"
                invalidMsg="กรุณาระบุที่อยู่"
                vruValidate
                [ngClass]="{ 'is-invalid': isSubmitted && f.address.errors }"
                [submitted]="isSubmitted">
            </textarea>
          </div>
        </div>
      </div>
    </div>
    <ng-container #officer
        *ngIf="selectedRole != null && isOfficer && !isDeanOrTeacher">
      <div class="frame-filter w-100 h-100 flex-nowrap mx-0 mt-4"
          [class.spinner-blur-effect]="isLoading">
        <h6 class="col-12 my-3">ข้อมูล{{ selectedRoleName }}</h6>
        <div class="col-lg-12 mt-2">
          <div class="row">
            <div class="col-lg-3 col-xxl-2 my-2"
                formGroupName="officer">
              <label for="code">รหัส{{ selectedRoleName }}<span class="text-red">*</span></label>
              <input class="form-control"
                  autocomplete="off"
                  type="text"
                  maxlength="10"
                  id="code"
                  formControlName="code"
                  title="รหัส{{ selectedRoleName }}"
                  vruValidate
                  autocomplete="off"
                  [showMsg]="false"
                  [submitted]="isSubmitted">
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>สถานะการทำงาน<span class="text-red">*</span></label>
              <ng-select bindLabel="label"
                  bindValue="value"
                  placeholder="เลือก"
                  formControlName="work_status"
                  vruValidate
                  [items]="workStatusDropdown"
                  [searchable]="true"
                  [clearable]="true"
                  [showMsg]="false"
                  [submitted]="isSubmitted">
                <ng-template ng-option-tmp
                    let-item="item">
                  <div class="d-flex text-wrap">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>ประเภทบุคลากร<span class="text-red">*</span></label>
              <ng-select bindLabel="label"
                  bindValue="value"
                  placeholder="เลือก"
                  formControlName="employment_types"
                  vruValidate
                  [items]="employeeTypeDropdown"
                  [multiple]="true"
                  [searchable]="true"
                  [clearable]="true"
                  [showMsg]="false"
                  [submitted]="isSubmitted">
                <ng-template ng-option-tmp
                    let-item="item">
                  <div class="d-flex text-wrap">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label for="start-date">วันที่เข้าทำงาน<span class="text-red">*</span></label>
              <input type="text"
                  class="form-control input-container readonly"
                  name="dpTimeStart"
                  autocomplete="off"
                  ngbDatepicker
                  #dpTimeStart="ngbDatepicker"
                  formControlName="start_date"
                  id="start-date"
                  pattern="\d{4}-\d{2}-\d{2}"
                  oninput="validity.valid||(value=null)"
                  vruValidate
                  [placeholder]="'เลือกช่วงวัน'"
                  [showMsg]="false"
                  [submitted]="isSubmitted"
                  (click)="dpTimeStart.toggle()">
              <span class="span-calendar">
                <span class="bi bi-calendar-event text-gray"
                    (click)="dpTimeStart.toggle()"></span>
              </span>
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label for="resign-date">วันที่ลาออก</label>
              <input type="text"
                  class="form-control input-container"
                  name="dpTimeStart"
                  autocomplete="off"
                  ngbDatepicker
                  #dpTimeEnd="ngbDatepicker"
                  formControlName="resignation_date"
                  id="resign-date"
                  pattern="\d{4}-\d{2}-\d{2}"
                  oninput="validity.valid||(value=null)"
                  vruValidate
                  [placeholder]="'เลือกช่วงวัน'"
                  [showMsg]="false"
                  [submitted]="isSubmitted"
                  (click)="dpTimeEnd.toggle()">
              <span class="span-calendar">
                <span class="bi bi-calendar-event text-gray"
                    (click)="dpTimeEnd.toggle()"></span>
              </span>
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>คณะ</label>
              <ng-select bindLabel="label"
                  bindValue="value"
                  placeholder="เลือก"
                  formControlName="faculty"
                  vruValidate
                  [items]="facultyDropdown"
                  [searchable]="true"
                  [clearable]="true"
                  [showMsg]="false"
                  [submitted]="isSubmitted"
                  (change)="onSelectFaculty($event)">
                <ng-template ng-option-tmp
                    let-item="item">
                  <div class="d-flex text-wrap">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>สาขาวิชา</label>
              <ng-select bindLabel="label"
                  bindValue="value"
                  placeholder="เลือก"
                  formControlName="field_of_study"
                  notFoundText="ไม่พบสาขาวิชา"
                  vruValidate
                  [items]="fieldOfStudyDropdown"
                  [searchable]="true"
                  [clearable]="true"
                  [showMsg]="false"
                  [submitted]="isSubmitted"
                  [readonly]="!isSelectFaculty">
                <ng-template ng-option-tmp
                    let-item="item">
                  <div class="d-flex text-wrap">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row mt-4">
            <div class="col">
              <h6>คุณวุฒิทางการศึกษา</h6>
            </div>
          </div>
        </div>
        <ng-container formArrayName="educational_records"
            *ngFor="let item of educationalRecords?.controls; index as i">
          <div class="col-12 d-flex no-gutters px-0"
              [formGroupName]="i">
            <div class="d-flex flex-wrap col-11 col-xxl-6 mt-4">
              <div class="col-12 col-md-6 col-lg-3 my-2">
                <label>คุณวุฒิ-สาขาวิชา</label>
                <input type="text"
                    autocomplete="off"
                    formControlName="educational_background"
                    class="form-control" />
              </div>
              <div class="col-12 col-md-6 col-lg-3 my-2">
                <label>สถาบันการศึกษา</label>
                <input type="text"
                    autocomplete="off"
                    formControlName="academy"
                    class="form-control" />
              </div>
              <div class="col-12 col-md-6 col-lg-3 my-2">
                <label>ระดับการศึกษา</label>
                <ng-select bindLabel="label"
                    bindValue="value"
                    placeholder="เลือก"
                    formControlName="degree"
                    [items]="degreeDropdown"
                    [searchable]="true"
                    [clearable]="true">
                  <ng-template ng-option-tmp
                      let-item="item">
                    <div class="d-flex text-wrap">{{ item.label }}</div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-12 col-md-6 col-lg-3 my-2">
                <label>ปีที่จบ</label>
                <input type="text"
                    autocomplete="off"
                    formControlName="year"
                    class="form-control" />
              </div>
            </div>
            <ng-container *vruDisplayPermission="['admin']">
              <div class="d-flex col align-items-end mb-2 pb-2 px-2"
                  *ngIf="educationalRecords.controls.length > 1">
                <vru-carbon-icon iconName="delete"
                    (click)="deleteDynamicEducationalForms(i)"></vru-carbon-icon>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <div *vruDisplayPermission="['admin']"
            class="d-flex col justify-content-start mt-3">
          <button class="btn btn-green-vru"
              (click)="addDynamicEducationalForms()">
            + เพิ่มข้อมูล
          </button>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="permissionTemplate"></ng-container>
    </ng-container>
    <ng-container #teacher
        *ngIf="isDeanOrTeacher">
      <div class="frame-filter w-100 h-100 flex-nowrap mx-0 mt-4"
          [class.spinner-blur-effect]="isLoading">
        <h6 class="col-12 my-3">ข้อมูล{{ selectedRoleName }}</h6>
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-3 col-xxl-2 my-2">
              <label for="teacher-code">รหัสอาจารย์<span class="text-red">*</span></label>
              <ng-container formGroupName="teacher">
                <input class="form-control"
                    maxlength="10"
                    autocomplete="off"
                    formControlName="code"
                    id="teacher-code"
                    type="text"
                    vruValidate
                    [showMsg]="false"
                    [submitted]="isSubmitted">
              </ng-container>
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>สถานะการทำงาน<span class="text-red">*</span></label>
              <ng-select bindLabel="label"
                  bindValue="value"
                  placeholder="เลือก"
                  formControlName="work_status"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted"
                  [items]="workStatusDropdown"
                  [searchable]="true"
                  [clearable]="true">
                <ng-template ng-option-tmp
                    let-item="item">
                  <div class="d-flex text-wrap">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>ประเภทบุคลากร<span class="text-red">*</span></label>
              <ng-select bindLabel="label"
                  bindValue="value"
                  placeholder="เลือก"
                  formControlName="employment_types"
                  [items]="employeeTypeDropdown"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted"
                  [multiple]="true"
                  [searchable]="true"
                  [clearable]="true">
                <ng-template ng-option-tmp
                    let-item="item">
                  <div class="d-flex text-wrap">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>วันที่เข้าทำงาน<span class="text-red">*</span></label>
              <input type="text"
                  class="form-control input-container"
                  name="dpTimeStart"
                  autocomplete="off"
                  ngbDatepicker
                  #dpTimeStart="ngbDatepicker"
                  formControlName="start_date"
                  pattern="\d{4}-\d{2}-\d{2}"
                  oninput="validity.valid||(value=null)"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted"
                  [placeholder]="'เลือกช่วงวัน'"
                  (click)="dpTimeStart.toggle()" />
              <span class="span-calendar">
                <span class="bi bi-calendar-event text-gray"
                    (click)="dpTimeStart.toggle()"></span>
              </span>
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>วันที่ลาออก</label>
              <input type="text"
                  class="form-control input-container"
                  name="dpTimeStart"
                  autocomplete="off"
                  ngbDatepicker
                  #dpTimeEnd="ngbDatepicker"
                  formControlName="resignation_date"
                  pattern="\d{4}-\d{2}-\d{2}"
                  oninput="validity.valid||(value=null)"
                  [placeholder]="'เลือกช่วงวัน'"
                  (click)="dpTimeEnd.toggle()" />
              <span class="span-calendar">
                <span class="bi bi-calendar-event text-gray"
                    (click)="dpTimeEnd.toggle()"></span>
              </span>
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>คณะ<span class="text-red">*</span></label>
              <ng-select bindLabel="label"
                  bindValue="value"
                  placeholder="เลือก"
                  formControlName="faculty"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted"
                  [items]="facultyDropdown"
                  [searchable]="true"
                  [clearable]="true"
                  (change)="onSelectFaculty($event)">
                <ng-template ng-option-tmp
                    let-item="item">
                  <div class="d-flex text-wrap">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>สาขาวิชา</label>
              <ng-select bindLabel="label"
                  bindValue="value"
                  placeholder="เลือก"
                  formControlName="field_of_study"
                  notFoundText="ไม่พบสาขาวิชา"
                  [items]="fieldOfStudyDropdown"
                  [searchable]="true"
                  [clearable]="true"
                  [readonly]="!isSelectFaculty">
                <ng-template ng-option-tmp
                    let-item="item">
                  <div class="d-flex text-wrap">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-lg-12"
            formGroupName="teacher">
          <div class="row mt-4">
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>สถานะอาจารย์<span class="text-red">*</span></label>
              <ng-select bindLabel="label"
                  bindValue="value"
                  placeholder="เลือก"
                  formControlName="status"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted"
                  [items]="teacherStatusDropdown"
                  [searchable]="true"
                  [clearable]="true">
                <ng-template ng-option-tmp
                    let-item="item">
                  <div class="d-flex text-wrap">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>ประเภทอาจารย์<span class="text-red">*</span></label>
              <ng-select bindLabel="label"
                  bindValue="value"
                  placeholder="เลือก"
                  formControlName="teacher_type"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted"
                  [items]="teacherTypeDropdown"
                  [searchable]="true"
                  [clearable]="true">
                <ng-template ng-option-tmp
                    let-item="item">
                  <div class="d-flex text-wrap">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-lg-12"
            formGroupName="teacher">
          <div class="row mt-4">
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>คำนำหน้าชื่อสุภาพ (ย่อ)<span class="text-red">*</span></label>
              <input type="text"
                  autocomplete="off"
                  formControlName="short_polite_name_title_thai"
                  class="form-control"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted" />
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>คำนำหน้าชื่อสุภาพ (เต็ม)<span class="text-red">*</span></label>
              <input type="text"
                  autocomplete="off"
                  formControlName="full_polite_name_title_thai"
                  class="form-control"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted" />
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>คำนำหน้าชื่อสุภาพอังกฤษ (ย่อ)<span class="text-red">*</span></label>
              <input type="text"
                  autocomplete="off"
                  formControlName="short_polite_name_title_eng"
                  class="form-control"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted" />
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>คำนำหน้าชื่อสุภาพอังกฤษ (เต็ม)<span class="text-red">*</span></label>
              <input type="text"
                  autocomplete="off"
                  formControlName="full_polite_name_title_eng"
                  class="form-control"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted" />
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>ตำแหน่งทางวิชาการ (ย่อ)<span class="text-red">*</span></label>
              <input type="text"
                  autocomplete="off"
                  formControlName="short_academic_position"
                  class="form-control"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted" />
            </div>
            <div class="col-lg-3 col-xxl-2 my-2">
              <label>ตำแหน่งทางวิชาการ (เต็ม)<span class="text-red">*</span></label>
              <input type="text"
                  autocomplete="off"
                  formControlName="full_academic_position"
                  class="form-control"
                  vruValidate
                  [showMsg]="false"
                  [submitted]="isSubmitted" />
            </div>
          </div>
        </div>
        <br />
        <div class="col-lg-12">
          <div class="row mt-4">
            <div class="col">
              <h6>คุณวุฒิทางการศึกษา</h6>
            </div>
          </div>
        </div>
        <ng-container formArrayName="educational_records"
            *ngFor="let item of educationalRecords?.controls; index as i">
          <div class="col-12 d-flex no-gutters px-0"
              [formGroupName]="i">
            <div class="d-flex flex-wrap col-11 mt-4">
              <div class="col-12 col-xl-6 col-xxl-4 my-2">
                <label>คุณวุฒิ-สาขาวิชา</label>
                <input type="text"
                    autocomplete="off"
                    formControlName="educational_background"
                    class="form-control" />
              </div>
              <div class="col-12 col-xl-6 col-xxl-4 my-2">
                <label>สถาบันการศึกษา</label>
                <input type="text"
                    autocomplete="off"
                    formControlName="academy"
                    class="form-control" />
              </div>
              <div class="col-12 col-md-6 col-xxl-2 my-2">
                <label>ระดับการศึกษา</label>
                <ng-select bindLabel="label"
                    bindValue="value"
                    placeholder="เลือก"
                    formControlName="degree"
                    [items]="degreeDropdown"
                    [searchable]="true"
                    [clearable]="true">
                  <ng-template ng-option-tmp
                      let-item="item">
                    <div class="d-flex text-wrap">{{ item.label }}</div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-12 col-md-6 col-xxl-2 my-2">
                <label>ปีที่จบ</label>
                <input type="text"
                    autocomplete="off"
                    formControlName="year"
                    class="form-control" />
              </div>
            </div>
            <ng-container *vruDisplayPermission="['admin']">
              <div class="d-flex col align-items-end mb-2 pb-2 px-2"
                  *ngIf="educationalRecords.controls.length > 1">
                <vru-carbon-icon iconName="delete"
                    (click)="deleteDynamicEducationalForms(i)"></vru-carbon-icon>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <div *vruDisplayPermission="['admin']"
            class="d-flex col justify-content-start mt-3">
          <button class="btn btn-green-vru"
              (click)="addDynamicEducationalForms()">
            + เพิ่มข้อมูล
          </button>
        </div>
        <ng-container *ngIf="roleSelected === 'teacher' || roleSelected === 'dean'">
          <ng-container formGroupName="teacher">
            <div class="col-lg-12">
              <div class="row mt-4">
                <div class="col">
                  <H6>ผลงานทางวิชาการ</H6>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="row mt-4">
                <div class="col-12">
                  <label>หนังสือ ตำรา งานแปล (ย้อนหลัง 5 ปี)<span class="text-red">*</span></label>
                  <textarea class="form-control"
                      rows="10"
                      formControlName="book"
                      vruValidate
                      [showMsg]="false"
                      [submitted]="isSubmitted"></textarea>
                </div>
                <div class="col-12 mt-3">
                  <label>งานวิจัย/บทความวิจัย (ย้อนหลัง 5 ปี)<span class="text-red">*</span></label>
                  <textarea class="form-control"
                      rows="10"
                      formControlName="research"
                      vruValidate
                      [showMsg]="false"
                      [submitted]="isSubmitted"></textarea>
                </div>
                <div class="col-12 mt-3">
                  <label>บทความทางวิชาการ (ย้อนหลัง 5 ปี)<span class="text-red">*</span></label>
                  <textarea class="form-control"
                      rows="10"
                      formControlName="academic_article"
                      vruValidate
                      [showMsg]="false"
                      [submitted]="isSubmitted"></textarea>
                </div>
                <div class="col-12 mt-3">
                  <label>สิ่งประดิษฐ์และการสร้างสรรค์ (ย้อนหลัง 5 ปี)<span class="text-red">*</span></label>
                  <textarea class="form-control"
                      rows="10"
                      formControlName="invention_and_creativity"
                      vruValidate
                      [showMsg]="false"
                      [submitted]="isSubmitted"></textarea>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="roleSelected === 'teacher'">
          <div class="col-lg-12">
            <div class="row mt-4">
              <div class="col"
                  *ngIf="teacherRole.length > 0">
                <h6>หลักสูตรที่รับผิดชอบ</h6>
              </div>
            </div>
          </div>
          <div class="col-lg-12"
              *ngFor="let item of teacherRole">
            <div class="row mt-4">
              <div class="col-lg-6">
                <label>ชื่อหลักสูตร</label>
                <input type="text"
                    autocomplete="off"
                    class="form-control"
                    readonly
                    title="หลักสูตร"
                    [value]="item.program.name || 'ไม่พบชื่อหลักสูตร'">
              </div>
              <div class="col-lg-6">
                <label>ชื่อตำแหน่งทางวิชาการ</label>
                <input type="text text-center"
                    autocomplete="off"
                    class="form-control"
                    readonly
                    title="ตำแหน่งทางวิชาการ"
                    [value]="item.position_display || 'ไม่พบชื่อตำแหน่งทางวิชาการ'">
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <ng-container *ngTemplateOutlet="permissionTemplate"></ng-container>
    </ng-container>

    <div class="row mb-4">
      <div class="col d-flex justify-content-end">
        <div class="position-relative px-2">
          <div class="d-flex justify-content-end">
            <button class="btn btn-outline-green-vru mt-30"
                data-spinner-color="#2d7054"
                type="button"
                [ladda]="isSubmitting"
                (click)="onBackToListPage()">
              ยกเลิก
            </button>
          </div>
        </div>
        <div class="position-relative">
          <div class="d-flex justify-content-end">
            <button class="btn btn-green-vru mt-30"
                type="button"
                [ladda]="isSubmitting"
                (click)="onSubmit()">
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-spinner-float">
      <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
          name="user-detail"
          color="#3cd070"
          size="medium"
          type="ball-clip-rotate-pulse"
          [fullScreen]="false"
          [showSpinner]="isLoading">
      </ngx-spinner>
    </div>
  </div>
</div>
<ng-template #permissionTemplate>
  <ng-container *vruDisplayPermission="['backoffice', 'admin']">
    <div *ngIf="!createMode"
        class="frame-filter mt-4 w-100 h-100 flex-nowrap mx-0 d-flex flex-column"
        [class.spinner-blur-effect]="isLoading"
        [formGroup]="permissionForm">
      <h6 class="col-12 my-3">กำหนดสิทธิ์การใช้งาน</h6>
      <div class="col-12 col-xl-6 d-flex flex-wrap no-gutters">
        <ng-container formArrayName="user_permissions">
          <ng-container *ngFor="let permission of userPermissionFormArray?.controls; let index=index"
              [formGroupName]="index">
            <div class="col-12 d-flex flex-nowrap no-gutters">
              <label class="col">{{ permission.get('code_name_th')?.value }}</label>
              <div class="custom-control custom-switch vru-checkbox">
                <input type="checkbox"
                    class="custom-control-input"
                    [id]="'system-toggle' + index"
                    formControlName="value">
                <label class="custom-control-label"
                    [for]="'system-toggle' + index">{{ permission.get('user_permissions.value')?.value }}</label>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="receiptPermissionFormArray.length > 0">
          <div class="col-12 d-flex flex-wrap no-gutters">
            <label class="col">ประเภทค่าธรรมเนียม</label>
            <button class="btn btn-outline-green-vru py-0 rounded rounded-pill"
                (click)="onOpenReceiptModal()">
              <span class="pointer bi-list-ul mb-0 mr-2"></span>
              <span>เลือก</span>
            </button>
          </div>
        </ng-container>
      </div>
      <div class="col-12 d-flex flex-column"
          formArrayName="field_of_study_permissions">
        <ng-container *ngFor="let permission of fieldOfStudyPermissionFormArray?.controls; let index=index"
            [formGroupName]="index">
          <label>{{ permission.get('permission')?.value.code_name_th }}</label>
          <ng-container formArrayName="field_of_studies">
            <ng-container *ngFor="let item of fieldOfStudyDetailPermissionFormArray(index)?.controls; let i=index"
                [formGroupName]="i">
              <div class="col-12 col-xl-6 d-flex flex-wrap align-items-end no-gutters">
                <div class="col row flex-wrap">
                  <div class="col-12 col-sm-6 form-group">
                    <label>คณะ</label>
                    <ng-select [items]="facultyDropdown"
                        bindLabel="label"
                        bindValue="value"
                        placeholder="เลือก"
                        formControlName="faculty"
                        [readonly]="!createMode && !isAdmin && !isBackoffice"
                        (change)="onSelectFacultyPermission(index, i, $event?.value)">
                      <ng-template ng-option-tmp
                          let-item="item">
                        <div class="d-flex text-wrap">{{ item.label }}</div>
                      </ng-template>
                    </ng-select>
                  </div>
                  <div class="col-12 col-sm-6 form-group">
                    <label>สาขา</label>
                    <ng-select bindLabel="label"
                        bindValue="value"
                        [items]="fieldOfStudyDetailPermissionFormArray(index).at(i).get('dropdown_list')?.value || initialFieldOfStudyDropdown"
                        [readonly]="!(fieldOfStudyDetailPermissionFormArray(index).at(i).get('faculty')?.value)"
                        notFoundText="ไม่พบข้อมูลสาขา"
                        placeholder="เลือก"
                        formControlName="id">
                      <ng-template ng-option-tmp
                          let-item="item">
                        <div class="d-flex text-wrap">{{ item.label }}</div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <ng-container *ngIf="fieldOfStudyDetailPermissionFormArray(index).length - 1 === i; else elseTemplate">
                  <span class="col-auto form-group bi bi-plus-circle pointer pb-2 ml-auto pr-xl-2"
                      (click)="addFieldOfStudyDetail(index)"></span>
                </ng-container>
                <ng-template #elseTemplate>
                  <span class="col-auto form-group bi bi-dash-circle pointer pb-2 ml-auto pr-xl-2"
                      (click)="deleteFieldOfStudyDetail(index, i)"></span>
                </ng-template>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #receiptSettingTemplate>
  <form [formGroup]="permissionForm">
    <ng-container formArrayName="receipt_permissions">
      <div class="d-flex flex-column no-gutters pt-5 pb-3 px-4 rounded bg-white">
        <div class="d-flex flex-wrap">
          <div class="col">
            <h5>กำหนดรายการค่าใช้จ่าย</h5>
          </div>
          <div class="custom-control custom-switch vru-checkbox">
            <input type="checkbox"
                class="custom-control-input"
                id="select-all-receipt"
                [(ngModel)]="isSelectAllReceipt"
                [ngModelOptions]="{standalone: true}"
                (change)="onToggleAllReceipt()">
            <label class="custom-control-label"
                for="select-all-receipt">{{ isSelectAllReceiptToggle ? 'ยกเลิกทั้งหมด' : 'เลือกทั้งหมด' }}</label>
          </div>
        </div>
        <div class="d-flex flex-wrap mt-4">
          <ng-container *ngFor="let receipt of receiptPermissionFormArray.controls; let index=index"
              [formGroupName]="index">
            <div class="col-12 col-sm-6 d-flex flex-nowrap no-gutters toggle-container align-items-center">
              <label class="col mb-0 p-2">{{ receipt.get('code_name_th')?.value }}</label>
              <div class="custom-control custom-switch vru-checkbox">
                <input type="checkbox"
                    class="custom-control-input"
                    [id]="'receipt-toggle' + index"
                    formControlName="value"
                    (change)="onToggleReceipt()">
                <label class="custom-control-label"
                    [for]="'receipt-toggle' + index"></label>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="modal-footer d-flex flex-wrap mt-4">
          <button class="btn btn-green-vru mr-2"
              type="button"
              (click)="onSubmitReceiptToggle()">ตกลง</button>
          <button class="btn btn-outline-green-vru"
              type="button"
              (click)="onClearReceiptToggle()">ยกเลิก</button>
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>