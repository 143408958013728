import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationTableFooterComponent } from './pagination-table-footer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PaginationTableFooterComponent],
  exports: [PaginationTableFooterComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,],
})
export class PaginationTableFooterModule {}
