<ng-container [ngSwitch]="status">
  <span *ngSwitchCase="'approved'"
      class="bi bi-file-earmark-check-fill text-green-munsell"
      [ngbTooltip]="getThaiTqfStatus(status)">
  </span>
  <span *ngSwitchCase="'in_use'"
      class="bi bi-file-earmark-slides-fill text-green-vru"
      [ngbTooltip]="getThaiTqfStatus(status)">
  </span>
  <span *ngSwitchCase="'cancel'"
      class="bi bi-file-earmark-x-fill text-red"
      [ngbTooltip]="getThaiTqfStatus(status)">
  </span>
  <span *ngSwitchCase="'need_to_revise'"
      class="bi bi-exclamation-diamond-fill text-red"
      [ngbTooltip]="getThaiTqfStatus(status)">
  </span>
  <span *ngSwitchDefault
      class="bi bi-file-earmark-play-fill text-warning"
      [ngbTooltip]="getThaiTqfStatus(status)">
  </span>
</ng-container>