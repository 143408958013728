import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from '../api/api.service';
import { CurrentSemester } from '../education/student/student.model';
import { ChangeSectionBody } from './enroll-change-group.model';

@Injectable({
  providedIn: 'root',
})
export class EnrollChangeService {
  private readonly CoursePath = '/course-sections/';
  constructor(private apiService: ApiService) {}

  patchChangeSection(
    section_no: { section_no: number },
    data: ChangeSectionBody
  ) {
    return this.apiService.patch(
      `${this.CoursePath}${section_no}/change_section/`,
      data
    );
  }
  patchChangeCourse(
    section_no: { section_no: number },
    data: ChangeSectionBody
  ) {
    return this.apiService.patch(
      `${this.CoursePath}${section_no}/change_course/`,
      data
    );
  }
  getCurrentSemester(): Observable<CurrentSemester> {
    return this.apiService.get<CurrentSemester>(
      '/academic-calendars/current-semester/'
    );
  }
}
