import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UtilityService } from '@vru/utils';
import * as _ from 'lodash';
import { MapConfigs } from './information-display.model';

@Component({
  selector: 'vru-information-display',
  templateUrl: './information-display.component.html',
  styleUrls: ['./information-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationDisplayComponent implements OnChanges {
  @Input() dataMap: MapConfigs[] = [];
  @Input() labelWidth?: number | string;
  @Input() source: { [k: string]: any } = {};
  @Input() header?: string;

  constructor(private utils: UtilityService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.labelWidth) {
      if (changes.labelWidth.currentValue === 'fit') {
        const maxWidth = this.dataMap.reduce((width, item) => {
          const labelWidth = this.utils.getTextWidth(item.label);
          if (labelWidth > width) {
            return labelWidth;
          }
          return width;
        }, 0);
        this.labelWidth =
          maxWidth !== 0 ? `calc(${maxWidth.toFixed(1)}px + 1.5rem)` : 'auto';
      } else if (typeof this.labelWidth === 'number') {
        this.labelWidth = this.labelWidth.toString() + 'px';
      }
    }
  }

  checkIsMany(content: any): boolean {
    return Array.isArray(content);
  }

  getAttribute(source: { [k: string]: any }, path: string) {
    return _.get(source, path);
  }

  getColClass(size?: MapConfigs['maxSize'] | null): string | undefined {
    if (size === 'full') {
      return 'col-xxl-12 col-lg-12';
    } else if (size === 'half') {
      return 'col-xxl-6';
    } else if (size === 'trimester') {
      return 'col-xxl-4';
    }
    return undefined;
  }

  getDateTime(dateIso: string): Date {
    return new Date(dateIso);
  }
}
