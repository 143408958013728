import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgLabelComponent } from './svg-label.component';

@NgModule({
  declarations: [SvgLabelComponent],
  exports: [SvgLabelComponent],
  imports: [CommonModule],
})
export class SvgLabelModule {}
