<div class="label-arrow">
  <svg [attr.height]="labelHeight"
      [attr.width]="labelWidth">
    <path [attr.d]="labelSvgPath"
        [attr.fill]="backgroundColor" />
    <text class="label-text"
        [attr.x]="(labelWidth / 2) - ((title?.length || 0) * titleSize / 3.2)"
        [attr.y]="(labelHeight / 2) + (titleSize / 3)"
        [style.fill]="titleColor"
        [style.font-size.px]="titleSize">
      {{ title }}
    </text>
  </svg>
</div>