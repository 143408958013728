<div class="position-relative input-express-invalid">
  <div class="board-white d-flex-column w-100 h-100 px-5 py-4 overflow-auto"
      [class.spinner-blur-effect]="isLoading">
    <div class="row justify-content-between mt-2 mb-4">
      <div class="col-auto d-flex align-items-start">
        <div class="d-flex align-items-center pointer"
            (click)="onBackToListGrade()">
          <vru-carbon-icon class="d-inline-block"
              iconName="chevron-left"
              size="22">
          </vru-carbon-icon>
          <h5 class="mb-0">กลับไปหน้ารายการ</h5>
        </div>
      </div>
      <div class="col-auto d-flex justify-content-end align-items-end">
        <button class="btn btn-outline-green-vru"
            type="button"
            (click)="submitPrint('pdf')">
          <span class="bi bi-file-earmark-text h5 mb-0"></span>
          <span class="d-none d-md-inline ml-2">พิมพ์รายงาน</span>
        </button>
      </div>
    </div>
    <div *ngIf="!isLoading"
        class="row no-gutters badge badge-warning mb-3 py-3">
      <label *ngIf="gradeDeadlines.length"
          class="text-dark-gray mr-5 mb-0">
        <span class="font-weight-normal">สามารถบันทึกเกรดได้ตั้งแต่</span>
        <span *ngFor="let deadline of gradeDeadlines; index as i; last as last"
            class="text-red mx-1">
          {{deadline.start_date_display}} ถึง {{deadline.end_date_display}}
          <ng-container *ngIf="gradeDeadlines.length > 1 && !last">,</ng-container>
        </span>
        <span class="font-weight-normal">เท่านั้น!</span>
      </label>
    </div>
    <div class="frame-filter flex-nowrap grade-info mx-0 mb-4 p-4">
      <div class="form-group row">
        <div class="col-lg-6">
          <div class="row mx-0">
            <label class="col-form-label label-title">ภาคการศึกษา</label>
            <label class="col-form-label col">
              {{gradeSubmissionDetail?.semester_course?.semester || '-'}}
            </label>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row mx-0">
            <label class="col-form-label label-title">ปีการศึกษา</label>
            <label class="col-form-label col">
              {{gradeSubmissionDetail?.semester_course?.academic_year || '0'}}
            </label>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row mx-0">
            <label class="col-form-label label-title">ผู้สอน</label>
            <div class="col-form-label col">
              <div *ngFor="let teachers of gradeSubmissionDetail?.teachers">
                <label class="text-primary tag-green">
                  {{ teachers?.name || 'ไม่พบชื่อผู้สอน' }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row mx-0">
            <label class="col-form-label label-title">เวลาเรียน</label>
            <div class="col col-form-label">
              <div *ngFor="let timetable of gradeSubmissionDetail?.timetable_managements"
                  class="d-block">
                <label class="label-day mb-0">{{ thaiShortDays[days[timetable.day]] }}</label>
                <label class="mb-0">({{ timetable.start_time }} - {{ timetable.end_time }})</label>
              </div>
              <label *ngIf="!gradeSubmissionDetail?.timetable_managements?.length">
                ไม่พบเวลาเรียน
              </label>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row mx-0">
            <label class="col-form-label label-title">รายวิชา</label>
            <div class="col col-form-label">
              <label class="d-block">
                {{gradeSubmissionDetail?.semester_course?.course?.code || '-'}}
                {{gradeSubmissionDetail?.semester_course?.course?.name_thai || '-'}}
              </label>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row mx-0">
            <label class="col-form-label label-title">เวลาสอบ</label>
            <label class="col col-form-label">
              <div *ngFor="let examTimetable of gradeSubmissionDetail?.examination_managements"
                  class="d-block">
                <label class="mb-0">{{ examTimetable.datetime_as_text }}</label>
              </div>
              <label *ngIf="!gradeSubmissionDetail?.timetable_managements?.length">
                ไม่พบเวลาเรียน
              </label>
            </label>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row mx-0">
            <label class="col-form-label label-title">ประเภทนักศึกษา</label>
            <label class="col col-form-label">
              {{gradeSubmissionDetail?.student_type?.name || '-'}}
            </label>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row mx-0">
            <div class="col-form-label label-title">คณะที่ยืนยันผลการเรียน</div>
            <div class="col col-form-label">
              {{gradeSubmissionDetail?.approve_dean?.name_thai || '-'}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="frame-filter flex-nowrap mx-0 mb-4 p-4">
      <form [formGroup]="gradeSettingForm">
        <div class="form-group d-flex mb-4">
          <label class="col-form-label label-title">วิธีตัดเกรด</label>
          <div class="col col-lg-3">
            <ng-select #gradeCalculationMethod
                bindLabel="label"
                bindValue="value"
                placeholder="เลือก"
                formControlName="calculate_grade_method"
                [clearable]="false"
                [items]="gradeMethodDropdown"
                [readonly]="!isGradeSettingEditMode"
                [searchable]="true"
                (change)="onGradeCalculationMethodChange($event.value)">
            </ng-select>
          </div>
        </div>
        <ng-container *ngIf="
            ['mean_sd', 't_score'].includes(
              $any(gradeCalculationMethod.selectedValues[0])?.value
            )">
          <div class="d-flex">
            <div class="col-12">
              <div class="w-100">
                <svg-icon class="d-block w-100"
                    [src]="'assets/utils/images/normal-distribution.svg'"
                    [svgStyle]="{ width: '100%', height: 'auto' }">
                </svg-icon>
              </div>
            </div>
          </div>
          <div class="d-flex"
              formGroupName="grade_ranges">
            <table class="table">
              <thead>
                <tr>
                  <th *ngFor="let col of ['-3', '-2', '-1', '0', '1', '2', '3']"
                      class="text-center">
                    <span>Z ({{ col }})</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <ng-container *ngFor="let gradeRange of gradeRangeFormArr.controls | reverseArr;
                      index as i; last as last">
                    <td *ngIf="!last"
                        [formGroup]="gradeRange">
                      <input #maxSdInput
                          class="form-control number-non-arrow"
                          formControlName="max_sd"
                          title="Lower SD of {{ gradeRange.value?.name }}"
                          type="number"
                          vruValidate
                          [readonly]="
                            $any(gradeCalculationMethod.selectedValues[0])?.value === 't_score' ||
                            !isGradeSettingEditMode"
                          [submitted]="true"
                          (keyup)="onGradeSdChange(maxSdInput.value)">
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
        <div class="form-group d-flex mb-4">
          <label class="col-form-label label-title">คะแนนรวม</label>
          <div class="col">
            <div class="row input-score-group text-center">
              <div class="input-group mb-3 mb-lg-0">
                <div class="input-group-prepend">
                  <label class="input-group-text"
                      for="input-md-grade"
                      ngbPopover="">
                    MD
                  </label>
                </div>
                <input class="form-control number-non-arrow"
                    formControlName="midterm_score"
                    id="input-md-grade"
                    type="number"
                    vruValidate
                    [readonly]="!isGradeSettingEditMode"
                    [showMsg]="false"
                    [submitted]="true">
              </div>
              <div class="input-group mb-3 mb-lg-0">
                <div class="input-group-prepend">
                  <label class="input-group-text"
                      for="input-fn-grade">
                    FN
                  </label>
                </div>
                <input class="form-control number-non-arrow"
                    formControlName="final_score"
                    id="input-fn-grade"
                    type="number"
                    vruValidate
                    [readonly]="!isGradeSettingEditMode"
                    [showMsg]="false"
                    [submitted]="true">
              </div>
              <div class="input-group mb-3 mb-lg-0">
                <div class="input-group-prepend">
                  <label class="input-group-text"
                      for="input-total-score">
                    TT
                  </label>
                </div>
                <input #maxScoreInput
                    class="form-control number-non-arrow"
                    formControlName="total_score"
                    id="input-total-score"
                    readonly
                    type="number"
                    vruValidate
                    [showMsg]="false"
                    [submitted]="true">
              </div>
            </div>
          </div>
        </div>
        <div formArrayName="grade_ranges"
            class="form-group d-flex mb-2">
          <label class="col-form-label label-title">ช่วงคะแนน</label>
          <div class="col d-flex flex-wrap">
            <ng-container *ngFor="let gradeRangeControl of gradeRangeFormArr?.controls;
                index as gradeRangeIndex; first as firstGrade; last as lastGrade"
                [formGroupName]="gradeRangeIndex">
              <ng-container [formGroup]="$any(gradeRangeControl)">
                <ng-container *ngTemplateOutlet="lowerScoreInputTemplate;
                    context: { 
                      control: $any(gradeRangeControl).get('max_value'),
                      method: $any(gradeCalculationMethod.selectedValues[0])?.value,
                      orderScoreErr: gradeRangeFormArr.getError('orderScore')?.[gradeRangeIndex] || null
                    }">
                </ng-container>
                <ng-template #lowerScoreInputTemplate
                    let-control="control"
                    let-method="method"
                    let-orderScoreErr="orderScoreErr">
                  <div class="d-flex grade-range-item mb-3">
                    <div class="position-relative">
                      <input *ngIf="['mean_sd', 't_score'].includes(method) || !firstGrade;
                          else firstGradeRangeTemp"
                          class="form-control number-non-arrow input-grade-boundary"
                          title="ขอบเขตคะแนน"
                          type="number"
                          vruValidate
                          [class.invalid]="orderScoreErr"
                          [formControl]="control"
                          [readonly]="
                            !isGradeSettingEditMode ||
                            ['mean_sd', 't_score'].includes(method)"
                          [showMsg]="false"
                          [submitted]="true">
                      <ng-template #firstGradeRangeTemp>
                        <input class="form-control number-non-arrow input-grade-boundary"
                            title="ขอบเขตคะแนน"
                            type="number"
                            [class.invalid]="orderScoreErr"
                            [readonly]="true"
                            [value]="maxScoreInput.value">
                      </ng-template>
                      <ng-container *ngIf="control.invalid || orderScoreErr">
                        <vru-carbon-icon presetFilter="fill-red"
                            size="24"
                            triggers="mouseover:mouseleave"
                            [iconName]="!control.getError('required') && orderScoreErr
                                ? 'caret-down'
                                : 'warning'"
                            [popoverTitle]="invalidScoreOrderTitle"
                            [ngbPopover]="invalidMsgTemp">
                        </vru-carbon-icon>
                        <ng-template #invalidScoreOrderTitle>
                          <label class="mb-0">
                            กรุณาใส่คะแนนให้ถูกต้อง
                          </label>
                        </ng-template>
                        <ng-template #invalidMsgTemp>
                          <label *ngIf="control.getError('required'); else invalidNumberMsgTemp">
                            จำเป็นต้องใส่ตัวเลขเท่านั้น
                          </label>
                          <ng-template #invalidNumberMsgTemp>
                            <label *ngIf="orderScoreErr">
                              คะแนนเกรดในช่องนี้ต้องไม่น้อยกว่าหรือเท่ากับคะแนนของเกรดช่องถัดไป
                            </label>
                            <label *ngIf="control.getError('pattern')">
                              คะแนนต้องเป็นตัวเลขไม่เกิน 5 ตัวเลขและมีทศนิยมได้ไม่เกิน 2 ตำแหน่ง
                            </label>
                            <label *ngIf="control.getError('maxScore')">
                              คะแนนในช่องนี้มากกว่าคะแนนเต็มที่กำหนด
                            </label>
                          </ng-template>
                        </ng-template>
                      </ng-container>
                    </div>
                    <div class="label-grade-range">
                      <label class="grade-name">{{gradeRangeControl?.value?.name}}</label>
                      <div class="grade-border"></div>
                    </div>
                    <div *ngIf="lastGrade">
                      <input class="form-control"
                          title="ขอบเขตคะแนน"
                          type="text"
                          [readonly]="true"
                          [value]="['mean_sd', 't_score'].includes(method) ? null : 0">
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="isInSubmitPeriod">
          <div *vruDisplayPermission="{
                teacher: submissionStatus === 'wait_for_professor_submit_grade',
                backoffice: submissionStatus === 'wait_for_backoffice_approval',
                admin: true
              }"
              class="d-flex justify-content-end align-items-end">
            <button *ngIf="$any(gradeCalculationMethod.selectedValues[0])?.value !== 't_score'"
                class="btn btn-outline-green-vru mr-2"
                type="button"
                (click)="resetGradeRange()">
              ใช้ค่าเริ่มต้น
            </button>
            <button *ngIf="!isGradeSettingEditMode"
                class="btn btn-outline-green-vru"
                type="button"
                (click)="onGradeSettingEditModeClick()">
              แก้ไข
            </button>
            <ng-container *ngIf="isGradeSettingEditMode">
              <button class="btn btn-outline-danger mr-2"
                  type="button"
                  (click)="onGradeSettingCancel()">
                ยกเลิก
              </button>
              <button class="btn btn-green-vru"
                  type="button"
                  [disabled]="gradeSettingForm.invalid"
                  (click)="onGradeSettingSubmit()">
                บันทึก
              </button>
            </ng-container>
          </div>
        </ng-container>
      </form>
    </div>
    <div class="frame-filter mx-0 mb-4 p-0">
      <label class="table-title mb-0">
        รายชื่อนักศึกษา
      </label>
      <div class="table-responsive">
        <table class="table table-enrollment text-center mb-0">
          <thead>
            <tr>
              <th scope="col">ลำดับที่</th>
              <th scope="col">รหัสนักศึกษา</th>
              <th scope="col">ชื่อ-สกุล</th>
              <th class="col-fund"
                  scope="col"></th>
              <th scope="col">MD</th>
              <th scope="col">FN</th>
              <th scope="col">TT</th>
              <th scope="col">Grade</th>
              <th scope="col"
                  *ngIf="isFixGradeMethod">
                <span class="text-red">I</span>
              </th>
              <th scope="col">สถานะการชำระ</th>
            </tr>
          </thead>
          <tbody [formGroup]="enrollmentForm">
            <ng-container *ngIf="enrollmentDetailFormArr?.length; else noEnrollmentRowTemp"
                formArrayName="enrollments">
              <tr *ngFor="let enrollmentForm of enrollmentDetailFormArr?.controls; index as i"
                  [formGroupName]="i">
                <td class="align-middle">{{i + 1}}</td>
                <td class="align-middle">{{enrollmentForm?.value?.student?.code || '-'}}</td>
                <td class="align-middle">{{enrollmentForm?.value?.student?.full_name_thai || '-'}}</td>
                <td class="align-middle">
                  <span *ngIf="enrollmentForm?.value?.has_scholarship"
                      class="bi bi-cash-coin text-warning h5 mb-0"
                      ngbTooltip="นักศึกษาได้รับทุน">
                  </span>
                </td>
                <td class="align-middle">
                  <div class="position-relative">
                    <input class="form-control"
                        formControlName="midterm_score"
                        title="คะแนนกลางภาค"
                        type="text"
                        vruValidate
                        [class.input-invalid-icon]="enrollmentForm.get('midterm_score')?.invalid"
                        [showMsg]="false"
                        [submitted]="true">
                    <ng-container *ngTemplateOutlet="alertMsgPopoverTemplate;
                        context: { control: enrollmentForm.get('midterm_score') }">
                    </ng-container>
                  </div>
                </td>
                <td class="align-middle">
                  <div class="position-relative">
                    <input class="form-control"
                        formControlName="final_score"
                        title="คะแนนปลายภาค"
                        type="text"
                        vruValidate
                        [class.input-invalid-icon]="enrollmentForm.get('final_score')?.invalid"
                        [showMsg]="false"
                        [submitted]="true">
                    <ng-container *ngTemplateOutlet="alertMsgPopoverTemplate;
                        context: { control: enrollmentForm.get('final_score') }">
                    </ng-container>
                  </div>
                </td>
                <td class="align-middle">
                  <div class="position-relative">
                    <input #totalScoreInput
                        class="form-control number-non-arrow"
                        formControlName="total_score"
                        readonly
                        title="คะแนนรวม"
                        type="number"
                        vruValidate
                        [class.input-invalid-icon]="enrollmentForm.get('total_score')?.invalid"
                        [submitted]="true">
                    <ng-container *ngTemplateOutlet="alertMsgPopoverTemplate;
                        context: { control: enrollmentForm.get('total_score') }">
                    </ng-container>
                  </div>
                </td>
                <td class="align-middle">
                  <input class="form-control"
                      formControlName="grade"
                      readonly
                      title="เกรด"
                      type="text">
                </td>
                <td class="align-middle"
                    *ngIf="isFixGradeMethod">
                  <div class="form-check mb-4">
                    <input class="form-check-input"
                        formControlName="is_incomplete_grade"
                        title="ติ๊กสำหรับนักศึกษาที่ติด I"
                        type="checkbox"
                        [readonly]="enrollmentForm.get('payment_status')?.value === 'unpaid'">
                  </div>
                </td>
                <td class="align-middle">
                  <ng-container [ngTemplateOutlet]="paymentStatusTemp"
                      [ngTemplateOutletContext]="{
                        paymentStatus: enrollmentForm.get('payment_status')?.value,
                        paymentStatusDisplay: enrollmentForm.get('payment_status_display')?.value
                      }">
                  </ng-container>
                  <ng-template #paymentStatusTemp
                      let-status="paymentStatus"
                      let-display="paymentStatusDisplay">
                    <label class="badge-pill badge-payment text-center text-nowrap mb-0 text-white"
                        [class.bg-red]="status === 'unpaid'"
                        [class.bg-green]="status === 'paid'"
                        [class.bg-gray]="status !== 'unpaid' && status !== 'paid' && display">
                      {{ display }}
                    </label>
                  </ng-template>
                </td>
              </tr>
            </ng-container>
            <ng-template #noEnrollmentRowTemp>
              <tr>
                <td colspan="10">
                  ไม่มีรายการ
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
        <ng-template #alertMsgPopoverTemplate
            let-control="control">
          <ng-container *ngIf="control?.invalid">
            <vru-carbon-icon container="body"
                iconName="warning"
                presetFilter="fill-red"
                size="24"
                triggers="mouseover:mouseleave"
                popoverClass="popover-alert"
                [popoverTitle]="invalidScoreTitleTemp"
                [ngbPopover]="invalidScoreMsgTemp">
            </vru-carbon-icon>
            <ng-template #invalidScoreTitleTemp>
              <label class="mb-0">
                กรุณาใส่คะแนนให้ถูกต้อง
              </label>
            </ng-template>
            <ng-template #invalidScoreMsgTemp>
              <label *ngIf="control.getError('required'); else invalidFormatScoreMsgTemp">
                จำเป็นต้องใส่ตัวเลขหรือเว้นว่างเท่านั้น
              </label>
              <ng-template #invalidFormatScoreMsgTemp>
                <label *ngIf="control.getError('maxScore')">
                  คะแนนในช่องนี้มากกว่าคะแนนเต็มที่กำหนด
                </label>
                <label *ngIf="control.getError('pattern')">
                  คะแนนต้องเป็นตัวเลขไม่เกิน 5 ตัวเลขและมีทศนิยมได้ไม่เกิน 2 ตำแหน่ง
                </label>
              </ng-template>
            </ng-template>
          </ng-container>
        </ng-template>
      </div>
      <ng-container *ngIf="isInSubmitPeriod">
        <div *vruDisplayPermission="{
              teacher: submissionStatus === 'wait_for_professor_submit_grade' || submissionStatus === 'revised',
              backoffice: submissionStatus === 'wait_for_backoffice_approval',
              admin: true
            }"
            class="d-flex justify-content-end container-button-grade-update align-items-center p-4">
          <div *ngIf="isGradeSettingEditMode"
              triggers="mouseover:mouseleave"
              [popoverClass]="'warning'"
              [ngbPopover]="'ไม่สามารถบันทึกเกรดได้เนื่องจากกำลังอยู่ในโหมดแก้ไขการตั้งค่าเกรด'"
              [popoverTitle]="'อยู่ระหว่างการแก้ไขการตั้งค่าเกรด'">
            <ng-container *ngTemplateOutlet="iconContentButton"></ng-container>
          </div>
          <div *ngIf="enrollmentForm.pristine && !isGradeSettingEditMode"
              triggers="mouseover:mouseleave"
              [ngbPopover]="'ข้อมูลนี้ถูกบันทึกล่าสุดแล้ว'"
              [popoverTitle]="'ข้อมูลบันทึกล่าสุดแล้ว'">
            <ng-container *ngTemplateOutlet="iconContentButton"></ng-container>
          </div>
          <div *ngIf="enrollmentForm.dirty && !isGradeSettingEditMode">
            <ng-container *ngTemplateOutlet="iconContentButton"></ng-container>
          </div>
          <ng-template #iconContentButton>
            <button class="btn btn-green-vru d-flex align-items-center flex-nowrap mr-2"
                id="btn-update-enrollment"
                type="button"
                [disabled]="
                  (enrollmentForm.pristine && hasGradeRange) ||
                  isGradeSettingEditMode"
                (click)="onUpdateEnrollment()">
              <div class="pr-1">
                <vru-carbon-icon presetFilter="fill-white"
                    size="22"
                    [class.d-none]="!isGradeSettingEditMode"
                    [iconName]="'locked'">
                </vru-carbon-icon>
                <vru-carbon-icon presetFilter="fill-white"
                    size="22"
                    [class.d-none]="enrollmentForm.dirty || isGradeSettingEditMode"
                    [iconName]="'save'">
                </vru-carbon-icon>
              </div>
              <span>บันทึกเกรด</span>
            </button>
          </ng-template>
          <button *ngIf="submissionStatus === 'wait_for_professor_submit_grade' || submissionStatus === 'revised'"
              class="btn btn-green-vru"
              type="submit"
              (click)="onSubmitGrade()"
              [disabled]="
                isGradeSettingEditMode ||
                !isCanSubmit ||
                enrollmentForm.dirty ||
                !hasGradeRange">
            ยืนยันการส่งเกรด
          </button>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="isInSubmitPeriod && isWaitForApproval">
      <div *vruDisplayPermission="{
            dean: submissionStatus === 'wait_for_dean_approval',
            backoffice: submissionStatus === 'wait_for_backoffice_approval',
            admin: true
          }"
          class="row justify-content-end mb-2 px-2">
        <div class="col-auto px-2">
          <button class="btn btn-orange"
              type="button"
              [ladda]="isRejecting"
              (click)="onRejectClick()">
            ไม่อนุมัติการส่งเกรด
          </button>
        </div>
        <div class="col-auto px-2">
          <button class="btn btn-green-vru"
              type="button"
              [ladda]="isApproving"
              (click)="onApproveClick()">
            อนุมัติการส่งเกรด
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="container-spinner-float">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0)"
        name="grade-results-detail"
        color="#3cd070"
        size="medium"
        type="ball-clip-rotate-pulse"
        [fullScreen]="false"
        [showSpinner]="isLoading">
    </ngx-spinner>
  </div>
</div>