import { NewsFeedComponent } from './news-feed/news-feed.component';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { NewsListComponent } from './news-list/news-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsFeedRoutingModule } from './news-feed-routing.module';
import { RouterModule } from '@angular/router';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiModule } from '@vru/ui';
import { UtilsModule } from '@vru/utils';

@NgModule({
  declarations: [
    NewsFeedComponent,
    NewsDetailComponent,
    NewsListComponent
  ],
  imports: [
    CommonModule,
    NewsFeedRoutingModule,
    RouterModule,
    UiModule,
    NgbCarouselModule,
    NgbModule,
    UtilsModule
  ]
})
export class NewsFeedModule {}
