import { Subscription } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgControl } from '@angular/forms';
import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from "@angular/core";

@Directive({
  selector: '[formValidation]',
})

export class UtilFormValidationDirective implements OnInit, OnDestroy {

  constructor(
    private elRef: ElementRef, private control: NgControl
  ) { }

  @Input() validateMsg!: string;
  @Input() key = '';
  @Input() errorMsg = 'กรุณาระบุ';
  errorSpanId = '';
  statusChangeSubscription: any;
  valueChangeSubscription: any;

  ngOnInit(): void {
    this.errorSpanId = this.control.name + '-error-msg' + this.key;
    this.statusChangeSubscription = this.control.statusChanges?.subscribe(() => {
      this.checkValidate();
    });
    this.valueChangeSubscription = this.control.valueChanges?.subscribe(() => {
      this.control.valueAccessor as NgSelectComponent;
      setTimeout(() => {
        this.checkValidate();
      }, 500);
    });
  }

  ngOnDestroy(): void {
    this.statusChangeSubscription?.unsubscribe();
    this.valueChangeSubscription?.unsubscribe();
  }

  @HostListener('blur', ['$event'])
  handleBlurEvent() {
    this.checkValidate();
  }

  private showError() {
    this.removeError();
    const valErrors: any = this.control.errors;
    const firstKey = Object.keys(valErrors)[0];
    const errorMsgKey = this.validateMsg + '-' + firstKey + '-msg';
    let errorMsg = (this.validateMsg ? this.validateMsg : this.control.name) as string;
    errorMsg = errorMsg.replace(/_/g, ' ');
    errorMsg = errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1);
    const errSpan =
      '<small style="color:red;position: absolute;z-index: 2; left: 7px; top: 75px;" id="' +
      this.errorSpanId +
      '">' +
      this.errorMsg +
      ' ' +
      errorMsg
      '</small>';
    this.elRef.nativeElement.parentElement.insertAdjacentHTML('beforeend', errSpan);
  }

  private removeError() {
    const errorElement = document.getElementById(this.errorSpanId);
    if (errorElement) {
      errorElement.remove();
    }
  }

  checkValidate() {
    if (this.control.status === 'INVALID') {
      this.showError();
    } else {
      this.removeError();
    }
  }

}