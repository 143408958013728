import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  Dropdown,
  DropdownApiService,
  StudentApiService,
} from '@vru/master-data';
import { AlertService } from '@vru/services';
import { StudentAccessorComponent } from '../../student-accessor/student-accessor.component';
import { StudentInformationConfig } from '../../student-information.config';

@Component({
  selector: 'vru-student-change-program',
  templateUrl: './student-change-program.component.html',
  styleUrls: ['./student-change-program.scss'],
})
export class StudentChangeProgramComponent
  extends StudentAccessorComponent
  implements OnInit {
  studentId!: number;
  program!: Dropdown<number, any> | null;
  programs!: Dropdown<number, any>[];
  student_section!: number | null;
  student_sections!: Dropdown<number, any>[];
  study_plan!: number | null;
  study_plans!: Dropdown<number, any>[];
  yearDropdown: number[] = [];
  academicYear!: string;

  constructor(
    protected config: StudentInformationConfig,
    private dropdownService: DropdownApiService,
    private studentService: StudentApiService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected store: Store,
    private alert: AlertService
  ) {
    super(route, router, store);
  }

  ngOnInit(): void {
    this.getData();
    this.fetchStudentId();
    this.getYearDropdown();
  }

  getYearDropdown() {
    const arrayYear = this.yearDropdown;
    let addedNumberOfYear = this.yearDropdown?.length;
    const currentYear = new Date().getFullYear() + 543;
    for (let index = 0; index < 10; index++) {
      arrayYear.push(currentYear - addedNumberOfYear);
      addedNumberOfYear++;
    }
    this.yearDropdown = [...arrayYear];
  }

  onScroll(end: { start: number; end: number }) {
    if (end.end === this.yearDropdown?.length) {
      this.getYearDropdown();
    }
  }

  getData() {
    this.dropdownService.getDropdown('program').subscribe((res) => {
      this.programs = res.map((program) => ({
        ...program,
        value: program.value,
        label: program.label + ' / ' + program.context.year,
        context: program.context,
      }));
    });
  }

  handleProgramChange() {
    this.student_sections = [];
    this.student_section = null;
    this.study_plans = [];
    this.study_plan = null;
    if (!this.program) return;
    const programId = String(this.program.value);
    this.dropdownService
      .getDropdown('student_section', {
        program: programId,
        extend: true,
        start_academic_year_by_student: this.studentId,
      })
      .subscribe((res) => {
        this.student_sections = res.map((student_section) => ({
          ...student_section,
          value: student_section.value,
          label: student_section.label + ': ' + student_section.context.student_type.name,
          context: student_section.context,
        }));
      });
  }

  handleStudentSectionChange() {
    this.study_plans = [];
    this.study_plan = null;
    if (!this.student_section) return;
    this.dropdownService
      .getDropdown('study_plan', {
        student_section: this.student_section,
      })
      .subscribe((res) => {
        this.study_plans = res;
      });
  }

  rejectAccessStudentId(): void {
    this.goToBackPage();
  }

  resolveAccessStudentId(studentId: number): void {
    this.studentId = studentId;
  }

  cancel() {
    this.study_plan = null;
    this.program = null;
  }

  onSubmit() {
    if (!this.program) return;
    this.studentService
      .postMoveCourse(
        {
          student_section: this.student_section,
          study_plan: this.study_plan,
        },
        this.studentId
      )
      .subscribe(
        () => {
          this.alert.success('ย้ายหลักสูตรสำเร็จ');
          this.cancel();
        },
        (res) => {
          this.alert.error(res.error.detail);
        }
      );
  }
}
