import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserProfile } from '@vru/master-data';

@Component({
  template: ``,
})
export class UserProfileBase {
  _user!: UserProfile;

  constructor(protected store: Store) {
    this._user = this.store.selectSnapshot((state) => state.auth);
  }

  get user(): UserProfile {
    return this._user;
  }
}
