import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JoiningGraduationCeremonyBlockComponent } from './joining-graduation-ceremony-block.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';

@NgModule({
  declarations: [JoiningGraduationCeremonyBlockComponent],
  imports: [CommonModule, LaddaModule, ReactiveFormsModule],
  exports: [JoiningGraduationCeremonyBlockComponent],
})
export class JoiningGraduationCeremonyBlockModule {}
