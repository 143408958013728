import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UtilityService } from '@vru/utils';

@Component({
  selector: 'vru-carbon-icon',
  templateUrl: './carbon-icon.component.html',
  styleUrls: ['./carbon-icon.component.scss'],
})
export class CarbonIconComponent implements OnChanges {
  @Input() iconName!: string;
  @Input() description?: string | null;
  @Input() labelSize: 'auto' | 'sm' | 'md' | 'lg' = 'auto';
  /**
   * Specify filling color by using pattern `fill-#{colorName}`.
   * The default color depend on the global color of the project.
   * If you want to add a color you can do at maps color of scss of this component or `variables.scss` in `utils/libs`.
   * @example
   * ```html
   *  <vru-carbon-icon [presetFilter]="'fill-black'"></vru-carbon-icon>
   * ```
   */
  @Input() presetFilter = 'fill-black';
  @Input() size: number | string = 16;
  @Input() paddingBottom = 2;

  _labelSize: 'sm' | 'md' | 'lg' = 'sm';
  iconPath = 'assets/utils/images/carbon-icons/';

  constructor(private util: UtilityService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.labelSize || changes.description) {
      if (this.labelSize === 'auto') {
        const textSize = this.util.getTextWidth(this.description || '');
        if (textSize > 182) {
          this._labelSize = 'lg';
        } else if (textSize > 100) {
          this._labelSize = 'md';
        } else if (textSize <= 100) {
          this._labelSize = 'sm';
        } else {
          this._labelSize = 'lg';
        }
      } else {
        this._labelSize = this.labelSize;
      }
    }
  }
}
