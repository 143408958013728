<div>
  <h4 *ngIf="header"
      class="header-custom mb-4">{{ header }}</h4>
  <div class="row">
    <div *ngFor="let field of dataMap"
        class="col-12 col-lg-6 mb-2"
        [ngClass]="getColClass(field.maxSize) || 'col-xxl-3'">
      <div class="row form-row"
          [class.flex-md-nowrap]="field?.minSize !== 'full'">
        <label class="col-12 col-form-label mb-0"
            [class.col-md-auto]="field?.minSize !== 'full'"
            [ngStyle]="{ width: this.labelWidth }">
          {{ field.label }}
        </label>
        <ng-container *ngTemplateOutlet="renderTemp;
            context: { $implicit: getAttribute(source, field.path) }">
        </ng-container>
        <ng-template #renderTemp
            let-content>
          <div class="col-12"
              [class.col-md]="field?.minSize !== 'full'">
            <label class="d-block col-form-label w-100 mb-0 px-3">
              <ng-container *ngIf="field.arrayPath != null && field.arrayPath !== '';
                  else nonManyTemp">
                <ng-container [ngSwitch]="checkIsMany(content)">
                  <div *ngSwitchCase="true">
                    <label *ngFor="let item of content"
                        class="tag-many-item mb-1 mr-2 px-2">
                      <ng-container *ngTemplateOutlet="contextTemp;
                          context: { $implicit: getAttribute(item, field.arrayPath) }">
                      </ng-container>
                    </label>
                  </div>
                  <span *ngSwitchDefault>
                    {{ content | json }}
                  </span>
                </ng-container>
              </ng-container>
              <ng-template #nonManyTemp>
                <ng-container *ngTemplateOutlet="contextTemp; context: { $implicit: content }">
                </ng-container>
              </ng-template>
              <ng-template #contextTemp
                  let-context>
                <ng-container *ngTemplateOutlet="field.pipe?.type === 'date'
                    ? dateContext
                    : plainContext">
                </ng-container>
                <ng-template #plainContext>
                  <span>{{ context }}</span>
                </ng-template>
                <ng-template #dateContext>
                  <span>
                    {{ context | buddhaDate: field.pipe?.params || 'dd/MM/yyyy' }}
                  </span>
                </ng-template>
              </ng-template>
            </label>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>