import { ActivatedRoute } from '@angular/router';
import { distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';
import { StudentCreditTransferService } from './service/student-credit-transfer.service';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AlertService, ComponentSubscriptionService } from '@vru/services';
import { Dropdown, DropdownApiService, ErrorHeader } from '@vru/master-data';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateStudentCreditTransfer, CreditTransferParams, CurrentAcademicCalendars, StudentCreditTransferModel } from './model/student-credit-transfer.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'vru-student-credit-transfer',
  templateUrl: './student-credit-transfer.component.html',
  styleUrls: ['./student-credit-transfer.component.scss'],
  providers: [ComponentSubscriptionService],
})
export class StudentCreditTransferComponent implements OnInit, OnDestroy {
  @ViewChild('studentCreditTransferModal')
  studentCreditTransferModal!: ElementRef;
  studentParamsId!: number;
  studentCreditTransferFormGroup!: FormGroup;
  studentCreditTransferList!: StudentCreditTransferModel[];
  studentCreditTransferDropdown!: Dropdown[];
  studentCreditTransferDropdown$ = new Subject<string>();
  modalService!: NgbModalRef;
  isLoading = true;
  isSubmitted = false;
  currentCalendar!: CurrentAcademicCalendars;

  constructor(
    private studentCreditTransferService: StudentCreditTransferService,
    private comSubs: ComponentSubscriptionService,
    private alertService: AlertService,
    private dropdownApiService: DropdownApiService,
    private ngxSpinnerService: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private ngbModal: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.onSpinnerShow();
    this.setParams();
    this.initialFormGroup();
    this.setTypeahead();
    this.subscribeStudentCreditTransfer();
    this.getCurrentAcademicCalender();
  }

  ngOnDestroy(): void {
    this.comSubs.destroy();
  }

  onSpinnerShow() {
    this.ngxSpinnerService.show('student-credit-transfer');
  }

  setParams() {
    this.studentParamsId = this.activatedRoute.parent?.snapshot.params['id']
  }

  getCurrentAcademicCalender() {
    this.studentCreditTransferService.getCalendar().subscribe({
      next: (res) => {
        this.currentCalendar = res
      }
    })
  }

  setTypeahead() {
    this.studentCreditTransferDropdown$
      .pipe(
        distinctUntilChanged(),
        debounceTime(200),
        switchMap((text) =>
          this.dropdownApiService.getDropdown('transferred_course', {
            code: text,
            course_name: text,
          })
        )
      )
      .subscribe({
        next: (response) => {
          this.studentCreditTransferDropdown = response;
        },
      });
  }

  subscribeStudentCreditTransfer() {
    const param: CreditTransferParams = {
      student: this.studentParamsId
    }
    this.comSubs.store.onGetStudentCreditTransfer =
      this.studentCreditTransferService.onGetStudentCreditTransfer(param).subscribe({
        next: (response) => {
          this.studentCreditTransferList = response.results;
          this.isLoading = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoading = false;
        },
      });
  }

  onChangeCreditTransfer(data: Dropdown) {
    this.studentCreditTransferFormGroup.patchValue(data);
    if (data.context?.['institution.name']) {
      this.studentCreditTransferFormGroup.get(
        ['context','institution','name']
      )?.setValue(data.context?.['institution.name'])
    }
  }

  onCheckInvalidModal(data: CreateStudentCreditTransfer) {
    this.isSubmitted = true;
    if (this.studentCreditTransferFormGroup.invalid) {
      this.alertService.warn('กรุณากรอกข้อมูลให้ครบถ้วน');
      return;
    }
    this.modalService.close(data);
  }

  onCreditTransferOpenModal(transfer?: StudentCreditTransferModel) {
    this.isSubmitted = false;
    this.modalService = this.ngbModal.open(this.studentCreditTransferModal, {
      size: 'lg',
      backdrop: 'static',
    });
    if (transfer) {
      const data: Dropdown<number, CourseContext> = {
        label: transfer.transferred_course.code,
        value: transfer.transferred_course.id,
        context: {
          institution: transfer.transferred_course.institution,
          course_name: transfer.transferred_course.course.name_thai,
          'course.name_thai': transfer.transferred_course.course.name_thai,
        },
      };
      this.studentCreditTransferDropdown$.next(
        transfer.transferred_course.code
      );
      this.onChangeCreditTransfer(data);
      this.studentCreditTransferFormGroup.get('transferred_grade')?.setValue(transfer.transferred_grade);
    }
    this.modalService.result.then((response) => {
        const payload = {
          transferred_course: response.value,
          student: +this.studentParamsId,
          semester: this.currentCalendar.semester,
          academic_year: this.currentCalendar.academic_year,
          transferred_grade: response.transferred_grade,
        };
        if (transfer?.id) {
          this.onUpdateCreditTransferData(transfer?.id, payload);
        } else {
          this.onCreateCreditTransferData(payload);
        }
        this.studentCreditTransferFormGroup.reset();
      })
      .catch((error) => {
        console.error(error);
        this.studentCreditTransferFormGroup.reset();
      });
  }

  onCreateCreditTransferData(data: CreateStudentCreditTransfer) {
    this.studentCreditTransferService
      .onCreateStudentCreditTransfer(data)
      .subscribe({
        next: () => {
          this.alertService.success('โอนย้ายสำเร็จ');
          this.subscribeStudentCreditTransfer();
          this.isLoading = false;
        },
        error: (err: ErrorHeader) => {
          if (err.error.detail) {
            this.alertService.error(
              'ไม่สามารถโอนย้ายได้เนื่องจากมีข้อมูลที่ถูกโอนย้ายอยู่แล้ว',
              4000
            );
            return this.onCreditTransferOpenModal();
          } else {
            this.alertService.error(
              'ไม่สามารถโอนย้ายได้กรุณาติดต่อ Admin',
              4000
            );
          }
          console.error(err);
          this.isLoading = false;
        },
      });
  }

  onUpdateCreditTransferData(id: number, data: CreateStudentCreditTransfer) {
    this.studentCreditTransferService
      .onUpdateStudentCreditTransfer(id, data)
      .subscribe({
        next: () => {
          this.alertService.success('บันทึกสำเร็จ');
          this.subscribeStudentCreditTransfer();
          this.isLoading = false;
        },
        error: () => {
          this.alertService.error(
            'ไม่สามารถบันทึกได้ กรุณาตรวจสอบข้อมูลอีกครั้ง หรือ ติดต่อ Admin',
            4000
          );
          this.isLoading = false;
        },
      });
  }

  onDeleteCreditTransferData(id: number, data?: StudentCreditTransferModel) {
    this.alertService
      .confirm(
        'ยืนยันการลบข้อมูล',
        `ท่านต้องการลบข้อมูล ${data?.transferred_course.institution.name} ใช่ หรือ ไม่ ?`,
        'warning',
        'Confirm'
      )
      .then((results) => {
        if (results.value) {
          this.studentCreditTransferService
            .onDeleteStudentCreditTransfer(id)
            .subscribe({
              next: () => {
                this.alertService.success('ลบข้อมูลสำเร็จ');
                this.subscribeStudentCreditTransfer();
              },
              error: () => {
                this.alertService.error(
                  'ไม่สามารถลบข้อมูลได้ กรุณาลองใหม่อีกครั้ง หรือ ติดต่อ Admin'
                );
              },
            });
        }
      });
  }

  initialFormGroup() {
    this.studentCreditTransferFormGroup = this.buildTransferredCourseGroup();
  }

  buildTransferredCourseGroup(
    initialForm?: Partial<Dropdown<number, CourseContext>> | null
  ): FormGroup {
    return this.formBuilder.group({
      label: [initialForm?.label, [Validators.required]],
      value: [initialForm?.value, [Validators.required]],
      context: this.formBuilder.group({
        institution: this.formBuilder.group({
          id: [initialForm?.context?.institution.id],
          code: [initialForm?.context?.institution.code],
          name: [initialForm?.context?.institution.name],
        }),
        course_name: [initialForm?.context?.course_name, [Validators.required]],
        'course.name_thai': [
          initialForm?.context?.['course.name_thai'],
          [Validators.required],
        ],
      }),
      transferred_grade: [null, [Validators.required]],
    });
  }

  get form() {
    return this.studentCreditTransferFormGroup.controls;
  }
}

export interface CourseContext {
  institution: Institution;
  course_name: string;
  'course.name_thai': string;
}

export interface Institution {
  id: number;
  code: string;
  name: string;
}
