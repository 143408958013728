import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  ValidationLabelConfig,
  VALIDATION_LABEL_CONFIG,
} from './validation.model';
import { UtilityService } from '../services/utility.service';

@Directive({
  selector: '[vruValidate]',
})
export class UtilValidationDirective
  implements OnChanges, OnInit, AfterViewInit
{
  @Input() labelClass = 'text-red mb-0';
  @Input() invalidMsg = 'Invalid';
  @Input() invalidMsgJustify: 'left' | 'right' = 'left';
  /**
   * @description
   * This is custom config for displaying error message.
   */
  @Input() showMsg?: boolean;
  @Input() submitted = false;

  /** @deprecate Instead using font-size of page */
  labelMarginTop = 7;
  msgActive = false;

  private config: ValidationLabelConfig;

  constructor(
    @Optional() @Inject(VALIDATION_LABEL_CONFIG) config: ValidationLabelConfig,
    private control: NgControl,
    private elRef: ElementRef,
    private utils: UtilityService
  ) {
    this.config = config;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const submittedChange = changes.submitted;
    if (submittedChange?.currentValue && !submittedChange.firstChange) {
      this.validate();
    }
  }

  ngOnInit(): void {
    this.showMsg = this.showMsg ?? this.config?.showMsg ?? true;
    this.setControlSubscription();
  }

  ngAfterViewInit(): void {
    if (this.submitted) {
      this.validate();
    }
  }

  getPosition(): ElementPosition {
    return {
      height: this.elRef.nativeElement.offsetHeight,
      width: this.elRef.nativeElement.offsetWidth,
      left: this.elRef.nativeElement.offsetLeft,
      top: this.elRef.nativeElement.offsetTop,
    };
  }

  insertMessage(): void {
    const labelElm = document.createElement('label');
    let errorMsg = this.invalidMsg;
    if (this.config?.prefixMsg) {
      errorMsg = this.config?.prefixMsg + this.invalidMsg;
    }
    labelElm.innerText = errorMsg;
    labelElm.className = 'position-absolute ' + this.labelClass;
    const cssStyles = labelElm.style;
    const position = this.getPosition();
    const bodyFont = window
      .getComputedStyle(document.body)
      .getPropertyValue('font');
    const bodyFontSize = window
      .getComputedStyle(document.body)
      .getPropertyValue('font-size');
    const bodyFontSizePx = Number(bodyFontSize.match(/\d*/)?.[0]);
    let newBodyFontSize = bodyFontSizePx * (this.config?.fontSizeRem || 0.92);
    newBodyFontSize = Math.round(newBodyFontSize * 100) / 100;
    cssStyles.setProperty('font-size', `${newBodyFontSize}px`);
    cssStyles.setProperty(
      'top',
      `${position.top + position.height + newBodyFontSize * 0.4}px`
    );
    let leftPosition = '0';
    const errorMsgWidth = this.utils.getTextWidth(
      errorMsg,
      bodyFont.replace(/\d*/, `${newBodyFontSize}`)
    );
    if (this.invalidMsgJustify === 'right') {
      leftPosition = `${position.width + position.left - errorMsgWidth}px`;
    } else {
      leftPosition = `${position.left}px`;
    }
    cssStyles.setProperty('left', leftPosition);
    this.elRef.nativeElement.insertAdjacentElement('afterend', labelElm);
    this.msgActive = true;
  }

  removeErrorMsg(): void {
    this.elRef.nativeElement.nextElementSibling.remove();
    this.msgActive = false;
  }

  setControlSubscription(): void {
    this.control.statusChanges?.subscribe({
      next: () => {
        if (this.submitted) {
          this.validate();
        }
      },
    });
  }

  validate(): void {
    if (this.control.invalid) {
      if (this.showMsg && !this.msgActive) {
        this.insertMessage();
      }
      return;
    }
    if (this.msgActive) {
      this.removeErrorMsg();
    }
  }
}

export interface ElementPosition {
  height: number;
  left: number;
  top: number;
  width: number;
}
