export const common = {
  cannotProcess: 'ไม่สามารถดำเนินการได้ กรุณาติดต่อผู้ดูแลระบบ',
  foundError: 'พบข้อผิดพลาด',
  foundErrorPlsAdmin: 'พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
  plsCheckOrAdmin: 'กรุณาตรวจสอบข้อมูลอีกครั้งหรือติดต่อผู้ดูแลระบบ',
  /** @deprecated use AlertMessage.contact.admin instead */
  plsContactAdmin: 'กรุณาติดต่อผู้ดูแลระบบ',
  notAllowPermit: 'ไม่ได้รับสิทธิ์เข้าใช้งาน',
  notAllowPermitExplain: 'มีการจำกัดสิทธิ์ในการเข้าถึง บทบาทของคุณไม่ได้รับสิทธิ์ให้เข้าใช้งานในส่วนนี้',
  hasAdminQuestion: 'หากมีข้อสงสัยโปรดติดต่อเจ้าหน้าที่ผู้ดูแลระบบ',
  errUploadFile: 'การอัพโหลดไฟล์ล้มเหลว',
};

export const gradeSubmissionError = {
  updateGradeSetting: 'บันทึกการตั้งค่าเกรดไม่สำเร็จ',
  updateStudent: 'บันทึกข้อมูลนักศึกษาไม่สำเร็จ',
};

export const login = {
  foundLoginError: 'พบข้อผิดพลาดในการเข้าใช้งาน',
  foundResetPasswordError: 'พบข้อผิดพลาดในการรีเซ็ตรหัสผ่าน',
}

export const studyPlanError = {
  mayBeNoStudyPlan: 'อาจไม่มีแผนการเรียน',
}
