import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  AlertMessages,
  ErrorHeader,
  StudentApiService,
  StudentSetting,
} from '@vru/master-data';
import { AlertService } from '@vru/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { delay, tap } from 'rxjs/operators';

@Component({
  selector: 'vru-student-activation',
  templateUrl: './student-activation.component.html',
  styleUrls: ['./student-activation.component.scss'],
})
export class StudentActivationComponent implements OnInit {
  studentListId!: number;
  permissionForm!: FormGroup;
  readOnly = false;
  isLoading = true;
  isSaving = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private ngxSpinnerService: NgxSpinnerService,
    private studentApi: StudentApiService
  ) {}

  ngOnInit(): void {
    this.setParamsId();
    this.initialFormControl();
    this.onSpinnerShow();
  }

  onSpinnerShow() {
    this.ngxSpinnerService.show('permission-settings');
  }

  setParamsId() {
    this.activatedRoute.parent?.params.subscribe((params) => {
      this.studentListId = params.id;
      this.getSettingsOption(params.id);
    });
  }

  getSettingsOption(id: number) {
    this.studentApi
      .getSetting(id)
      .pipe(
        tap((res) => {
          this.permissionForm.patchValue(res);
        }, delay(500))
      )
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.readOnly = false;
          this.permissionForm.enable();
        },
        error: (error: ErrorHeader) => {
          if (error.status === 403) {
            this.readOnly = true;
            this.permissionForm.disable();
            this.alertService.error(
              error.error.detail +
                ' ' +
                'เกี่ยวกับนักศึกษาคนนี้' +
                ' ' +
                'กรุณาติดต่อ Admin',
              3000
            );
          }
          this.isLoading = false;
        },
      });
  }

  onSubmit() {
    const data = this.permissionForm.getRawValue();
    this.updateSettings(this.studentListId, data);
  }

  updateSettings(id: number, data: StudentSetting) {
    this.isSaving = true;
    this.studentApi.patchSetting(id, data).subscribe({
      next: () => {
        this.alertService.success(AlertMessages.success.save);
        this.isSaving = false;
      },
      error: (err) => {
        console.error(err);
        this.isSaving = false;
      },
    });
  }

  initialFormControl() {
    this.permissionForm = this.formBuilder.group({
      can_login: [{ value: false, disabled: false }],
      can_enroll: [{ value: false, disabled: false }],
    });
  }

  getToggleLabel(control: string) {
    return this.permissionForm.get(control)?.value ? 'เปิด' : 'ปิด';
  }
}
