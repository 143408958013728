import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor() {}

  getMean(samples: number[]): number {
    if (!samples.length) {
      throw new Error(
        'Cannot resolve the mean because of the samples is empty'
      );
    }
    return samples.reduce((a, b) => a + b) / samples.length;
  }

  getStandardDeviation(samples: number[], mean?: number) {
    const n = samples.length;
    const _mean = mean ?? this.getMean(samples);
    return Math.sqrt(
      samples.map((x) => Math.pow(x - _mean, 2)).reduce((a, b) => a + b) / n
    );
  }

  getNormalDistribution(samples: number[]) {
    const mean = this.getMean(samples);
    const sd = this.getStandardDeviation(samples, mean);
    return samples.map(
      (sample) =>
        (1 / (sd * Math.sqrt(2 * Math.PI))) *
        Math.pow(Math.E, -0.5 * Math.pow((sample - mean) / sd, 2))
    );
  }
}
