import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DrfList } from '../common.model';
import { TqfLoa } from './tqf.model';
import { Tqf7ListItem } from './tqf7.model';

@Injectable({
  providedIn: 'root',
})
export class Tqf7ApiService {
  readonly tqf7Path = '/tqf7-teachers/';

  constructor(private api: ApiService) {}

  getApprovalTransaction(tqfId: number): Observable<TqfLoa> {
    return this.api.get([
      this.tqf7Path,
      tqfId.toString(),
      'approval-transactions',
    ]);
  }

  getList(param?: { [key: string]: any }): Observable<DrfList<Tqf7ListItem>> {
    return this.api.get(this.tqf7Path, param);
  }

  getTQF7File(id: number, type: 'pdf' | 'word') {
    return this.api.postBlob(this.tqf7Path + `${id}/${type}`);
  }
}
