import { Directive, ElementRef, OnInit } from "@angular/core";

@Directive({
  selector: '[formPrefixFocusAndSelect]',
})

export class UtilFocusOnShowDirective implements OnInit {

  constructor(
    private el: ElementRef
  ) {
    if (!el.nativeElement.focus) {
      throw new Error('Element does not accept focus');
    }
  }

  ngOnInit() {
    const input: HTMLInputElement = this.el.nativeElement as HTMLInputElement;
    input.focus();
    input.select();
  }

}