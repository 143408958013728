import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserProfileBase } from '@vru/utils';
import { StudentInformationConfig } from '../student-information.config';

@Component({
  selector: 'vru-student-information-info',
  templateUrl: './student-information-info.component.html',
  styleUrls: ['./student-information-info.component.scss'],
})
export class StudentInformationInfoComponent extends UserProfileBase {
  isNotFromTimetable = false;
  active = 1;
  backPagePath?: string;
  menuConfigs: MenuConfig[] = [
    {
      label: 'ประวัตินักศึกษา',
      path: 'profile',
      permissible: ['teacher', 'backoffice', 'admin'],
    },
    {
      label: 'ผลการเรียน',
      path: 'grade-record',
      permissible: ['teacher', 'backoffice', 'admin'],
    },
    {
      label: 'เทียบโอน',
      path: 'credit-transfer',
      permissible: ['backoffice', 'admin'],
    },
    {
      label: 'ตรวจสอบจบ',
      path: 'verify-graduation',
      permissible: ['teacher', 'backoffice', 'admin'],
    },
    {
      label: 'ย้ายหลักสูตร',
      path: 'change-program',
      permissible: ['teacher', 'backoffice', 'admin'],
    },
    {
      label: 'สิทธิ์การใช้งาน',
      path: 'activation',
      permissible: ['teacher', 'admin'],
    },
  ];
  menuConfigsDisplay: MenuConfig[] = [];
  isAdvisor = false;
  isRegister = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private config: StudentInformationConfig,
    private router: Router,
    protected store: Store
  ) {
    super(store);
    this.checkTapMenu();
    this.checkTeacherPermission();
    this.menuConfigsDisplay =
      this.config.menuDisplayConfigs || this.menuConfigs;
  }

  checkTeacherPermission() {
    // this.isRegister = Boolean(
    //   this.backPagePath?.includes('professor') ||
    //     this.backPagePath?.includes('/student/to-back')
    // );
    // this.activatedRoute.params.subscribe((res) => {
    //   if (res.id && this.isRegister) {
    //     this.studentService.getStudentId(res.id).subscribe({
    //       next: (res) => {
    //         this.isAdvisor = (res as { advisors: number[] }).advisors.includes(
    //           super.user.context.teacher.id
    //         );
    //         this.menuConfigsDisplay = this.menuConfigs
    //           .filter((menu) => menu.path !== 'change-program')
    //           .filter((menu) => {
    //             if (menu.path === 'activation') {
    //               return this.isAdvisor;
    //             }
    //             return true;
    //           });
    //       },
    //     });
    //   } else {
    //     this.menuConfigsDisplay = this.menuConfigs;
    //   }
    // });
  }

  checkTapMenu(): void {
    const url = window.location.href;
    switch (url.split('/').pop()) {
      case 'profile':
        this.active = 0;
        break;
      case 'grade-record':
        this.active = 1;
        break;
      case 'credit-transfer':
        this.active = 2;
        break;
      case 'verify-graduation':
        this.active = 3;
        break;
      default:
        break;
    }
  }

  goToBackPage(): void {
    if (this.config.backPagePath) {
      this.router.navigateByUrl(this.config.backPagePath);
      return;
    }
    this.router.navigate(['']);
  }

  onClick(click: number) {
    this.active = click;
  }

  onTabClick(click: number) {
    this.active = click;
  }
}

export interface MenuConfig {
  label: string;
  path: string;
  permissible: { [k: string]: any } | string[];
}
