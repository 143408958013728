import { Component } from '@angular/core';

@Component({
  selector: 'homepage-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  onActivate(event: any, container:any) {
    container.scrollTop = 0;
  }
}
