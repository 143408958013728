<div class="board-white p-5 mt-5">
  <div class="d-flex flex-wrap justify-content-between no-gutters align-items-center pb-4">
    <h4 class="mt-2">ผลการอนุมัติ</h4>
    <div class="col-auto d-flex no-gutters flex-wrap align-items-center">
      <h6 class="pr-2 mb-0">สถานะคำร้อง :</h6>
      <div class="badge badge-pill d-flex justify-content-center align-items-center"
          style="min-height: 30px;"
          [ngClass]="appealRequestDetail?.status ? appealStatusBadgeColorList[appealRequestDetail.status] : ''">
        <h6 class="mb-0">{{ appealRequestDetail?.status ? appealStatusList[appealRequestDetail.status] : '' }}</h6>
      </div>
    </div>
  </div>
  <ng-container *ngFor="let loaLevel of appealRequestDetail?.loa_group?.levels; let i=index">
    <div class="card mb-3">
      <div class="card-header d-flex flex-wrap justify-content-between"
          [id]="'heading-approver-' + i"
          [ngClass]="getCardHeaderColorAppealStatus(loaLevel.level, appealRequestDetail?.status)">
        <h2 class="mb-0 flex-fill d-flex no-gutters align-items-center">
          <button class="btn btn-block text-left text-green-vru p-0 flex-fill"
              type="button"
              data-toggle="collapse"
              [attr.data-target]="'#collapse-approver-' + i"
              aria-expanded="true">
            <h5 class="mb-0"
                [ngClass]="getLabelColorAppealStatus(loaLevel.level)">
              ลำดับที่ {{ i+1 }}
            </h5>
          </button>
        </h2>
        <ng-container *vruDisplayPermission="{
              Backoffice: !['success', 'cancel_by_student', 'cancel_by_staff'].includes(appealRequestDetail.status)
            }">
          <ng-container *ngIf="onAvailableEditPhase(loaLevel.level); else notInEditPhaseTemplate">
            <ng-container *ngIf="loaLevel.payment_required">
              <vru-carbon-icon class="ml-3 bg-white rounded-circle p-1 shadow-sm"
                  iconName="information-filled"
                  size="25"
                  presetFilter="fill-green-vru"
                  ngbTooltip="การแก้ไขสถานะของคำร้องจะไม่สามารถแก้ไขข้ามระดับอนุมัติที่มีชำระเงิน">
              </vru-carbon-icon>
            </ng-container>
            <vru-carbon-icon class="ml-3 bg-white rounded-circle p-1 shadow-sm"
                iconName="rotate"
                size="25"
                [presetFilter]="getIconColorAppealStatus(loaLevel.level)"
                (click)="editAppealStatus(loaLevel.level)">
            </vru-carbon-icon>
          </ng-container>
          <ng-template #notInEditPhaseTemplate>
            <ng-container *ngIf="loaLevel.payment_required">
              <vru-carbon-icon class="ml-3 bg-white rounded-circle p-1 shadow-sm"
                  iconName="information-filled"
                  size="25"
                  presetFilter="fill-green-vru"
                  ngbTooltip="การแก้ไขสถานะของคำร้องจะไม่สามารถแก้ไขข้ามระดับอนุมัติที่มีการชำระเงิน">
              </vru-carbon-icon>
            </ng-container>
            <vru-carbon-icon class="ml-3 bg-white rounded-circle p-1 shadow-sm"
                iconName="rotate"
                size="25"
                [presetFilter]="getIconColorAppealStatus(loaLevel.level)">
            </vru-carbon-icon>
          </ng-template>
        </ng-container>
      </div>
      <div [id]="'collapse-approver-' + i"
          class="collapse show">
        <div class="card-body">
          <h6>รายละเอียด</h6>
          <div class="d-flex flex-column no-gutters flex-wrap">
            <ng-container *ngIf="!loaLevel?.payment_required; else paymentLevelTemplate">
              <ng-container *ngFor="let member of loaLevel.members">
                <ng-container *ngIf="member?.approve_type === 'person'">
                  <div class="d-flex">
                    <label class="d-flex justify-content-between align-content-center">
                      {{ 'ผู้อนุมัติ : ' + member?.person_name || '-' }}
                    </label>
                  </div>
                </ng-container>
                <ng-container *ngIf="member?.approve_type === 'role'">
                  <div class="d-flex">
                    <label class="d-flex justify-content-between align-content-center">
                      {{ 'ผู้อนุมัติ : ' + member?.role_display || '-' }}
                    </label>
                  </div>
                </ng-container>
              </ng-container>
              <label>
                {{
                loaLevel?.min_approve_count
                ? 'จำนวนผู้อนุมัติที่ต้องการ : ' + loaLevel?.min_approve_count
                : 'จำนวนผู้อนุมัติที่ต้องการ : 0'
                }}
              </label>
            </ng-container>
            <ng-template #paymentLevelTemplate>
              <div class="d-flex flex-wrap justify-content-between">
                <label>ชำระค่าธรรมเนียม
                  {{
                  appealRequestDetail?.template?.payment_amount
                  ? appealRequestDetail?.template?.payment_amount
                  : '0'
                  }} บาท
                </label>
                <ng-container *vruDisplayPermission="{
                    admin: appealRequestDetail?.status === 'wait_for_payment',
                    finance: appealRequestDetail?.status === 'wait_for_payment'
                  }">
                  <button class="btn btn-info"
                      type="button"
                      (click)="paymentStatusAction()">
                    อนุมัติการชำระค่าธรรมเนียม
                  </button>
                </ng-container>
              </div>
            </ng-template>
            <div class="col-12 d-flex flex-column">
              <h6>ผลการดำเนินการ</h6>
              <ng-container *ngFor="let record of loaLevel?.transactions">
                <div class="d-flex flex-column no-gutters frame-filter">
                  <div class="d-flex no-gutters flex-wrap">
                    <div class="col-12 col-lg-6 d-flex flex-column">
                      <label>
                        {{
                        record?.full_name
                        ? 'ผู้ดำเนินการ : ' + record.full_name
                        :'ผู้ดำเนินการ : ' + '-'
                        }}
                      </label>
                    </div>
                    <div class="col-12 col-lg-6 d-flex flex-column">
                      <div class="d-flex flex-wrap no-gutters">
                        <ng-container *ngIf="record?.is_approved; else rejectTemplate">
                          <label> ผลการประเมิน : </label>
                          <div class="col-auto pl-2">
                            <div class="badge badge-pill badge-success font-weight-normal text-white">ผ่าน</div>
                          </div>
                        </ng-container>
                      </div>
                      <ng-template #rejectTemplate>
                        <div class="d-flex flex-wrap no-gutters">
                          <label> ผลการประเมิน : </label>
                          <div class="col-auto pl-2">
                            <div class="badge badge-pill badge-danger font-weight-normal text-white">ไม่ผ่าน</div>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                    <div class="col-12 col-lg-6 d-flex flex-column">
                      <label>
                        {{
                        record?.created_at
                        ? 'วันที่ประเมิน : ' + getLabelDate(record.created_at)
                        : '-'
                        }}
                      </label>
                    </div>
                  </div>
                  <textarea class="form-control"
                      cols="30"
                      rows="5"
                      readonly
                      placeholder="หมายเหตุ"
                      [value]="record?.comment"></textarea>
                  <div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="loaLevel?.transactions?.length === 0">
                <div class="badge badge-pill badge-light border text-secondary font-weight-normal d-flex justify-content-center align-items-center"
                    style="min-height: 50px;">
                  ไม่มีการดำเนินการ
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!appealRequestDetail?.loa_group">
    <div class="badge badge-pill badge-light border-dashed d-flex justify-content-center align-items-center"
        style="min-height: 50px;">
      <h5 class="mb-0 text-secondary">ยังไม่มีสายอนุมัติ</h5>
    </div>
  </ng-container>
</div>

<ng-template #editAppealStatusModal>
  <div class="modal-header">
    <h4>แก้ไขสถานะคำร้อง</h4>
  </div>
  <div class="modal-body py-4">
    <div class="form-group">
      <label for="appeal_type_name">สถานะคำร้อง</label>
      <ng-select [items]="appealStatusDropdown"
          id="appeal_type_name"
          bindLabel="name"
          bindValue="description"
          [(ngModel)]="selectedStatus">
      </ng-select>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <button class="btn btn-outline-green-vru mr-2"
          type="button"
          (click)="closeAppealStatusModal()">
        ยกเลิก
      </button>
      <button class="btn btn-green-vru"
          type="submit"
          (click)="editStatusAction()">
        ยืนยัน
      </button>
    </div>
  </div>
</ng-template>