export enum AppealEngStatus {
  draft = 'Draft',
  wait_for_approve = 'Wait for approve',
  missing_documents = 'Missing documents',
  wait_for_payment = 'Wait for payment',
  success = 'Success',
  rejected = 'Rejected',
  cancel_by_student = 'Cancel by student',
  cancel_by_staff = 'Cancel by staff'
}

export enum AppealThaiStatus {
  draft = 'ร่างคำร้อง',
  wait_for_approve = 'รออนุมัติ',
  missing_documents = 'เอกสารไม่ครบ',
  wait_for_payment = 'รอชำระเงิน',
  success = 'ดำเนินการสำเร็จ',
  rejected = 'ไม่อนุมัติ',
  cancel_by_student = 'ยกเลิกคำร้องโดยนักศึกษา',
  cancel_by_staff = 'ยกเลิกคำร้องโดยเจ้าหน้าที่'
}

export enum AppealStatusBadgeColor {
  draft = 'badge-primary text-white',
  wait_for_approve = 'badge-info text-white',
  missing_documents = 'badge-warning text-white',
  wait_for_payment = 'badge-info text-white',
  success = 'badge-success text-white',
  rejected = 'badge-danger text-white',
  cancel_by_student = 'badge-secondary text-white',
  cancel_by_staff = 'badge-secondary text-white'
}

export const AppealStatusDropdown = [
  {
    name: 'รออนุมัติ',
    description: 'wait_for_approve',
  },
  {
    name: 'ดำเนินการสำเร็จ',
    description: 'success',
  },
  {
    name: 'ร่างคำร้อง',
    description: 'draft',
  },
  {
    name: 'รอชำระเงิน',
    description: 'wait_for_payment',
  },
  {
    name: 'ยกเลิกคำร้องโดยนักศึกษา',
    description: 'cancel_by_student',
  },
  {
    name: 'ยกเลิกคำร้องโดยเจ้าหน้าที่',
    description: 'cancel_by_staff',
  },
  {
    name: 'ไม่อนุมัติ',
    description: 'rejected',
  },
  {
    name: 'เอกสารไม่ครบ',
    description: 'missing_documents',
  },
]

export type AppealStatus = keyof typeof AppealEngStatus;

export enum AppealPaymentEngStatus {
  no_payment = 'No payment',
  not_paid = 'Not paid',
  paid = 'Paid'
}

export enum AppealPaymentThaiStatus {
  no_payment = 'ไม่มีการชำระเงิน',
  not_paid = 'ยังไม่ชำระเงิน',
  paid = 'ชำระเงินเสร็จสิ้น'
}

export enum AppealPaymentStatusBadgeColor {
  no_payment = 'badge-secondary text-white',
  not_paid = 'badge-danger text-white',
  paid = 'badge-success text-white'
}

export type AppealPaymentStatus = keyof typeof AppealPaymentEngStatus;