<vru-ng-select-typeahead ngbTooltip="ค้นหาด้วยชื่อหรือรหัสนักศึกษา"
    [bindLabel]="bindLabel"
    [clearable]="clearable"
    [loading]="isLoading"
    [dataApiGenerator]="dataApiGenerator"
    [placeholder]="placeholder"
    [multiple]="multiple"
    [openDelay]="300"
    [readonly]="readonly"
    [(ngModel)]="selectedStudent"
    (ngModelChange)="onValueChange($event)">
  <ng-template vruNgSelectOptionDirective
      let-item>
    <label *ngIf="showName"
        class="d-block pointer mb-0">
      {{ item.label }}
    </label>
    <label *ngIf="showCode"
        class="d-block pointer small mb-0">
      {{ item.context?.code }}
    </label>
  </ng-template>
</vru-ng-select-typeahead>