import { NewsFeedComponent } from './news-feed/news-feed.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsDetailComponent } from './news-detail/news-detail.component';

const routes: Routes = [
  {
    path: '',
    component: NewsFeedComponent, 
  },
  {
    path: 'news-list',
    component: NewsListComponent
  },
  {
    path: 'news/:id',
    component: NewsDetailComponent
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class NewsFeedRoutingModule {}
