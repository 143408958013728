<div class="modal-header"
    id="modal-header"
    [ngClass]="[theme]">
  <h4>{{ header }}</h4>
</div>
<div *ngIf="body"
    class="modal-body"
    [ngClass]="[theme]">
  <p>{{ body }}</p>
</div>
<div class="modal-footer"
    [ngClass]="[theme]">
  <button class="btn btn-outline-{{ theme }}"
    id="button-cancel"
      type="button"
      (click)="activeModal.dismiss()">
    ยกเลิก
  </button>
  <button class="btn btn-{{ theme }}"
      id="button-confirm"
      type="button"
      (click)="activeModal.close()">
    ยืนยัน
  </button>
</div>