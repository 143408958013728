import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../api/api.service';
import { CourseCategoryChange } from './study-plan.model';

@Injectable({
  providedIn: 'root',
})
export class StudyPlanApiService {
  readonly enrollmentPath = '/enrollments/';

  constructor(private api: ApiService) {}

  changeCourseCategory(changes: CourseCategoryChange): Observable<unknown> {
    return this.api.post(
      [this.enrollmentPath, 'change-course-category/'],
      changes
    );
  }
}
