<vru-ng-select-typeahead ngbTooltip="ค้นหาด้วยชื่อหรือรหัสอาจารย์"
    [bindLabel]="bindLabel"
    [clearable]="clearable"
    [loading]="loading"
    [dataApiGenerator]="dataApiGenerator"
    [openDelay]="300"
    [readonly]="readonly"
    [(ngModel)]="selectedStudent"
    (ngModelChange)="onValueChange($event)">
  <ng-template vruNgSelectOptionDirective
      let-item>
    <label *ngIf="showName"
        class="d-block pointer mb-0">
      {{ item.name }}
      <span *ngIf="showCode">({{ item.code }})</span>
    </label>
    <label *ngIf="showCode && !showName"
        class="d-block pointer mb-0">
      {{ item.code }}
    </label>
    <label *ngIf="showFaculty"
        class="d-block pointer small mb-0">
      คณะ{{ item.faculty?.name_thai }}
    </label>
  </ng-template>
</vru-ng-select-typeahead>