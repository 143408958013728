import { CommonModule } from '@angular/common';
import { TextSignatureComponent } from './text-signature.component';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [TextSignatureComponent],
  exports: [TextSignatureComponent],
  imports: [CommonModule, FormsModule],
})
export class TextSignatureModule {}
