<ng-select bindLabel="label"
    bindValue="value"
    class="select-sort"
    placeholder="แสดงผลเรียงลำดับโดยอัตโนมัติ"
    [items]="sortDropdown"
    [(ngModel)]="orderBy"
    (ngModelChange)="onOrderChange($event)"
    (clear)="onOrderChange()">
  <ng-template ng-option-tmp
      let-item="item">
    <div class="d-flex justify-content-between p-2">
      {{ item.label }}
      <span class="bi" [ngClass]="item.icon"></span>
    </div>
  </ng-template>
</ng-select>