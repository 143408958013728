import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DrfDefaultError, ErrorMessages } from '@vru/master-data';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor() {}

  appendFoundErrorMsg(errDetail: string): string {
    return ErrorMessages.common.foundError + ': ' + errDetail;
  }

  getDrfErrMsg(errors: DrfDefaultError): string[] {
    const msg: string[] = [];
    Object.values(errors).forEach((error) => {
      if (Array.isArray(error)) {
        msg.push(...error);
      } else if (typeof error === 'object') {
        msg.push(...this.getDrfErrMsg(error));
      } else if (typeof error === 'string') {
        msg.push(error);
      } else {
        throw new Error('The error is not DrfDefaultError type');
      }
      return msg as string[];
    });
    return msg;
  }

  getDrfErrMsgHtml(errors: DrfDefaultError) {
    const context = this.getDrfErrMsg(errors).map(
      (msg) => `<li class="mb-1">${msg}</li>`
    );
    const htmlList = `<ul class="col-auto text-left pl-4">${context.join('')}</ul>`;
    return (
      `<h6 style="line-height: 2.25rem">พบข้อผิดพลาด</h6>` +
      `<div class="d-flex justify-content-left">${htmlList}</div>`
    );
  }

  getErrorMsgByVarType(err: any | null): string | null {
    if (err['detail']) {
      return this.appendFoundErrorMsg(err);
    } else if (Array.isArray(err)) {
      return this.handleErrorFile(err);
    } else if (typeof err === 'object') {
      return this.getDrfErrMsgHtml(err);
    }
    return null;
  }

  handleListError(
    errors: { [key: string]: string }[],
    options: ErrorOptions = {
      showIndex: false,
      showAllField: true,
    }
  ): string {
    const errorLineMsg: string[] = [];
    errors.forEach((error, index) => {
      let keys: string[] = [];
      if (options?.showAllField) {
        keys = Object.keys(error);
      } else if (options?.targetKey) {
        keys = [options?.targetKey];
      } else {
        keys = ['detail'];
      }
      keys.forEach((key) => {
        if (error[key] == null) return;
        const rowIndex = options?.showIndex
          ? `${options.indexPrefix ?? ''}[${index + 1}] `
          : '';
        errorLineMsg.push(`<li class="mb-0">${rowIndex}${error[key]}</li>`);
      });
    });
    const context = `${errorLineMsg.join('')}`;
    const htmlList = `<ul class="col-auto text-left pl-4">${context}</ul>`;
    return (
      `<h6 style="line-height: 2.25rem">พบข้อผิดพลาด</h6>` +
      `<div class="d-flex justify-content-left">${htmlList}</div>`
    );
  }

  // TODO: Reuse handleListError method
  handleErrorFile(errors: { [key: string]: string }[]): string {
    const errorLineMsg: string[] = [];
    errors.forEach((error, row) => {
      Object.keys(error).forEach((key) => {
        errorLineMsg.push(
          `<label class="d-block">[${row}] ${error[key]}</label>`
        );
      });
    });
    return (
      '<h6 style="line-height: 2.25rem">พบข้อผิดพลาดดังต่อไปนี้</h6>' +
      '<div class="d-flex justify-content-center">' +
      `<div class="col-auto text-left">${errorLineMsg.join('')}</div></div>`
    );
  }
}

export type ErrorOptions = {
  indexPrefix?: string;
  showIndex?: boolean;
  showAllField?: boolean;
  targetKey?: string;
};
