import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  SkipSelf,
} from '@angular/core';
import {
  API_CONFIG,
  ApiConfig,
  ChatMessage,
  ChatMessageParam,
  ChatRoom,
  ChatService,
} from '@vru/master-data';
import { AlertService, AuthenticationService } from '@vru/services';
import { convertError } from '@vru/utils';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

@Component({
  selector: 'vru-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnChanges, OnInit, OnDestroy {
  @Input() user: number | null = null;
  @Input() chatRoom: ChatRoom | null = null;

  @Output() closeChatRoom = new EventEmitter();

  messages!: string;

  page = 1;
  pageSize = 40;
  isLoading = false;

  sizeClient = 0;
  nextPage = '';
  previousScrollbar = 0;
  selectMessages: number | null = null;
  chatMessageList: ChatMessage[] = [];
  previousChat: ChatMessage[] = [];

  chatWebSocket!: WebSocketSubject<any>;
  CHAT_URL = '';

  constructor(
    @SkipSelf() @Inject(API_CONFIG) private apiConfig: ApiConfig,
    private auth: AuthenticationService,
    private chatService: ChatService,
    private alertService: AlertService,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    const domain = this.apiConfig.apiEndpoint.split('//')[1]
    this.CHAT_URL = `wss://${domain}/ws/chat-rooms/`;
    this.sizeClient = Math.ceil(
      Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      ) / 40
    );
  }

  ngOnDestroy(): void {
    this.chatWebSocket?.unsubscribe();
  }

  ngOnInit(): void {
    this.onSpinnerMessageChat();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chatRoom.currentValue) {
      this.chatWebSocket?.unsubscribe();
      this.page = 1;
      this.pageSize = this.sizeClient;
      this.chatMessageList = [];
      this.previousChat = [];
      this.subscribeChatMessages();
      this.getHistoryChatMessages();
    }
  }

  subscribeChatMessages() {
    this.chatWebSocket = webSocket(
      this.CHAT_URL + `${this.chatRoom?.id}/?uid=${this.auth.accessToken}`
    );
    this.chatWebSocket.asObservable().subscribe({
      next: (res) => {
        this.chatMessageList.unshift(res);
        this.previousScrollbar = 0;
      },
      error: (error) => {
        this.onCloseChatRoom();
        console.error(error);
      },
    });
  }

  getHistoryChatMessages() {
    if (!this.chatRoom) {
      return;
    }
    this.isLoading = true;
    const payload: ChatMessageParam = {
      page: this.page,
      page_size: this.pageSize,
      chat_room: this.chatRoom.id,
    };
    this.chatService.getChatMessageList(payload).subscribe({
      next: (res) => {
        this.nextPage = res.next;
        if (!_.isEqual(this.previousChat, res.results)) {
          this.chatMessageList = res.results;
          this.previousChat = res.results;
        }
        this.isLoading = false;
      },
      error: async (err) => {
        if (err) {
          this.alertService.error(await convertError(err), 5000);
        }
      },
    });
  }

  onSelectMessages(messages: ChatMessage) {
    if (this.selectMessages == messages.id) {
      this.selectMessages = null;
    } else {
      this.selectMessages = messages.id;
    }
  }

  onMessageScrollEnd(event: any) {
    const tracker = event.target;
    const limit = tracker.scrollHeight - tracker.clientHeight;
    const position = -event.target.scrollTop;
    if (position == limit) {
      this.previousScrollbar = position;
      if (this.nextPage) {
        this.pageSize = this.pageSize + this.sizeClient;
      }
      this.isLoading = true;
      this.getHistoryChatMessages();
    }
  }

  onSendChatMessage() {
    if (this.isBlank(this.messages)) {
      return;
    }
    if (!this.chatRoom) {
      return;
    }
    this.chatWebSocket.next({ message: this.messages });
    this.messages = '';
  }

  onCloseChatRoom() {
    this.page = 1;
    this.pageSize = this.sizeClient;
    this.chatMessageList = [];
    this.previousChat = [];
    this.chatWebSocket.unsubscribe();
    this.closeChatRoom.emit();
  }

  isBlank(messages: string | null) {
    return !messages || /^\s*$/.test(messages);
  }

  parseDateLabel(date: string) {
    const splitted = date.split('T');
    const splittedDate = splitted[0].split('-');
    const dateParse =
      splittedDate[2] +
      '/' +
      splittedDate[1] +
      '/' +
      (Number(splittedDate[0]) + 543);
    const splittedTime = splitted[1].split('.')[0];
    return [dateParse, splittedTime];
  }

  onSpinnerMessageChat() {
    this.ngxSpinnerService.show('messages-loading');
  }

  setScrollbarElementBottom() {
    const objDiv = document.getElementById('messages-box');
    if (objDiv) {
      objDiv.scrollTop = -this.previousScrollbar + 1;
    }
  }
}
