import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nArray',
})
export class NArrayPipe implements PipeTransform {
  transform(value: number): number[] {
    return new Array(value).fill(null).map((_, i) => i);
  }
}
