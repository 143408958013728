import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification.component';
import { NgbPaginationModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CarbonIconModule } from '../carbon-icon/carbon-icon.module';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    CommonModule,
    NgbPopoverModule,
    CarbonIconModule,
    NgbPaginationModule,
    NgxSpinnerModule
  ],
  exports: [NotificationComponent],
  declarations: [NotificationComponent],
})
export class NotificationModule { }
