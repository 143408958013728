import { Injectable } from '@angular/core';
import { Primitive } from '@vru/master-data';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  compareTimeStr(timeA: string, timeB: string): number {
    const timeAParts = timeA.split(':').map((time) => Number(time));
    const timeBParts = timeB.split(':').map((time) => Number(time));
    const isValidTimeA = timeAParts.every((part) => !Number.isNaN(part));
    if (!isValidTimeA) {
      throw new Error('The startTime argument is invalid');
    }
    const isValidTimeB = timeBParts.every((part) => !Number.isNaN(part));
    if (!isValidTimeB) {
      throw new Error('The endTime argument is invalid');
    }
    const targetTimeParts =
      timeAParts.length > timeBParts.length ? timeAParts : timeBParts;
    let result = 0;
    targetTimeParts.forEach((_part, inx) => {
      if (timeAParts[inx] || 0 === timeBParts[inx] || 0) {
        return;
      }
      if (timeAParts[inx] || 0 > timeBParts[inx] || 0) {
        result = -1;
        return;
      }
      result = 1;
    });
    return result;
  }

  getStrDates(
    startDate: Date,
    endDate: Date,
    converse: (date: Date) => Primitive = (date: Date) =>
      date.toISOString().split('T')[0]
  ) {
    if (endDate < startDate) {
      const temp = endDate;
      endDate = startDate;
      startDate = temp;
    }
    const maxDateNumber = 366;
    const dates: string[] = [converse(startDate) as string];
    for (let i = 0; i < maxDateNumber; i++) {
      const nextDate = startDate;
      nextDate.setDate(startDate.getDate() + 1);
      if (nextDate < endDate) {
        dates.push(converse(nextDate) as string);
        continue;
      }
      break;
    }
    return dates;
  }

  getStrThaiDateTime(dateTime: string): string {
    const splittedLabel = dateTime.split('T');
    const splittedDate = splittedLabel[0].split('-');
    const splittedTime = splittedLabel[1].split('.');
    return (
      Number(splittedDate[2]) +
      '/' +
      Number(splittedDate[1]) +
      '/' +
      (Number(splittedDate[0]) + 543) +
      ' ' +
      splittedTime[0]
    );
  }

  formatNgbDateToISO(date: NgbDate | null) {
    return date ? date.year + '-' + date.month + '-' + date.day : null;
  }

  isSelectedDateRange(
    date: NgbDate,
    fromDate: NgbDate | null,
    toDate: NgbDate | null,
  ) {
    return date.equals(fromDate) || (toDate && date.equals(toDate))
  }

  isBetween(
    date: NgbDate,
    fromDate: NgbDate | null,
    toDate: NgbDate | null,
  ) {
    return date.after(fromDate) && (toDate && date.before(toDate));
  }

}
