<div class="svg-container"
    [style.lineHeight.px]="size">
  <svg-icon class="{{ presetFilter }}"
      popoverClass="popover-body-dark-gray popover-min-{{ _labelSize }}"
      triggers="mouseenter:mouseleave"
      [ngbPopover]="description != null ? descriptionTemp : null"
      [src]="iconPath + iconName + '.svg'"
      [svgStyle]="{
        'height.px': size,
        'width.px': size,
        'padding-bottom.px': paddingBottom
      }">
  </svg-icon>
  <ng-template #descriptionTemp>
    <label class="text-white text-center w-100 mb-0">
      {{ description }}
    </label>
  </ng-template>
</div>