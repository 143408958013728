import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UtilityService } from '@vru/utils';

@Component({
  selector: 'vru-upload-profile-photo',
  templateUrl: './upload-profile-photo.component.html',
  styleUrls: ['./upload-profile-photo.component.scss'],
})
export class UploadProfilePhotoComponent {
  @Input() canEdit = true;
  @Input() radius = 'none';
  /** Size of image */
  @Input() size = '200px';
  @Input() src?: string | null;
  @Output() photoChange = new EventEmitter<File>();
  @Output() removeClick = new EventEmitter<{ src: string }>();

  photoUrl?: string | null;

  constructor(private util: UtilityService) {}

  onRemoveClick(): void {
    this.photoUrl = null;
    this.removeClick.emit();
  }

  onUploadFileChange(e: Event): void {
    const input = e.target as HTMLInputElement;
    const file = input.files?.[0];
    if (file) {
      this.util.convertImageToUrl(file).subscribe({
        next: (res) => (this.photoUrl = res as string),
      });
      this.photoChange.emit(file);
    }
  }
}
