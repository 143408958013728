import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  AlertMessages,
  BloodType,
  Dropdown,
  DropdownApiService,
  ErrorMessages,
  Father,
  Guardian,
  GuardianType,
  IMasterDataDegrees,
  IMasterDataFaculty,
  IMasterDataNameTitles,
  IMasterDataNationality,
  IMasterDataQualifications,
  IMasterDataRaces,
  IMasterDataReligions,
  IMasterDataStudentGroup,
  IMasterDataStudentTypes,
  MasterDataApiService,
  Mother,
  Nullable,
  Student,
  StudentApiService,
  StudentCreate,
  StudentListParams,
  StudentSection,
  StudentUpdate,
  StudentWithFamily,
  SubDistrict,
  Supporter,
  ThaiLocationService,
  UnitLocation,
} from '@vru/master-data';
import { AlertService, ErrorService, StudentService } from '@vru/services';
import { UtilityService } from '@vru/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { AsyncSubject, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { StudentAccessorComponent } from '../student-accessor/student-accessor.component';
import { StudentInformationConfig } from '../student-information.config';

@Component({
  selector: 'vru-student-profile-form',
  templateUrl: './student-profile-form.component.html',
  styleUrls: ['./student-profile-form.component.scss'],
})
export class StudentProfileFormComponent
  extends StudentAccessorComponent
  implements OnInit
{
  active = 1;
  attachments: File[] = [];
  bloodTypeDropdown!: string[];
  isLoading = false;
  isSaving = false;
  studentTypesModel$!: Observable<IMasterDataStudentTypes[]>;
  nameTitlesModel$!: Observable<IMasterDataNameTitles[]>;
  degreesModel$!: Observable<IMasterDataDegrees[]>;
  facultyModel$!: Observable<IMasterDataFaculty[]>;
  guardianDropdown: Dropdown<GuardianType>[] = [
    { label: 'พ่อ', value: 'father' },
    { label: 'แม่', value: 'mother' },
    { label: 'อื่น ๆ', value: 'other' },
  ];
  genderDropdown = [
    { label: 'ชาย', value: 'male' },
    { label: 'หญิง', value: 'female' },
  ];
  postalCode?: SubDistrict | null;
  qualificationModel$!: Observable<IMasterDataQualifications[]>;
  racesModel$!: Observable<IMasterDataRaces[]>;
  religionsModel$!: Observable<IMasterDataReligions[]>;
  requiredParentKey: (keyof (Father | Mother | Guardian))[] = [
    'first_name',
    'last_name',
    'phone_number',
    'email',
    'income',
  ];
  requiredSupportKey: (keyof Supporter)[] = [
    'first_name',
    'last_name',
    'phone_number',
    'email',
    'income',
  ];
  nationalityModel$!: Observable<IMasterDataNationality[]>;
  otherGuardianForm!: FormGroup;
  provincesModel$!: Observable<UnitLocation[]>;
  programDropdown$!: Observable<Dropdown[]>;
  studentInfo?: Student<'guardian' | 'father' | 'mother' | 'supporter'>;
  studentSectionDropdown$!: Observable<IMasterDataStudentGroup[]>;
  teacherDropdown$!: Observable<Dropdown[]>;
  incomeDropdown$!: Observable<Dropdown[]>;
  educationLevelDropdown$!: Observable<Dropdown[]>;
  campusDropdown$!: Observable<Dropdown[]>;
  schoolDropdown: Dropdown[] = [];
  isSubmitted = false;
  studentPreview: any;
  form!: FormGroup;
  menuConfigs = [
    {
      label: 'ประวัตินักศึกษา',
      path: 'information',
    },
    {
      label: 'ผลการเรียน',
      path: 'grade-record',
    },
    {
      label: 'ปัดรายวิชา',
      path: 'migrate-course',
    },
    {
      label: 'ตรวจสอบการลงทะเบียน',
      path: 'verify-enrollment',
    },
    {
      label: 'ตรวจสอบจบ',
      path: 'verify-graduation',
    },
  ];
  uploadPhoto?: File;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected config: StudentInformationConfig,
    private errorService: ErrorService,
    private fb: FormBuilder,
    private studentApi: StudentApiService,
    private location: Location,
    private masterDataApi: MasterDataApiService,
    private alert: AlertService,
    private dropdownApiService: DropdownApiService,
    protected router: Router,
    private spinner: NgxSpinnerService,
    protected store: Store,
    private student: StudentService,
    private thaiLocationApi: ThaiLocationService,
    private util: UtilityService
  ) {
    super(activatedRoute, router, store);
    this.bloodTypeDropdown = Object.keys(BloodType).filter((key) =>
      key.match(/[^0-9]/)
    );
  }

  ngOnInit(): void {
    this.spinner.show('student-profile-form');
    this.checkActivatedRoute();
    this.initializeForm();
    this.getDropdown();
  }

  changeFormForGuardianView(): void {
    this.form.disable();
    const parentKeys = ['father', 'mother', 'guardian'] as const;
    parentKeys.forEach((key) => {
      const parent = this.studentInfo?.[key];
      if (parent?.profile === this.user.profile) {
        this.form.get(key)?.enable();
        this.form.get('address_current')?.enable();
        this.form.get('address_id_card')?.enable();
      }
    });
    this.form.get('guardian_type')?.enable();
  }

  checkActivatedRoute(): void {
    const lastPath = [...this.activatedRoute.pathFromRoot].pop();
    lastPath?.url.subscribe({
      next: (res) => {
        if (res[0].path !== 'create') {
          this.fetchStudentId();
        }
      },
    });
  }

  checkAndUpdateParentValidate(formGroup: FormGroup): void {
    const isEmpty = this.checkParentFormEmpty(formGroup.value);
    if (isEmpty) {
      this.clearParentValidators(formGroup);
    } else {
      this.setParentValidators(formGroup);
    }
  }

  checkParentFormEmpty(parent: Father | Mother | Guardian): boolean {
    return this.requiredParentKey.every(
      (name) => parent[name] == null || parent[name] === ''
    );
  }

  checkSupportFormEmpty(supporter: Supporter): boolean {
    return this.requiredSupportKey.every(
      (name) => supporter[name] != null || supporter[name] !== ''
    );
  }

  clearParentValidators(parentForm: FormGroup): void {
    const ctrlNames = this.requiredParentKey;
    ctrlNames.forEach((field) => {
      const control = parentForm.get(field) as FormControl;
      control.setValidators(null);
      control.updateValueAndValidity({ emitEvent: false });
    });
  }

  createStudent(payload: StudentCreate): Observable<Student> {
    const subject = new AsyncSubject<Student>();
    this.studentApi.create(payload).subscribe(subject);
    subject.subscribe({
      error: (err) => {
        if (typeof err.error === 'object') {
          const errorHtml = this.errorService.getDrfErrMsgHtml(err.error);
          this.alert.error(errorHtml);
          return;
        }
        this.alert.error(ErrorMessages.common.plsCheckOrAdmin);
      },
    });
    return subject;
  }

  getRequestForm(): Omit<StudentUpdate, 'photo'> {
    const formValue = this.form.value;
    // Modifying for a request
    delete formValue.photo;
    if (formValue.address_current?.sub_district) {
      formValue.address_current.sub_district =
        formValue.address_current.sub_district.id;
    }
    const student: Omit<StudentUpdate, 'photo'> = formValue;
    // Fill default value according to field name from the form
    const defaultValues: { [k in keyof typeof student]?: any } = {
      address_id_card: '',
      special_interest: '',
      previous_education_level: '',
      previous_education_level_name: '',
      previous_field_of_study: '',
      previous_academy_gpax: null,
    };
    Object.keys(defaultValues).forEach((key) => {
      if (key in student) {
        (student as Params)[key] ||=
          defaultValues[key as keyof typeof defaultValues];
      }
    });
    // Remove some field because of UI constrain.
    const removeInPerson = (
      person?: Partial<Father | Mother | Guardian | Supporter> | null
    ) => {
      if (person == null) return;
      if ('marital_status' in person) delete person.marital_status;
      if ('status' in person) delete person.status;
    };
    removeInPerson(student.father);
    removeInPerson(student.mother);
    removeInPerson(student.guardian);
    removeInPerson(student.supporter);
    // ---
    const supporter = student.supporter as Supporter;
    if (supporter) {
      const isEmpty = this.checkSupportFormEmpty(supporter);
      if (isEmpty) {
        student.supporter = null;
      }
    }
    const parentKeys = ['father', 'mother', 'guardian'] as const;
    parentKeys.forEach((guardianType) => {
      const guardian = student?.[guardianType as keyof typeof student] as
        | Father
        | Mother
        | Guardian
        | undefined;
      if (!guardian) return;
      const isEmpty = this.checkParentFormEmpty(guardian);
      if (isEmpty) {
        student[guardianType] = null;
      }
    });
    if (['father', 'mother'].includes(formValue.guardian_type)) {
      student.guardian = null;
    }
    return student;
  }

  getQueryById(id: number) {
    this.isLoading = true;
    const expand = ['guardian', 'father', 'mother', 'supporter'] as const;
    const params: StudentListParams = { expand: [...expand] };
    this.studentApi.getDetail<typeof expand[number]>(id, params).subscribe({
      next: (data) => {
        this.studentInfo = data;
        this.postalCode = data.address_current?.sub_district;
        this.form.patchValue(data);
        this.form.get('campus')?.patchValue(this.studentInfo.campus?.id);
        const formArray = this.fb.array(data.name_of_brothers_sisters || []);
        this.form.setControl('name_of_brothers_sisters', formArray);
        const multiFileFormArray = this.fb.array(
          data.student_file_uploads || []
        );
        this.form.setControl('student_file_uploads', multiFileFormArray);
        this.patchValidatorByStudentType();
        this.enableFormByRole();
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        this.alert.errorWithContactAdmin();
        this.isLoading = false;
        this.onBack();
      },
    });
  }

  patchValidatorByStudentType(): void {
    if (this.studentInfo?.is_credit_bank_student === true) {
      const disableFieldValidators = [
        'student_section',
        'degree',
        'faculty',
        'program',
        'qualification',
        'college_year',
      ];
      disableFieldValidators.forEach((field) => {
        const control = this.form.get(field) as FormControl;
        control.setValidators(null);
        control.updateValueAndValidity();
      });
    }
  }

  rejectAccessStudentId(): void {
    this.onBack();
  }

  resolveAccessStudentId(studentId: number): void {
    this.getQueryById(studentId);
  }

  onBack(): void {
    this.location.back();
  }

  onPhotoChange(file: File): void {
    this.uploadPhoto = file;
  }

  onPostalCodeChange(subDistrict?: SubDistrict | null): void {
    this.form.get('address_current')?.patchValue({ sub_district: subDistrict });
  }

  onSubDistrictChange(subDistrict?: SubDistrict | null): void {
    this.postalCode = subDistrict;
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      this.alert.error(AlertMessages.validation.plsFillValid);
      return;
    }
    const payload = this.getRequestForm();
    this.isSaving = true;
    (this.studentId ? this.updateStudent(payload) : this.createStudent(payload))
      .pipe(
        switchMap((res) => {
          this.studentId = res.id;
          const optionalSave = [this.saveAttachments()];
          if (this.uploadPhoto) {
            optionalSave.push(this.savePhoto());
          }
          return forkJoin(optionalSave);
        })
      )
      .subscribe({
        next: () => {
          this.alert.success(AlertMessages.success.save);
          this.isSaving = false;
          this.onBack();
        },
        error: () => {
          this.isSaving = false;
        },
      });
  }

  savePhoto(): Observable<Student | null> {
    if (!this.uploadPhoto) {
      return of(null);
    }
    if (!this.studentId) {
      throw new Error('Cannot save photo without id of student');
    }
    const form = new FormData();
    form.append('photo', this.uploadPhoto);
    const subject = new AsyncSubject<Student>();
    this.studentApi.patch(this.studentId, form).subscribe(subject);
    subject.subscribe({
      error: () => {
        this.alert.errorWithContactAdmin();
      },
    });
    return subject;
  }

  getDropdown() {
    // TODO: Refactor dropdown
    this.studentTypesModel$ = this.masterDataApi.getDropdownStudentTypes();
    this.degreesModel$ = this.masterDataApi.getDropdownDegrees();
    this.facultyModel$ = this.masterDataApi.getDropdownFaculty();
    this.racesModel$ = this.masterDataApi.getDropdownRaces();
    this.nationalityModel$ = this.masterDataApi.getDropdownNationality();
    this.religionsModel$ = this.masterDataApi.getDropdownReligion();
    this.qualificationModel$ = this.masterDataApi.getDropdownQualifications();
    this.nameTitlesModel$ = this.masterDataApi.getDropdownNameTitles();
    this.provincesModel$ = this.thaiLocationApi
      .getProvinces()
      .pipe(map((res) => res.results));
    this.programDropdown$ = this.dropdownApiService.getDropdown('program');
    this.studentSectionDropdown$ = this.masterDataApi.getDropdownStudentGroup();
    this.teacherDropdown$ = this.dropdownApiService.getDropdown('teacher');
    this.incomeDropdown$ = this.dropdownApiService.getDropdown(
      'student_family_income',
      { order_by: 'null' }
    );
    this.educationLevelDropdown$ = this.dropdownApiService.getDropdown(
      'education_level',
      { order_by: 'null' }
    );
    this.campusDropdown$ = this.dropdownApiService.getDropdown('campus', {
      order_by: 'null',
    });
    this.dropdownApiService.getPublicDropdown('school').subscribe({
      next: (response) => (this.schoolDropdown = response),
    });
  }

  addItem() {
    this.siblingFormArray.controls.push(this.formNameOfBrothersSisters);
  }

  deleteItem(i: number) {
    this.siblingFormArray.removeAt(i);
  }

  getFileFormArray(): FormArray {
    return this.form.get('student_file_uploads') as FormArray;
  }

  deleteFile(index: number, fileID: number) {
    this.alert.confirm(
      'ลบไฟล์เอกสารแนบ',
      'ท่านต้องการลบไฟล์เอกสารแนบนี้ใช่หรือไม่ ?',
      'warning',
      'ยืนยัน',
      'ยกเลิก'
    ).then((res) => {
      if (res.value) {
        if (!fileID) {
          this.alert.success('ลบไฟล์ที่' + ' ' + (index + 1) + ' ' + 'สำเร็จ');
          this.getFileFormArray().removeAt(index);
          return
        }
        this.studentApi
          .deleteFile(fileID)
          .pipe()
          .subscribe(
            () => {
              this.alert.success('ลบไฟล์ที่' + ' ' + (index + 1) + ' ' + 'สำเร็จ');
              this.getFileFormArray().removeAt(index);
            },
            () => {
              this.alert.error('ลบไฟล์ที่' + ' ' + (index + 1) + ' ' + 'ไม่สำเร็จ');
            }
          );
      }
    });
  }

  enableFormByRole(): void {
    if (this.role === 'student') {
      this.form.get('first_name_thai')?.disable();
      this.form.get('last_name_thai')?.disable();
      this.form.get('code')?.disable();
      this.form.get('student_section')?.disable();
      this.form.get('college_year')?.disable();
      this.form.get('campus')?.disable();
      this.form.get('id_card')?.disable();
    } else if (this.role === 'guardian') {
      this.changeFormForGuardianView();
    } else {
      this.form.get('first_name_thai')?.enable();
      this.form.get('last_name_thai')?.enable();
      this.form.get('code')?.enable();
      this.form.get('student_section')?.enable();
      this.form.get('college_year')?.enable();
      this.form.get('campus')?.enable();
      this.form.get('id_card')?.enable();
    }
  }

  checkFileSize(file: File) {
    if (file != null && file.size / (1024 * 1024) >= 30) {
      this.alert.error('กรุณาอัพโหลดไฟล์ขนาดไม่เกิน 30MB');
      return false;
    }
    return true;
  }

  saveAttachments(): Observable<unknown | null> {
    if (!this.attachments.length) {
      return of(null);
    }
    const subject = new AsyncSubject();
    this.studentApi
      .uploadFile(this.studentId, this.attachments)
      .subscribe(subject);
    subject.subscribe({
      error: () => {
        this.alert.error(ErrorMessages.common.errUploadFile);
      },
    });
    return subject;
  }

  selectFile(fileInput: Event) {
    const fileList = (fileInput.target as HTMLInputElement).files as FileList;
    this.attachments = this.util.fileListToArr(fileList);
    for (const item of this.attachments) {
      this.fileLength.insert(this.fileLength.length, this.fb.control(item));
    }
  }

  onChangeTab(click: number) {
    this.active = click;
  }

  initializeForm(student?: Nullable<StudentWithFamily>): void {
    const initData: Partial<StudentWithFamily> = {
      congenital_disease: '',
      disability: '',
      medical_history: '',
      blood_type: '',
      college_year: '',
    };
    this.form = this.student.buildStudentForm({
      ...initData,
      ...(student || {}),
      guardian_type: student?.guardian_type || 'other',
    });
    // Fix value because of UI constrain.
    this.form.patchValue(
      {
        guardian: { address_selected: 'address_current' },
        father: { address_selected: 'address_current' },
        mother: { address_selected: 'address_current' },
        supporter: { address_selected: 'address_current' },
      },
      { emitEvent: false }
    );
    // ---
    this.otherGuardianForm = this.guardianForm;
    this.setParentValidators(this.otherGuardianForm);
    this.setGuardianTypeValueChange();
    this.setParentValueChange();
    this.enableFormByRole();
  }

  setGuardianTypeValueChange(): void {
    const ctrl = this.form.get('guardian_type') as FormControl;
    ctrl.valueChanges.subscribe({
      next: (val) => {
        if (val === 'other') {
          this.form.setControl('guardian', this.otherGuardianForm);
          return;
        } else if (val === 'father') {
          this.form.setControl('guardian', this.fatherForm);
        } else if (val === 'mother') {
          this.form.setControl('guardian', this.motherForm);
        }
      },
    });
  }

  onChangeStudentSection(event: Required<StudentSection>) {
    if (event) {
      this.teacherDropdown$ = this.dropdownApiService.getDropdown('teacher', {
        student_section: event.id,
      });
      this.form.patchValue({
        degree: event.degree,
        faculty: event.faculty,
        qualification: event.qualification,
        program: event.program,
        student_type: event.student_type,
        start_academic_year: event.start_academic_year,
        advisors: event.advisors,
      });
    } else {
      this.form.get('degree')?.reset();
      this.form.get('faculty')?.reset();
      this.form.get('qualification')?.reset();
      this.form.get('program')?.reset();
      this.form.get('student_type')?.reset();
      this.form.get('start_academic_year')?.reset();
    }
  }

  onSelectGuardianStatus(event: Dropdown<string>) {
    switch (event.value) {
      case 'father':
        this.checkAndUpdateParentValidate(this.motherForm);
        this.setParentValidators(this.fatherForm);
        break;
      case 'mother':
        this.checkAndUpdateParentValidate(this.fatherForm);
        this.setParentValidators(this.motherForm);
        break;
      case 'other':
        this.checkAndUpdateParentValidate(this.fatherForm);
        this.checkAndUpdateParentValidate(this.motherForm);
        break;
      default:
        break;
    }
  }

  setParentValidators(parentForm: FormGroup): void {
    const ctrlNames = this.requiredParentKey;
    ctrlNames.forEach((name) => {
      const control = parentForm.get(name) as FormControl;
      control.setValidators(Validators.required);
      control.updateValueAndValidity({ emitEvent: false });
    });
  }

  onSupporterChange(): void {
    const supporter = this.supporterForm.value;
    const targetControls: (keyof Supporter)[] = [
      'address',
      'first_name',
      'last_name',
      'phone_number',
      'income',
      'occupation',
    ];
    const isEmpty = targetControls.every(
      (key) => supporter[key] == null || supporter[key] === ''
    );
    if (!isEmpty) {
      targetControls.forEach((key) => {
        const ctrl = this.supporterForm.get(key) as FormControl;
        const validators = [];
        const lessValidators =
          (ctrl.validator?.length || 0) < 1 + (key === 'phone_number' ? 1 : 0);
        if (lessValidators) {
          validators.push(Validators.required);
          if (key === 'phone_number') validators.push(Validators.maxLength(10));
        }
        if (validators.length) {
          ctrl.setValidators(validators);
          ctrl.updateValueAndValidity();
        }
      });
      return;
    }
    targetControls.forEach((key) => {
      const ctrl = this.supporterForm.get(key) as FormControl;
      if (!ctrl.validator?.length) {
        return;
      }
      ctrl.clearValidators();
      ctrl.updateValueAndValidity();
    });
  }

  setParentValueChange(): void {
    if (!this.motherForm || !this.fatherForm) {
      throw new Error('There is no mother or father form');
    }
    this.requiredParentKey.forEach((name) => {
      const formObj = {
        mother: this.motherForm,
        father: this.fatherForm,
      };
      Object.keys(formObj).forEach((type) => {
        const formGroup = formObj[type as keyof typeof formObj] as FormGroup;
        const control = formGroup.get(name) as FormControl;
        control?.valueChanges.subscribe({
          next: (val) => {
            const form = { ...formGroup.value, [name]: val };
            if (this.guardianTypeForm.value === type) return;
            const isEmpty = this.checkParentFormEmpty(form);
            if (isEmpty) {
              this.clearParentValidators(formGroup);
            } else {
              this.setParentValidators(formGroup);
            }
          },
        });
      });
    });
  }

  updateStudent(payload: Partial<StudentUpdate>): Observable<Student> {
    const subject = new AsyncSubject<Student>();
    this.studentApi.patch(this.studentId, payload).subscribe(subject);

    subject.subscribe({
      error: (err) => {
        if (typeof err.error === 'object') {
          const errorHtml = this.errorService.getDrfErrMsgHtml(err.error);
          this.alert.error(errorHtml);
          return;
        }
        this.alert.error(ErrorMessages.common.plsCheckOrAdmin);
      },
    });
    return subject;
  }

  onSelectProvince(event: any): void {
    const academicValue = event as Dropdown;
    if (!academicValue.context.province) {
      return;
    }
    this.form.controls.province.patchValue(academicValue.context.province.id);
  }

  checkRequiredField(control: string): boolean {
    const abstractControl = this.form.get(control) as AbstractControl
    if (abstractControl.validator) {
      const validator = abstractControl.validator( {} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }

  get isGuardian(): boolean {
    let guardian = false;
    const parentKeys = ['father', 'mother', 'guardian'] as const;
    parentKeys.forEach((key) => {
      const parent = this.studentInfo?.[key];
      if (parent?.profile === this.user.profile) {
        guardian = true
      }
    });
    return guardian
  }

  get guardianForm(): FormGroup {
    return this.form.get('guardian') as FormGroup;
  }

  get guardianTypeForm(): FormControl {
    return this.form.get('guardian_type') as FormControl;
  }

  get fatherForm(): FormGroup {
    return this.form.get('father') as FormGroup;
  }

  get fileLength(): FormArray {
    return this.form.get('student_file_uploads') as FormArray;
  }

  get formNameOfBrothersSisters() {
    return this.fb.control(null);
  }

  get motherForm(): FormGroup {
    return this.form.get('mother') as FormGroup;
  }

  get siblingFormArray(): FormArray {
    return this.form.get('name_of_brothers_sisters') as FormArray;
  }

  get supporterForm(): FormGroup {
    return this.form.get('supporter') as FormGroup;
  }
}
