import { Injectable } from '@angular/core';
import { ApiService, DrfList } from '@vru/master-data';
import { Observable } from 'rxjs';
import {
  TQF4ApprovalModel,
  TQF4CloneParams,
  Tqf4ListItem,
  TQF4Params,
  TQF4POSTModel,
  TQF4RejectReasonModel,
} from './tqf4.model';

@Injectable({
  providedIn: 'root',
})
export class Tqf4ApiService {
  private readonly tqf4Url = '/tqf4-teachers/';

  constructor(private apiService: ApiService) {}

  approve(id: number, params: TQF4ApprovalModel | null) {
    return this.apiService.post(this.tqf4Url + `${id}/approve/`, params);
  }

  clone(id: number, params: TQF4CloneParams) {
    return this.apiService.post(this.tqf4Url + `${id}/clone/`, params);
  }

  create(data: TQF4POSTModel): Observable<TQF4POSTModel> {
    return this.apiService.post<TQF4POSTModel>(this.tqf4Url, data);
  }

  delete(id: number): Observable<number> {
    return this.apiService.delete<number>(this.tqf4Url + `${id}/`);
  }

  getList(params?: TQF4Params): Observable<DrfList<Tqf4ListItem>> {
    return this.apiService.get<DrfList<Tqf4ListItem>>(this.tqf4Url, params);
  }

  getDetail(id: number, params?: TQF4Params): Observable<TQF4ApprovalModel> {
    return this.apiService.get<TQF4ApprovalModel>(
      this.tqf4Url + `${id}/`,
      params
    );
  }

  getTqf4File(id: number, type: 'pdf' | 'word') {
    return this.apiService.postBlob(this.tqf4Url + `${id}/${type}`);
  }

  patch(id: number, data: TQF4POSTModel): Observable<TQF4POSTModel> {
    return this.apiService.patch<TQF4POSTModel>(this.tqf4Url + `${id}/`, data);
  }

  reject(id: number, params: TQF4RejectReasonModel) {
    return this.apiService.post(this.tqf4Url + `${id}/reject/`, params);
  }

  revise(id: number, params: TQF4ApprovalModel | null) {
    return this.apiService.post(this.tqf4Url + `${id}/revise/`, params);
  }

  submitDraft(id: number, params: TQF4ApprovalModel | null) {
    return this.apiService.post(this.tqf4Url + `${id}/submit/`, params);
  }
}
