<vru-ng-select-typeahead ngbTooltip="ค้นหาด้วยรหัสหรือชื่อวิชา"
    [bindLabel]="bindLabel"
    [clearable]="clearable"
    [loading]="isLoading"
    [dataApiGenerator]="dataApiGenerator"
    [openDelay]="300"
    [readonly]="readonly"
    [(ngModel)]="selectedStudent"
    (ngModelChange)="onValueChange($event)">
  <ng-template vruNgSelectOptionDirective
      let-item>
    <div class="d-flex justify-content-between">
      <span>{{ item.context.code }}</span>
      <span>
        {{ item.context.credit }}
        ({{ item.context.theory_hour + '-' + item.context.practice_hour + '-' + item.context.self_learning_hour }})
      </span>
      <span>ปี {{ item.context.year }}</span>
    </div>
    <div class="line-height-fit">
      <small>{{ item.label }}</small>
    </div>
    <div class="line-height-fit">
      <small>{{ item?.name_eng }}</small>
    </div>
  </ng-template>
</vru-ng-select-typeahead>