import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { DrfList } from '../common.model';
import {
  PaymentPaidDebt,
  PaymentStudentDebt,
  PaymentStudentDebtParams,
  PaymentStudentDebtReportParams,
  PaymentUnpaidDebt,
} from './payment-debt.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentDebtService {
  private readonly paymentStudentDebtUrl = '/student-debts/';
  private readonly paymentStudentDebtReportUrl =
    '/student-payments/export-student-debt/';
  private readonly paymentUnpaidUrl = '/unpaid-student-fees/';
  private readonly paymentPaidUrl = '/paid-student-fees/';

  constructor(private apiService: ApiService) {}

  getListOfPaymentStudentDebt(
    params?: PaymentStudentDebtParams
  ): Observable<DrfList<PaymentStudentDebt>> {
    return this.apiService.get<DrfList<PaymentStudentDebt>>(
      this.paymentStudentDebtUrl,
      params
    );
  }

  getListOfUnpaidPaidPaymentStudentDebt(
    params?: PaymentStudentDebtParams
  ): Observable<DrfList<PaymentUnpaidDebt>> {
    return this.apiService.get<DrfList<PaymentUnpaidDebt>>(
      this.paymentUnpaidUrl,
      params
    );
  }

  getListOfPaidPaymentStudentDebt(
    params?: PaymentStudentDebtParams
  ): Observable<DrfList<PaymentPaidDebt>> {
    return this.apiService.get<DrfList<PaymentPaidDebt>>(
      this.paymentPaidUrl,
      params
    );
  }

  getListOfPaymentStudentDebtById(id: number): Observable<PaymentStudentDebt> {
    return this.apiService.get<PaymentStudentDebt>(
      this.paymentStudentDebtUrl + `${id}/`
    );
  }

  getReportOfPaymentStudentDebt(
    params: PaymentStudentDebtReportParams
  ): Observable<File> {
    return this.apiService.getBlob(this.paymentStudentDebtReportUrl, params);
  }
}
