import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'buddhaDate',
})
export class BuddhaDatePipe extends DatePipe implements PipeTransform {
  transform(value: Date | string | number, format?: string): string | null;
  transform(value: null | undefined, format?: string): null;
  transform(
    value: string | number | Date | undefined | null,
    format?: string
  ): string | null {
    if (!value) return null;
    const date = new Date(value);
    const dateStr = date.toISOString();
    const dateTimeParts = dateStr.split('T');
    const dateParts = dateTimeParts[0].split('-');
    dateParts[0] = (+dateParts[0] + 543).toString();
    dateTimeParts[0] = dateParts.join('-');
    return super.transform(dateTimeParts.join('T'), format);
  }
}
