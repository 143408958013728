import { Injectable } from '@angular/core';
import { ApiService, DrfList } from '@vru/master-data';
import { Observable } from 'rxjs';
import { FieldOfStudy, FieldOfStudyParams } from './field-of-study.model';

@Injectable({
  providedIn: 'root',
})
export class FieldOfStudyService {
  private readonly fieldOfStudyPath = '/field-of-studies/';

  constructor(private api: ApiService) {}

  getList(params?: FieldOfStudyParams): Observable<DrfList<FieldOfStudy>> {
    return this.api.get<DrfList<FieldOfStudy>>(this.fieldOfStudyPath, params);
  }

  getDetail(id: number, params?: FieldOfStudyParams): Observable<FieldOfStudy> {
    return this.api.get<FieldOfStudy>(this.fieldOfStudyPath + `${id}`, params);
  }

  post(payload: Omit<FieldOfStudy, 'id'>): Observable<FieldOfStudy> {
    return this.api.post<FieldOfStudy>(this.fieldOfStudyPath, payload);
  }

  patch(id: number, payload: Partial<FieldOfStudy>): Observable<FieldOfStudy> {
    return this.api.patch<FieldOfStudy>(
      this.fieldOfStudyPath + `${id}/`,
      payload
    );
  }

  delete(id: number): Observable<void> {
    return this.api.delete(this.fieldOfStudyPath + `${id}/`);
  }
}
