import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../api/api.service';
import { DrfList } from '../../common.model';
import { Teacher, TeacherExpand, TeacherWrite, TeachingHour } from './teacher.model';
import { TeacherPayload } from './teacher.model';

@Injectable({
  providedIn: 'root',
})
export class TeacherApiService {
  teacherPath = '/teachers/';

  constructor(private http: ApiService) {}

  getTeachers<T extends TeacherExpand | void>(params?: any, id?: number) {
    return this.http.get<DrfList<Teacher<T>> | Teacher>(
      `/teachers/${id ? id + '/' : ''}`,
      params
    );
  }

  getTeachersByID<T extends TeacherExpand | void>(id: number, params?: {expand: string}) {
    return this.http.get<Teacher<T>>(
      `/teachers/${id}/`,
      params
    );
  }

  getPublicTeachers(params?: any) {
    return this.http.get<DrfList<Teacher> | Teacher>(
      `/teachers/information/`,
      params
    );
  }

  getTeachingHours(params: any): Observable<TeachingHour[]> {
    return this.http.get(this.teacherPath + 'teaching-hours/', params);
  }

  deleteTeachers(id?: number) {
    return this.http.delete<DrfList<any>>(`/teachers/${id ? id + '/' : ''}`);
  }

  patchTeachers(id: number, payload: any) {
    return this.http.patch(`/teachers/${id}/`, payload);
  }

  putTeachers(id: number, payload: any) {
    return this.http.put(`/teachers/${id}/`, payload);
  }

  postTeachers(payload: TeacherWrite) {
    return this.http.post<Teacher>(`/teachers/`, payload);
  }

  resetPassword(payload: any) {
    return this.http.post<TeacherPayload>(
      `/back-office-reset-password-for-teacher/`,
      payload
    );
  }
}
