import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  StudentApiService,
  StudentGraduateQualification,
  UserProfile,
} from '@vru/master-data';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class GraduateQualificationResolver
  implements
    Resolve<StudentGraduateQualification | { error: HttpErrorResponse }>
{
  constructor(private store: Store, private student: StudentApiService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<StudentGraduateQualification | { error: HttpErrorResponse }> {
    const id = this.studentId || route?.params?.id || this.guardianStudentId;
    return this.getGraduateQualification(id);
  }

  getGraduateQualification(
    studentId: number
  ): Observable<StudentGraduateQualification | { error: HttpErrorResponse }> {
    return this.student.getGraduateQualification(studentId).pipe(
      map((res) => {
        if (!res) {
          const err = new Error('There is no record that may be caused by');
          err.name = 'mayBeNoStudyPlan';
          throw err;
        }
        return res;
      }),
      catchError((err) => of({ error: err }))
    );
  }

  get selfUser(): UserProfile {
    return this.store.selectSnapshot((state) => state.auth);
  }
  get guardianStudentId(): number | undefined {
    const user = this.selfUser;
    if (user && user.role === 'guardian') {
      return user.context['guardian']?.student_id;
    }
    return;
  }

  get studentId(): number | undefined {
    const user = this.selfUser;
    if (user && user.role === 'student') {
      return user.context['student']?.id;
    }
    return;
  }

  get creditBankStudentId(): number | undefined {
    const user = this.selfUser;
    if (user && user.role === 'credit_bank_student') {
      return user.context['credit_bank_student']?.student_id;
    }
    return;
  }
}
