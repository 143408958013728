import {
  Directive,
  DoCheck,
  Input,
  IterableDiffer,
  IterableDiffers,
  KeyValueDiffer,
  KeyValueDiffers,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  SkipSelf,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { RoleGroup } from '@vru/master-data';
import { DisplayPermissionService } from '@vru/services';

@Directive({
  selector: '[vruDisplayPermission]',
})
export class DisplayPermissionDirective implements OnChanges, OnInit, DoCheck {
  @Input('vruDisplayPermission') permissive?:
    | string
    | string[]
    | { [k: string]: any }
    | null;
  private _permissive: { [role: string]: boolean } = {};
  private groupNames: string[] = [];
  private iterableDiffer!: IterableDiffer<string> | null;
  private kvDiffer!: KeyValueDiffer<string, boolean> | null;

  constructor(
    @Optional() @SkipSelf() private permission: DisplayPermissionService,
    private iterableDiffers: IterableDiffers,
    private kvDiffers: KeyValueDiffers,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    if (permission?.roleGroups) {
      this.groupNames = Object.keys(
        permission.roleGroups
      ) as RoleGroup[];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('permissive' in changes) {
      this.iterableDiffer = null;
      this.kvDiffer = null;
      if (Array.isArray(this.permissive)) {
        this.iterableDiffer = this.iterableDiffers
          .find(this.permissive)
          .create();
      } else if (this.permissive && typeof this.permissive === 'object') {
        this.kvDiffer = this.kvDiffers.find(this.permissive).create();
      }
      this.updatePermissive();
      this.updateView();
    }
  }

  ngOnInit(): void {
    this.updateView();
  }

  ngDoCheck(): void {
    if (this.iterableDiffer) {
      const changes = this.iterableDiffer.diff(this.permissive as string[]);
      if (changes) {
        this.updateView();
      }
    } else if (this.kvDiffer) {
      const changes = this.kvDiffer.diff(
        this.permissive as { [k: string]: boolean }
      );
      if (changes) {
        this.updateView();
      }
    }
  }

  private updatePermissive(): void {
    let roleKeys: readonly string[] = [];
    this._permissive = {};
    const retrieveRoles = (roleKey: string): readonly string[] => {
      const roleGroup = roleKey.toLowerCase();
      return this.groupNames.includes(roleGroup)
        ? this.permission?.roleGroups[roleGroup]
        : [];
    };
    if (typeof this.permissive === 'string') {
      roleKeys = [this.permissive];
    } else if (Array.isArray(this.permissive)) {
      roleKeys = this.permissive as string[];
    } else if (this.permissive && typeof this.permissive === 'object') {
      roleKeys = Object.keys(this.permissive).filter(
        (key) => (this.permissive as { [k: string]: string })[key]
      );
    }
    if (roleKeys.length > 0) {
      roleKeys.forEach((roleKey) => {
        const isRoleGroup = /^[A-Z]/.test(roleKey);
        if (!isRoleGroup) {
          this._permissive[roleKey] = true;
          return;
        }
        const roles = retrieveRoles(roleKey);
        roles.forEach((role) => {
          this._permissive[role] = true;
        });
      });
    }
  }

  private updateView() {
    this.viewContainer.clear();
    if (this._hasPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private _hasPermission(): boolean | null {
    if (this.permission == null) {
      throw new Error('Not found permission source for checking permissive');
    }
    this.updatePermissive();
    return (
      !!this._permissive[this.permission.selfPermission] ||
      this.permission.canAccept
    );
  }
}
